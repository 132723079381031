import React, { useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import Api from './lib/api/api';

class PrivateRoute extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            authenticationChecked: false,
            isAuthenticated: false
        }
    }

    componentDidMount() {
        const token = localStorage.getItem('Authorization');

        Api.LoginNux(token).then((_resOne) => {
            let _resultOne = _resOne.data;
            if (_resultOne["code"] === 200) {
                this.setState({ isAuthenticated: true, authenticationChecked: true })
            } else {
                this.setState({ isAuthenticated: false, authenticationChecked: true })
            }
        }).catch((error) => {
            this.setState({ isAuthenticated: false, authenticationChecked: true })
        });
    }

    render() {
        const { component: Component, ...rest } = this.props
        if (!this.state.authenticationChecked) return null;
        return (
            <Route
                {...rest}
                render={props =>
                    this.state.isAuthenticated ? (
                        <Component {...props} {...rest} />
                    ) : (
                        <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                    )
                }
            />
        )
    }
}


export default PrivateRoute;