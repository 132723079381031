// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HomeNew_body__DZ3Xh {
    display: flex;
}

.HomeNew_menu__lDmHT {
    min-width: 300px;
    background: #FFFFFF;
    height: 100vh;
    padding-top: 20px;
}

.HomeNew_content__p1nyq {
    flex: 1 1;
    padding: 30px;
}

/* .logoutButtonContainer {
    width: 100%;
} */
.HomeNew_logoutButton__Jz5PY {
    margin: auto;
    margin-top: 30px;
    background: #5C2D91;
    height: 40px;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;

    border-radius: 15px;
}

.HomeNew_logoutButton__Jz5PY:hover {
    cursor: pointer;
}


@media only screen and (max-width: 600px) {
    .HomeNew_body__DZ3Xh {
        display: block;
    }

    .HomeNew_menu__lDmHT {
        height: auto;
        border-bottom: 1px solid #000;
        overflow: hidden;
        padding-bottom: 8px;
    }

    .HomeNew_menu__lDmHT .HomeNew_logoutButton__Jz5PY {
        margin-top: 8px;
    }

    .HomeNew_content__p1nyq {
        padding: 0px;
    }
}`, "",{"version":3,"sources":["webpack://./src/HomeNew/HomeNew.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,aAAa;IACb,iBAAiB;AACrB;;AAEA;IACI,SAAO;IACP,aAAa;AACjB;;AAEA;;GAEG;AACH;IACI,YAAY;IACZ,gBAAgB;IAChB,mBAAmB;IACnB,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,cAAc;;IAEd,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB;;;AAGA;IACI;QACI,cAAc;IAClB;;IAEA;QACI,YAAY;QACZ,6BAA6B;QAC7B,gBAAgB;QAChB,mBAAmB;IACvB;;IAEA;QACI,eAAe;IACnB;;IAEA;QACI,YAAY;IAChB;AACJ","sourcesContent":[".body {\n    display: flex;\n}\n\n.menu {\n    min-width: 300px;\n    background: #FFFFFF;\n    height: 100vh;\n    padding-top: 20px;\n}\n\n.content {\n    flex: 1;\n    padding: 30px;\n}\n\n/* .logoutButtonContainer {\n    width: 100%;\n} */\n.logoutButton {\n    margin: auto;\n    margin-top: 30px;\n    background: #5C2D91;\n    height: 40px;\n    width: 200px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    color: #FFFFFF;\n\n    border-radius: 15px;\n}\n\n.logoutButton:hover {\n    cursor: pointer;\n}\n\n\n@media only screen and (max-width: 600px) {\n    .body {\n        display: block;\n    }\n\n    .menu {\n        height: auto;\n        border-bottom: 1px solid #000;\n        overflow: hidden;\n        padding-bottom: 8px;\n    }\n\n    .menu .logoutButton {\n        margin-top: 8px;\n    }\n\n    .content {\n        padding: 0px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": `HomeNew_body__DZ3Xh`,
	"menu": `HomeNew_menu__lDmHT`,
	"content": `HomeNew_content__p1nyq`,
	"logoutButton": `HomeNew_logoutButton__Jz5PY`
};
export default ___CSS_LOADER_EXPORT___;
