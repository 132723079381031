// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HealthStationNew_content__7V0MT {

}

.HealthStationNew_selectTypeContainer__3hPDR {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    grid-gap: 20px;
    padding-right: 10px;
}

.HealthStationNew_button__LJ10B {
    
}
.HealthStationNew_button__LJ10B:hover {
    cursor: pointer;
}
.HealthStationNew_buttonActive__e2Pu9 {
    border-bottom: 2px solid #5c2d91;
}

`, "",{"version":3,"sources":["webpack://./src/HealthStationNew/HealthStationNew.module.css"],"names":[],"mappings":"AAAA;;AAEA;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,cAAc;IACd,mBAAmB;AACvB;;AAEA;;AAEA;AACA;IACI,eAAe;AACnB;AACA;IACI,gCAAgC;AACpC","sourcesContent":[".content {\n\n}\n\n.selectTypeContainer {\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n    grid-gap: 20px;\n    padding-right: 10px;\n}\n\n.button {\n    \n}\n.button:hover {\n    cursor: pointer;\n}\n.buttonActive {\n    border-bottom: 2px solid #5c2d91;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `HealthStationNew_content__7V0MT`,
	"selectTypeContainer": `HealthStationNew_selectTypeContainer__3hPDR`,
	"button": `HealthStationNew_button__LJ10B`,
	"buttonActive": `HealthStationNew_buttonActive__e2Pu9`
};
export default ___CSS_LOADER_EXPORT___;
