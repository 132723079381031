import { withRouter } from 'react-router-dom';
import { Row, Col, Button, Table, Layout, Menu, Avatar, Image } from 'antd';
import React from "react";
import { CommentOutlined, VideoCameraOutlined } from '@ant-design/icons';
// import './style.css';
const { Sider, Content } = Layout;

class symptom_monitoring extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }


    async componentDidMount() {
    }

    render() {
        return <>
            <Layout>
                <Sider className="sider-menu-item"
                    breakpoint="lg"
                    collapsedWidth="0">
                    <Menu mode="inline" defaultSelectedKeys={['1']} style={{ marginTop: '30px' }}>
                        <Menu.Item key="1">หน้าหลัก</Menu.Item>
                    </Menu>
                </Sider>
                <Layout style={{ backgroundColor: '#EFF1F5', minHeight: '100vh' }}>
                    <Content style={{ padding: '30px 30px 20px 30px' }}>
                        <Row>
                            <Col sm={{ span: 24 }} xs={{ span: 24 }} coll="true" >
                                <div style={{ backgroundColor: '#E4E7FB', padding: '10px 20px 10px 20px', borderRadius: '10px' }}>
                                    <span style={{ color: '#5C2D91' }}>ติดตามอาการที่บ้าน</span>
                                </div>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '20px' }}>
                            <Col sm={{ span: 24 }} xs={{ span: 24 }} coll="true" >
                                <div style={{ display: 'flex', alignItems: 'center', backgroundColor: 'white', borderRadius: '10px', padding: '10px 20px 10px 20px' }}>
                                    <Avatar
                                        size={80}
                                        src={<Image src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png" />}
                                    />
                                    <div style={{ paddingLeft: '20px' }}>
                                        <span style={{ color: '#5C2D91' }}>แข็งแรง สุขภาพดี</span><br />
                                        <span style={{ color: '#5A39A7' }}>เพศ ชาย อายุ 60 ปี</span>
                                        <div>
                                            <CommentOutlined size="50" style={{ color: 'white', backgroundColor: '#DB57BE', borderRadius: '20px', padding: '8px', fontSize: '24px' }} />
                                            <VideoCameraOutlined style={{ color: 'white', backgroundColor: '#DB57BE', borderRadius: '20px', padding: '8px', fontSize: '24px' }} />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '20px' }}>
                            <Col sm={{ span: 24 }} xs={{ span: 24 }} coll="true" >
                                <div style={{ backgroundColor: 'white', padding: '10px 20px 10px 20px', borderRadius: '10px' }}>
                                    <p style={{ color: '#5C2D91' }}>หนังสือรับรองสุขภาพ</p>
                                    <p style={{ borderBottom: '1px solid #4D23A0' }}>จากกระทรวงสาธารณสุข</p>
                                    <p style={{ borderBottom: '1px solid #ECF1FF', color: '#5C2D91' }}>11/07/2564</p>
                                    <p style={{ color: '#5C2D91' }}>Sinovac</p>
                                    <p>รพ.ดูแลรักษา</p>
                                    <p style={{ borderBottom: '1px solid #ECF1FF', color: '#5C2D91' }}>11/07/2564</p>
                                    <p style={{ color: '#5C2D91' }}>Sinovac</p>
                                    <p style={{ borderBottom: '1px solid #4D23A0' }}>รพ.ดูแลรักษา</p>
                                    <div>
                                        <img src={require('../icon/icon_ATK.svg').default} alt="icon_ATK" />
                                        <p>ผลการตรวจ ATK</p>
                                        <p>ผลเป็นลบ (ไม่พบเชื้อ)</p>
                                        <p>วันที่ 10/08/2564</p>
                                    </div>
                                    <div>
                                        <img src={require('../icon/icon_History_of_treatment.svg').default} alt="icon_History_of_treatment" />
                                        <p>ประวัติการรักษาอาการติดเชื้อครั้งแรก</p>
                                        <p>รักษาหาย มากกว่า 14 วัน</p>
                                    </div>

                                </div>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '20px' }}>
                            <Col sm={{ span: 24 }} xs={{ span: 24 }} coll="true" >
                                <div style={{ backgroundColor: 'white', padding: '10px 20px 10px 20px', borderRadius: '10px' }}>
                                    <div style={{ borderBottom: '1px solid #4D23A0', display: 'flex' }}>
                                        <img src={require('../icon/symptom_tracking_record.svg').default} alt="symptom_tracking_record" />
                                        <div>
                                            <p style={{ color: '#5C2D91' }}>บันทึกติดตามอาการ</p>
                                            <p>ติดตามอาการมาแล้ว 10 วัน</p>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ backgroundColor: '#5C2D91', borderRadius: '10px', alignItems: 'center', justifyContent: 'center' }}>
                                            <img src={require('../icon/Icon_Temperature.svg').default} alt="Icon_Temperature" style={{ filter: 'brightness(0) invert(1)' }} />
                                            <p>Temperature</p>
                                        </div>
                                    </div>

                                    {/* <img src={require('../icon/Icon_Blood_presure.svg').default} alt="Icon_Blood_presure" />
                                    <span>Blood presure</span>
                                    <img src={require('../icon/Icon_Pulse_Rate.svg').default} alt="Icon_Pulse_Rate" />
                                    <span>Pulse Rate</span>
                                    <img src={require('../icon/Icon_O2Sat.svg').default} alt="Icon_O2Sat" />
                                    <span>O2Sat</span>
                                    <img src={require('../icon/Icon_Respiratory _Rate.svg').default} alt="Icon_Respiratory _Rate" />
                                    <span>Respiratory Rate</span> */}

                                </div>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '20px' }}>
                            <Col sm={{ span: 24 }} xs={{ span: 24 }} coll="true" >
                                <div style={{ backgroundColor: 'white', padding: '10px 20px 10px 20px', borderRadius: '10px' }}>
                                    <span style={{ borderBottom: '1px solid #4D23A0' }}>แบบฟอร์มการส่งต่อผู้ป่วยเพื่อรับการรักษา</span>
                                    <Button style={{ borderRadius: '20px', backgroundColor: '#4D23A0', color: 'white' }}>แบบฟอร์ม</Button>
                                    <Button style={{ borderRadius: '20px', backgroundColor: '#4D23A0', color: 'white' }}>แนบภาพ X-ray</Button>
                                </div>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '20px' }}>
                            <Col sm={{ span: 24 }} xs={{ span: 24 }} coll="true" >
                                <div style={{ backgroundColor: 'white', padding: '10px 20px 10px 20px', borderRadius: '10px' }}>
                                    <span style={{ borderBottom: '1px solid #4D23A0' }}>ข้อมูลจากการบันทึกสุขภาพ</span>
                                    <p>การบันทึกข้อมูลสุขภาพจากอุปกรณ์ bluetooth และ Wristband</p>
                                </div>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '20px' }}>
                            <Col sm={{ span: 24 }} xs={{ span: 24 }} coll="true" >
                                <div style={{ backgroundColor: 'white', padding: '10px 20px 10px 20px', borderRadius: '10px' }}>
                                    <div style={{ borderBottom: '1px solid #4D23A0', display: 'flex' }}>
                                        {/* <img src={require('../icon/').default} alt="symptom_tracking_record" /> */}
                                        <div>
                                            <p style={{ color: '#5C2D91' }}>ไทม์ไลน์</p>
                                            <p>ย้อนหลังก่อนติดเชื้อ</p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                    </Content>
                </Layout>
            </Layout>
        </>
    }
}

export default withRouter(symptom_monitoring);