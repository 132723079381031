import axios from "axios";

export async function getUserData() {
    return new Promise( resolve => {
        let url = 'https://ncds.numedapp.com/api/dashboard/v2/h4u/OnesDiD/getUserData'
        let header = {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('Authorization')
        }
        axios.post(url, {}, { headers: header }).then(res => {
            resolve(res)
        }).catch( () => {resolve(false)})
    })
}
export async function searchAffiliation(searchAffiliation, affiliationType) {
    return new Promise(resolve => {
          let url = 'https://ncds.numedapp.com/api/dashboard/v2/h4u/searchAffiliation'
          let body = {
                searchAffiliation: searchAffiliation,
                affiliation_type: affiliationType
          }
          let header = {
                'Content-Type': 'application/json',
                // 'Authorization': localStorage.getItem('Authorization')
          }
          axios.post(url, body, { headers: header }).then(res => {
                resolve(res)
          }).catch(() => {
                resolve(false)
          })
    })
}

export async function getAmount(body) {
    return new Promise( resolve => {
        let url = 'https://ncds.numedapp.com/api/dashboard/v4/table/getAmount'
        let header = {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('Authorization')
        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch( () => {resolve(false)})
    })

}

export async function getAmountNCDs(body) {
    return new Promise( resolve => {
        let url = 'https://h4u-mhealth.moph.go.th/api/v1/microservice/getAmountNCDs'
        let header = {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('access_token'),
            'platform_type': 'doctor-know-you-doctor'
        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch( () => {resolve(false)})
    })

}

export async function verifyToken() {
    return new Promise(resolve => {
      let url = "https://www.dkyapps.com/api/doctor/accounts/lookup"
      var header = {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem('access_token')
      };
      axios.get(url, { headers: header, timeout: 30000 }).then(res => {
        resolve(res)
      }).catch(() => {
        resolve(false)
      })
    })
  }

export async function getFilterAddress(type, id) {
    return new Promise(resolve => {
        let url = 'https://ncds.numedapp.com/api/dashboard/v2/h4u/getFilterAddress'
        let body = {
            type: type,
            id: id
        }
        let header = {
            'Content-Type': 'application/json'
        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export async function getScreenedHealthAreaAll(date_type, year, quarter, month) {
    return new Promise(resolve => {
        let url = 'https://ncds.numedapp.com/api/dashboard/v2/h4u/people_screenedAreaAll'
        let body = {
            date_type: date_type,
            year: year ? Number(year) : '',
            quarter: quarter ? Number(quarter) : '',
            month: month ? Number(month) : ''
        }
        let header = {
            'Content-Type': 'application/json',

        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}
export async function getScreenedHealthArea(date_type, year, quarter, month, healthAreaId) {
    return new Promise(resolve => {
        let url = 'https://ncds.numedapp.com/api/dashboard/v2/h4u/people_screenedArea'
        let body = {
            date_type: date_type,
            year: year ? Number(year) : '',
            quarter: quarter ? Number(quarter) : '',
            month: month ? Number(month) : '',
            area: String(healthAreaId)
        }
        let header = {
            'Content-Type': 'application/json',

        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}
export async function getScreenedProvince(date_type, year, quarter, month, provinceId) {
    return new Promise(resolve => {
        let url = 'https://ncds.numedapp.com/api/dashboard/v2/h4u/people_screened'
        let body = {
            date_type: date_type,
            year: year ? Number(year) : '',
            quarter: quarter ? Number(quarter) : '',
            month: month ? Number(month) : '',
            province_id: String(provinceId)
        }
        let header = {
            'Content-Type': 'application/json',

        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}
export async function getScreenedDistrict(date_type, year, quarter, month, districtId) {
    return new Promise(resolve => {
        let url = 'https://ncds.numedapp.com/api/dashboard/v2/h4u/people_screenedByDistrict'
        let body = {
            date_type: date_type,
            year: year ? Number(year) : '',
            quarter: quarter ? Number(quarter) : '',
            month: month ? Number(month) : '',
            district_id: String(districtId)
        }
        let header = {
            'Content-Type': 'application/json',

        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}
export async function getScreenedSubDistrict(date_type, year, quarter, month, subDistrictId) {
    return new Promise(resolve => {
        let url = 'https://ncds.numedapp.com/api/dashboard/v2/h4u/people_screenedBySubDistrict'
        let body = {
            date_type: date_type,
            year: year ? Number(year) : '',
            quarter: quarter ? Number(quarter) : '',
            month: month ? Number(month) : '',
            sub_district_id: String(subDistrictId)
        }
        let header = {
            'Content-Type': 'application/json',

        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}
export async function getDataByAll(date_type, year, quarter, month, hospitalId) {
    return new Promise(resolve => {
        let url = 'https://ncds.numedapp.com/api/dashboard/v2/h4u/getDataByAll'
        let body = {
            date_type: date_type,
            year: year ? Number(year) : '',
            quarter: quarter ? Number(quarter) : '',
            month: month ? Number(month) : '',
            hospital_id: String(hospitalId)
        }
        let header = {
            'Content-Type': 'application/json',

        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}





export async function getDataHealthRecordClensing(date_type, year, quarter, month, hospitalId, type, platformType, time) {
    return new Promise(resolve => {
        let url = 'https://ncds.numedapp.com/api/dashboard/v2/h4u/getDataHealthRecordClensing'
        let body = {
            date_type: date_type,
            year: year ? Number(year) : '',
            quarter: quarter ? Number(quarter) : '',
            month: month ? Number(month) : '',
            hospital_id: String(hospitalId),
            type: type,
            platform_type: platformType,
            time: time
        }
        let header = {
            'Content-Type': 'application/json',

        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}
export async function getDataByTypeTableSubDistrict(date_type, year, quarter, month, subDistrictId, type, platformType, time) {
    return new Promise(resolve => {
        let url = 'https://ncds.numedapp.com/api/dashboard/v2/h4u/getDataByTypeTableSubDistrict'
        let body = {
            date_type: date_type,
            year: year ? Number(year) : '',
            quarter: quarter ? Number(quarter) : '',
            month: month ? Number(month) : '',
            sub_district_id: String(subDistrictId),
            type: type,
            platform_type: platformType,
            time: time
        }
        let header = {
            'Content-Type': 'application/json',

        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}
export async function getDataByTypeTableDistrict(date_type, year, quarter, month, districtId, type, platformType, time) {
    return new Promise(resolve => {
        let url = 'https://ncds.numedapp.com/api/dashboard/v2/h4u/getDataByTypeTableDistrict'
        let body = {
            date_type: date_type,
            year: year ? Number(year) : '',
            quarter: quarter ? Number(quarter) : '',
            month: month ? Number(month) : '',
            district_id: String(districtId),
            type: type,
            platform_type: platformType,
            time: time
        }
        let header = {
            'Content-Type': 'application/json',

        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}
export async function getDataByTypeTableProvince(date_type, year, quarter, month, provinceId, type, platformType, time) {
    return new Promise(resolve => {
        let url = 'https://ncds.numedapp.com/api/dashboard/v2/h4u/getDataByTypeTableProvince'
        let body = {
            date_type: date_type,
            year: year ? Number(year) : '',
            quarter: quarter ? Number(quarter) : '',
            month: month ? Number(month) : '',
            province_id: String(provinceId),
            type: type,
            platform_type: platformType,
            time: time
        }
        let header = {
            'Content-Type': 'application/json',

        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}
export async function getDataByTypeTableProvinceAll(date_type, year, quarter, month, provinceId, type, platformType, time) {
    return new Promise(resolve => {
        let url = 'https://ncds.numedapp.com/api/dashboard/v2/h4u/getDataByTypeTableProvinceAll'
        let body = {
            date_type: date_type,
            year: year ? Number(year) : '',
            quarter: quarter ? Number(quarter) : '',
            month: month ? Number(month) : '',
            province_id: String(provinceId),
            type: type,
            platform_type: platformType,
            time: time
        }
        let header = {
            'Content-Type': 'application/json',

        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}
export async function getDataByTypeTableArea(date_type, year, quarter, month, healthAreaId, type, platformType, time) {
    return new Promise(resolve => {
        let url = 'https://ncds.numedapp.com/api/dashboard/v2/h4u/getDataByTypeTableArea'
        let body = {
            date_type: date_type,
            year: year ? Number(year) : '',
            quarter: quarter ? Number(quarter) : '',
            month: month ? Number(month) : '',
            area: String(healthAreaId),
            type: type,
            platform_type: platformType,
            time: time
        }
        let header = {
            'Content-Type': 'application/json',

        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export async function getAllExportExcel2(hosptial_id, date_type, year = '', quarter = '', month = '') {
    let url = 'https://ncds.numedapp.com/api/dashboard/v3/h4u/GetAllExportExcel'
    let parameter = '?hospital_id=' + hosptial_id + '&date_type=' + date_type

    if (date_type == 'by_year') {
        parameter += '&year=' + year
    } else if (date_type == 'by_quarter') {
        parameter += '&year=' + year + "&quarter=" + quarter
    } else if (date_type == 'by_month') {
        parameter += '&year=' + year + "&quarter=" + quarter + "&month=" + month
    }


    return axios.get(
        url + parameter,
        {
          headers: {
            'Authorization': localStorage.getItem('Authorization'),
            'Content-Type': 'application/octet-stream'
          },
          responseType: 'blob'
        }
        )
}


export async function getDataByTypeTableAreaAll(date_type, year, quarter, month, type, platformType, time) {
    return new Promise(resolve => {
        let url = 'https://ncds.numedapp.com/api/dashboard/v2/h4u/getDataByTypeTableAreaAll'
        let body = {
            date_type: date_type,
            year: year ? Number(year) : '',
            quarter: quarter ? Number(quarter) : '',
            month: month ? Number(month) : '',
            type: type,
            platform_type: platformType,
            time: time
        }
        let header = {
            'Content-Type': 'application/json',

        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}



export async function getAllExportExcel(hospitalId, dateType, year, quarter, month) {
    return new Promise(resolve => {
        let url = 'https://ncds.numedapp.com/api/dashboard/v2/h4u/GetAllExportExcel'
        let body = {
            hospital_id: hospitalId,
            date_type: dateType,
            year: year ? Number(year) : '',
            quarter: quarter ? Number(quarter) : '',
            month: month ? Number(month) : ''
        }
        let header = {
            'Content-Type': 'application/json'
        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

