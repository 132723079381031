import React, { Component } from 'react'
import styles from './HealthStationMicroservice.module.css'
import HomeTableMicroservice from '../HomeTableMicroservice/HomeTableMicroservice'
import HomeGraph from '../HomeGraph/HomeGraph'
import * as api from '../services/api/api.js'
export default class HealthStationNew extends Component {
  constructor(props) {
    super(props)

    this.state = {
      menu: 'graph',
      showTableContent: false
    }

    this.getParameter()
  }

  onMenuClick(menu) {
    this.setState({
      menu: menu
    })
  }
  async getParameter() {
    let getURL = decodeURIComponent(window.location.href);
    let url = new URL(getURL);
    let data = url.searchParams;
    if (data.get('access_token')) {
      localStorage.setItem('access_token', data.get('access_token').split(" ").join("+"))
    }
    if (data.get('token')) {
      localStorage.setItem('access_token', data.get('token').split(" ").join("+"))
    }

    let res = await api.verifyToken()

    if (res) {
      let pname = res['data']['INITIAL_NAME_TH']
      let fname = res['data']['FIRST_NAME_TH']
      let lname = res['data']['LAST_NAME_TH']
      let pid = res['data']['THAI_ID_TRUTH']
      // let hospitalId = `00${res['data']['HOSPITAL_CODE']}00`
      let hospitalId = ''
      let hospitalName = res['data']['HOSPITAL_NAME']


      if (res['data']['HOSPITAL_CODE'].length == 5) {
        hospitalId = `00${res['data']['HOSPITAL_CODE']}00`
        this.setState({
          showTableContent: true
        })
      } else {
        hospitalId = '000000000'
        hospitalName = 'ไม่พบโรงพยาบาล'
      }

      localStorage.setItem('pname', pname)
      localStorage.setItem('fname', fname)
      localStorage.setItem('lname', lname)
      localStorage.setItem('pid', pid)
      localStorage.setItem('hospital_id', hospitalId)
      localStorage.setItem('hospital_name', hospitalName)
      localStorage.setItem('first', "false")
      // this.getOpdAppointmentAmount()
    } else {
      alert('ยันยันตัวตนไม่ได้')
    }
  }

  render() {
    return (
      <div className={styles.content}>
        <div className={styles.selectTypeContainer}>
          <div className={`${styles.button} ${this.state.menu === 'graph' ? styles.buttonActive : ''}`} onClick={() => { this.onMenuClick('graph') }}>กราฟวงกลม</div>
          {this.state.showTableContent ? <div className={`${styles.button} ${this.state.menu === 'table' ? styles.buttonActive : ''}`} onClick={() => { this.onMenuClick('table') }}>ตารางรายชื่อ</div> : null}
        </div>

        {this.state.menu === 'graph' ? <HomeGraph /> : null}
        {this.state.menu === 'table' ? <HomeTableMicroservice /> : null}
      </div>
    )
  }
}
