import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import React from 'react';

am4core.useTheme(am4themes_animated);
am4core.options.autoDispose = true;
var axios = require('axios');
///-------chart------///
export async function API_GetFilterAddress(type, id) {
  var data = JSON.stringify({
    "type": type,
    "id": id
  });

  var config = {
    method: 'post',
    url: 'https://ncds.numedapp.com/api/dashboard/v2/h4u/getFilterAddress',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  };

  return axios(config)
    .then(function (response) {
      // console.log(response);
      return response.data.results
    })
    .catch(function (error) {
      // console.log(error);
      return ''
    });
}

export async function API_GetAreaAll(area, date_type, year = '0', quarter = '0', month = '0') {

  var data = {
    "area": area,
    "date_type": date_type,  //"by_year" ,"by_quarter" ,"by_month"
    "year": year === '0' ? '' : Number(year),
    "quarter": quarter === '0' ? '' : Number(quarter),
    "month": month === '0' ? '' : Number(month)
  };
  // console.log(data);

  var config = {
    method: 'post',
    url: 'https://ncds.numedapp.com/api/dashboard/v2/h4u/getAreaAll',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  };

  return axios(config)
    .then(function (response) {
      // console.log(response);
      return response.data.result
    })
    .catch(function (error) {
      // console.log(error);
      return ''
    });
}

export async function API_GetProvinceAll(province_id, date_type, year = '0', quarter = '0', month = '0') {

  var data = {
    "province_id": province_id,
    "date_type": date_type,  //"by_year" ,"by_quarter" ,"by_month"
    "year": year === '0' ? '' : Number(year),
    "quarter": quarter === '0' ? '' : Number(quarter),
    "month": month === '0' ? '' : Number(month)
  };
  console.log(data);

  var config = {
    method: 'post',
    url: 'https://ncds.numedapp.com/api/dashboard/v2/h4u/getProvinceAll',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  };

  return axios(config)
    .then(function (response) {
      // console.log(response);
      return response.data.result
    })
    .catch(function (error) {
      // console.log(error);
      return ''
    });
}

export async function API_GetDistrictAll(district_id, date_type, year = '0', quarter = '0', month = '0') {
  var data = {
    "district_id": district_id === '0' ? '' : district_id,
    "date_type": date_type,  //"by_year" ,"by_quarter" ,"by_month"
    "year": year === '0' ? '' : Number(year),
    "quarter": quarter === '0' ? '' : Number(quarter),
    "month": month === '0' ? '' : Number(month)
  };

  var config = {
    method: 'post',
    url: 'https://ncds.numedapp.com/api/dashboard/v2/h4u/getDistrictAll',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  };

  return axios(config)
    .then(function (response) {
      // console.log(response);
      return response.data.result
    })
    .catch(function (error) {
      // console.log(error);
      return ''
    });
}

export async function API_GetSubDistrictAll(sub_district_id, date_type, year = '0', quarter = '0', month = '0') {
  var data = {
    "sub_district_id": sub_district_id === '0' ? '' : sub_district_id,
    "date_type": date_type,  //"by_year" ,"by_quarter" ,"by_month"
    "year": year === '0' ? '' : Number(year),
    "quarter": quarter === '0' ? '' : Number(quarter),
    "month": month === '0' ? '' : Number(month)
  };

  var config = {
    method: 'post',
    url: 'https://ncds.numedapp.com/api/dashboard/v2/h4u/getSubDistrictAll',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  };

  return axios(config)
    .then(function (response) {
      // console.log(response);
      return response.data.result
    })
    .catch(function (error) {
      // console.log(error);
      return ''
    });
}

export async function API_GetHospitalAll(hospital_id, date_type, year = '0', quarter = '0', month = '0') {
  var data = {
    "hospital_id": hospital_id === '0' ? '' : hospital_id,
    "date_type": date_type,  //"by_year" ,"by_quarter" ,"by_month"
    "year": year === '0' ? '' : Number(year),
    "quarter": quarter === '0' ? '' : Number(quarter),
    "month": month === '0' ? '' : Number(month)
  };
  // console.log(data);
  var config = {
    method: 'post',
    url: 'https://ncds.numedapp.com/api/dashboard/v2/h4u/getHospitalAll',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  };

  return axios(config)
    .then(function (response) {
      // console.log(response);
      return response.data.result
    })
    .catch(function (error) {
      // console.log(error);
      return ''
    });
}

export async function API_GetFiscal_Year() {

  var config = {
    method: 'post',
    url: 'https://ncds.numedapp.com/api/dashboard/v2/h4u/getDateDB',
    headers: {
      'Content-Type': 'application/json'
    },
    data: ''
  };

  return axios(config)
    .then(function (response) {
      // console.log(response);
      return response.data.result
    })
    .catch(function (error) {
      // console.log(error);
      return ''
    });
}

export async function API_GetSumUserTotal(type, id) {

  var data = JSON.stringify({
    "type": type,
    "id": id
  });

  var config = {
    method: 'post',
    url: 'https://ncds.numedapp.com/api/dashboard/v2/h4u/getSumUserTotal',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  };

  return axios(config)
    .then(function (response) {
      // console.log(response);
      return response.data.result.sum
    })
    .catch(function (error) {
      // console.log(error);
      return 0
    });
}

export async function API_GetDataByType(data) {
  console.log(data);
  var config = {
    method: 'post',
    url: 'https://ncds.numedapp.com/api/dashboard/v2/h4u/getDataByType',
    headers: { 'Content-Type': 'application/json' },
    data: data
  };

  return axios(config)
    .then(function (response) {
      console.log(response);
      return response.data.result
    })
    .catch(function (error) {
      // console.log(error);
      return 0
    });
}
///-----------------------------------------------///



///-------Table------///

export async function getAllExportExcel(hosptial_id, date_type, year = '', quarter = '', month = '') {
  let url = 'https://ncds.numedapp.com/api/dashboard/v3/h4u/GetAllExportExcel'
  let parameter = '?hospital_id=' + hosptial_id + '&date_type=' + date_type

  if (date_type == 'by_year') {
    parameter += '&year=' + year
  } else if (date_type == 'by_quarter') {
    parameter += '&year=' + year + "&quarter=" + quarter
  } else if (date_type == 'by_month') {
    parameter += '&year=' + year + "&quarter=" + quarter + "&month=" + month
  }


  return axios.get(
    url + parameter,
    {
      headers: {
        'Authorization': localStorage.getItem('Authorization'),
        'Content-Type': 'application/octet-stream'
      },
      responseType: 'blob'
    }
    )
}

export async function Export_Excel(hospital_id, date_type, year = '0', quarter = '0', month = '0') {

  var data = {
    "hospital_id": hospital_id,
    "date_type": date_type,  //"by_year" ,"by_quarter" ,"by_month"
    "year": year === '0' ? '' : Number(year),
    "quarter": quarter === '0' ? '' : Number(quarter),
    "month": month === '0' ? '' : Number(month)
  };

  
  console.log(data);
  var config = {
    method: 'post',
    url: 'https://ncds.numedapp.com/api/dashboard/v2/h4u/GetAllExportExcel',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  };

  return axios(config)
    .then(function (response) {
      // console.log(response);
      return response.data.result
    })
    .catch(function (error) {
      // console.log(error);
      return ''
    });
}

export async function API_GetPeople_screenedAreaAll(date_type, year = '0', quarter = '0', month = '0') {

  var data = {
    "date_type": date_type,  //"by_year" ,"by_quarter" ,"by_month"
    "year": year === '0' ? '' : Number(year),
    "quarter": quarter === '0' ? '' : Number(quarter),
    "month": month === '0' ? '' : Number(month)
  };

  var config = {
    method: 'post',
    url: 'https://ncds.numedapp.com/api/dashboard/v2/h4u/people_screenedAreaAll',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  };

  return axios(config)
    .then(function (response) {
      // console.log(response);
      return response.data.result
    })
    .catch(function (error) {
      // console.log(error);
      return ''
    });
}

export async function API_GetPeople_screenedArea(area, date_type, year = '0', quarter = '0', month = '0') {

  var data = {
    "area": area,
    "date_type": date_type,  //"by_year" ,"by_quarter" ,"by_month"
    "year": year === '0' ? '' : Number(year),
    "quarter": quarter === '0' ? '' : Number(quarter),
    "month": month === '0' ? '' : Number(month)
  };

  var config = {
    method: 'post',
    url: 'https://ncds.numedapp.com/api/dashboard/v2/h4u/people_screenedArea',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  };

  return axios(config)
    .then(function (response) {
      // console.log(response);
      return response.data.result
    })
    .catch(function (error) {
      // console.log(error);
      return ''
    });
}

export async function API_People_screenedAll(date_type, year = '0', quarter = '0', month = '0') {
  var data = JSON.stringify({
    "date_type": date_type,  //"by_year" ,"by_quarter" ,"by_month"
    "year": year === '0' ? '' : Number(year),
    "quarter": quarter === '0' ? '' : Number(quarter),
    "month": month === '0' ? '' : Number(month)
  });

  var config = {
    method: 'post',
    url: 'https://ncds.numedapp.com/api/dashboard/v2/h4u/people_screenedAll',
    headers: { 'Content-Type': 'application/json' },
    data: data
  };

  return axios(config)
    .then(function (response) {
      console.log(response);
      return response.data.result
    })
    .catch(function (error) {
      // console.log(error);
      return 0
    });
}

export async function API_People_screened_province(province_id, date_type, year = '0', quarter = '0', month = '0') {
  var data = JSON.stringify({
    "province_id": province_id,
    "date_type": date_type,  //"by_year" ,"by_quarter" ,"by_month"
    "year": year === '0' ? '' : Number(year),
    "quarter": quarter === '0' ? '' : Number(quarter),
    "month": month === '0' ? '' : Number(month)
  });

  var config = {
    method: 'post',
    url: 'https://ncds.numedapp.com/api/dashboard/v2/h4u/people_screened',
    headers: { 'Content-Type': 'application/json' },
    data: data
  };

  return axios(config)
    .then(function (response) {
      // console.log(response);
      return response.data.result
    })
    .catch(function (error) {
      // console.log(error);
      return 0
    });
}

export async function API_People_screened_district(district_id, date_type, year = '0', quarter = '0', month = '0') {
  var data = JSON.stringify({
    "district_id": district_id,
    "date_type": date_type,  //"by_year" ,"by_quarter" ,"by_month"
    "year": year === '0' ? '' : Number(year),
    "quarter": quarter === '0' ? '' : Number(quarter),
    "month": month === '0' ? '' : Number(month)
  });

  var config = {
    method: 'post',
    url: 'https://ncds.numedapp.com/api/dashboard/v2/h4u/people_screenedByDistrict',
    headers: { 'Content-Type': 'application/json' },
    data: data
  };

  return axios(config)
    .then(function (response) {
      // console.log(response);
      return response.data.result
    })
    .catch(function (error) {
      // console.log(error);
      return 0
    });
}

export async function API_People_screened_SubDistrict(sub_district_id, date_type, year = '0', quarter = '0', month = '0') {
  var data = JSON.stringify({
    "sub_district_id": sub_district_id,
    "date_type": date_type,  //"by_year" ,"by_quarter" ,"by_month"
    "year": year === '0' ? '' : Number(year),
    "quarter": quarter === '0' ? '' : Number(quarter),
    "month": month === '0' ? '' : Number(month)
  });

  var config = {
    method: 'post',
    url: 'https://ncds.numedapp.com/api/dashboard/v2/h4u/people_screenedBySubDistrict',
    headers: { 'Content-Type': 'application/json' },
    data: data
  };

  return axios(config)
    .then(function (response) {
      // console.log(response);
      return response.data.result
    })
    .catch(function (error) {
      // console.log(error);
      return 0
    });
}

export async function API_GetDataByHospital(hospital_id, date_type, year = '0', quarter = '0', month = '0') {
  var data = JSON.stringify({
    "hospital_id": hospital_id,
    "date_type": date_type,  //"by_year" ,"by_quarter" ,"by_month"
    "year": year === '0' ? '' : Number(year),
    "quarter": quarter === '0' ? '' : Number(quarter),
    "month": month === '0' ? '' : Number(month)
  });

  var config = {
    method: 'post',
    url: 'https://ncds.numedapp.com/api/dashboard/v2/h4u/getDataByAll',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  };

  return axios(config)
    .then(function (response) {
      // console.log(response);
      return response.data.result
    })
    .catch(function (error) {
      // console.log(error);
      return 0
    });
}


//token
export async function API_GetDataByHospital_ByParam(Authorization) {

  var config = {
    method: 'post',
    url: 'https://ncds.numedapp.com/api/dashboard/v2/h4u/App/getFilterAddress',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': Authorization
    },
    data: {}
  };

  return axios(config)
    .then(function (response) {
      return response.data.result
    })
    .catch(function (error) {
      // console.log(error);
      return 0
    });
}

export async function API_GetUserData(Authorization) {
  var config = {
    method: 'post',
    url: 'https://ncds.numedapp.com/api/dashboard/v2/h4u/App/getUserData',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': Authorization
    },
    data: {}
  };

  return axios(config)
    .then(function (res) {
      // console.log(res);
      return res.data
    })
    .catch(function (error) {
      // console.log(error);
      return ''
    });
}

//type Table
export async function API_GetDataByTypeTableTableAreaAll(type, platform_type, time, date_type, year = '0', quarter = '0', month = '0') {
  var data = JSON.stringify({
    "type": type,
    "platform_type": platform_type,
    "time": time,
    "date_type": date_type,  //"by_year" ,"by_quarter" ,"by_month"
    "year": year === '0' ? '' : Number(year),
    "quarter": quarter === '0' ? '' : Number(quarter),
    "month": month === '0' ? '' : Number(month)
  });

  console.log(data);
  var config = {
    method: 'post',
    url: 'https://ncds.numedapp.com//api/dashboard/v2/h4u/getDataByTypeTableAreaAll',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  };

  return axios(config)
    .then(function (response) {
      // console.log(response);
      return response.data.result
    })
    .catch(function (error) {
      // console.log(error);
      return 0
    });
}

export async function API_GetDataByTypeTableTableArea(area, type, platform_type, time, date_type, year = '0', quarter = '0', month = '0') {
  var data = JSON.stringify({
    "area": area,
    "type": type,
    "platform_type": platform_type,
    "time": time,
    "date_type": date_type,  //"by_year" ,"by_quarter" ,"by_month"
    "year": year === '0' ? '' : Number(year),
    "quarter": quarter === '0' ? '' : Number(quarter),
    "month": month === '0' ? '' : Number(month)
  });

  var config = {
    method: 'post',
    url: 'https://ncds.numedapp.com/api/dashboard/v2/h4u/getDataByTypeTableArea',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  };

  return axios(config)
    .then(function (response) {
      // console.log(response);
      return response.data.result
    })
    .catch(function (error) {
      // console.log(error);
      return 0
    });
}

export async function API_GetDataByTypeTableProvinceAll(type, platform_type, time, date_type, year = '0', quarter = '0', month = '0') {
  var data = JSON.stringify({
    "type": type,
    "platform_type": platform_type,
    "time": time,
    "date_type": date_type,  //"by_year" ,"by_quarter" ,"by_month"
    "year": year === '0' ? '' : Number(year),
    "quarter": quarter === '0' ? '' : Number(quarter),
    "month": month === '0' ? '' : Number(month)
  });

  var config = {
    method: 'post',
    url: 'https://ncds.numedapp.com/api/dashboard/v2/h4u/getDataByTypeTableProvinceAll',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  };

  return axios(config)
    .then(function (response) {
      // console.log(response);
      return response.data.result
    })
    .catch(function (error) {
      // console.log(error);
      return 0
    });
}

export async function API_GetDataByTypeTableProvince(province_id, type, platform_type, time, date_type, year = '0', quarter = '0', month = '0') {
  var data = JSON.stringify({
    "province_id": province_id,
    "type": type,
    "platform_type": platform_type,
    "time": time,
    "date_type": date_type,  //"by_year" ,"by_quarter" ,"by_month"
    "year": year === '0' ? '' : Number(year),
    "quarter": quarter === '0' ? '' : Number(quarter),
    "month": month === '0' ? '' : Number(month)
  });
  // console.log(data);
  var config = {
    method: 'post',
    url: 'https://ncds.numedapp.com/api/dashboard/v2/h4u/getDataByTypeTableProvince',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  };

  return axios(config)
    .then(function (response) {
      // console.log(response);
      return response.data.result
    })
    .catch(function (error) {
      // console.log(error);
      return 0
    });
}

export async function API_GetDataByTypeTableDistrict(district_id, type, platform_type, time, date_type, year = '0', quarter = '0', month = '0') {
  var data = JSON.stringify({
    "district_id": district_id,
    "type": type,
    "platform_type": platform_type,
    "time": time,
    "date_type": date_type,  //"by_year" ,"by_quarter" ,"by_month"
    "year": year === '0' ? '' : Number(year),
    "quarter": quarter === '0' ? '' : Number(quarter),
    "month": month === '0' ? '' : Number(month)
  });

  // console.log(data);
  var config = {
    method: 'post',
    url: 'https://ncds.numedapp.com/api/dashboard/v2/h4u/getDataByTypeTableDistrict',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  };

  return axios(config)
    .then(function (response) {
      // console.log(response);
      return response.data.result
    })
    .catch(function (error) {
      // console.log(error);
      return 0
    });
}

export async function API_GetDataByTypeTableSubDistrict(sub_district_id, type, platform_type, time, date_type, year = '0', quarter = '0', month = '0') {
  var data = JSON.stringify({
    "sub_district_id": sub_district_id,
    "type": type,
    "platform_type": platform_type,
    "time": time,
    "date_type": date_type,  //"by_year" ,"by_quarter" ,"by_month"
    "year": year === '0' ? '' : Number(year),
    "quarter": quarter === '0' ? '' : Number(quarter),
    "month": month === '0' ? '' : Number(month)
  });

  var config = {
    method: 'post',
    url: 'https://ncds.numedapp.com/api/dashboard/v2/h4u/getDataByTypeTableSubDistrict',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  };

  return axios(config)
    .then(function (response) {
      // console.log(response);
      return response.data.result
    })
    .catch(function (error) {
      // console.log(error);
      return 0
    });
}

export async function API_GetDataHealthRecord(hospital_id, type, patient_type, time, date_type, year = '0', quarter = '0', month = '0') {
  var data = JSON.stringify({
    "hospital_id": hospital_id,
    "type": type,
    "patient_type": patient_type,
    "time": time,
    "date_type": date_type,  //"by_year" ,"by_quarter" ,"by_month"
    "year": year === '0' ? '' : Number(year),
    "quarter": quarter === '0' ? '' : Number(quarter),
    "month": month === '0' ? '' : Number(month)
  });
  // console.log(data);
  var config = {
    method: 'post',
    url: 'https://ncds.numedapp.com/api/dashboard/v2/h4u/getDataHealthRecordClensing',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  };

  return axios(config)
    .then(function (res) {
      // console.log(res);
      return res.data.result
    })
    .catch(function (error) {
      // console.log(error);
      return ''
    });
}


///-----------------------------------------------///

///-------chart------///
export async function Get_chart(Data_chart) {

  var chart = am4core.create("chartdiv", am4charts.PieChart);

  // Add data
  chart.data = Data_chart

  // Add and configure Series
  var pieSeries = chart.series.push(new am4charts.PieSeries());
  pieSeries.dataFields.value = "value";
  pieSeries.dataFields.category = "name_th";
  pieSeries.slices.template.stroke = am4core.color("#fff");
  pieSeries.slices.template.strokeOpacity = 1;
  pieSeries.ticks.template.disabled = true;
  pieSeries.labels.template.text = ''

  var rgm = new am4core.RadialGradientModifier();
  rgm.brightnesses.push(0);
  pieSeries.slices.template.fillModifier = rgm;
  pieSeries.slices.template.strokeModifier = rgm;
  pieSeries.slices.template.propertyFields.fill = "color";

  // This creates initial animation
  pieSeries.hiddenState.properties.opacity = 1;
  pieSeries.hiddenState.properties.endAngle = -90;
  pieSeries.hiddenState.properties.startAngle = -90;

  chart.legend = new am4charts.Legend();
  chart.legend.position = "right";
  chart.legend.maxWidth = undefined;
  chart.legend.valueLabels.template.disabled = true;
  chart.legend.labels.template.text = "{name} [bold]{value}[/] คน";

  chart.responsive.enabled = true;
  chart.responsive.rules.push({
    relevant: function (target) {
      chart.legend.position = "right";
      if (target.pixelWidth <= 600) {
        return true;
      }
      return false;
    },
    state: function (target, stateId) {
      chart.legend.position = "bottom";
      if (target instanceof am4charts.PieSeries) {
        var state = target.states.create(stateId);

        var labelState = target.labels.template.states.create(stateId);
        labelState.properties.disabled = true;

        var tickState = target.ticks.template.states.create(stateId);
        tickState.properties.disabled = true;
        return state;
      }

      return null;
    }
  });
}

export async function Get_chartNormal(Data_chart) {

  var chart = am4core.create("chart_normal", am4charts.PieChart);

  // Add data
  chart.data = Data_chart

  // Add and configure Series
  var pieSeries = chart.series.push(new am4charts.PieSeries());
  pieSeries.dataFields.value = "value";
  pieSeries.dataFields.category = "name_th";
  pieSeries.slices.template.stroke = am4core.color("#fff");
  pieSeries.slices.template.strokeOpacity = 1;
  pieSeries.ticks.template.disabled = true;
  pieSeries.labels.template.text = ''
  // pieSeries.labels.template.text = "{value.percent.formatNumber('#.0')}%";

  var rgm = new am4core.RadialGradientModifier();
  rgm.brightnesses.push(0);
  pieSeries.slices.template.fillModifier = rgm;
  pieSeries.slices.template.strokeModifier = rgm;
  pieSeries.slices.template.propertyFields.fill = "color";

  // This creates initial animation
  pieSeries.hiddenState.properties.opacity = 1;
  pieSeries.hiddenState.properties.endAngle = -90;
  pieSeries.hiddenState.properties.startAngle = -90;

  chart.legend = new am4charts.Legend();
  chart.legend.position = "right";
  chart.legend.maxWidth = undefined;
  chart.legend.valueLabels.template.disabled = true;
  chart.legend.labels.template.text = "{name} [bold]{value}[/] คน";

  chart.responsive.enabled = true;
  chart.responsive.rules.push({
    relevant: function (target) {
      chart.legend.position = "right";
      if (target.pixelWidth <= 600) {
        return true;
      }
      return false;
    },
    state: function (target, stateId) {
      chart.legend.position = "bottom";
      if (target instanceof am4charts.PieSeries) {
        var state = target.states.create(stateId);

        var labelState = target.labels.template.states.create(stateId);
        labelState.properties.disabled = true;

        var tickState = target.ticks.template.states.create(stateId);
        tickState.properties.disabled = true;
        return state;
      }

      return null;
    }
  });
}

export async function Get_chartSick(Data_chart) {

  var chart = am4core.create("chart_sick", am4charts.PieChart);

  // Add data
  chart.data = Data_chart

  // Add and configure Series
  var pieSeries = chart.series.push(new am4charts.PieSeries());
  pieSeries.dataFields.value = "value";
  pieSeries.dataFields.category = "name_th";
  pieSeries.slices.template.stroke = am4core.color("#fff");
  pieSeries.slices.template.strokeOpacity = 1;
  pieSeries.ticks.template.disabled = true;
  pieSeries.labels.template.text = ''

  var rgm = new am4core.RadialGradientModifier();
  rgm.brightnesses.push(0);
  pieSeries.slices.template.fillModifier = rgm;
  pieSeries.slices.template.strokeModifier = rgm;
  pieSeries.slices.template.propertyFields.fill = "color";

  // This creates initial animation
  pieSeries.hiddenState.properties.opacity = 1;
  pieSeries.hiddenState.properties.endAngle = -90;
  pieSeries.hiddenState.properties.startAngle = -90;

  chart.legend = new am4charts.Legend();
  chart.legend.position = "right";
  chart.legend.maxWidth = undefined;
  chart.legend.valueLabels.template.disabled = true;
  chart.legend.labels.template.text = "{name} [bold]{value}[/] คน";

  chart.responsive.enabled = true;
  chart.responsive.rules.push({
    relevant: function (target) {
      chart.legend.position = "right";
      if (target.pixelWidth <= 600) {
        return true;
      }
      return false;
    },
    state: function (target, stateId) {
      chart.legend.position = "bottom";
      if (target instanceof am4charts.PieSeries) {
        var state = target.states.create(stateId);

        var labelState = target.labels.template.states.create(stateId);
        labelState.properties.disabled = true;

        var tickState = target.ticks.template.states.create(stateId);
        tickState.properties.disabled = true;
        return state;
      }

      return null;
    }
  });
}
///-----------------------------------------------///



///-------Columns------///
export function ColumnsTable_adress(title, dataIndex) {
  return [
    {
      title: "",
      render: (text, record) => (
        <React.Fragment>
          <div className="columns-header-responsive-start">
            <div>{title}</div><div>{record[dataIndex]}</div>
          </div>
          <div className="columns-header-responsive">
            <div>จำนวนผู้ใช้งาน<br />H4U (คน)</div><div>{record.quantity_user}</div>
          </div>
          <div className="columns-header-responsive">
            <div>จำนวนผู้บันทึก<br />ดัชนีมวลกาย (คน)</div><div>{record.bmi}</div>
          </div>
          <div className="columns-header-responsive">
            <div>จำนวนผู้บันทึก<br />รอบเอว (คน)</div><div>{record.waistline}</div>
          </div>
          <div className="columns-header-responsive">
            <div>จำนวนผู้บันทึก<br />ค่าความดันโลหิต (คน)</div><div>{record.blood_pressure}</div>
          </div>
          <div className="columns-header-responsive-end">
            <div>จำนวนผู้บันทึกค่า<br />ระดับน้ำตาลในเลือด (คน)</div><div>{record.blood_sugar}</div>
          </div>
        </React.Fragment>
      ),
      responsive: ["xs"]
    },
    {
      title: <div style={{ color: '#5C2D91' }}>{title}</div>,
      dataIndex: dataIndex,
      responsive: ["sm"],
      align: 'center',
      render(text, record) {
        return {
          props: {
            style: { color: '#5C2D91' }
          },
          children: <div style={{ padding: '10px' }}>{text}</div>
        };
      }
    },
    {
      title: <div style={{ color: '#5C2D91' }}>จำนวนผู้ใช้งาน<br />H4U (คน)</div>,
      dataIndex: "quantity_user",
      responsive: ["sm"],
      align: 'center',
      render(text, record) {
        return {
          props: {
            style: { color: '#5C2D91' }
          },
          children: <div>{text}</div>
        };
      }
    },
    {
      title: <div style={{ color: '#5C2D91' }}>จำนวนผู้บันทึก<br />ดัชนีมวลกาย (คน)</div>,
      dataIndex: "bmi",
      responsive: ["sm"],
      align: 'center',
      render(text, record) {
        return {
          props: {
            style: { color: '#5C2D91' }
          },
          children: <div>{text}</div>
        };
      }
    },
    {
      title: <div style={{ color: '#5C2D91' }}>จำนวนผู้บันทึก<br />รอบเอว (คน)</div>,
      dataIndex: "waistline",
      responsive: ["sm"],
      align: 'center',
      render(text, record) {
        return {
          props: {
            style: { color: '#5C2D91' }
          },
          children: <div>{text}</div>
        };
      }
    },
    {
      title: <div style={{ color: '#5C2D91' }}>จำนวนผู้บันทึก<br />ค่าความดันโลหิต (คน)</div>,
      dataIndex: "blood_pressure",
      responsive: ["sm"],
      align: 'center',
      render(text, record) {
        return {
          props: {
            style: { color: '#5C2D91' }
          },
          children: <div>{text}</div>
        };
      }
    },
    {
      title: <div style={{ color: '#5C2D91' }}>จำนวนผู้บันทึกค่า<br />ระดับน้ำตาลในเลือด (คน)</div>,
      dataIndex: "blood_sugar",
      responsive: ["sm"],
      align: 'center',
      render(text, record) {
        return {
          props: {
            style: { color: '#5C2D91' }
          },
          children: <div>{text}</div>
        };
      }
    }
  ];
}

export function ColumnsTable_BMI(title) {
  return [
    {
      title: "",
      render: (text, record) => (
        <React.Fragment>
          <div className="columns-header-responsive-start">
            <div>{title}</div><div>{record.name}</div>
          </div>
          <div className="columns-header-responsive">
            <div>ต่ำกว่าเกณฑ์ (คน)<br />{'0-<18.5'}</div><div>{record.underweight}</div>
          </div>
          <div className="columns-header-responsive">
            <div>สมส่วน (คน)<br />{'18.5-22.9'}</div><div>{record.normalweight}</div>
          </div>
          <div className="columns-header-responsive">
            <div>น้ำหนักเกิน (คน)<br />{'23-24.9'}</div><div>{record.normal}</div>
          </div>
          <div className="columns-header-responsive">
            <div>อ้วน (คน)<br />{'25-29.9'}</div><div>{record.overweight}</div>
          </div>
          <div className="columns-header-responsive-end">
            <div>อันตราย (คน)<br />{'>=30'}</div><div>{record.obesity}</div>
          </div>
        </React.Fragment>
      ),
      responsive: ["xs"]
    },
    {
      title: <div style={{ color: '#5C2D91' }}>{title}</div>,
      dataIndex: "name",
      responsive: ["sm"],
      align: 'center',
      render(text, record) {
        return {
          props: {
            style: { color: '#5C2D91' }
          },
          children: <div style={{ padding: '10px' }}>{text}</div>
        };
      }
    },
    {
      title: <div style={{ color: '#5C2D91' }}>จำนวนดัชนีมวลกายแยกตามกลุ่ม</div>,
      responsive: ["sm"],
      children: [
        {
          key: 'underweight',
          title: <div style={{ backgroundColor: '#D27FF5' }}>ต่ำกว่าเกณฑ์ (คน)<br />{'0-<18.5'}</div>,
          dataIndex: 'underweight',
          align: 'center'
        },
        {
          key: 'normalweight',
          title: <div style={{ backgroundColor: '#A0EA57' }}>สมส่วน (คน)<br />{'18.5-22.9'}</div>,
          dataIndex: 'normalweight',
          align: 'center'
        },
        {
          key: 'normal',
          title: <div style={{ backgroundColor: '#ECEC5F' }}>น้ำหนักเกิน (คน)<br />{'23-24.9'}</div>,
          dataIndex: 'normal',
          align: 'center'
        },
        {
          key: 'overweight',
          title: <div style={{ backgroundColor: '#EEAE52' }}>อ้วน (คน)<br />{'25-29.9'}</div>,
          dataIndex: 'overweight',
          align: 'center'
        },
        {
          key: 'obesity',
          title: <div style={{ backgroundColor: '#FD6056' }}>อันตราย (คน)<br />{'>=30'}</div>,
          dataIndex: 'obesity',
          align: 'center'
        }
      ]
    }
  ]
}

export function ColumnsTable_blood_pressure(title, type) {
  if (type === 'sick') {
    return [
      {
        title: "",
        render: (text, record) => (
          <React.Fragment>
            <div className="columns-header-responsive-start">
              <div>{title}</div><div>{record.name}</div>
            </div>
            <div className="columns-header-responsive">
              <div>ต่ำกว่าเกณฑ์ (คน)<br />{'( SBP < 90  DBP< 60) '}</div><div>{record.underweight}</div>
            </div>
            <div className="columns-header-responsive">
              <div>ปกติ (คน)<br />{'(S = 120-129 หรือ D = 80-84)'}</div><div>{record.normal}</div>
            </div>
            <div className="columns-header-responsive">
              <div>เกือบสูง (คน)<br />{'(S = 130-139 หรือ D = 85-89)'}</div><div>{record.almosthigh}</div>
            </div>
            <div className="columns-header-responsive">
              <div>สูง (คน)<br />{'(S = 140-179 หรือ D = 90-109)'}</div><div>{record.hypertension}</div>
            </div>
            <div className="columns-header-responsive-end">
              <div>อันตราย (คน)<br />{'(S >= 180  หรือ D >= 110)'}</div><div>{record.high_hypertension}</div>
            </div>
          </React.Fragment>
        ),
        responsive: ["xs"]
      },
      {
        title: <div style={{ color: '#5C2D91' }}>{title}</div>,
        dataIndex: "name",
        responsive: ["sm"],
        align: 'center',
        render(text, record) {
          return {
            props: {
              style: { color: '#5C2D91' }
            },
            children: <div style={{ padding: '10px' }}>{text}</div>
          };
        }
      },
      {
        title: <div style={{ color: '#5C2D91' }}>จำนวนรายงานความดันโลหิตแยกตามกลุ่ม</div>,
        align: 'center',
        responsive: ["sm"],
        children: [
          {
            key: 'underweight',
            title: <div style={{ backgroundColor: '#FF67FF' }}>ต่ำกว่าเกณฑ์ (คน)<br />{'( SBP < 90  DBP< 60) '}</div>,
            dataIndex: 'underweight',
            align: 'center'
          },
          {
            key: 'normal',
            title: <div style={{ backgroundColor: '#A0EA57' }}>ปกติ (คน)<br />{'(S = 120-129 หรือ D = 80-84)'}</div>,
            dataIndex: 'normal',
            align: 'center'
          },
          {
            key: 'almosthigh',
            title: <div style={{ backgroundColor: '#ECEC5F' }}>เกือบสูง (คน)<br />{'(S = 130-139 หรือ D = 85-89)'}</div>,
            dataIndex: 'almosthigh',
            align: 'center'
          },
          {
            key: 'hypertension',
            title: <div style={{ backgroundColor: '#EEAE52' }}>สูง (คน)<br />{'(S = 140-179 หรือ D = 90-109)'}</div>,
            dataIndex: 'hypertension',
            align: 'center'
          },
          {
            key: 'high_hypertension',
            title: <div style={{ backgroundColor: '#FD6056' }}>อันตราย (คน)<br />{'(S >= 180  หรือ D >= 110)'}</div>,
            dataIndex: 'high_hypertension',
            align: 'center'
          }
        ]
      }
    ];
  } else {
    return [
      {
        title: "",
        render: (text, record) => (
          <React.Fragment>
            <div className="columns-header-responsive-start">
              <div>{title}</div><div>{record.name}</div>
            </div>
            <div className="columns-header-responsive">
              <div>ปกติ (คน)<br />{'(S = 120-129 หรือ D = 80-84)'}</div><div>{record.normal}</div>
            </div>
            <div className="columns-header-responsive">
              <div>เกือบสูง (คน)<br />{'(S = 130-139 หรือ D = 85-89)'}</div><div>{record.almosthigh}</div>
            </div>
            <div className="columns-header-responsive">
              <div>สูง (คน)<br />{'(S = 140-179 หรือ D = 90-109)'}</div><div>{record.hypertension}</div>
            </div>
            <div className="columns-header-responsive-end">
              <div>อันตราย (คน)<br />{'(S >= 180  หรือ D >= 110)'}</div><div>{record.high_hypertension}</div>
            </div>
          </React.Fragment>
        ),
        responsive: ["xs"]
      },
      {
        title: <div style={{ color: '#5C2D91' }}>{title}</div>,
        dataIndex: "name",
        responsive: ["sm"],
        align: 'center',
        render(text, record) {
          return {
            props: {
              style: { color: '#5C2D91' }
            },
            children: <div style={{ padding: '10px' }}>{text}</div>
          };
        }
      },
      {
        title: <div style={{ color: '#5C2D91' }}>จำนวนรายงานความดันโลหิตแยกตามกลุ่ม</div>,
        responsive: ["sm"],
        align: 'center',
        children: [
          {
            key: 'normal',
            title: <div style={{ backgroundColor: '#A0EA57' }}>ปกติ (คน)<br />{'(S = 120-129 หรือ D = 80-84)'}</div>,
            dataIndex: 'normal',
            align: 'center'
          },
          {
            key: 'almosthigh',
            title: <div style={{ backgroundColor: '#ECEC5F' }}>เกือบสูง (คน)<br />{'(S = 130-139 หรือ D = 85-89)'}</div>,
            dataIndex: 'almosthigh',
            align: 'center'
          },
          {
            key: 'hypertension',
            title: <div style={{ backgroundColor: '#EEAE52' }}>สูง (คน)<br />{'(S = 140-179 หรือ D = 90-109)'}</div>,
            dataIndex: 'hypertension',
            align: 'center'
          },
          {
            key: 'high_hypertension',
            title: <div style={{ backgroundColor: '#FD6056' }}>อันตราย (คน)<br />{'(S >= 180  หรือ D >= 110)'}</div>,
            dataIndex: 'high_hypertension',
            align: 'center'
          }
        ]
      }
    ];
  }
}

export function ColumnsTable_blood_sugar(title, type, time) {
  if (type === 'sick') {
    return [
      {
        title: "",
        render: (text, record) => (
          <React.Fragment>
            <div className="columns-header-responsive-start">
              <div>{title}</div><div>{record.name}</div>
            </div>
            <div className="columns-header-responsive">
              <div>ต่ำระดับรุนแรง (คน)<br />{'0-49'}</div><div>{record.severelylow}</div>
            </div>
            <div className="columns-header-responsive">
              <div>ต่ำ (คน)<br />{'50-69'}</div><div>{record.low}</div>
            </div>
            <div className="columns-header-responsive">
              <div>เหมาะสม (คน)<br />{'70-179'}</div><div>{record.normal}</div>
            </div>
            <div className="columns-header-responsive">
              <div>การควบคุม (คน)<br />{'180-249'}</div><div>{record.high}</div>
            </div>
            <div className="columns-header-responsive">
              <div>แทรกซ้อนที่รุนแรง (คน))<br />{'>=250'}</div><div>{record.very_high}</div>
            </div>
            <div className="columns-header-responsive-end">
              <div>น้ำตาลสูงมาก (คน)<br />{'>300'}</div><div>{record.very_very_hight}</div>
            </div>
          </React.Fragment>
        ),
        responsive: ["xs"]
      },
      {
        title: <div style={{ color: '#5C2D91' }}>{title}</div>,
        dataIndex: "name",
        responsive: ["sm"],
        align: 'center',
        render(text, record) {
          return {
            props: {
              style: { color: '#5C2D91' }
            },
            children: <div style={{ padding: '10px' }}>{text}</div>
          };
        }
      },
      {
        title: <div style={{ color: '#5C2D91' }}>ระดับน้ำตาลในเลือด<br />ต่ำระดับรุนแรง (คน)</div>,
        responsive: ["sm"],
        align: 'center',
        children: [
          {
            key: 'severelylow',
            title: <div style={{ backgroundColor: '#690E8A' }}>{'0-49'}</div>,
            dataIndex: 'severelylow',
            align: 'center'
          }
        ]
      },
      {
        title: <div style={{ color: '#5C2D91' }}>ระดับน้ำตาล<br />ในเลือดต่ำ (คน)</div>,
        responsive: ["sm"],
        align: 'center',
        children: [
          {
            key: 'low',
            title: <div style={{ backgroundColor: '#FF67FF' }}>{'50-69'}</div>,
            dataIndex: 'low',
            align: 'center',
          }
        ]
      },
      {
        title: <div style={{ color: '#5C2D91' }}>ระดับน้ำตาลอยู่ใน<br />เกณฑ์เหมาะสม (คน)</div>,
        responsive: ["sm"],
        align: 'center',
        children: [
          {
            key: 'normal',
            title: <div style={{ backgroundColor: '#92D050' }}>{'70-179'}</div>,
            dataIndex: 'normal',
            align: 'center'
          }
        ]
      },
      {
        title: <div style={{ color: '#5C2D91' }}>ระดับน้ำตาลสูงกว่าเป้า<br />หมายการควบคุม (คน)</div>,
        responsive: ["sm"],
        align: 'center',
        children: [
          {
            key: 'high',
            title: <div style={{ backgroundColor: '#FFFF02' }}>{'180-249'}</div>,
            dataIndex: 'high',
            align: 'center'
          }
        ]
      },
      {
        title: <div style={{ color: '#5C2D91' }}>ระดับน้ำตาลสูงมาก<br />เสี่ยงต่อการเกิดภาวะ<br />แทรกซ้อนที่รุนแรง (คน)</div>,
        responsive: ["sm"],
        align: 'center',
        children: [
          {
            key: 'very_high',
            title: <div style={{ backgroundColor: '#FFC001' }}>{'>=250'}</div>,
            dataIndex: 'very_high',
            align: 'center'
          }
        ]
      },
      {
        title: <div style={{ color: '#5C2D91' }}>ระดับน้ำตาลสูงมาก<br />เสี่ยงต่อการเกิดภาวะ<br />แทรกซ้อนรุนแรงระดับ<br />น้ำตาลสูงมาก (คน)</div>,
        responsive: ["sm"],
        align: 'center',
        children: [
          {
            key: 'very_very_hight',
            title: <div style={{ backgroundColor: '#FF0000' }}>{'>300'}</div>,
            dataIndex: 'very_very_hight',
            align: 'center'
          }
        ]
      }
    ];
  } else {
    if (time === '8') {
      return [
        {
          title: "",
          render: (text, record) => (
            <React.Fragment>
              <div className="columns-header-responsive-start">
                <div>{title}</div><div>{record.name}</div>
              </div>
              <div className="columns-header-responsive">
                <div>ต่ำกว่าปกติ (คน)<br />{'<50'}</div><div>{record.low}</div>
              </div>
              <div className="columns-header-responsive">
                <div>ปกติ (คน)<br />{'<140'}</div><div>{record.normal}</div>
              </div>
              <div className="columns-header-responsive">
                <div>เสี่ยงเบาหวาน (คน)<br />{'141-199'}</div><div>{record.high}</div>
              </div>
              <div className="columns-header-responsive-end">
                <div>สูงกว่าปกติ (คน)<br />{'<=200'}</div><div>{record.very_high}</div>
              </div>
            </React.Fragment>
          ),
          responsive: ["xs"]
        },
        {
          title: <div style={{ color: '#5C2D91' }}>{title}</div>,
          dataIndex: "name",
          responsive: ["sm"],
          align: 'center',
          render(text, record) {
            return {
              props: {
                style: { color: '#5C2D91' }
              },
              children: <div style={{ padding: '10px' }}>{text}</div>
            };
          }
        },
        {
          title: <div style={{ color: '#5C2D91' }}>ภาวะน้ำตาลใน<br />เลือดต่ำกว่าปกติ (คน)</div>,
          responsive: ["sm"],
          align: 'center',
          children: [
            {
              key: 'low',
              title: <div style={{ backgroundColor: '#FF67FF' }}>{'<50'}</div>,
              dataIndex: 'low',
              align: 'center'
            }
          ]
        },
        {
          title: <div style={{ color: '#5C2D91' }}>ภาวะน้ำตาลในเลือดปกติ (คน)</div>,
          responsive: ["sm"],
          align: 'center',
          children: [
            {
              key: 'normal',
              title: <div style={{ backgroundColor: '#92D050' }}>{'<140'}</div>,
              dataIndex: 'normal',
              align: 'center'
            }
          ]
        },
        {
          title: <div style={{ color: '#5C2D91' }}>มีความเสี่ยงเบาหวาน (คน)</div>,
          responsive: ["sm"],
          align: 'center',
          children: [
            {
              key: 'high',
              title: <div style={{ backgroundColor: '#FFFF02' }}>{'141-199'}</div>,
              dataIndex: 'high',
              align: 'center',
            }
          ]
        },
        {
          title: <div style={{ color: '#5C2D91' }}>ภาวะน้ำตาลในเลือดสูงกว่า<br />ปกติ (คน)</div>,
          responsive: ["sm"],
          align: 'center',
          children: [
            {
              key: 'very_high',
              title: <div style={{ backgroundColor: '#FFC001' }}>{'<=200'}</div>,
              dataIndex: 'very_high',
              align: 'center'
            }
          ]
        }
      ];
    } else {
      return [
        {
          title: "",
          render: (text, record) => (
            <React.Fragment>
              <div className="columns-header-responsive-start">
                <div>{title}</div><div>{record.name}</div>
              </div>
              <div className="columns-header-responsive">
                <div>ปกติ (คน)<br />{'<140'}</div><div>{record.normal}</div>
              </div>
              <div className="columns-header-responsive">
                <div>เสี่ยงเบาหวาน (คน)<br />{'141-199'}</div><div>{record.high}</div>
              </div>
              <div className="columns-header-responsive-end">
                <div>สูงกว่าปกติ (คน)<br />{'<=200'}</div><div>{record.very_high}</div>
              </div>
            </React.Fragment>
          ),
          responsive: ["xs"]
        },
        {
          title: <div style={{ color: '#5C2D91' }}>{title}</div>,
          dataIndex: "name",
          responsive: ["sm"],
          align: 'center',
          render(text, record) {
            return {
              props: {
                style: { color: '#5C2D91' }
              },
              children: <div style={{ padding: '10px' }}>{text}</div>
            };
          }
        },
        {
          title: <div style={{ color: '#5C2D91' }}>ภาวะน้ำตาลในเลือดปกติ (คน)</div>,
          responsive: ["sm"],
          align: 'center',
          children: [
            {
              key: 'normal',
              title: <div style={{ backgroundColor: '#92D050' }}>{'<140'}</div>,
              dataIndex: 'normal',
              align: 'center'
            }
          ]
        },
        {
          title: <div style={{ color: '#5C2D91' }}>มีความเสี่ยงเบาหวาน (คน)</div>,
          responsive: ["sm"],
          align: 'center',
          children: [
            {
              key: 'high',
              title: <div style={{ backgroundColor: '#FFFF02' }}>{'141-199'}</div>,
              dataIndex: 'high',
              align: 'center',
            }
          ]
        },
        {
          title: <div style={{ color: '#5C2D91' }}>ภาวะน้ำตาลในเลือดสูงกว่า<br />ปกติ (คน)</div>,
          responsive: ["sm"],
          align: 'center',
          children: [
            {
              key: 'very_high',
              title: <div style={{ backgroundColor: '#FFC001' }}>{'<=200'}</div>,
              dataIndex: 'very_high',
              align: 'center'
            }
          ]
        }
      ];
    }
  }
}

export function ColumnsTable_waist(title) {
  return [
    {
      title: "",
      render: (text, record) => (
        <React.Fragment>
          <div className="columns-header-responsive-start">
            <div>{title}</div><div>{record.name}</div>
          </div>
          <div className="columns-header-responsive">
            <div>{'<ส่วนสูง/2'}<br />(เหมาะสม)</div><div>{record.normal}</div>
          </div>
          <div className="columns-header-responsive-end">
            <div>{'>=ส่วนสูง/2'}<br />(มีภาวะอ้วนลงพุง)</div><div>{record.abnormal}</div>
          </div>
        </React.Fragment>
      ),
      responsive: ["xs"]
    },
    {
      title: <div style={{ color: '#5C2D91' }}>{title}</div>,
      dataIndex: "name",
      responsive: ["sm"],
      align: 'center',
      render(text, record) {
        return {
          props: {
            style: { color: '#5C2D91' }
          },
          children: <div style={{ padding: '10px' }}>{text}</div>
        };
      }
    },
    {
      title: <div style={{ color: '#5C2D91' }}>รอบเอว</div>,
      responsive: ["sm"],
      align: 'center',
      children: [
        {
          key: 'normal',
          title: <div style={{ backgroundColor: '#A0EA57' }}>{'<ส่วนสูง/2'}<br />(เหมาะสม)</div>,
          dataIndex: 'normal',
          align: 'center'
        },
        {
          key: 'abnormal',
          title: <div style={{ backgroundColor: '#ECEC5F' }}>{'>=ส่วนสูง/2'}<br />(มีภาวะอ้วนลงพุง)</div>,
          dataIndex: 'abnormal',
          align: 'center'
        }
      ]
    }
  ];
}


export const Colums_Hospital_ALL = [
  {
    title: "",
    render: (text, record) => (
      <React.Fragment>
        <div className="columns-header-responsive-start">
          <div>รายชื่อ</div><div>{record.name}</div>
        </div>
        <div className="columns-header-responsive">
          <div>เลขบัตรประจำตัวประชาชน</div><div>{record.pid}</div>
        </div>
        <div className="columns-header-responsive">
          <div>เบอร์โทรศัพท์</div><div>{record.phone_number}</div>
        </div>
        <div className="columns-header-responsive">
          <div>BP (mmHg)</div><div>{record.blood_pressure}</div>
        </div>
        <div className="columns-header-responsive">
          <div>DTX (mg/dL)</div><div>{record.blood_sugar}</div>
        </div>
        <div className="columns-header-responsive">
          <div>BMI (kg/m2)</div><div>{record.bmi}</div>
        </div>
        <div className="columns-header-responsive-end">
          <div>รอบเอว (cm)</div><div>{record.waist_circumference}</div>
        </div>
      </React.Fragment>
    ),
    responsive: ["xs"]
  },
  {
    title: <div style={{ color: '#5C2D91' }}>รายชื่อ</div>,
    dataIndex: "name",
    responsive: ["sm"],
    align: 'center',
    render(text, record) {
      return {
        props: {
          style: { color: '#5C2D91' }
        },
        children: <div>{text}</div>
      };
    }
  }, {
    title: <div style={{ color: '#5C2D91' }}>เลขบัตรประจำตัว<br />ประชาชน</div>,
    dataIndex: "pid",
    responsive: ["sm"],
    align: 'center'
  }, {
    title: <div style={{ color: '#5C2D91' }}>เบอร์โทรศัพท์</div>,
    dataIndex: "phone_number",
    responsive: ["sm"],
    align: 'center'
  }, {
    title: <div style={{ color: '#5C2D91' }}>BP (mmHg)</div>,
    dataIndex: "blood_pressure",
    responsive: ["sm"],
    align: 'center',
    render: (text, record) => (
      <div className="table-space-between-color">
        <div style={{ backgroundColor: record.blood_pressure_color, width: '15px', height: '45px' }}></div>
        <span style={{ margin: '0 auto' }}>{text}</span>
      </div>
    )
  }, {
    title: <div style={{ color: '#5C2D91' }}>DTX (mg/dL)</div>,
    dataIndex: "blood_sugar",
    responsive: ["sm"],
    align: 'center',
    render: (text, record) => (
      <div className="table-space-between-color">
        <div style={{ backgroundColor: record.blood_sugar_color, width: '15px', height: '45px' }}></div>
        <span style={{ margin: '0 auto' }}>{text}</span>
      </div>
    )
  }, {
    title: <div style={{ color: '#5C2D91' }}>BMI (kg/m2)</div>,
    dataIndex: "bmi",
    responsive: ["sm"],
    align: 'center',
    render: (text, record) => (
      <div className="table-space-between-color">
        <div style={{ backgroundColor: record.bmi_coler, width: '15px', height: '45px' }}></div>
        <span style={{ margin: '0 auto' }}>{text}</span>
      </div>
    )
  }, {
    title: <div style={{ color: '#5C2D91' }}>รอบเอว (cm)</div>,
    dataIndex: "waist_circumference",
    responsive: ["sm"],
    align: 'center',
    render: (text, record) => (
      <div className="table-space-between-color">
        <div style={{ backgroundColor: record.waist_circumference_color, width: '15px', height: '45px' }}></div>
        <span style={{ margin: '0 auto' }}>{text}</span>
      </div>
    )
  },
]
export const Colums_Hospital_BMI = [
  {
    title: "",
    render: (text, record) => (
      <React.Fragment>
        <div className="columns-header-responsive-start">
          <div>ชื่อ-นามสกุล</div><div>{record.name}</div>
        </div>
        <div className="columns-header-responsive">
          <div>เบอร์โทร</div><div>{record.phone_number}</div>
        </div>
        <div className="columns-header-responsive">
          <div>อายุ</div><div>{record.age}</div>
        </div>
        <div className="columns-header-responsive">
          <div>เพศ</div><div>{record.gender}</div>
        </div>
        <div className="columns-header-responsive">
          <div>วันที่ชั่งน้ำหนัก</div><div>{record.date}</div>
        </div>
        <div className="columns-header-responsive">
          <div>ส่วนสูง</div><div>{record.height}</div>
        </div>
        <div className="columns-header-responsive">
          <div>น้ำหนัก</div><div>{record.weight}</div>
        </div>
        <div className="columns-header-responsive">
          <div>ดัชนีมวลกาย</div><div>{record.value}</div>
        </div>
        <div className="columns-header-responsive-end">
          <div>แปลผล</div><div>{record.analysis}</div>
        </div>
      </React.Fragment>
    ),
    responsive: ["xs"]
  },
  {
    title: <div style={{ color: '#5C2D91' }}>ชื่อ-นามสกุล</div>,
    dataIndex: "name",
    responsive: ["sm"],
    align: 'center',
    render(text, record) {
      return {
        props: {
          style: { color: '#5C2D91' }
        },
        children: <div style={{ padding: '10px' }}>{text}</div>
      };
    }
  }, {
    title: <div style={{ color: '#5C2D91' }}>เบอร์โทร</div>,
    dataIndex: "phone_number",
    responsive: ["sm"],
    align: 'center'
  }, {
    title: <div style={{ color: '#5C2D91' }}>อายุ</div>,
    dataIndex: "age",
    responsive: ["sm"],
    align: 'center'
  }, {
    title: <div style={{ color: '#5C2D91' }}>เพศ</div>,
    dataIndex: "gender",
    responsive: ["sm"],
    align: 'center'
  }, {
    title: <div style={{ color: '#5C2D91' }}>วันที่ชั่งน้ำหนัก</div>,
    dataIndex: "date",
    responsive: ["sm"],
    align: 'center'
  }, {
    title: <div style={{ color: '#5C2D91' }}>ส่วนสูง</div>,
    dataIndex: "height",
    responsive: ["sm"],
    align: 'center'
  }, {
    title: <div style={{ color: '#5C2D91' }}>น้ำหนัก</div>,
    dataIndex: "weight",
    responsive: ["sm"],
    align: 'center'
  }, {
    title: <div style={{ color: '#5C2D91' }}>ดัชนีมวลกาย</div>,
    dataIndex: "value",
    responsive: ["sm"],
    align: 'center'
  }, {
    title: <div style={{ color: '#5C2D91' }}>แปลผล</div>,
    dataIndex: "analysis",
    responsive: ["sm"],
    align: 'center',
    render(text, record) {
      return {
        props: {
          style: { backgroundColor: record.color }
        },
        children: <div>{text}</div>
      };
    }
  },
  //  {
  //     title: <div style={{ color: '#5C2D91' }}>การจัดการ<br />รายบุคคล</div>,
  //     dataIndex: "option",
  //     responsive: ["sm"],
  //     align: 'center'
  // }
]
export const Colums_Hospital_blood_pressure = [
  {
    title: "",
    render: (text, record) => (
      <React.Fragment>
        <div className="columns-header-responsive-start">
          <div>ชื่อ-นามสกุล</div><div>{record.name}</div>
        </div>
        <div className="columns-header-responsive">
          <div>เบอร์โทร</div><div>{record.phone_number}</div>
        </div>
        <div className="columns-header-responsive">
          <div>อายุ</div><div>{record.age}</div>
        </div>
        <div className="columns-header-responsive">
          <div>เพศ</div><div>{record.gender}</div>
        </div>
        <div className="columns-header-responsive">
          <div>วันที่วัดความดัน</div><div>{record.date}</div>
        </div>
        <div className="columns-header-responsive">
          <div>ค่าความดันตัวบน</div><div>{record.systolic}</div>
        </div>
        <div className="columns-header-responsive">
          <div>ค่าความดันตัวล่าง</div><div>{record.diastolic}</div>
        </div>
        <div className="columns-header-responsive">
          <div>แปลผล</div><div>{record.analysis}</div>
        </div>
        <div className="columns-header-responsive-end">
          <div>ที่มาข้อมูล</div><div>{record.platform_type}</div>
        </div>
      </React.Fragment>
    ),
    responsive: ["xs"]
  },
  {
    title: <div style={{ color: '#5C2D91' }}>ชื่อ-นามสกุล</div>,
    dataIndex: "name",
    responsive: ["sm"],
    align: 'center',
    render(text, record) {
      return {
        props: {
          style: { color: '#5C2D91' }
        },
        children: <div style={{ padding: '10px' }}>{text}</div>
      };
    }
  }, {
    title: <div style={{ color: '#5C2D91' }}>เบอร์โทร</div>,
    dataIndex: "phone_number",
    responsive: ["sm"],
    align: 'center'
  }, {
    title: <div style={{ color: '#5C2D91' }}>อายุ</div>,
    dataIndex: "age",
    responsive: ["sm"],
    align: 'center'
  }, {
    title: <div style={{ color: '#5C2D91' }}>เพศ</div>,
    dataIndex: "gender",
    responsive: ["sm"],
    align: 'center'
  }, {
    title: <div style={{ color: '#5C2D91' }}>วันที่วัดความดัน</div>,
    dataIndex: "date",
    responsive: ["sm"],
    align: 'center'
  }, {
    title: <div style={{ color: '#5C2D91' }}>ค่าความดัน<br />ตัวบน</div>,
    dataIndex: "systolic",
    responsive: ["sm"],
    align: 'center'
  }, {
    title: <div style={{ color: '#5C2D91' }}>ค่าความดัน<br />ตัวล่าง</div>,
    dataIndex: "diastolic",
    responsive: ["sm"],
    align: 'center'
  }, {
    title: <div style={{ color: '#5C2D91' }}>แปลผล</div>,
    dataIndex: "analysis",
    responsive: ["sm"],
    align: 'center',
    render(text, record) {
      return {
        props: {
          style: { backgroundColor: record.color }
        },
        children: <div>{text}</div>
      };
    }
  }, {
    title: <div style={{ color: '#5C2D91' }}>ที่มาข้อมูล</div>,
    dataIndex: "platform_type",
    responsive: ["sm"],
    align: 'center'
  },
  // {
  //     title: <div style={{ color: '#5C2D91' }}>การจัดการ<br />รายบุคคล</div>,
  //     dataIndex: "option",
  //     responsive: ["sm"],
  //     align: 'center'
  // }
]
export const Colums_Hospital_blood_sugar = [
  {
    title: "",
    render: (text, record) => (
      <React.Fragment>
        <div className="columns-header-responsive-start">
          <div>ชื่อ-นามสกุล</div><div>{record.name}</div>
        </div>
        <div className="columns-header-responsive">
          <div>เบอร์โทร</div><div>{record.phone_number}</div>
        </div>
        <div className="columns-header-responsive">
          <div>อายุ</div><div>{record.age}</div>
        </div>
        <div className="columns-header-responsive">
          <div>เพศ</div><div>{record.gender}</div>
        </div>
        <div className="columns-header-responsive">
          <div>วันที่ตรวจ</div><div>{record.date}</div>
        </div>
        <div className="columns-header-responsive">
          <div>ระดับน้ำตาลในเลือด</div><div>{record.value}</div>
        </div>
        <div className="columns-header-responsive-end">
          <div>แปลผล</div><div>{record.analysis}</div>
        </div>
      </React.Fragment>
    ),
    responsive: ["xs"]
  },
  {
    title: <div style={{ color: '#5C2D91' }}>ชื่อ-นามสกุล</div>,
    dataIndex: "name",
    responsive: ["sm"],
    align: 'center',
    render(text, record) {
      return {
        props: {
          style: { color: '#5C2D91' }
        },
        children: <div style={{ padding: '10px' }}>{text}</div>
      };
    }
  }, {
    title: <div style={{ color: '#5C2D91' }}>เบอร์โทร</div>,
    dataIndex: "phone_number",
    responsive: ["sm"],
    align: 'center'
  }, {
    title: <div style={{ color: '#5C2D91' }}>อายุ</div>,
    dataIndex: "age",
    responsive: ["sm"],
    align: 'center'
  }, {
    title: <div style={{ color: '#5C2D91' }}>เพศ</div>,
    dataIndex: "gender",
    responsive: ["sm"],
    align: 'center'
  }, {
    title: <div style={{ color: '#5C2D91' }}>วันที่ตรวจ</div>,
    dataIndex: "date",
    responsive: ["sm"],
    align: 'center'
  }, {
    title: <div style={{ color: '#5C2D91' }}>ระดับน้ำตาลในเลือด</div>,
    dataIndex: "value",
    responsive: ["sm"],
    align: 'center'
  }, {
    title: <div style={{ color: '#5C2D91' }}>แปลผล</div>,
    dataIndex: "analysis",
    responsive: ["sm"],
    align: 'center',
    render(text, record) {
      return {
        props: {
          style: { backgroundColor: record.color }
        },
        children: <div>{text}</div>
      };
    }
  },
  // {
  //     title: <div style={{ color: '#5C2D91' }}>การจัดการ<br />รายบุคคล</div>,
  //     dataIndex: "option",
  //     responsive: ["sm"],
  //     align: 'center'
  // }
]
export const Colums_Hospital_waist = [
  {
    title: "",
    render: (text, record) => (
      <React.Fragment>
        <div className="columns-header-responsive-start">
          <div>ชื่อ-นามสกุล</div><div>{record.name}</div>
        </div>
        <div className="columns-header-responsive">
          <div>เบอร์โทร</div><div>{record.phone_number}</div>
        </div>
        <div className="columns-header-responsive">
          <div>อายุ</div><div>{record.age}</div>
        </div>
        <div className="columns-header-responsive">
          <div>เพศ</div><div>{record.gender}</div>
        </div>
        <div className="columns-header-responsive">
          <div>วันที่ตรวจ</div><div>{record.date}</div>
        </div>
        <div className="columns-header-responsive">
          <div>รอบเอว</div><div>{record.value}</div>
        </div>
        <div className="columns-header-responsive-end">
          <div>แปลผล</div><div>{record.analysis}</div>
        </div>
      </React.Fragment>
    ),
    responsive: ["xs"]
  },
  {
    title: <div style={{ color: '#5C2D91' }}>ชื่อ-นามสกุล</div>,
    dataIndex: "name",
    responsive: ["sm"],
    align: 'center',
    render(text, record) {
      return {
        props: {
          style: { color: '#5C2D91' }
        },
        children: <div style={{ padding: '10px' }}>{text}</div>
      };
    }
  }, {
    title: <div style={{ color: '#5C2D91' }}>เบอร์โทร</div>,
    dataIndex: "phone_number",
    responsive: ["sm"],
    align: 'center'
  }, {
    title: <div style={{ color: '#5C2D91' }}>อายุ</div>,
    dataIndex: "age",
    responsive: ["sm"],
    align: 'center'
  }, {
    title: <div style={{ color: '#5C2D91' }}>เพศ</div>,
    dataIndex: "gender",
    responsive: ["sm"],
    align: 'center'
  }, {
    title: <div style={{ color: '#5C2D91' }}>วันที่ตรวจ</div>,
    dataIndex: "date",
    responsive: ["sm"],
    align: 'center'
  }, {
    title: <div style={{ color: '#5C2D91' }}>รอบเอว</div>,
    dataIndex: "value",
    responsive: ["sm"],
    align: 'center'
  }, {
    title: <div style={{ color: '#5C2D91' }}>แปลผล</div>,
    dataIndex: "analysis",
    responsive: ["sm"],
    align: 'center',
    render(text, record) {
      return {
        props: {
          style: { backgroundColor: record.color }
        },
        children: <div>{text}</div>
      };
    }
  },
  // {
  //     title: <div style={{ color: '#5C2D91' }}>การจัดการ<br />รายบุคคล</div>,
  //     dataIndex: "option",
  //     responsive: ["sm"],
  //     align: 'center'
  // }
]
///-----------------------------------------------///