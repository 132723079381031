import React, { Component } from 'react'
import styles from './HomeTableMicroservice.module.css'
import defaultMonthList from '../services/default-month-list.json'
import * as api from '../services/api/api.js'
import moment from 'moment';
import {
    addressColumn,
    bmiColumn,
    bloodPressureColumn,
    bloodSugarColumn,
    waistColumn,
    hospitalAllColumnMicroservice,
    hospitalBmiColumnMicroservice,
    hospitalBloodPressureColumnMicroservice,
    hospitalBloodSugarColumnMicroservice,
    hospitalWaistColumnMicroservice
} from '../services/NcdsTableColumn'

import {
    Spin,
    Select,
    Table
} from 'antd'

import { LeftOutlined } from '@ant-design/icons'
import { RightOutlined } from '@ant-design/icons'


const { Option } = Select;
export default class HomeTable extends Component {

    constructor(props) {
        super(props)
        this.state = {
            searchName: '',
            haveParamToken: true,
            loading: false,
            noData: true,
            disableType: '',
            healthAreaList: [],
            provinceList: [],
            districtList: [],
            subDistrictList: [],
            hospitalList: [],

            yearList: [],
            quarterList: [],
            monthList: [],

            groupList: [],

            healthAreaId: '',
            provinceId: '',
            districtId: '',
            subDistrictId: '',
            hospitalId: '',

            healthAreaName: '',
            provinceName: '',
            districtName: '',
            subDistrictName: '',
            hospitalName: '',

            yearId: '',
            quarterId: '',
            monthId: '',
            groupId: '',
            columns: [],
            dataSource: [],
            filterAddressType: 'health_area',
            dateType: 'by_year',
            platformTypeId: 'normal',
            platformTypeList: [
                {
                    id: 'normal',
                    name: 'กลุ่มที่ยังไม่ป่วย'
                },
                {
                    id: 'sick',
                    name: 'กลุ่มป่วย'
                }
            ],
            timeId: '0',
            timeList: [
                {
                    id: '0',
                    name: 'ไม่งดอาหาร'
                },
                {
                    id: '8',
                    name: 'งดอาหารอย่างน้อย 8 ชั่วโมง'
                }
            ],
            groupColorId: 'bmi',
            groupColorList: [
                {
                    id: 'bmi',
                    name: 'ค่าดัชนีมวลกาย'
                },
                {
                    id: 'blood_pressure',
                    name: 'ค่าความดันโลหิต'
                },
                {
                    id: 'blood_sugar',
                    name: 'ค่าระดับน้ำตาลในเลือด'
                },
                {
                    id: 'waist_circumference',
                    name: 'ค่ารอบเอว'
                },
            ],
            numberPerPage: 10,
            page: 1,
            maxPage: 1
        }
    }

    componentDidMount() {
        this.initFunction()
    }

    // funciton

    initFunction() {
        // create health area list 
        let healthAreaList = []
        for (let i = 1; i < 14; i += 1) {
            let healthArea = {
                id: i,
                name: 'เขตสุขภาพที่ ' + i
            }
            healthAreaList.push(healthArea)
        }

        // create year list

        let currentYear = Number(moment().format('YYYY'))
        let yearList = []
        for (let i = 0; i < 10; i += 1) {
            let year = {
                id: currentYear - i,
                name: currentYear - i + 543
            }
            yearList.push(year)
        }

        // create quarter list
        let quarterList = []
        for (let i = 1; i < 5; i += 1) {
            let quarter = {
                id: i,
                name: 'ไตรมาสที่ ' + i
            }
            quarterList.push(quarter)
        }

        this.setState({
            healthAreaList: healthAreaList,
            provinceList: [],
            yearList: yearList,
            quarterList: quarterList,
            groupList: [
                {
                    id: 'bmi',
                    name: 'ค่าดัชนีมวลกาย'
                },
                {
                    id: 'blood_pressure',
                    name: 'ค่าความดันโลหิต'
                },
                {
                    id: 'blood_sugar',
                    name: 'ค่าระดับน้ำตาลในเลือด'
                },
                {
                    id: 'waist_circumference',
                    name: 'ค่ารอบเอว'
                },
            ]
        }, () => {
            // this.getTableData()
            this.getUserData()

        })
    }

    async getUserData() {
        let userLevel = 'by_hospital'
        let userLevelId = localStorage.getItem('hospital_id')
        this.setDefaultAddress(userLevel, userLevelId)
    }

    async setDefaultAddress(userLevel, userLevelId) {
        let res = await api.searchAffiliation(userLevelId, userLevel)
        if (res) {
            if (res['data']['code'] == 200) {
                let address = res['data']['result'][0]
                console.log(res)

                if (userLevel === 'by_area') {
                    this.setState({
                        healthAreaList: [{ id: address['health_area_id'], name: address['health_area_name'] }],
                        healthAreaId: address['health_area_id'],
                        healthAreaName: address['health_area_name'],
                        filterAddressType: 'health_area',
                        disableType: 'health_area'
                    }, () => { this.onChangeSelect('health_area', address['health_area_id']) })
                } else if (userLevel === 'by_province') {
                    this.setState({
                        provinceList: [{ id: address['province_id'], name: address['province_name'] }],
                        provinceId: address['province_id'],
                        provinceName: address['province_name'],
                        healthAreaList: [{ id: address['health_area_id'], name: address['health_area_name'] }],
                        healthAreaId: address['health_area_id'],
                        healthAreaName: address['health_area_name'],
                        filterAddressType: 'province',
                        disableType: 'province'
                    }, () => { this.onChangeSelect('province', address['province_id']) })
                } else if (userLevel === 'by_district') {
                    this.setState({
                        districtList: [{ id: address['district_id'], name: address['district_name'] }],
                        districtId: address['district_id'],
                        districtName: address['district_name'],
                        provinceList: [{ id: address['province_id'], name: address['province_name'] }],
                        provinceId: address['province_id'],
                        provinceName: address['province_name'],
                        healthAreaList: [{ id: address['health_area_id'], name: address['health_area_name'] }],
                        healthAreaId: address['health_area_id'],
                        healthAreaName: address['health_area_name'],
                        filterAddressType: 'district',
                        disableType: 'district'
                    }, () => { this.onChangeSelect('district', address['district_id']) })
                } else if (userLevel === 'by_sub_district') {
                    this.setState({
                        subDistrictList: [{ id: address['sub_district_id'], name: address['sub_district_name'] }],
                        subDistrictId: address['sub_district_id'],
                        subDistrictName: address['sub_district_name'],
                        districtList: [{ id: address['district_id'], name: address['district_name'] }],
                        districtId: address['district_id'],
                        districtName: address['district_name'],
                        provinceList: [{ id: address['province_id'], name: address['province_name'] }],
                        provinceId: address['province_id'],
                        provinceName: address['province_name'],
                        healthAreaList: [{ id: address['health_area_id'], name: address['health_area_name'] }],
                        healthAreaId: address['health_area_id'],
                        healthAreaName: address['health_area_name'],
                        filterAddressType: 'sub_district',
                        disableType: 'sub_district'
                    }, () => { this.onChangeSelect('sub_district', address['sub_district_id']) })
                } else if (userLevel === 'by_hospital') {
                    this.setState({
                        hospitalList: [{ id: address['hospital_id'], name: address['hospital_name'] }],
                        hospitalId: address['hospital_id'],
                        hospitalName: address['hospital_name'],
                        subDistrictList: [{ id: address['sub_district_id'], name: address['sub_district_name'] }],
                        subDistrictId: address['sub_district_id'],
                        subDistrictName: address['sub_district_name'],
                        districtList: [{ id: address['district_id'], name: address['district_name'] }],
                        districtId: address['district_id'],
                        districtName: address['district_name'],
                        provinceList: [{ id: address['province_id'], name: address['province_name'] }],
                        provinceId: address['province_id'],
                        provinceName: address['province_name'],
                        healthAreaList: [{ id: address['health_area_id'], name: address['health_area_name'] }],
                        healthAreaId: address['health_area_id'],
                        healthAreaName: address['health_area_name'],
                        filterAddressType: 'hospital',
                        disableType: 'hospital'
                    }, () => { this.onChangeSelect('hospital', address['hospital_id']) })
                }
            } else {
                alert(res['data']['message'])
            }
        } else {
            alert('ไม่ตอบสนอง')
        }
    }

    setAddressList(type, list) {
        if (type === 'by_area') {
            let newList = list.map(item => {
                return {
                    id: item['province_id'],
                    name: item['province_name']
                }
            })
            this.setState({
                provinceList: newList
            })
        } else if (type === 'by_province') {
            let newList = list.map(item => {
                return {
                    id: item['district_id'],
                    name: item['district_name']
                }
            })
            this.setState({
                districtList: newList
            })
        } else if (type === 'by_district') {
            let newList = list.map(item => {
                return {
                    id: item['sub_district_id'],
                    name: item['sub_district_name']
                }
            })
            this.setState({
                subDistrictList: newList
            })
        } else if (type === 'by_sub_district') {
            let newList = list.map(item => {
                return {
                    id: item['hospital_id'],
                    name: item['hospital_name']
                }
            })
            this.setState({
                hospitalList: newList
            })
        }
    }

    searchAddressName(type, id) {
        if (type === 'health_area') {
            this.state.healthAreaList.forEach(element => {
                if (element['id'] === id) {
                    this.setState({
                        healthAreaName: element['name']
                    })
                }
            });
        } else if (type === 'province') {
            this.state.provinceList.forEach(element => {
                if (element['id'] === id) {
                    this.setState({
                        provinceName: element['name']
                    })
                }
            })
        } else if (type === 'district') {
            this.state.districtList.forEach(element => {
                if (element['id'] === id) {
                    this.setState({
                        districtName: element['name']
                    })
                }
            })
        } else if (type === 'sub_district') {
            this.state.subDistrictList.forEach(element => {
                if (element['id'] === id) {
                    this.setState({
                        subDistrictName: element['name']
                    })
                }
            })
        } else if (type === 'hospital') {
            this.state.hospitalList.forEach(element => {
                if (element['id'] === id) {
                    this.setState({
                        hospitalName: element['name']
                    })
                }
            })
        }
    }

    setMonthList(quarter) {
        quarter = Number(quarter)
        let monthList = []

        if (quarter === 1) {
            monthList = defaultMonthList.filter(month => {
                // return ['10', '11', '12'].includes(month['id'])
                return ['01', '02', '03'].includes(month['id'])
            })
        } else if (quarter === 2) {
            monthList = defaultMonthList.filter(month => {
                // return ['01', '02', '03'].includes(month['id'])
                return ['04', '05', '06'].includes(month['id'])
            })
        } else if (quarter === 3) {
            monthList = defaultMonthList.filter(month => {
                // return ['04', '05', '06'].includes(month['id'])
                return ['07', '08', '09'].includes(month['id'])
            })
        } else if (quarter === 4) {
            monthList = defaultMonthList.filter(month => {
                // return ['07', '08', '09'].includes(month['id'])
                return ['10', '11', '12'].includes(month['id'])
            })
        }

        this.setState({
            monthList: monthList
        })
    }

    // event

    async onChangeSelect(type, event) {
        this.searchAddressName(type, event)
        this.setState({
            page: 1
        })
        console.log(type)
        console.log(event)
        if (type === 'health_area') {
            this.setState({
                healthAreaId: event,
                h4uAmount: 0,
                provinceId: '',
                districtId: '',
                subDistrictId: '',
                hospitalId: '',
                filterAddressType: 'health_area',
                groupId: ''
            }, () => {
                this.getFilterAddressApi('by_area', event)
                this.getTableData()
            })
        } else if (type === 'province') {
            this.setState({
                provinceId: event,
                districtId: '',
                subDistrictId: '',
                hospitalId: '',
                groupId: ''
            }, () => {
                if (this.state.provinceId) {
                    this.getFilterAddressApi('by_province', event)
                    this.setState({ filterAddressType: 'province' }, () => { this.getTableData() })
                } else {
                    this.setState({ filterAddressType: 'health_area' }, () => { this.getTableData() })
                }
            })
        } else if (type === 'district') {
            this.setState({
                districtId: event,
                subDistrictId: '',
                hospitalId: '',
                groupId: ''
            }, () => {
                if (this.state.districtId) {
                    this.getFilterAddressApi('by_district', event)
                    this.setState({ filterAddressType: 'district' }, () => { this.getTableData() })
                } else {
                    this.setState({ filterAddressType: 'province' }, () => { this.getTableData() })
                }
            })
        } else if (type === 'sub_district') {
            this.setState({
                subDistrictId: event,
                hospitalId: '',
                groupId: ''
            }, () => {
                if (this.state.subDistrictId) {
                    this.getFilterAddressApi('by_sub_district', event)
                    this.setState({ filterAddressType: 'sub_district' }, () => { this.getTableData() })
                } else {
                    this.setState({ filterAddressType: 'district' }, () => { this.getTableData() })
                }

            })
        } else if (type === 'hospital') {
            this.setState({
                hospitalId: event,
                groupId: ''
            }, () => {
                if (this.state.hospitalId) {
                    this.setState({ filterAddressType: 'hospital' }, () => { this.getTableData() })
                } else {
                    this.setState({ filterAddressType: 'sub_district' }, () => { this.getTableData() })
                }
            })
        } else if (type === 'year') {
            this.setState({
                yearId: event,
                dateType: 'by_year',
                groupId: '',
                quarterId: '',
                monthId: ''
            }, () => {
                this.getTableData()
            })
        } else if (type === 'quarter') {
            this.setState({
                quarterId: event,
                groupId: '',
                monthId: ''
            }, () => {
                if (this.state.quarterId) {
                    this.setState({ dateType: 'by_quarter' }, () => { this.getTableData() })
                } else {
                    this.setState({ dateType: 'by_year' }, () => { this.getTableData() })
                }
            })
            this.setMonthList(event)
        } else if (type === 'month') {
            this.setState({
                monthId: event,
                groupId: ''
            }, () => {
                if (this.state.monthId) {
                    this.setState({ dateType: 'by_month' }, () => { this.getTableData() })
                } else {
                    this.setState({ dateType: 'by_quarter' }, () => { this.getTableData() })
                }
            })
        } else if (type === 'group') {
            this.setState({
                groupId: event
            }, () => {
                if (this.state.groupId) {
                    this.getDataByType()
                } else {
                    this.getTableData()
                }
            })
        } else if (type === 'platform_type') {
            let timeList = []
            let time = '0'
            if (event === 'normal') {
                timeList = [{ id: '0', name: 'ไม่งดอาหาร' }, { id: '8', name: 'งดอาหารอย่างน้อย 8 ชั่วโมง' }]
                time = '0'
            } else if (event === 'sick') {
                timeList = [{ id: '2', name: 'งดอาหารอย่างน้อย 2 ชั่วโมง' }, { id: '8', name: 'งดอาหารอย่างน้อย 8 ชั่วโมง' }]
                time = '2'
            }
            this.setState({
                platformTypeId: event,
                timeList: timeList,
                timeId: time
            }, () => {
                this.getDataByType()
            })
        } else if (type === 'time') {
            this.setState({
                timeId: event
            }, () => {
                this.getDataByType()
            })
        } else if (type === 'group_color') {
            this.setState({
                groupColorId: event
            })
        }
    }

    async exportButton() {
        this.setState({ loading: true })

        await api.getAllExportExcel2(this.state.hospitalId, this.state.dateType, this.state.yearId, this.state.quarterId, this.state.monthId).then(res => {
            console.log(res)
            let url = window.URL.createObjectURL(res['data'])
            window.open(url)
            this.setState({ loading: false })
        }).catch(() => {
            alert('ไม่ตอบสนอง')
            this.setState({ loading: false })
        })
        // this.setState({ loading: true })
        // let res = await api.getAllExportExcel(this.state.hospitalId, this.state.dateType, this.state.yearId, this.state.quarterId, this.state.monthId)
        // this.setState({ loading: false })

        // console.log(res)
        // if (res) {
        //     if (res['data']['code'] = 200) {
        //         window.open(res['data']['result'])
        //     } else {
        //         alert(res['data']['message'])
        //     }
        // } else {
        //     alert('ไม่ตอบสนอง')
        // }
    }

    onPagerClick(type) {
        if (type === 'forword') {
            if (this.state.page < this.state.maxPage) {
                this.setState({
                    page: this.state.page + 1
                }, () => {
                    this.getTableData()
                })
            }
        } else if (type === 'back') {
            if (this.state.page > 1) {
                this.setState({
                    page: this.state.page - 1
                }, () => {
                    this.getTableData()
                })
            }
        }
    }

    //api

    async getFilterAddressApi(type, id) {
        this.setState({ loadingSpin: true })
        let res = await api.getFilterAddress(type, String(id))
        this.setState({ loadingSpin: false })
        if (res) {
            if (res['data']['code'] === 200) {
                console.log(res)
                this.setAddressList(type, res['data']['results'])

            } else {
                alert(res['data']['message'])
            }
        } else {
            alert('ไม่ตอบสนอง')
        }
    }

    async getTableData() {
        if (this.state.groupId) {
            this.getDataByType()
        } else {
            this.getAmount()
        }
    }

    async getDataByType() {
        let {
            groupId,
            healthAreaId,
            provinceId,
            districtId,
            subDistrictId,
            hospitalId,
            healthAreaName,
            provinceName,
            districtName,
            subDistrictName,
            hospitalName,
            yearId,
            monthId,
            quarterId,
            dateType,
            platformTypeId,
            filterAddressType,
            timeId
        } = this.state

        let res = false

        if (filterAddressType === 'health_area' && !healthAreaId) {
            this.setState({ loading: true })
            res = await api.getDataByTypeTableAreaAll(dateType, yearId, quarterId, monthId, groupId, platformTypeId, timeId)
            this.setState({ loading: false })
        } else if (filterAddressType === 'health_area' && healthAreaId) {
            this.setState({ loading: true })
            res = await api.getDataByTypeTableArea(dateType, yearId, quarterId, monthId, healthAreaId, groupId, platformTypeId, timeId)
            this.setState({ loading: false })
        } else if (filterAddressType === 'province') {
            this.setState({ loading: true })
            res = await api.getDataByTypeTableProvince(dateType, yearId, quarterId, monthId, provinceId, groupId, platformTypeId, timeId)
            this.setState({ loading: false })
        } else if (filterAddressType === 'district') {
            this.setState({ loading: true })
            res = await api.getDataByTypeTableDistrict(dateType, yearId, quarterId, monthId, districtId, groupId, platformTypeId, timeId)
            this.setState({ loading: false })
        } else if (filterAddressType === 'sub_district') {
            this.setState({ loading: true })
            res = await api.getDataByTypeTableSubDistrict(dateType, yearId, quarterId, monthId, subDistrictId, groupId, platformTypeId, timeId)
            this.setState({ loading: false })
        } else if (filterAddressType === 'hospital') {
            this.setState({ loading: true })
            res = await api.getDataHealthRecordClensing(dateType, yearId, quarterId, monthId, hospitalId, groupId, platformTypeId, timeId)
            this.setState({ loading: false })
        }

        console.log(res)

        if (res) {
            if (res['data']['code'] == 200) {
                if (this.state.filterAddressType === 'hospital') {
                    if (groupId === 'bmi') {
                        this.setState({
                            columns: hospitalBmiColumnMicroservice,
                            dataSource: res['data']['result']
                        })
                    } else if (groupId === 'blood_pressure') {
                        this.setState({
                            columns: hospitalBloodPressureColumnMicroservice,
                            dataSource: res['data']['result']
                        })
                    } else if (groupId === 'blood_sugar') {
                        this.setState({
                            columns: hospitalBloodSugarColumnMicroservice,
                            dataSource: res['data']['result']
                        })
                    } else if (groupId === 'waist_circumference') {
                        this.setState({
                            columns: hospitalWaistColumnMicroservice,
                            dataSource: res['data']['result']
                        })
                    }
                } else {
                    if (groupId === 'bmi') {
                        this.setState({
                            columns: bmiColumn('BMI'),
                            dataSource: res['data']['result']
                        })
                    } else if (groupId === 'blood_pressure') {
                        this.setState({
                            columns: bloodPressureColumn('BP', platformTypeId),
                            dataSource: res['data']['result']
                        })
                    } else if (groupId === 'blood_sugar') {
                        this.setState({
                            columns: bloodSugarColumn('BS', platformTypeId, timeId),
                            dataSource: res['data']['result']
                        })
                    } else if (groupId === 'waist_circumference') {
                        this.setState({
                            columns: waistColumn('Waist'),
                            dataSource: res['data']['result']
                        })
                    }
                }

            } else {
                alert(res['data']['message'])
            }
        } else {
            alert('ไม่ตอบสนอง')
        }



    }

    async getAmount() {
        let {
            dateType,
            healthAreaId,
            provinceId,
            districtId,
            subDistrictId,
            hospitalId,
            yearId,
            quarterId,
            monthId,
            groupId,
            filterAddressType,
            healthAreaName,
            provinceName,
            districtName,
            subDistrictName,
            hospitalName,
            timeId,
            platformTypeId
        } = this.state
        let addressId = ''
        let addressType = ''



        if (filterAddressType === 'health_area' && !healthAreaId) {
            // this.setState({ loading: true })
            // res = await api.getScreenedHealthAreaAll(dateType, yearId, quarterId, monthId)
            // this.setState({ loading: false })
            addressType = 'health_area'
            addressId = healthAreaId
        } else if (filterAddressType === 'health_area' && healthAreaId) {
            // this.setState({ loading: true })
            // res = await api.getScreenedHealthArea(dateType, yearId, quarterId, monthId, healthAreaId)
            // this.setState({ loading: false })
            addressType = 'health_area'
            addressId = healthAreaId
        } else if (filterAddressType === 'province') {
            // this.setState({ loading: true })
            // res = await api.getScreenedProvince(dateType, yearId, quarterId, monthId, provinceId)
            // this.setState({ loading: false })
            addressType = 'province'
            addressId = provinceId
        } else if (filterAddressType === 'district') {
            // this.setState({ loading: true })
            // res = await api.getScreenedDistrict(dateType, yearId, quarterId, monthId, districtId)
            // this.setState({ loading: false })
            addressType = 'district'
            addressId = districtId
        } else if (filterAddressType === 'sub_district') {
            // this.setState({ loading: true })
            // res = await api.getScreenedSubDistrict(dateType, yearId, quarterId, monthId, subDistrictId)
            // this.setState({ loading: false })
            addressType = 'sub_district'
            addressId = subDistrictId
        } else if (filterAddressType === 'hospital') {
            // this.setState({ loading: true })
            // res = await api.getDataByAll(dateType, yearId, quarterId, monthId, hospitalId)
            // this.setState({ loading: false })
            addressType = 'hospital'
            addressId = hospitalId
        }

        let body = {
            "type": groupId ? groupId : 'total',
            "patient_type": platformTypeId,
            "address_type": addressType,
            "address_id": String(addressId),
            "time": timeId,
            "year": yearId ? Number(yearId) : '',
            "quarter": quarterId ? Number(quarterId) : '',
            "month": monthId ? Number(monthId) : '',
            "page": this.state.page ? Number(this.state.page) : 0,
            "number_per_page": this.state.numberPerPage ? Number(this.state.numberPerPage) : 0
        }

        this.setState({ loading: true })
        let res = await api.getAmountNCDs(body)
        this.setState({ loading: false })

        console.log(res)

        if (res) {
            if (res['data']['code'] == 200) {
                this.setState({
                    noData: false
                })
                if (filterAddressType === 'health_area' && healthAreaId === '') {
                    this.setState({
                        columns: addressColumn('เขตสุขภาพ', 'address_name'),
                        dataSource: res['data']['result']
                    })
                } else if (filterAddressType === 'health_area' && healthAreaId) {
                    this.setState({
                        columns: addressColumn('จังหวัด', 'address_name'),
                        dataSource: res['data']['result']
                    })
                } else if (filterAddressType === 'province') {
                    this.setState({
                        columns: addressColumn(provinceName, 'address_name'),
                        dataSource: res['data']['result']
                    })
                } else if (filterAddressType === 'district') {
                    this.setState({
                        columns: addressColumn(districtName, 'address_name'),
                        dataSource: res['data']['result']
                    })
                } else if (filterAddressType === 'sub_district') {
                    this.setState({
                        columns: addressColumn(subDistrictName, 'address_name'),
                        dataSource: res['data']['result']
                    })
                } else if (filterAddressType === 'hospital') {
                    this.setState({
                        columns: hospitalAllColumnMicroservice,
                        dataSource: res['data']['result']
                    })
                }
                let total = res['data']['total']
                let max = Math.ceil(total / this.state.numberPerPage)
                this.setState({
                    maxPage: max
                })
            } else {
                this.setState({
                    noData: true
                })
            }
        } else {
            this.setState({
                noData: true
            })
            alert('ไม่ตอบสนอง')
        }
    }

    //components

    selectComponent(type, value, list, disable, placeHolder) {
        return (
            <Select
                disabled={disable}
                value={value}
                placeholder={placeHolder}
                onChange={(event) => { this.onChangeSelect(type, event) }}
            >

                {!['platform_type', 'time', 'group_color'].includes(type) ? <Option value=''>{placeHolder}</Option> : null}

                {
                    list.map(item => {
                        return <Option value={item['id']}>{item['name']}</Option>
                    })
                }
            </Select>
        )
    }

    colorItemComponent(type, patientType, time) {
        let list = []
        if (type === 'bmi') {
            list = [
                {
                    color: '#FF67FF',
                    text: 'ต่ำกว่าเกณฑ์'
                },
                {
                    color: '#92D050',
                    text: 'สมส่วน'
                },
                {
                    color: '#FFFF02',
                    text: 'น้ำหนักเกิน'
                },
                {
                    color: '#FFC001',
                    text: 'อ้วน'
                },
                {
                    color: '#FF0000',
                    text: 'อ้วนอันตราย'
                }
            ]
        } else if (type === 'blood_pressure') {
            if (patientType === 'normal') {
                list = [
                    {
                        color: '#92D050',
                        text: 'ปกติ'
                    },
                    {
                        color: '#FFFF02',
                        text: 'เกือบสูง'
                    },
                    {
                        color: '#FFC001',
                        text: 'สูง'
                    },
                    {
                        color: '#FF0000',
                        text: 'อันตราย'
                    },
                ]

            } else if (patientType === 'sick') {
                list = [
                    {
                        color: '#FF67FF',
                        text: 'ต่ำกว่าเกณฑ์'
                    },
                    {
                        color: '#92D050',
                        text: 'เหมาะสม'
                    },
                    {
                        color: '#FFFF02',
                        text: 'สูงเล็กน้อย'
                    },
                    {
                        color: '#FFC001',
                        text: 'สูงปานกลาง'
                    },
                    {
                        color: '#FF0000',
                        text: 'สูงอันตราย'
                    },
                ]

            }
        } else if (type === 'blood_sugar') {
            if (patientType === 'normal') {
                if (time === '0') {
                    list = [
                        {
                            color: '#92D050',
                            text: 'ปกติ'
                        },
                        {
                            color: '#FFFF02',
                            text: 'เสี่ยงเบาหวาน'
                        },
                        {
                            color: '#FFC001',
                            text: 'สงสัยป่วยเบาหวาน'
                        },

                    ]

                } else if (time === '8') {
                    list = [
                        {
                            color: '#FF67FF',
                            text: 'ต่ำ'
                        },
                        {
                            color: '#92D050',
                            text: 'ปกติ'
                        },
                        {
                            color: '#FFFF02',
                            text: 'เสี่ยงเบาหวาน'
                        },
                        {
                            color: '#FFC001',
                            text: 'สงสัยป่วยเบาหวาน'
                        },
                    ]
                }
            } else if (patientType === 'sick') {
                if (time === '2') {
                    list = [
                        {
                            color: '#690E8A',
                            text: 'ต่ำรุนแรง'
                        },
                        {
                            color: '#92D050',
                            text: 'เหมาะสม'
                        },
                        {
                            color: '#FFFF02',
                            text: 'สูงกว่าเป้าหมาย'
                        },
                        {
                            color: '#FFC001',
                            text: 'สูงมาก'
                        },
                        {
                            color: '#FF0000',
                            text: 'สูงอันตรายถึงชีวิต'
                        }
                    ]

                } else if (time === '8') {
                    list = [
                        {
                            color: '#690E8A',
                            text: 'ต่ำรุนแรง'
                        },
                        {
                            color: '#92D050',
                            text: 'เหมาะสม'
                        },
                        {
                            color: '#FFFF02',
                            text: 'สูงกว่าเป้าหมาย'
                        },
                        {
                            color: '#FFC001',
                            text: 'สูงมาก'
                        },
                        {
                            color: '#FF0000',
                            text: 'สูงอันตรายถึงชีวิต'
                        }
                    ]
                }
            }
        } else if (type === 'waist_circumference') {
            list = [
                {
                    color: '#92D050',
                    text: 'เหมาะสม'
                },
                {
                    color: '#FFFF02',
                    text: 'มีภาวะอ้วนลงพุง'
                }
            ]

        }


        return (
            <div className={styles.colorItemComponent}>
                {
                    list.map(item => {
                        return (
                            <div className={styles.colorItem}>
                                <div className={styles.color} style={{ background: item['color'] }}></div>
                                <div className={styles.text}>{item['text']}</div>
                            </div>
                        )
                    })

                }
            </div>
        )
    }

    checkDisableSelect(type) {
        let selectList = ['health_area', 'province', 'district', 'sub_district', 'hospital']
        let disableList = []

        for (let index = 0; index < selectList.length; index += 1) {
            disableList.push(selectList[index])
            if (selectList[index] == this.state.disableType) {
                break
            }
        }

        if (disableList.includes(type)) {
            return true
        } else {
            return false
        }
    }

    //render

    render() {
        return (
            <Spin tip='โปรดรอ...' spinning={this.state.loading}>
                {this.state.haveParamToken ?
                    <div className={styles.content}>

                        <div className={styles.titleBar}>เลือกข้อมูล</div>
                        <div className={styles.rowSelect}>
                            {/* {this.selectComponent('health_area', this.state.healthAreaId, this.state.healthAreaList, false, 'เลือกเขตสุขภาพ')}
                      {this.selectComponent('province', this.state.provinceId, this.state.provinceList, this.state.healthAreaId ? false : true, 'เลือกจังหวัด')} */}
                            {this.selectComponent('health_area', this.state.healthAreaId, this.state.healthAreaList, this.checkDisableSelect('health_area'), 'เลือกเขตสุขภาพ')}
                            {this.selectComponent('province', this.state.provinceId, this.state.provinceList, (this.state.healthAreaId ? false : true) || (this.checkDisableSelect('province')), 'เลือกจังหวัด')}
                            {this.selectComponent('district', this.state.districtId, this.state.districtList, (this.state.provinceId ? false : true) || (this.checkDisableSelect('district')), 'เลือกอำเภอ')}
                            {this.selectComponent('sub_district', this.state.subDistrictId, this.state.subDistrictList, (this.state.districtId ? false : true) || (this.checkDisableSelect('sub_district')), 'เลือกตำบล')}
                            {this.selectComponent('hospital', this.state.hospitalId, this.state.hospitalList, (this.state.subDistrictId ? false : true) || (this.checkDisableSelect('hospital')), 'เลือกสังกัด')}
                        </div>
                        <div className={styles.titleBar}>เลือกช่วงเวลา</div>
                        <div className={styles.rowSelect}>
                            {this.selectComponent('year', this.state.yearId, this.state.yearList, false, 'เลือกปีงบประมาณ')}
                            {this.selectComponent('quarter', this.state.quarterId, this.state.quarterList, this.state.yearId ? false : true, 'เลือกไตรมาส')}
                            {this.selectComponent('month', this.state.monthId, this.state.monthList, this.state.quarterId ? false : true, 'เลือกเดือน')}
                        </div>
                        <div className={styles.titleBar}>เลือกกลุ่มรายงาน</div>
                        <div className={styles.rowSelect}>
                            {this.selectComponent('group', this.state.groupId, this.state.groupList, false, 'เลือกกลุ่มรายงาน')}
                        </div>

                        {
                            ['blood_pressure', 'blood_sugar'].includes(this.state.groupId) ?
                                <div className={styles.subSelectContainer}>
                                    <div className={styles.key}>กลุ่มผู้ป่วย</div>
                                    {this.selectComponent('platform_type', this.state.platformTypeId, this.state.platformTypeList, false, 'เลือกกลุ่มผู้ป่วย')}
                                </div>
                                :
                                null
                        }

                        {
                            ['blood_sugar'].includes(this.state.groupId) ?
                                <div className={styles.subSelectContainer}>
                                    <div className={styles.key}>เวลางดอาหาร</div>
                                    {this.selectComponent('time', this.state.timeId, this.state.timeList, false, 'เลือกเวลางดอาหาร')}
                                </div>
                                :
                                null
                        }

                        <div className={styles.titleTable}>
                            <div className={styles.text}>ตารางแสดงจำนวนการคัดกรอง NCDs</div>
                            {/* {this.state.hospitalId ? <div className={styles.exportButton} onClick={() => { this.exportButton() }}>Export Excel</div> : null} */}

                        </div>
                        {
                            this.state.noData ?
                                <div style={{
                                    marginTop: 80,
                                    display: 'flex',
                                    height: 100,
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    <div style={{
                                        fontSize: 18
                                    }}>ไม่พบข้อมูล</div>
                                </div> :
                                <div>
                                    {
                                        this.state.groupId == '' ? <div className={styles.numberPerPage}>
                                            <div>จำนวนแถว : </div>
                                            <input
                                                value={this.state.numberPerPage}
                                                onChange={(e) => {
                                                    this.setState({ numberPerPage: e.target.value })
                                                }}
                                            />
                                            <div className={styles.numberPerPageButton} onClick={() => { this.getTableData() }}>ตกลง</div>

                                        </div> : null
                                    }

                                    {
                                        this.state.groupId != '' ? 
                                        <div 
                                            className={styles.numberPerPage}
                                        >
                                            <div>ค้นหา : </div>
                                            <input
                                                value={this.state.searchName}
                                                style={{ width: 200, textAlign: 'left', paddingLeft: 7 }}
                                                onChange={(e) => {
                                                    this.setState({ searchName: e.target.value })
                                                }}
                                            ></input>
                                        </div> : null
                                    }

                                    <div className={styles.slideTable}>
                                        <Table
                                            className={styles.table}
                                            columns={this.state.columns}
                                            dataSource={this.state.groupId != '' ? this.state.dataSource.filter((item, index) => {
                                                return String(item['name']).includes(this.state.searchName)
                                            }) : this.state.dataSource}
                                            pagination={this.state.groupId != ''}
                                        />
                                    </div>

                                    {
                                        this.state.groupId == '' ? <div className={styles.pager}>
                                            <div className={styles.pageButton} onClick={() => { this.onPagerClick('back') }}><LeftOutlined /></div>
                                            <div className={styles.pageNumber}>{this.state.page}/{this.state.maxPage}</div>
                                            <div className={styles.pageButton} onClick={() => { this.onPagerClick('forword') }}><RightOutlined /></div>
                                        </div> : null
                                    }


                                </div>
                        }

                        {
                            this.state.filterAddressType === 'hospital' ?
                                <>
                                    <div style={{ marginTop: 15 }}>คำอธิบายค่าสี</div>
                                    {this.state.groupId === '' ?
                                        <>
                                            <div className={styles.subSelectContainer}>
                                                <div className={styles.key}>เลือกกลุ่มรายงาน</div>
                                                {this.selectComponent('group_color', this.state.groupColorId, this.state.groupColorList, false, 'เลือกกลุ่มผู้ป่วย')}
                                            </div>

                                            {this.state.groupColorId === 'bmi' ? this.colorItemComponent('bmi', '', '') : null}


                                            {this.state.groupColorId === 'blood_pressure' ? <div className={styles.colorKey}>กลุ่มที่ยังไม่ป่วยเป็นโรคความดันโลหิต</div> : null}
                                            {this.state.groupColorId === 'blood_pressure' ? this.colorItemComponent('blood_pressure', 'normal', '') : null}
                                            {this.state.groupColorId === 'blood_pressure' ? <div className={styles.colorKey}>กลุ่มที่ป่วยเป็นโรคความดันโลหิต</div> : null}
                                            {this.state.groupColorId === 'blood_pressure' ? this.colorItemComponent('blood_pressure', 'sick', '') : null}

                                            {this.state.groupColorId === 'blood_sugar' ? <div className={styles.colorKey}>กลุ่มที่ยังไม่ป่วยเป็นโรคเบาหวาน (ไม่งดอาหาร)</div> : null}
                                            {this.state.groupColorId === 'blood_sugar' ? this.colorItemComponent('blood_sugar', 'normal', '0') : null}
                                            {this.state.groupColorId === 'blood_sugar' ? <div className={styles.colorKey}>กลุ่มที่ยังไม่ป่วยเป็นโรคเบาหวาน (งดอาหารอย่างน้อย 8 ชั่วโมง)</div> : null}
                                            {this.state.groupColorId === 'blood_sugar' ? this.colorItemComponent('blood_sugar', 'normal', '8') : null}
                                            {this.state.groupColorId === 'blood_sugar' ? <div className={styles.colorKey}>กลุ่มที่ป่วยเป็นโรคเบาหวาน</div> : null}
                                            {this.state.groupColorId === 'blood_sugar' ? this.colorItemComponent('blood_sugar', 'sick', '2') : null}

                                            {this.state.groupColorId === 'waist_circumference' ? this.colorItemComponent('waist_circumference', '', '') : null}


                                        </>
                                        :
                                        <></>
                                    }
                                    {this.state.groupId === 'bmi' ? this.colorItemComponent('bmi', '', '') : null}


                                    {this.state.groupId === 'blood_pressure' ? <div className={styles.colorKey}>กลุ่มที่ยังไม่ป่วยเป็นโรคความดันโลหิต</div> : null}
                                    {this.state.groupId === 'blood_pressure' ? this.colorItemComponent('blood_pressure', 'normal', '') : null}
                                    {this.state.groupId === 'blood_pressure' ? <div className={styles.colorKey}>กลุ่มที่ป่วยเป็นโรคความดันโลหิต</div> : null}
                                    {this.state.groupId === 'blood_pressure' ? this.colorItemComponent('blood_pressure', 'sick', '') : null}

                                    {this.state.groupId === 'blood_sugar' ? <div className={styles.colorKey}>กลุ่มที่ยังไม่ป่วยเป็นโรคเบาหวาน (ไม่งดอาหาร)</div> : null}
                                    {this.state.groupId === 'blood_sugar' ? this.colorItemComponent('blood_sugar', 'normal', '0') : null}
                                    {this.state.groupId === 'blood_sugar' ? <div className={styles.colorKey}>กลุ่มที่ยังไม่ป่วยเป็นโรคเบาหวาน (งดอาหารอย่างน้อย 8 ชั่วโมง)</div> : null}
                                    {this.state.groupId === 'blood_sugar' ? this.colorItemComponent('blood_sugar', 'normal', '8') : null}
                                    {this.state.groupId === 'blood_sugar' ? <div className={styles.colorKey}>กลุ่มที่ป่วยเป็นโรคเบาหวาน</div> : null}
                                    {this.state.groupId === 'blood_sugar' ? this.colorItemComponent('blood_sugar', 'sick', '2') : null}

                                    {this.state.groupId === 'waist_circumference' ? this.colorItemComponent('waist_circumference', '', '') : null}

                                    <div style={{ marginTop: 15 }}>หมายเหตุ : หากสีไม่ตรง แสดงว่าเป็นค่าสีเดิมที่เคยบันทึกเข้ามา</div>
                                </>
                                : null
                        }

                    </div>
                    : <div className={styles.noAuthen}>ยืนยันตัวตนไม่สำเร็จ</div>
                }
            </Spin>
        )
    }
}
