import React, { Component } from 'react'
import { Menu } from 'antd'
import styles from './HomeNew.module.css'
import HealthStationNew from '../HealthStationNew/HealthStationNew'
import MedicineList from '../medicine_list/MedicineList'
import UserManagement from '../UserManagement/UserManagement'
import {
  getUserData
} from '../services/api.js'
export default class HomeNew extends Component {
  constructor(props) {
    super(props)
    this.state = {
      items: [
        {
          key: 'health_station',
          label: 'สถิติการบันทึกข้อมูล health station'
        },
        {
          key: 'home_medicine',
          label: 'รายชื่อผู้ป่วยรอรับยาที่บ้าน'
        },
        {
          key: 'user_management',
          label: 'จัดการผู้ใช้งาน'
        }
      ],
      menu: 'health_station'
    }

  }

  componentDidMount() {
    this.getUserDataApi()
  }

  async getUserDataApi() {
    let res = await getUserData()
    console.log(res)
    if (res) {
      if (res['data']['code'] === 200) {

      } else {
        alert('Authorization is expire.')
      }
    } else {
      alert('Authorization is expire.')
    }
  }

  onMenuClick(key) {
    this.setState({
      menu: key
    })
  }

  onLogoutButtonClick() {
    let confirm = window.confirm('ยืนยันการออกจากระบบ')
    if (confirm) {
      localStorage.clear()
      this.props.history.push('/guest')
    }
  }

  render() {
    return (
      <div className={styles.body}>

        <Menu
          defaultSelectedKeys={['0']}
          className={styles.menu}
        >

          {this.state.items.map((item, index) => {
            return <>
              <Menu.Item key={index} onClick={() => { this.onMenuClick(item['key']) }}>{item['label']}</Menu.Item>
            </>
          })}


          <div className={styles.logoutButton} onClick={() => { this.onLogoutButtonClick() }}>ออกจากระบบ</div>

        </Menu>

        <div className={styles.content}>
          {this.state.menu === 'health_station' ? <HealthStationNew /> : null}
          {this.state.menu === 'home_medicine' ? <MedicineList /> : null}
          {this.state.menu === 'user_management' ? <UserManagement /> : null}
        </div>
      </div>
    )
  }
}
