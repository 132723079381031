import { withRouter } from 'react-router-dom';
import { Row, Col, Button, Table, Modal } from 'antd';
import React from "react";
import { PlusOutlined } from '@ant-design/icons';
import './style.css';
var axios = require('axios');

const columns = [
    {
        title: <div style={{ color: '#5C2D91' }}>บัญชี ONED ID</div>,
        dataIndex: "onesdid3",
        key: "onesdid3",
        align: 'center',
        render(text, record) {
            return {
                props: {
                    style: { color: '#5C2D91' }
                },
                children: <div style={{ padding: '10px' }}>{text}</div>
            };
        }
    },
    {
        title: <div style={{ color: '#5C2D91' }}>ชื่อ - นามสกุล</div>,
        dataIndex: "name",
        key: 'name',
        align: 'center',
        render(text, record) {
            return {
                props: {
                    style: { color: '#5C2D91' }
                },
                children: <div style={{ padding: '10px' }}>{text}</div>
            };
        }
    },
    {
        title: <div style={{ color: '#5C2D91' }}>สถานะผู้ใช้งาน</div>,
        dataIndex: "activate",
        key: 'activate',
        align: 'center',
        render(text, record) {
            let activate = ''
            let check_color = ''
            if (record.activate === true) {
                activate = 'อนุมัติ'
                check_color = '#159269'
            } else {
                activate = 'รอดำเนินการ'
                check_color = '#F37979'
            }
            return {
                props: {
                    style: { color: check_color }
                },
                children: <div style={{ padding: '10px' }}>{activate}</div>
            };
        }
    },
];

class manage_user_accounts extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            modalVisible: false,
        }
    }


    async componentDidMount() {

        var config = {
            method: 'post',
            url: 'https://ncds.numedapp.com/api/dashboard/v2/h4u/OnesDiD/adminGetListUser',
            headers: {
                'Authorization': 'X94sz4ieiPZvKBY835lqBss1L2a3S4hmDwTjC8WWueu8ZaQV46MPNp6YX5tuSMJuvkrzMXutB5PJu9vQf03M4n1EEqw6oEzwYwYGS1zCmc3OwKLrs1L2a3S4hiqISyJOAd1wFjKgwGqpks1L2a3S4hBA8s1L2a3S4hTg5jITztAjX8WASuU6rS7r2lp1L2u3S9maCbzkgrfS65OiXnIAugQa54GbaInEgr4s1L2a3S4h3GNmSOI5p1L2u3S8cDxpNsNik2iahImdzjxj0JcxFfbjsP3vFrfzRY5nOJb9xpEMnSIe1Q2u3A4l'
            }
        };

        let result = await axios(config).then(function (response) { return response.data.result }).catch(function (error) { return '' });
        if (result) {
            result.map((element, index) => { element.key = index + 1 })
        }
        this.setState({ dataSource: result });
    }

    render() {
        console.log(this.state.dataSource);
        return <>
            <Row style={{ paddingTop: '90px' }}>
                <Col sm={{ span: 24 }} xs={{ span: 24 }} coll="true">
                    <div style={{ display: 'flex', justifyContent: 'end', paddingRight: '150px' }}>
                        <Button
                            onClick={() => this.setState({ modalVisible: true })}
                            style={{
                                backgroundColor: '#5C2D91',
                                color: 'white',
                                borderRadius: '20px'
                            }}
                        >
                            <PlusOutlined style={{ color: 'white' }} />เพิ่มผู้ใช้งาน
                        </Button>
                    </div>
                </Col>
            </Row>

            <Row justify="center" style={{ paddingTop: '70px' }}>
                <Col sm={{ span: 14 }} xs={{ span: 24 }} coll="true">
                    <Table dataSource={this.state.dataSource} columns={columns} />
                </Col>
            </Row>

            <Modal
                centered
                title="Vertically centered modal dialog"
                visible={this.state.modalVisible}
                onOk={() => this.setState({ modalVisible: false })}
                onCancel={() => this.setState({ modalVisible: false })}
                maskClosable={false}
            >
                <p>some contents...</p>
                <p>some contents...</p>
                <p>some contents...</p>
            </Modal>
        </>
    }
}

export default withRouter(manage_user_accounts);
