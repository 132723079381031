import React, { Component } from 'react'
import styles from './RegisterEmail.module.css'
import {
  Input,
  Select
} from 'antd';
import {
  Modal
} from 'react'
import {
  checkPid
} from '../services/api'

const { Option } = Select

export default class RegisterEmail extends Component {

  constructor(props) {
    super(props)

    this.state = {
      pid: '',
      pname: '',
      fname: '',
      lname: '',
      mobile: '',
      email: '',
      password: '',
      confirmPassword: ''
    }
  }

  pnameList = ['นาย', 'นาง', 'นางสาว']

  componentDidMount() {
    if (localStorage.getItem('register-data')) {
      let registerData = JSON.parse(localStorage.getItem('register-data'))
      this.setState({
        pid: registerData['pid'],
        pname: registerData['pname'],
        fname: registerData['fname'],
        lname: registerData['lname'],
        mobile: registerData['mobile'],
        email: registerData['email'],
        password: registerData['password'],
        confirmPassword: registerData['confirm_password']
      })
    }
  }


  backButton() {
    this.props.history.push({
      pathname: '/guest',
      query: ''
    });
  }

  nextButton() {
    console.log(this.state.pid)
    console.log(checkPid(this.state.pid))
    if (!checkPid(this.state.pid)) {
      alert('กรุณากรอกเลขบัตรประชาชนให้ถูกต้อง')
      return
    }
    if (!this.state.fname) {
      alert('กรุณากรอกชื่อ')
      return
    }
    if (!this.state.lname) {
      alert('กรุณากรอกนามสกุล')
      return
    }
    if (!this.state.mobile) {
      alert('กรุณากรอกเบอร์โทร')
      return
    }
    if (!this.state.email) {
      alert('กรุณากรอกอีเมล')
      return
    }
    if (!this.verifyPassword(this.state.password)) {
      alert('รหัสผ่านต้องประกอบไปด้วย ตัวเลข, ตัวอักษร และต้องมี 6 หลักขึ้นไป')
      return
    }
    if (this.state.password != this.state.confirmPassword) {
      alert('รหัสผ่านไม่ตรงกัน')
      return
    }

    localStorage.setItem('register-data', JSON.stringify({
      pid: this.state.pid,
      pname: this.state.pname,
      fname: this.state.fname,
      lname: this.state.lname,
      mobile: this.state.mobile,
      email: this.state.email,
      password: this.state.password,
      confirm_password: this.state.confirmPassword
    }))

    this.props.history.push({
      pathname: '/register-level',
      query: ''
    });
  }

  verifyPassword(password) {
    let regex = /^.*(?=.{6,})(?=.*\d)(?=.*[a-zA-Z]).*$/g
    return String(password).match(regex)
  }

  render() {
    return (
      <div>
        <div className={styles.header}>
          <div className={styles.backButton} onClick={() => this.backButton()}>
            <img src={require('../icon/back-icon.svg').default}></img>
            <span>ย้อนกลับ</span>
          </div>
        </div>

        <div className={styles.content}>
          <img className={styles.logo} src={require('../icon/logo-smart-city.svg').default}></img>
          <div className={styles.title}>สมัครใช้งาน</div>

          <div className={styles.registerCard}>
            <div className={styles.titleCard}>กรุณาระบุข้อมูลผู้ใช้งาน</div>

            <input className={styles.input} value={this.state.pid} onChange={(e) => { this.setState({ pid: e.target.value }) }} placeholder='เลขบัตรประชาชน' />
            <Select
              style={{
                width: '100%',
                marginTop: 10
              }}
              value={this.state.pname}
              onChange={(data) => { this.setState({ pname: data }) }}
            >
              <Option value=''>เลือกคำนำหน้า</Option>
              {
                this.pnameList.map(item => {
                  return <Option value={item}>{item}</Option>
                })
              }
            </Select>
            <input className={styles.input} value={this.state.fname} onChange={(e) => { this.setState({ fname: e.target.value }) }} placeholder='ชื่อ' />
            <input className={styles.input} value={this.state.lname} onChange={(e) => { this.setState({ lname: e.target.value }) }} placeholder='นามสกุล' />
            <input className={styles.input} value={this.state.mobile} onChange={(e) => { this.setState({ mobile: e.target.value }) }} placeholder='เบอร์โทร' />
            <input className={styles.input} value={this.state.email} onChange={(e) => { this.setState({ email: e.target.value }) }} placeholder='อีเมล' />
            <input className={styles.input} type='password' value={this.state.password} onChange={(e) => { this.setState({ password: e.target.value }) }} placeholder='ระบุรหัสผ่าน' />
            <input className={styles.input} type='password' value={this.state.confirmPassword} onChange={(e) => { this.setState({ confirmPassword: e.target.value }) }} placeholder='ยืนยันรหัสผ่าน' />

            <div className={styles.nextButton} onClick={() => this.nextButton()}>ถัดไป</div>
          </div>

          <div className={styles.footer}>
            <div className={styles.footerText}>ระบบเพื่อการประมวลผลค่าสัญญาณชีพและการรับยาที่บ้านในกลุ่มงานโรคไม่ติดต่อเรื้อรัง ผ่านแอปพลิเคชัน H4U สมาร์ท อสม. และระบบในเครือข่าย</div>
            <div className={styles.footerLogo}>
              <img className={styles.logo} src={require('../icon/ict-icon.svg').default}></img>
              <img className={styles.logo} src={require('../icon/nu-icon.svg').default}></img>
            </div>
          </div>

        </div>
      </div>
    )
  }
}
