import React from "react";
import { withRouter } from 'react-router-dom';
import { Row, Col, Button, Image, Avatar } from 'antd';
import { EnvironmentOutlined } from '@ant-design/icons';
import styles from './healthService.module.css'
import {
  getUserData
} from '../services/api'

class health_services extends React.Component {
  constructor() {
    super();

    this.state = {
      hospitalName: '-'
    }
  }


  componentDidMount() {
    this.getUserDataApi()
  }

  async getUserDataApi() {
    let res = await getUserData()
    if (res) {
      if (res['data']['code'] === 200) {
        console.log(res)
        let user = res['data']['user']

        this.setState({
          hospitalName: user['affiliation']['name']
        })
      } else {
        alert(res['data']['message'])
      }
    } else {
      alert('API getUserData : ไม่ตอบสนอง')
    }
  }

  


  render() {
    return (
      <>
        <Row style={{ paddingTop: '90px' }}>
          <Col sm={{ span: 24 }} xs={{ span: 24 }} coll="true">
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '0px 150px 0px 150px' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {/* <Avatar
                  size={80}
                  src={<Image src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png" />}
                /> */}
                <div style={{ paddingLeft: '20px' }}>
                  <span style={{ color: '#5C2D91', fontSize: 20 }}>{this.state.hospitalName}</span><br />
                  {/* <span style={{ color: '#5A39A7' }}><EnvironmentOutlined style={{ color: '#D0D0FF' }} /> จังหวัด พิษณุโลก</span> */}
                </div>
              </div>
              {/* <Button onClick={() => this.props.history.push({ pathname: '/manage_user_accounts' })} style={{ backgroundColor: '#5C2D91', color: 'white', borderRadius: '20px' }}>จัดการบัญชีผู้ใช้</Button> */}
            </div>
          </Col>
        </Row>

        <Row style={{ paddingTop: '150px' }}>
          <Col sm={{ span: 24 }} xs={{ span: 24 }} coll="true" style={{ textAlign: 'center' }}>
            <div className={styles.menuContainer} style={{ display: 'flex', justifyContent: 'space-evenly' }}>
              <div onClick={() => this.props.history.push({ pathname: '/home' })}>
                <img src={require('../icon/icon-Ncds.svg').default} alt="logo_nux" className="App-logo ncds size-menu"  />
              </div>
              <div>
                <img src={require('../icon/icon-Hi-Ci.svg').default} alt="logo_nux" className="App-logo grayscale hidden600 size-menu" />
              </div>
              <div >
                <img src={require('../icon/icon-ATK.svg').default} alt="logo_nux" className="App-logo grayscale hidden600 size-menu"  />
              </div>
            </div>
          </Col>
        </Row>
      </>
    )
  }
}

export default withRouter(health_services);