import { withRouter } from 'react-router-dom';
import React from "react";
import styles from './style.module.css';
import Qrcode from "qrcode.react";
import Api from '../lib/api/api';

class LoginPage extends React.Component {

    constructor() {
        super();
        this.setTimer = this.setTimer.bind(this);
        this.setApiTimer = this.setApiTimer.bind(this);
        this.loginCheck = this.loginCheck.bind(this);
        this.qrCodeCheck = this.qrCodeCheck.bind(this);
    }

    state = {
        qr: '',
        loginType: 'scan', // input, scan
        countdown: 0, // 120
        apiTimeInterval: 120,
        countdownInterval: null,
        apiCheckInterval: null,
        oneid: '',
        authReqId: '',
        showCountdown: false
    }

    async componentDidMount() {

        this.initialStateCheck();
    }

    componentWillUnmount() {
        clearInterval(this.state.apiCheckInterval);
        clearInterval(this.state.countdownInterval);
    }

    onclick_check() {
        if (this.state.showCountdown === true) {
            return;
        }
        if (this.state.oneid === '') {
            alert('กรุณากรอกข้อมูลให้ครบถ้วน');
            return;
        }
        this.getAuthId();
    }

    onclick_to_inputDid() {
        this.setState({
            loginType: 'input'
        }, () => {
            this.resetState();
        });
    }

    onclick_to_qrScan() {
        this.setState({
            loginType: 'scan'
        }, () => {
            this.resetState();
        });
    }

    onclick_register() {
        this.props.history.push({
            pathname: '/register',
            query: ''
        });
    }


    // ============================
    async initialStateCheck() {
        clearInterval(this.state.apiCheckInterval);
        clearInterval(this.state.countdownInterval);
        if (this.state.loginType === 'scan') {
            await this.getQrApi();
            var apiCheckInterval = setInterval(this.qrCodeCheck, 3000);
            this.setState({
                apiCheckInterval: apiCheckInterval
            });
        }

        if (this.state.loginType === 'input') {


        }
    }

    resetState() {
        this.setState({
            showCountdown: false,
            countdown: this.state.apiTimeInterval
        });
        this.initialStateCheck();
    }

    handleInput(event) {
        this.setState({
            oneid: event.target.value
        });
    }

    startCountdown() {
        var countdownInterval = setInterval(this.setTimer, 1000);
        var apiCheckInterval = setInterval(this.setApiTimer, 3000);
        this.setState({
            countdownInterval: countdownInterval,
            apiCheckInterval: apiCheckInterval,
            showCountdown: true
        });
    }

    setTimer() {
        console.log(this.state.countdown);
        if (this.state.countdown <= 0) {
            alert('หมดเวลา');
            clearInterval(this.state.countdownInterval);
            this.resetState();
        } else {
            this.setState({
                countdown: this.state.countdown - 1
            });
        }

    }

    setApiTimer() {
        this.loginCheck();
    }

    getQrApi() {
        return new Promise((resolve, reject) => {
            Api.GetDiDQr().then((response) => {
                console.log(response.data);
                let result = response.data;
                if (result['code'] === 200) {
                    this.setState({
                        authReqId: result['qr_code']['auth_req_id'],
                        qr: JSON.stringify(result['qr_code']),
                    }, () => {

                        resolve(null);
                    });
                } else {
                    resolve(null);
                }
            }).catch(err => {
                console.log(err);
                resolve(null);
            });
        });
    }

    getAuthId() {
        Api.DidAuth(this.state.oneid).then((res) => {
            console.log(res.data);
            let result = res.data;
            if (result['code'] === 200) {
                this.setState({
                    authReqId: result['auth_req_id']
                }, () => {
                    this.startCountdown();
                });
            } else {

            }
        }).catch((err) => {
            console.log(err);
        });
    }

    loginCheck() {
        if (this.state.authReqId === '') {
            return;
        }
        Api.CheckDidAuth(this.state.authReqId).then((res) => {
            let result = res.data;
            if (result["code"] === 200) {
                clearInterval(this.state.apiCheckInterval);
                clearInterval(this.state.countdownInterval);
                //    alert('log in สำเร็จ');
                let access_token = result['access_token'];
                // console.log(access_token);
                this.getUserDataOneDidApi(access_token);
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    qrCodeCheck() {
        if (this.state.authReqId === '') {
            return;
        }
        Api.GetQrCodeData(this.state.authReqId).then((res) => {
            let result = res.data;
            console.log(result);
            if (result["code"] === 200) {
                if (result["status"] === 'approved') {
                    clearInterval(this.state.apiCheckInterval);
                    //    alert('log in สำเร็จ');
                    let access_token = result['access_token'];
                    // console.log(access_token);
                    this.getUserDataOneDidApi(access_token);
                }
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    getUserDataOneDidApi(token) {
        // console.log(token);
        Api.GetUserData(token).then((res) => {
            let result = res.data;
            if (result["code"] === 200) {
                let userData = result["ones_did_user_data"];
                let login = {
                    "onesdid3": userData.username,
                    "pid": userData.additionalInfo.identityDocumentNumber
                }
                Api.Login(login).then((_res) => {
                    console.log(_res);
                    let _result = _res.data;
                    if (_result["code"] === 200) {
                        localStorage.setItem('Authorization', _result.access_token);
                        this.props.history.push({
                            pathname: '/Health_services'
                        });
                    } else {
                        alert("ไม่พบข้อมูล");
                    }
                }).catch((err) => {
                    // alert("พบข้อผิดพลาด");
                });
            } else {
                alert("ไม่พบข้อมูล");
            }
        }).catch((err) => {
            console.log(err);
            alert("พบข้อผิดพลาด");
        });
    }

    qrScanComponent() {
        return (
            <>
                <div>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        paddingTop: '55px'
                    }}>
                        <img src={require('../icon/smartcity_login.svg').default} alt="logo_nux" className="App-logo" style={{ width: '187px', height: '76px' }} />
                        <div className={styles.border_login_main}>
                            <div className={styles.card_container_login_text}>
                                <span>เข้าสู่ระบบ</span>
                            </div>
                            <div>
                                {
                                    this.state.qr !== '' && <Qrcode size={200} value={this.state.qr} />
                                }
                            </div>
                            <div className={styles.card_container_login_flow_text}>
                                <span>ขั้นตอนการเข้าสู่ระบบด้วย Digital ID</span>
                            </div>
                            <div className={styles.card_container_login_tutorial_container}>
                                <div className={styles.card_container_login_tutorial_item}>
                                    <div className={styles.card_container_login_tutorial_bullet}></div>
                                    <span className={styles.card_container_login_tutorial_text}>
                                        เปิดแอพพลิเคชัน Ones ID
                                    </span>
                                </div>
                                <div className={styles.card_container_login_tutorial_item}>
                                    <div className={styles.card_container_login_tutorial_bullet}></div>
                                    <span className={styles.card_container_login_tutorial_text}>
                                        กดที่รูปโปรไฟล์ของคุณ
                                    </span>
                                </div>
                                <div className={styles.card_container_login_tutorial_item}>
                                    <div className={styles.card_container_login_tutorial_bullet}></div>
                                    <span className={styles.card_container_login_tutorial_text}>
                                        กดเมนู Manage Accounts เลือก QR Scanner
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.login_by_oneid_container}>
                    <span className={styles.login_by_oneid_text1}>หรือเข้าสู่ระบบด้วย </span>
                    <span onClick={() => this.onclick_to_inputDid()} className={styles.login_by_oneid_text2}>ONES DID3</span>
                </div>
            </>
        );
    }

    inputComponent() {
        return (
            <>
                <div>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        paddingTop: '55px'
                    }}>
                        <img src={require('../icon/smartcity_login.svg').default} alt="logo_nux" className="App-logo" style={{ width: '187px', height: '76px' }} />
                        <div className={styles.border_login_main}>
                            <div className={styles.input_login_text}>
                                <span>เข้าสู่ระบบ</span>
                            </div>
                            <div className={styles.input_onedid_text}>
                                <span>OneDID</span>
                            </div>
                            {
                                this.state.showCountdown &&
                                <div className={styles.input_countdown_text}>
                                    <span>เวลาที่เหลือ {this.state.countdown} วินาที</span>
                                </div>
                            }

                            <div className={styles.input_onedid_container}>
                                <input
                                    className={styles.input}
                                    placeholder={'Ones DiD 3'}
                                    value={this.state.oneid}
                                    onChange={(event) => this.handleInput(event)}></input>
                            </div>
                            <div className={styles.input_button_container}>
                                <div onClick={() => this.onclick_check()} disabled={this.state.showCountdown}>
                                    ตรวจสอบ
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.login_by_oneid_container}>
                    <span className={styles.login_by_oneid_text1}>หรือ เข้าสู่ระบบด้วย  </span>
                    <span onClick={() => this.onclick_to_qrScan()} className={styles.login_by_oneid_text2}>QR Code</span>
                </div>
            </>
        );
    }

    body() {
        return (
            <div>
                {
                    this.state.loginType === 'scan' ? this.qrScanComponent() : this.inputComponent()
                }

                <div onClick={() => this.onclick_register()}>
                    <div className={styles.button_container}>
                        <div className={styles.button_container_text}>
                            ยังไม่มีบัญชีผู้ใช้งาน
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    render() {
        return (
            <>
                {this.body()}
            </>
        );
    }
}

export default withRouter(LoginPage);