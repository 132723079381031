import React, { useState } from "react";
import { Row, Col, Card, Form, Button, Modal } from "react-bootstrap";
import { Redirect, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import "yup-phone";
import axios from "axios";
import iconLogo from "./../icon/smartcity_login.svg";
import iconDoctor from "./../icon/iconDoctor.svg";
import iconFooterLogin from "./../icon/iconFooterLogin.svg";
import iconArrowLeftBackward from "./../icon/iconArrowLeftBackward.svg";
import iconSuccess from "./../icon/iconSuccess.svg";
function Resetpassword() {
  let history = useHistory();
  let url = decodeURIComponent(window.location.href);
  console.log(url);
  let token = "";
  let _token;
  if (url.includes("credentialtoken=")) {
    token = url.split("credentialtoken=")[1];
    if (token.includes("&")) {
      token = token.split("&")[0];
    }

    _token = token.split(" ").join("+");
  } else {
    history.push("/login");
  }

  const [showResetPasswordSuccess, setshowResetPasswordSuccess] =
    useState(false);

  const handleResetPasswordSuccessClose = () => {
    setshowResetPasswordSuccess(false);
  };

  const handleSendEmailSuccess = () => setshowResetPasswordSuccess(true);

  const MovegoPageDetail = (E) => {
    window.location.reload(E);
  };

  const MoveGoPageLogin = (e) => {
    const pushData = {
      pathname: "/login",
      formpage: "Resetpassword",
    };
    history.push(pushData);
    handleResetPasswordSuccessClose();
  };

  const Reset = useFormik({
    initialValues: {
      password: "",
      confirm_password: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required("กรุณากรอกรหัสผ่าน")
        .min(6, "ขั้นต่ำ 6 ตัวอักษร")
        .max(20, "สูงสุด 20 ตัวอักษร"),
      confirm_password: Yup.string()
        .required("กรุณายืนยันรหัสผ่าน")
        .oneOf([Yup.ref("password"), null], "ยืนยันรหัสผ่านไม่ถูกต้อง"),
    }),
    onSubmit: (values) => {
      console.log(values);

      var data = JSON.stringify({
        credential_token: _token,
        password: values.password,
        confirm_password: values.confirm_password,
      });

      var config = {
        method: "post",
        url: "https://ncds.numedapp.com/api/dashboard/v2/h4u/Email/saveResetPassword",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          if (
            response.data.message === "password low security" &&
            response.data.code === 400
          ) {
            alert(response.data.message_th);
          } else if (
            response.data.message === "success" &&
            response.data.code === 200
          ) {
            handleSendEmailSuccess();
          } else if (
            response.data.message === "credential not found" &&
            response.data.code === 404
          ) {
            alert("ไม่พบข้อมูลประจำตัว");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  });

  return (
    <div>
      <div className="container-login-nux">
        <div className="header-login-nux">
          <div className="btn-header-page">
            {/* <button className="btn-backward">
              <img src={iconArrowLeftBackward}></img>
              <div className="text-btn-backward">ย้อนกลับ</div>
            </button> */}
          </div>
        </div>
        <div className="body-login-nux">
          <form onSubmit={Reset.handleSubmit}>
            <div className="card-login-left">
              <img src={iconLogo}></img>
              <div className="title-login-nux">เปลี่ยนรหัสผ่าน</div>

              <Row>
                <Col className="col-login-nux">
                  <Form.Control
                    className="form-control-login"
                    placeholder="รหัสผ่าน"
                    type="password"
                    name="password"
                    value={Reset.values.password}
                    onChange={Reset.handleChange}
                    onBlur={Reset.handleBlur}
                  ></Form.Control>
                </Col>
                {Reset.touched.password && Reset.errors.password ? (
                  <p>{Reset.errors.password}</p>
                ) : null}
              </Row>
              <Row>
                <Col className="col-login-nux">
                  <Form.Control
                    className="form-control-login"
                    placeholder="ยืนยันรหัสผ่าน"
                    type="password"
                    name="confirm_password"
                    value={Reset.values.confirm_password}
                    onChange={Reset.handleChange}
                    onBlur={Reset.handleBlur}
                  ></Form.Control>
                </Col>
                {Reset.touched.confirm_password &&
                Reset.errors.confirm_password ? (
                  <p>{Reset.errors.confirm_password}</p>
                ) : null}
              </Row>
             
              <Row>
                <Col className="col-login-nux">
                  <Button className="btn-sumit-login" type="submit">
                    <div className="text-btn-login">บันทึก</div>
                  </Button>
                </Col>
              </Row>
            </div>
          </form>

          <div className="card-login-right">
            <img src={iconDoctor}></img>
          </div>
        </div>
        <div className="footer-login-nux">
          <div className="">
            ระบบเพื่อการประมวลผลค่าสัญญาณชีพและการรับยาที่บ้านในกลุ่มงานโรคไม่ติดต่อเรื้อรัง
          </div>
          <div>ผ่านแอปพลิเคชัน H4U สมาร์ท อสม. และระบบในเครือข่าย</div>
          <div className="">
            <img src={iconFooterLogin}></img>
          </div>
        </div>

        <Modal
          size="lg"
          centered
          show={showResetPasswordSuccess}
          onHide={MoveGoPageLogin}
        >
          <Modal.Header closeButton style={{ marginRight: "3rem" }}>
            <Modal.Title centered className="modal-title">
              <img src={iconSuccess}></img>
              <div className="text-title-center">เปลี่ยนรหัสผ่านสำเร็จ</div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-body">
            <Row>
              <Col style={{ padding: "7px" }}></Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="modal-footer">
            <buton className="btn-modal-confirm" onClick={MoveGoPageLogin}>
              <div className="font-submit">ปิด</div>
            </buton>
          </Modal.Footer>
        </Modal>

        <Modal
          size="lg"
          centered
          show={showResetPasswordSuccess}
          onHide={MoveGoPageLogin}
        >
          <Modal.Header closeButton style={{ marginRight: "3rem" }}>
            <Modal.Title centered className="modal-title">
              <img src={iconSuccess}></img>
              <div className="text-title-center">เปลี่ยนรหัสผ่านสำเร็จ</div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-body">
            <Row>
              <Col style={{ padding: "7px" }}></Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="modal-footer">
            <buton className="btn-modal-confirm" onClick={MoveGoPageLogin}>
              <div className="font-submit">ปิด</div>
            </buton>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default Resetpassword;
