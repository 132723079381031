import React, { Component } from 'react'
import styles from './HealthStationNew.module.css'
import HomeTable from '../HomeTable/HomeTable'
import HomeGraph from '../HomeGraph/HomeGraph'
export default class HealthStationNew extends Component {
  constructor(props) {
    super(props) 

    this.state = {
      menu: 'graph'
    }
  }

  onMenuClick(menu) {
    this.setState({
      menu: menu
    })
  }
  render() {
    return (
      <div className={styles.content}>
        <div className={styles.selectTypeContainer}>
          <div className={`${styles.button} ${this.state.menu === 'graph' ? styles.buttonActive : ''}`} onClick={() => {this.onMenuClick('graph')}}>กราฟวงกลม</div>
          <div className={`${styles.button} ${this.state.menu === 'table' ? styles.buttonActive : ''}`} onClick={() => {this.onMenuClick('table')}}>ตารางรายชื่อ</div>
        </div>

        { this.state.menu === 'graph' ? <HomeGraph /> : null}
        { this.state.menu === 'table' ? <HomeTable /> : null}
      </div>
    )
  }
}
