import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'antd/dist/antd.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';

import Home from './Home/Home';
import mHealth from './mHealth/mHealth';
import Pcc from './PCC/PCC';
import LoginPage from './login/login';
import Health_services from './health_services/health_services';
import Manage_user_accounts from './manage_user_accounts/manage_user_accounts';
import Symptom_monitoring from './symptom_monitoring/symptom_monitoring';
import Register from './register/register';
import PrivateRoute from './PrivateRoute';
import My404Component from './page404/My404Component';
import Login_nux_dashboard from './login_version_lately/Login_nux_dashboard'
import Resetpassword from './reset_password_nux/Resetpassword'
import RegisterEmail from './RegisterEmail/RegisterEmail'
import Guest from './guest/guest'
import RegisterLevel from './RegisterLevel/RegisterLevel'
import RegisterAddress from './RegisterAddress/RegisterAddress'
import HomeNew from './HomeNew/HomeNew'
import HealthStationMicroservice from './HealthStationMicroservice/HealthStationMicroservice'
ReactDOM.render(
  // <React.StrictMode>
  <BrowserRouter>
    <Switch>
      <Redirect exact from="/" to="/guest" />
      {/* <Route path="/login" component={LoginPage} /> */}
      <Route path="/login" component={Login_nux_dashboard}/>
      <Route path="/guest" component={Guest}/>
      {/* <Route path="/register" component={Register} /> */}
      <Route path="/register" component={RegisterEmail} />
      <Route path="/register-level" component={RegisterLevel} />
      <Route path="/register-address" component={RegisterAddress} />
      <Route path="/reset-password" component={Resetpassword}></Route>
      {/* <PrivateRoute path="/home" component={Home} /> */}
      <Route path="/home" component={HomeNew} />
      <Route path="/Health_services" component={Health_services} />
      <Route path="/Symptom_monitoring" component={Symptom_monitoring} />
      <Route path="/health-station-microservice" component={HealthStationMicroservice} />

      <Route path={["/mHealth/Authorization=:Authorization"]} component={mHealth} />
      <Route path={["/PCC/Authorization=:Authorization"]} component={Pcc} />
      {/* <Route path={["/h4u/hospital_id=:hospital_id"]} component={H4u} /> */}
      {/* <Route path={["/hospital_id=:hospital_id/type=:type", "/home"]} component={Home} /> */}

      <Route component={My404Component} path="/" />
    </Switch>
  </BrowserRouter>,
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
