import React from 'react';
import { Form, Row, Col, Select, DatePicker, Layout, Menu, Table, Spin } from 'antd';
import './App.css';
import {
    API_GetDataByType, Get_chartNormal, API_GetHospitalAll, ColumnsTable_adress, API_GetDataByHospital,
    API_GetFilterAddress, API_GetFiscal_Year, Get_chart, API_People_screened_province, API_GetDataHealthRecord,
    API_GetSumUserTotal, Get_chartSick, API_People_screenedAll, API_People_screened_district, ColumnsTable_waist,
    API_GetProvinceAll, API_GetDistrictAll, API_GetSubDistrictAll, API_GetDataByTypeTableSubDistrict, API_GetDataByTypeTableTableArea,
    API_People_screened_SubDistrict, API_GetDataByTypeTableProvince, API_GetDataByTypeTableDistrict, Export_Excel,
    API_GetDataByTypeTableProvinceAll, ColumnsTable_BMI, ColumnsTable_blood_pressure, ColumnsTable_blood_sugar,
    API_GetPeople_screenedAreaAll, API_GetPeople_screenedArea, API_GetAreaAll, API_GetDataByTypeTableTableAreaAll,
    Colums_Hospital_waist, Colums_Hospital_ALL, Colums_Hospital_BMI, Colums_Hospital_blood_pressure, Colums_Hospital_blood_sugar,getAllExportExcel
} from '../untils/function';
import { withRouter } from "react-router-dom";

import Medicine from '../medicine/Medicine'
import MedicineList from '../medicine_list/MedicineList';
import UserManagement from '../UserManagement/UserManagement';

import {
    getUserData
} from '../services/api'

const { Option } = Select;
const { Footer, Sider, Content } = Layout;
const { RangePicker } = DatePicker;

class Home extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            searchText: '',
            showMenuUserManage: false,
            Data_address: '',
            DataValue_address: '',

            health_area_name: '',
            province_name: '',
            district_name: '',
            sub_district_name: '',
            hospital_name: '',

            SetValueBy_health_area: undefined,
            SetValueBy_province: undefined,
            SetValueBy_district: undefined,
            SetValueBy_sub_district: undefined,
            SetValueBy_hospital: undefined,

            SetValueColor_description: 'bmi',

            Set_ID_province: '',
            Set_ID_district: '',
            Set_ID_sub_district: '',
            Set_ID_hospital: '',

            SumUserTotal: '',
            fiscal_year_name: '',
            SetValueBy_year: undefined,
            SetValueBy_quarter: undefined,
            SetValueBy_month: undefined,

            DataColumns_adress: '',
            DataColumnsTable_BMI: '',
            DataColumnsTable_blood_pressure: '',
            DataColumnsTable_blood_sugar: '',
            DataColumnsTable_waist: '',
            DataGraph_Type: 'all',
            DataTable_Type: 'all',
            DataTable: [],
            SetData_uninfecte_sick_group: '',
            value_group_fasting: 'not_fasting',
            date_type: 'by_year',

            //page Graph & Table
            SetvaluePage: 'Graph',
            DataTable_Number_records: 'ตารางแสดงจำนวนการคัดกรอง NCDs',

            //loading
            loadingSpin: true,
            menu: 'health_station'
        };
    }

    componentDidMount() {
        this.getUserDataApi()

        // console.log(this.props);
        // Get Data params URL
        if (this.props.match.params.hospital_id && this.props.match.params.type) {
            console.log(this.props.match.params.hospital_id);
            console.log(this.props.match.params.type);
        }

        API_GetAreaAll('', 'by_year').then((response) => {
            this.setState({ loadingSpin: false })
            Get_chart(response)
        })

        API_GetFilterAddress('by_area').then((response) => {
            // console.log(response);
            const health_area_name = []
            for (let index = 0; index < response.length; index++) {
                health_area_name.push(<Option key={index + 1} value={response[index].health_area}>{response[index].health_area}</Option>)
            }
            this.setState({ health_area_name: health_area_name })
        })

        API_GetFiscal_Year().then((response) => {
            const fiscal_year_name = []
            for (let index = 0; index < response.length; index++) {
                fiscal_year_name.push(<Option key={index + 1} value={response[index].year}>{response[index].year}</Option>)
            }
            this.setState({ fiscal_year_name: fiscal_year_name })
        })

        API_GetSumUserTotal().then((response) => {
            this.setState({ SumUserTotal: response })
        })


    }

    async getUserDataApi() {
        let res = await getUserData()
        console.log(res)
        if (res) {
            if (res['data']['code'] == 200) {
                let user = res['data']['user']
                if (user['auth']['user_type'] == 'admin' && user['auth']['activate'] == true && ['by_province', 'by_area'].includes(user['affiliation']['type'])) {
                    this.setState({ showMenuUserManage: true })
                } else {
                    this.setState({ showMenuUserManage: false })
                }
            } else {
                this.setState({ showMenuUserManage: false })
            }
        } else {
            this.setState({ showMenuUserManage: false })
        }
    }

    // getKeyByValue = (object1) => {
    //     const results = []
    //     for (const [key, value] of Object.entries(object1)) {
    //         results.push({
    //             "name": key,
    //             "value": value,
    //         })
    //     }
    //     return results
    // }

    onclick_TabPage = (e) => {
        this.setState({ SetvaluePage: e }, () => {
            this.setState({
                SetValueBy_health_area: undefined,
                SetValueBy_province: undefined,
                SetValueBy_district: undefined,
                SetValueBy_sub_district: undefined,
                SetValueBy_hospital: undefined,
                SetData_uninfecte_sick_group: '',
                value_group_fasting: 'not_fasting',
                DataTable_Type: 'all',
                DataGraph_Type: 'all',
                loadingSpin: true,
                SetValueBy_year: undefined,
                SetValueBy_quarter: undefined,
                SetValueBy_month: undefined,
            })

            if (this.state.SetvaluePage === 'Graph') {
                API_GetProvinceAll().then((response) => {
                    this.setState({ loadingSpin: false })
                    Get_chart(response)
                })
                API_GetSumUserTotal().then((response) => {
                    this.setState({ SumUserTotal: response })
                })
            } else if (this.state.SetvaluePage === 'Table') {
                API_GetPeople_screenedAreaAll('by_year').then((res) => {
                    this.setState({ DataTable: res, DataColumns_adress: ColumnsTable_adress('เขตสุขภาพ', 'area_name'), loadingSpin: false })
                })
            }
        })
        // console.log(this.state.data_chart);
    };
    //address
    handleChange = (type, value, select) => {
        // console.log(select);
        this.setState({
            loadingSpin: true,
            DataGraph_Type: 'all',
            DataTable_Type: 'all',
            SetData_uninfecte_sick_group: ''
        })
        if (type === 'by_health_area') {
            this.setState({
                Data_address: 'by_health_area',
                SetValueBy_health_area: select.children,
                SetValueBy_province: undefined,
                SetValueBy_district: undefined,
                SetValueBy_sub_district: undefined,
                SetValueBy_hospital: undefined,
                DataValue_address: value,
                Set_ID_province: value,
                SetValueBy_year: undefined,
                SetValueBy_quarter: undefined,
                SetValueBy_month: undefined,
            })

            API_GetFilterAddress('by_area', value).then((response) => {
                const province_name = []
                for (let index = 0; index < response.length; index++) {
                    province_name.push(<Option key={index + 1} value={response[index].province_id}>{response[index].province_name}</Option>)
                }
                this.setState({ province_name: province_name })
            })
            if (this.state.SetvaluePage === 'Graph') {
                API_GetAreaAll(value, 'by_year').then((response) => {
                    this.setState({ loadingSpin: false })
                    Get_chart(response)
                })

                API_GetSumUserTotal(type, value).then((response) => {
                    this.setState({ SumUserTotal: response })
                })
            } else if (this.state.SetvaluePage === 'Table') {
                API_GetPeople_screenedArea(value, 'by_year').then((res) => {
                    this.setState({ DataTable: res, loadingSpin: false, DataColumns_adress: ColumnsTable_adress('จังหวัด', 'area_name') })
                })
            }
        } else if (type === 'by_province') {
            this.setState({
                Data_address: 'by_province',
                SetValueBy_province: select.children,
                SetValueBy_district: undefined,
                SetValueBy_sub_district: undefined,
                SetValueBy_hospital: undefined,
                DataValue_address: value,
                Set_ID_province: value,
                SetValueBy_year: undefined,
                SetValueBy_quarter: undefined,
                SetValueBy_month: undefined,
            })
            API_GetFilterAddress(type, value).then((response) => {
                const district_name = []
                for (let index = 0; index < response.length; index++) {
                    district_name.push(<Option key={index + 1} value={response[index].district_id}>{response[index].district_name}</Option>)
                }
                this.setState({ district_name: district_name })
            })
            if (this.state.SetvaluePage === 'Graph') {
                API_GetProvinceAll(value, 'by_year').then((response) => {
                    this.setState({ loadingSpin: false })
                    Get_chart(response)
                })

                API_GetSumUserTotal(type, value).then((response) => {
                    this.setState({ SumUserTotal: response })
                })
            } else if (this.state.SetvaluePage === 'Table') {
                API_People_screened_province(value, 'by_year').then((res) => {
                    this.setState({ DataTable: res, loadingSpin: false, DataColumns_adress: ColumnsTable_adress('อำเภอ', 'district_name') })
                })
            }
        } else if (type === 'by_district') {
            this.setState({
                Data_address: 'by_district',
                SetValueBy_district: select.children,
                SetValueBy_sub_district: undefined,
                SetValueBy_hospital: undefined,
                DataValue_address: value,
                Set_ID_district: value,
                SetValueBy_year: undefined,
                SetValueBy_quarter: undefined,
                SetValueBy_month: undefined,
            })
            API_GetFilterAddress(type, value).then((response) => {
                const sub_district_name = []
                for (let index = 0; index < response.length; index++) {
                    sub_district_name.push(<Option key={index + 1} value={response[index].sub_district_id}>{response[index].sub_district_name}</Option>)
                }
                this.setState({ sub_district_name: sub_district_name })
            })
            if (this.state.SetvaluePage === 'Graph') {
                API_GetDistrictAll(value, 'by_year').then((response) => {
                    this.setState({ loadingSpin: false })
                    Get_chart(response)
                })

                API_GetSumUserTotal(type, value).then((response) => {
                    this.setState({ SumUserTotal: response })
                })
            } else if (this.state.SetvaluePage === 'Table') {
                API_People_screened_district(value, 'by_year').then((res) => {
                    this.setState({ DataTable: res, loadingSpin: false, DataColumns_adress: ColumnsTable_adress('ตำบล', 'sub_district_name') })
                })
            }
        } else if (type === 'by_sub_district') {
            this.setState({
                Data_address: 'by_sub_district',
                SetValueBy_sub_district: select.children,
                SetValueBy_hospital: undefined,
                DataValue_address: value,
                Set_ID_sub_district: value,
                SetValueBy_year: undefined,
                SetValueBy_quarter: undefined,
                SetValueBy_month: undefined,
            })
            API_GetFilterAddress(type, value).then((response) => {
                const hospital_name = []
                for (let index = 0; index < response.length; index++) {
                    hospital_name.push(<Option key={index + 1} value={response[index].hospital_id}>{response[index].hospital_name}</Option>)
                }
                this.setState({ hospital_name: hospital_name })
            })
            if (this.state.SetvaluePage === 'Graph') {
                API_GetSubDistrictAll(value, 'by_year').then((response) => {
                    this.setState({ loadingSpin: false })
                    Get_chart(response)
                })

                API_GetSumUserTotal(type, value).then((response) => {
                    this.setState({ SumUserTotal: response })
                })
            } else if (this.state.SetvaluePage === 'Table') {
                API_People_screened_SubDistrict(value, 'by_year').then((res) => {
                    this.setState({ DataTable: res, loadingSpin: false, DataColumns_adress: ColumnsTable_adress('หน่วยบริการ', 'hospital_name') })
                })
            }
        } else if (type === 'by_hospital') {
            this.setState({
                Data_address: 'by_hospital',
                SetValueBy_hospital: select.children,
                DataValue_address: value,
                Set_ID_hospital: value,
                SetValueBy_year: undefined,
                SetValueBy_quarter: undefined,
                SetValueBy_month: undefined,
            })
            if (this.state.SetvaluePage === 'Graph') {
                API_GetHospitalAll(value, 'by_year').then((response) => {
                    this.setState({ loadingSpin: false })
                    Get_chart(response)
                })

                API_GetSumUserTotal(type, value).then((response) => {
                    this.setState({ SumUserTotal: response })
                })
            } else if (this.state.SetvaluePage === 'Table') {
                API_GetDataByHospital(value, 'by_year').then((res) => {
                    this.setState({
                        DataTable: res,
                        loadingSpin: false,
                        DataColumns_adress: Colums_Hospital_ALL,
                        DataTable_Number_records: 'ตารางแสดงจำนวนผู้ได้รับการคัดกรอง NCDs'
                    })
                })
            }
        } else if (type === 'by_year') {
            // console.log(this.state.DataValue_address);
            this.setState({
                date_type: 'by_year',
                SetValueBy_year: value,
                SetValueBy_quarter: undefined,
                SetValueBy_month: undefined,
            })
            if (this.state.SetvaluePage === 'Graph') {
                if (this.state.Data_address === 'by_province') {
                    API_GetProvinceAll(this.state.DataValue_address, type, value).then((response) => {
                        this.setState({ loadingSpin: false })
                        Get_chart(response)
                    })
                } else if (this.state.Data_address === 'by_district') {
                    API_GetDistrictAll(this.state.DataValue_address, type, value).then((response) => {
                        this.setState({ loadingSpin: false })
                        Get_chart(response)
                    })
                } else if (this.state.Data_address === 'by_sub_district') {
                    API_GetSubDistrictAll(this.state.DataValue_address, type, value).then((response) => {
                        this.setState({ loadingSpin: false })
                        Get_chart(response)
                    })
                } else if (this.state.Data_address === 'by_hospital') {
                    API_GetHospitalAll(this.state.DataValue_address, type, value).then((response) => {
                        this.setState({ loadingSpin: false })
                        Get_chart(response)
                    })
                } else {
                    API_GetProvinceAll('', type, value).then((response) => {
                        this.setState({ loadingSpin: false })
                        Get_chart(response)
                    })
                }
            } else if (this.state.SetvaluePage === 'Table') {
                if (this.state.Data_address === 'by_province') {
                    API_People_screened_province(this.state.DataValue_address, type, value).then((res) => {
                        this.setState({ DataTable: res, loadingSpin: false, DataColumns_adress: ColumnsTable_adress('อำเภอ', 'district_name') })
                    })
                } else if (this.state.Data_address === 'by_district') {
                    API_People_screened_district(this.state.DataValue_address, type, value).then((res) => {
                        this.setState({ DataTable: res, loadingSpin: false, DataColumns_adress: ColumnsTable_adress('ตำบล', 'sub_district_name') })
                    })
                } else if (this.state.Data_address === 'by_sub_district') {
                    API_People_screened_SubDistrict(this.state.DataValue_address, type, value).then((res) => {
                        this.setState({ DataTable: res, loadingSpin: false, DataColumns_adress: ColumnsTable_adress('หน่วยบริการ', 'hospital_name') })
                    })
                } else if (this.state.Data_address === 'by_hospital') {
                    API_GetDataByHospital(this.state.Set_ID_hospital, type, value).then((res) => {
                        this.setState({
                            DataTable: res,
                            loadingSpin: false,
                            DataColumns_adress: Colums_Hospital_ALL,
                            DataTable_Number_records: 'ตารางแสดงจำนวนผู้ได้รับการคัดกรอง NCDs',
                            DataTable_Type: 'all'
                        })
                    })
                } else {
                    API_People_screenedAll(type, value).then((res) => {
                        this.setState({ DataTable: res, DataColumns_adress: ColumnsTable_adress('จังหวัด', 'province_name'), loadingSpin: false })
                    })
                }
            }
        } else if (type === 'by_quarter') {
            // console.log(value);
            this.setState({ SetValueBy_quarter: value, SetValueBy_month: undefined, date_type: 'by_quarter' })
            if (this.state.SetvaluePage === 'Graph') {
                if (this.state.Data_address === 'by_province') {
                    API_GetProvinceAll(this.state.DataValue_address, type, this.state.SetValueBy_year, value).then((response) => {
                        this.setState({ loadingSpin: false })
                        Get_chart(response)
                    })
                } else if (this.state.Data_address === 'by_district') {
                    API_GetDistrictAll(this.state.DataValue_address, type, this.state.SetValueBy_year, value).then((response) => {
                        this.setState({ loadingSpin: false })
                        Get_chart(response)
                    })
                } else if (this.state.Data_address === 'by_sub_district') {
                    API_GetSubDistrictAll(this.state.DataValue_address, type, this.state.SetValueBy_year, value).then((response) => {
                        this.setState({ loadingSpin: false })
                        Get_chart(response)
                    })
                } else if (this.state.Data_address === 'by_hospital') {
                    API_GetHospitalAll(this.state.DataValue_address, type, this.state.SetValueBy_year, value).then((response) => {
                        this.setState({ loadingSpin: false })
                        Get_chart(response)
                    })
                } else {
                    API_GetProvinceAll('', type, this.state.SetValueBy_year, value).then((response) => {
                        this.setState({ loadingSpin: false })
                        Get_chart(response)
                    })
                }
            } else {
                if (this.state.Data_address === 'by_province') {
                    API_People_screened_province(this.state.DataValue_address, type, this.state.SetValueBy_year, value).then((res) => {
                        this.setState({ DataTable: res, loadingSpin: false, DataColumns_adress: ColumnsTable_adress('อำเภอ', 'district_name') })
                    })
                } else if (this.state.Data_address === 'by_district') {
                    API_People_screened_district(this.state.DataValue_address, type, this.state.SetValueBy_year, value).then((res) => {
                        this.setState({ DataTable: res, loadingSpin: false, DataColumns_adress: ColumnsTable_adress('ตำบล', 'sub_district_name') })
                    })
                } else if (this.state.Data_address === 'by_sub_district') {
                    API_People_screened_SubDistrict(this.state.DataValue_address, type, this.state.SetValueBy_year, value).then((res) => {
                        this.setState({ DataTable: res, loadingSpin: false, DataColumns_adress: ColumnsTable_adress('หน่วยบริการ', 'hospital_name') })
                    })
                } else if (this.state.Data_address === 'by_hospital') {
                    API_GetDataByHospital(this.state.Set_ID_hospital, type, this.state.SetValueBy_year, value).then((res) => {
                        this.setState({
                            DataTable: res,
                            loadingSpin: false,
                            DataColumns_adress: Colums_Hospital_ALL,
                            DataTable_Number_records: 'ตารางแสดงจำนวนผู้ได้รับการคัดกรอง NCDs',
                            DataTable_Type: 'all'
                        })
                    })
                } else {
                    API_People_screenedAll(type, this.state.SetValueBy_year, value).then((res) => {
                        this.setState({ DataTable: res, DataColumns_adress: ColumnsTable_adress('จังหวัด', 'province_name'), loadingSpin: false })
                    })
                }
            }
        } else if (type === 'by_month') {
            this.setState({ SetValueBy_month: value, date_type: 'by_month' })
            if (this.state.SetvaluePage === 'Graph') {
                if (this.state.Data_address === 'by_province') {
                    API_GetProvinceAll(this.state.DataValue_address, type, this.state.SetValueBy_year, this.state.SetValueBy_quarter, value).then((response) => {
                        this.setState({ loadingSpin: false })
                        Get_chart(response)
                    })
                } else if (this.state.Data_address === 'by_district') {
                    API_GetDistrictAll(this.state.DataValue_address, type, this.state.SetValueBy_year, this.state.SetValueBy_quarter, value).then((response) => {
                        this.setState({ loadingSpin: false })
                        Get_chart(response)
                    })
                } else if (this.state.Data_address === 'by_sub_district') {
                    API_GetSubDistrictAll(this.state.DataValue_address, type, this.state.SetValueBy_year, this.state.SetValueBy_quarter, value).then((response) => {
                        this.setState({ loadingSpin: false })
                        Get_chart(response)
                    })
                } else if (this.state.Data_address === 'by_hospital') {
                    API_GetHospitalAll(this.state.DataValue_address, type, this.state.SetValueBy_year, this.state.SetValueBy_quarter, value).then((response) => {
                        this.setState({ loadingSpin: false })
                        Get_chart(response)
                    })
                } else {
                    API_GetProvinceAll('', type, this.state.SetValueBy_year, this.state.SetValueBy_quarter, value).then((response) => {
                        this.setState({ loadingSpin: false })
                        Get_chart(response)
                    })
                }
            } else {
                if (this.state.Data_address === 'by_province') {
                    API_People_screened_province(this.state.DataValue_address, type, this.state.SetValueBy_year, this.state.SetValueBy_quarter, value).then((res) => {
                        this.setState({ DataTable: res, loadingSpin: false, DataColumns_adress: ColumnsTable_adress('อำเภอ', 'district_name') })
                    })
                } else if (this.state.Data_address === 'by_district') {
                    API_People_screened_district(this.state.DataValue_address, type, this.state.SetValueBy_year, this.state.SetValueBy_quarter, value).then((res) => {
                        this.setState({ DataTable: res, loadingSpin: false, DataColumns_adress: ColumnsTable_adress('ตำบล', 'sub_district_name') })
                    })
                } else if (this.state.Data_address === 'by_sub_district') {
                    API_People_screened_SubDistrict(this.state.DataValue_address, type, this.state.SetValueBy_year, this.state.SetValueBy_quarter, value).then((res) => {
                        this.setState({ DataTable: res, loadingSpin: false, DataColumns_adress: ColumnsTable_adress('หน่วยบริการ', 'hospital_name') })
                    })
                } else if (this.state.Data_address === 'by_hospital') {
                    API_GetDataByHospital(this.state.Set_ID_hospital, type, this.state.SetValueBy_year, this.state.SetValueBy_quarter, value).then((res) => {
                        this.setState({
                            DataTable: res,
                            loadingSpin: false,
                            DataColumns_adress: Colums_Hospital_ALL,
                            DataTable_Number_records: 'ตารางแสดงจำนวนผู้ได้รับการคัดกรอง NCDs',
                            DataTable_Type: 'all'
                        })
                    })
                } else {
                    API_People_screenedAll(type, this.state.SetValueBy_year, this.state.SetValueBy_quarter, value).then((res) => {
                        this.setState({ DataTable: res, DataColumns_adress: ColumnsTable_adress('จังหวัด', 'province_name'), loadingSpin: false })
                    })
                }
            }
        }
    }
    //------------------------------------------------------------------------------//

    //Grap
    handleChange_TypeGraph = (e) => {
        // console.log(this.state.DataValue_address);
        this.setState({ loadingSpin: false })
        var check_DataValue_address = this.state.DataValue_address
        var year = this.state.SetValueBy_year === undefined ? '' : Number(this.state.SetValueBy_year)
        var quarter = this.state.SetValueBy_quarter === undefined ? '' : Number(this.state.SetValueBy_quarter)
        var month = this.state.SetValueBy_month === undefined ? '' : Number(this.state.SetValueBy_month)

        if (this.state.Data_address === 'by_health_area') {
            if (e === 'all') {
                API_GetAreaAll(check_DataValue_address, this.state.date_type, year, quarter, month).then((response) => {
                    this.setState({ loadingSpin: false, DataGraph_Type: e })
                    Get_chart(response)
                })
            } else if (e === 'bmi') {
                API_GetDataByType({ area: check_DataValue_address, type: e, date_type: this.state.date_type, year: year, quarter: quarter, month: month }).then((res) => {
                    this.setState({ loadingSpin: false, DataGraph_Type: e })
                    Get_chart(res)
                })
            } else if (e === 'blood_sugar') {
                API_GetDataByType({ area: check_DataValue_address, type: e, date_type: this.state.date_type, year: year, quarter: quarter, month: month, platform_type: "normal", time: "0" }).then((res) => {
                    this.setState({
                        loadingSpin: false,
                        DataGraph_Type: e
                    })
                    Get_chartNormal(res)
                })
                API_GetDataByType({ area: check_DataValue_address, type: e, date_type: this.state.date_type, year: year, quarter: quarter, month: month, platform_type: "sick", time: "2" }).then((res) => {
                    this.setState({
                        loadingSpin: false,
                        DataGraph_Type: e
                    })
                    Get_chartSick(res)
                })
            } else if (e === 'blood_pressure') {
                API_GetDataByType({ area: check_DataValue_address, type: e, date_type: this.state.date_type, year: year, quarter: quarter, month: month, platform_type: "normal" }).then((res) => {
                    this.setState({
                        loadingSpin: false,
                        DataGraph_Type: e
                    })
                    Get_chartNormal(res)
                })
                API_GetDataByType({ area: check_DataValue_address, type: e, date_type: this.state.date_type, year: year, quarter: quarter, month: month, platform_type: "sick" }).then((res) => {
                    this.setState({
                        loadingSpin: false,
                        DataGraph_Type: e
                    })
                    Get_chartSick(res)
                })
            } else if (e === 'waist_circumference') {
                API_GetDataByType({ area: check_DataValue_address, type: e, date_type: this.state.date_type, year: year, quarter: quarter, month: month }).then((res) => {
                    this.setState({
                        loadingSpin: false,
                        DataGraph_Type: e
                    })
                    Get_chart(res)
                })
            }
        } else if (this.state.Data_address === 'by_province') {
            if (e === 'all') {
                API_GetProvinceAll(check_DataValue_address, this.state.date_type, year, quarter, month).then((response) => {
                    this.setState({
                        loadingSpin: false,
                        DataGraph_Type: e
                    })
                    Get_chart(response)
                })
            } else if (e === 'bmi') {
                API_GetDataByType({ province_id: check_DataValue_address, type: e, date_type: this.state.date_type, year: year, quarter: quarter, month: month })
                    .then((res) => {
                        this.setState({ loadingSpin: false, DataGraph_Type: e })
                        Get_chart(res)
                    })
            } else if (e === 'blood_sugar') {
                API_GetDataByType({ province_id: check_DataValue_address, type: e, date_type: this.state.date_type, year: year, quarter: quarter, month: month, platform_type: "normal", time: "0" }).then((res) => {
                    this.setState({
                        loadingSpin: false,
                        DataGraph_Type: e
                    })
                    Get_chartNormal(res)
                })
                API_GetDataByType({ province_id: check_DataValue_address, type: e, date_type: this.state.date_type, year: year, quarter: quarter, month: month, platform_type: "sick", time: "2" }).then((res) => {
                    this.setState({
                        loadingSpin: false,
                        DataGraph_Type: e
                    })
                    Get_chartSick(res)
                })
            } else if (e === 'blood_pressure') {
                API_GetDataByType({ province_id: check_DataValue_address, type: e, date_type: this.state.date_type, year: year, quarter: quarter, month: month, platform_type: "normal" }).then((res) => {
                    this.setState({
                        loadingSpin: false,
                        DataGraph_Type: e
                    })
                    Get_chartNormal(res)
                })
                API_GetDataByType({ province_id: check_DataValue_address, type: e, date_type: this.state.date_type, year: year, quarter: quarter, month: month, platform_type: "sick" }).then((res) => {
                    this.setState({
                        loadingSpin: false,
                        DataGraph_Type: e
                    })
                    Get_chartSick(res)
                })
            } else if (e === 'waist_circumference') {
                API_GetDataByType({ province_id: check_DataValue_address, type: e, date_type: this.state.date_type, year: year, quarter: quarter, month: month }).then((res) => {
                    console.log(res);
                    this.setState({
                        loadingSpin: false,
                        DataGraph_Type: e
                    })
                    Get_chart(res)
                })
            }
        } else if (this.state.Data_address === 'by_district') {
            if (e === 'all') {
                API_GetDistrictAll(check_DataValue_address, this.state.date_type, year, quarter, month).then((response) => {
                    this.setState({ loadingSpin: false, DataGraph_Type: e })
                    Get_chart(response)
                })
            } else if (e === 'bmi') {
                API_GetDataByType({ district_id: check_DataValue_address, type: e, date_type: this.state.date_type, year: year, quarter: quarter, month: month }).then((res) => {
                    this.setState({
                        loadingSpin: false,
                        DataGraph_Type: e
                    })
                    Get_chart(res)
                })
            } else if (e === 'blood_sugar') {
                API_GetDataByType({ district_id: check_DataValue_address, type: e, date_type: this.state.date_type, year: year, quarter: quarter, month: month, platform_type: "normal", time: "0" }).then((res) => {
                    this.setState({
                        loadingSpin: false,
                        DataGraph_Type: e
                    })
                    Get_chartNormal(res)
                })
                API_GetDataByType({ district_id: check_DataValue_address, type: e, date_type: this.state.date_type, year: year, quarter: quarter, month: month, platform_type: "sick", time: "2" }).then((res) => {
                    this.setState({
                        loadingSpin: false,
                        DataGraph_Type: e
                    })
                    Get_chartSick(res)
                })
            } else if (e === 'blood_pressure') {
                API_GetDataByType({ district_id: check_DataValue_address, type: e, date_type: this.state.date_type, year: year, quarter: quarter, month: month, platform_type: "normal" }).then((res) => {
                    this.setState({
                        loadingSpin: false,
                        DataGraph_Type: e
                    })
                    Get_chartNormal(res)
                })
                API_GetDataByType({ district_id: check_DataValue_address, type: e, date_type: this.state.date_type, year: year, quarter: quarter, month: month, platform_type: "sick" }).then((res) => {
                    this.setState({
                        loadingSpin: false,
                        DataGraph_Type: e
                    })
                    Get_chartSick(res)
                })
            } else if (e === 'waist_circumference') {
                API_GetDataByType({ district_id: check_DataValue_address, type: e, date_type: this.state.date_type, year: year, quarter: quarter, month: month }).then((res) => {
                    console.log(res);
                    this.setState({
                        loadingSpin: false,
                        DataGraph_Type: e
                    })
                    Get_chart(res)
                })
            }
        } else if (this.state.Data_address === 'by_sub_district') {
            if (e === 'all') {
                API_GetSubDistrictAll(check_DataValue_address, this.state.date_type, year, quarter, month).then((response) => {
                    this.setState({ loadingSpin: false, DataGraph_Type: e })
                    Get_chart(response)
                })
            } else if (e === 'bmi') {
                API_GetDataByType({ by_sub_district: check_DataValue_address, type: e, date_type: this.state.date_type, year: year, quarter: quarter, month: month }).then((res) => {
                    this.setState({
                        loadingSpin: false,
                        DataGraph_Type: e
                    })
                    Get_chart(res)
                })
            } else if (e === 'blood_sugar') {
                API_GetDataByType({ by_sub_district: check_DataValue_address, type: e, date_type: this.state.date_type, year: year, quarter: quarter, month: month, platform_type: "normal", time: "0" }).then((res) => {
                    this.setState({
                        loadingSpin: false,
                        DataGraph_Type: e
                    })
                    Get_chartNormal(res)
                })
                API_GetDataByType({ by_sub_district: check_DataValue_address, type: e, date_type: this.state.date_type, year: year, quarter: quarter, month: month, platform_type: "sick", time: "2" }).then((res) => {
                    this.setState({
                        loadingSpin: false,
                        DataGraph_Type: e
                    })
                    Get_chartSick(res)
                })
            } else if (e === 'blood_pressure') {
                API_GetDataByType({ by_sub_district: check_DataValue_address, type: e, date_type: this.state.date_type, year: year, quarter: quarter, month: month, platform_type: "normal" }).then((res) => {
                    this.setState({
                        loadingSpin: false,
                        DataGraph_Type: e
                    })
                    Get_chartNormal(res)
                })
                API_GetDataByType({ by_sub_district: check_DataValue_address, type: e, date_type: this.state.date_type, year: year, quarter: quarter, month: month, platform_type: "sick" }).then((res) => {
                    this.setState({
                        loadingSpin: false,
                        DataGraph_Type: e
                    })
                    Get_chartSick(res)
                })
            } else if (e === 'waist_circumference') {
                API_GetDataByType({ by_sub_district: check_DataValue_address, type: e, date_type: this.state.date_type, year: year, quarter: quarter, month: month }).then((res) => {
                    console.log(res);
                    this.setState({
                        loadingSpin: false,
                        DataGraph_Type: e
                    })
                    Get_chart(res)
                })
            }
        } else if (this.state.Data_address === 'by_hospital') {
            if (e === 'all') {
                API_GetHospitalAll(check_DataValue_address, this.state.date_type, year, quarter, month).then((response) => {
                    this.setState({ loadingSpin: false, DataGraph_Type: e })
                    Get_chart(response)
                })
            } else if (e === 'bmi') {
                API_GetDataByType({ by_hospital: check_DataValue_address, type: e, date_type: this.state.date_type, year: year, quarter: quarter, month: month }).then((res) => {
                    this.setState({
                        loadingSpin: false,
                        DataGraph_Type: e
                    })
                    Get_chart(res)
                })
            } else if (e === 'blood_sugar') {
                API_GetDataByType({ by_hospital: check_DataValue_address, type: e, date_type: this.state.date_type, year: year, quarter: quarter, month: month, platform_type: "normal", time: "0" }).then((res) => {
                    this.setState({
                        loadingSpin: false,
                        DataGraph_Type: e
                    })
                    Get_chartNormal(res)
                })
                API_GetDataByType({ by_hospital: check_DataValue_address, type: e, date_type: this.state.date_type, year: year, quarter: quarter, month: month, platform_type: "sick", time: "2" }).then((res) => {
                    this.setState({
                        loadingSpin: false,
                        DataGraph_Type: e
                    })
                    Get_chartSick(res)
                })
            } else if (e === 'blood_pressure') {
                API_GetDataByType({ by_hospital: check_DataValue_address, type: e, date_type: this.state.date_type, year: year, quarter: quarter, month: month, platform_type: "normal" }).then((res) => {
                    this.setState({
                        loadingSpin: false,
                        DataGraph_Type: e
                    })
                    Get_chartNormal(res)
                })
                API_GetDataByType({ by_hospital: check_DataValue_address, type: e, date_type: this.state.date_type, year: year, quarter: quarter, month: month, platform_type: "sick" }).then((res) => {
                    this.setState({
                        loadingSpin: false,
                        DataGraph_Type: e
                    })
                    Get_chartSick(res)
                })
            } else if (e === 'waist_circumference') {
                API_GetDataByType({ by_hospital: check_DataValue_address, type: e, date_type: this.state.date_type, year: year, quarter: quarter, month: month }).then((res) => {
                    console.log(res);
                    this.setState({
                        loadingSpin: false,
                        DataGraph_Type: e
                    })
                    Get_chart(res)
                })
            }
        } else {
            if (e === 'all') {
                API_GetAreaAll('', this.state.date_type, year, quarter, month).then((response) => {
                    this.setState({ loadingSpin: false, DataGraph_Type: e })
                    Get_chart(response)
                })
            } else if (e === 'bmi') {
                API_GetDataByType({ type: e, date_type: this.state.date_type, year: year, quarter: quarter, month: month }).then((res) => {
                    this.setState({ loadingSpin: false, DataGraph_Type: e })
                    Get_chart(res)
                })
            } else if (e === 'blood_sugar') {
                API_GetDataByType({ type: e, date_type: this.state.date_type, year: year, quarter: quarter, month: month, platform_type: "normal", time: "0" }).then((res) => {
                    this.setState({
                        loadingSpin: false,
                        DataGraph_Type: e
                    })
                    Get_chartNormal(res)
                })
                API_GetDataByType({ type: e, date_type: this.state.date_type, year: year, quarter: quarter, month: month, platform_type: "sick", time: "2" }).then((res) => {
                    this.setState({
                        loadingSpin: false,
                        DataGraph_Type: e
                    })
                    Get_chartSick(res)
                })
            } else if (e === 'blood_pressure') {
                API_GetDataByType({ type: e, date_type: this.state.date_type, year: year, quarter: quarter, month: month, platform_type: "normal" }).then((res) => {
                    this.setState({
                        loadingSpin: false,
                        DataGraph_Type: e
                    })
                    Get_chartNormal(res)
                })
                API_GetDataByType({ type: e, date_type: this.state.date_type, year: year, quarter: quarter, month: month, platform_type: "sick" }).then((res) => {
                    this.setState({
                        loadingSpin: false,
                        DataGraph_Type: e
                    })
                    Get_chartSick(res)
                })
            } else if (e === 'waist_circumference') {
                API_GetDataByType({ type: e, date_type: this.state.date_type, year: year, quarter: quarter, month: month }).then((res) => {
                    this.setState({
                        loadingSpin: false,
                        DataGraph_Type: e
                    })
                    Get_chart(res)
                })
            }
        }
    }
    selectType_fasting = (type, vlaue) => {
        // console.log(type, vlaue);
        // console.log(this.state.Data_address, '======', this.state.DataValue_address);
        this.setState({ loadingSpin: false })
        var year = this.state.SetValueBy_year === undefined ? '' : this.state.SetValueBy_year
        var quarter = this.state.SetValueBy_quarter === undefined ? '' : this.state.SetValueBy_quarter
        var month = this.state.SetValueBy_month === undefined ? '' : this.state.SetValueBy_month

        if (type === 'normal') {
            if (vlaue === 'not_fasting') {
                if (this.state.Data_address === 'by_health_area') {
                    API_GetDataByType({ area: this.state.DataValue_address, type: 'blood_sugar', platform_type: "normal", time: "0", date_type: this.state.date_type, year: year, quarter: quarter, month: month }).then((res) => {
                        this.setState({ loadingSpin: false })
                        Get_chartNormal(res)
                    })
                } else if (this.state.Data_address === 'by_province') {
                    API_GetDataByType({ province_id: this.state.DataValue_address, type: 'blood_sugar', platform_type: "normal", time: "0", date_type: this.state.date_type, year: year, quarter: quarter, month: month }).then((res) => {
                        this.setState({ loadingSpin: false })
                        Get_chartNormal(res)
                    })
                } else if (this.state.Data_address === 'by_district') {
                    API_GetDataByType({ district_id: this.state.DataValue_address, type: 'blood_sugar', platform_type: "normal", time: "0", date_type: this.state.date_type, year: year, quarter: quarter, month: month }).then((res) => {
                        this.setState({ loadingSpin: false })
                        Get_chartNormal(res)
                    })
                } else if (this.state.Data_address === 'by_sub_district') {
                    API_GetDataByType({ sub_district_id: this.state.DataValue_address, type: 'blood_sugar', platform_type: "normal", time: "0", date_type: this.state.date_type, year: year, quarter: quarter, month: month }).then((res) => {
                        this.setState({ loadingSpin: false })
                        Get_chartNormal(res)
                    })
                } else if (this.state.Data_address === 'by_hospital') {
                    API_GetDataByType({ hospital_id: this.state.DataValue_address, type: 'blood_sugar', platform_type: "normal", time: "0", date_type: this.state.date_type, year: year, quarter: quarter, month: month }).then((res) => {
                        this.setState({ loadingSpin: false })
                        Get_chartNormal(res)
                    })
                } else {
                    API_GetDataByType({ type: 'blood_sugar', platform_type: "normal", time: "0", date_type: this.state.date_type, year: year, quarter: quarter, month: month }).then((res) => {
                        this.setState({ loadingSpin: false })
                        Get_chartNormal(res)
                    })
                }
            } else if (vlaue === 'fasting') {
                if (this.state.Data_address === 'by_health_area') {
                    API_GetDataByType({ area: this.state.DataValue_address, type: 'blood_sugar', platform_type: "normal", time: "8", date_type: this.state.date_type, year: year, quarter: quarter, month: month }).then((res) => {
                        this.setState({ loadingSpin: false })
                        Get_chartNormal(res)
                    })
                } else if (this.state.Data_address === 'by_province') {
                    API_GetDataByType({ province_id: this.state.DataValue_address, type: 'blood_sugar', platform_type: "normal", time: "8", date_type: this.state.date_type, year: year, quarter: quarter, month: month }).then((res) => {
                        this.setState({ loadingSpin: false })
                        Get_chartNormal(res)
                    })
                } else if (this.state.Data_address === 'by_district') {
                    API_GetDataByType({ district_id: this.state.DataValue_address, type: 'blood_sugar', platform_type: "normal", time: "8", date_type: this.state.date_type, year: year, quarter: quarter, month: month }).then((res) => {
                        this.setState({ loadingSpin: false })
                        Get_chartNormal(res)
                    })
                } else if (this.state.Data_address === 'by_sub_district') {
                    API_GetDataByType({ sub_district_id: this.state.DataValue_address, type: 'blood_sugar', platform_type: "normal", time: "8", date_type: this.state.date_type, year: year, quarter: quarter, month: month }).then((res) => {
                        this.setState({ loadingSpin: false })
                        Get_chartNormal(res)
                    })
                } else if (this.state.Data_address === 'by_hospital') {
                    API_GetDataByType({ hospital_id: this.state.DataValue_address, type: 'blood_sugar', platform_type: "normal", time: "8", date_type: this.state.date_type, year: year, quarter: quarter, month: month }).then((res) => {
                        this.setState({ loadingSpin: false })
                        Get_chartNormal(res)
                    })
                } else {
                    API_GetDataByType({ type: 'blood_sugar', platform_type: "normal", time: "8", date_type: this.state.date_type, year: year, quarter: quarter, month: month }).then((res) => {
                        this.setState({ loadingSpin: false })
                        Get_chartNormal(res)
                    })
                }
            }
        } else if (type === 'sick') {
            if (vlaue === 'not_fasting') {
                if (this.state.Data_address === 'by_health_area') {
                    API_GetDataByType({ area: this.state.DataValue_address, type: 'blood_sugar', platform_type: "sick", time: "2", date_type: this.state.date_type, year: year, quarter: quarter, month: month }).then((res) => {
                        this.setState({ loadingSpin: false })
                        Get_chartSick(res)
                    })
                } else if (this.state.Data_address === 'by_province') {
                    API_GetDataByType({ province_id: this.state.DataValue_address, type: 'blood_sugar', platform_type: "sick", time: "2", date_type: this.state.date_type, year: year, quarter: quarter, month: month }).then((res) => {
                        this.setState({ loadingSpin: false })
                        Get_chartSick(res)
                    })
                } else if (this.state.Data_address === 'by_district') {
                    API_GetDataByType({ district_id: this.state.DataValue_address, type: 'blood_sugar', platform_type: "sick", time: "2", date_type: this.state.date_type, year: year, quarter: quarter, month: month }).then((res) => {
                        this.setState({ loadingSpin: false })
                        Get_chartSick(res)
                    })
                } else if (this.state.Data_address === 'by_sub_district') {
                    API_GetDataByType({ sub_district_id: this.state.DataValue_address, type: 'blood_sugar', platform_type: "sick", time: "2", date_type: this.state.date_type, year: year, quarter: quarter, month: month }).then((res) => {
                        this.setState({ loadingSpin: false })
                        Get_chartSick(res)
                    })
                } else if (this.state.Data_address === 'by_hospital') {
                    API_GetDataByType({ hospital_id: this.state.DataValue_address, type: 'blood_sugar', platform_type: "sick", time: "2", date_type: this.state.date_type, year: year, quarter: quarter, month: month }).then((res) => {
                        this.setState({ loadingSpin: false })
                        Get_chartSick(res)
                    })
                } else {
                    API_GetDataByType({ type: 'blood_sugar', platform_type: "sick", time: "2", date_type: this.state.date_type, year: year, quarter: quarter, month: month }).then((res) => {
                        this.setState({ loadingSpin: false })
                        Get_chartSick(res)
                    })
                }
            } else if (vlaue === 'fasting') {
                if (this.state.Data_address === 'by_health_area') {
                    API_GetDataByType({ area: this.state.DataValue_address, type: 'blood_sugar', platform_type: "sick", time: "8", date_type: this.state.date_type, year: year, quarter: quarter, month: month }).then((res) => {
                        this.setState({ loadingSpin: false })
                        Get_chartSick(res)
                    })
                } else if (this.state.Data_address === 'by_province') {
                    API_GetDataByType({ province_id: this.state.DataValue_address, type: 'blood_sugar', platform_type: "sick", time: "8", date_type: this.state.date_type, year: year, quarter: quarter, month: month }).then((res) => {
                        this.setState({ loadingSpin: false })
                        Get_chartSick(res)
                    })
                } else if (this.state.Data_address === 'by_district') {
                    API_GetDataByType({ district_id: this.state.DataValue_address, type: 'blood_sugar', platform_type: "sick", time: "8", date_type: this.state.date_type, year: year, quarter: quarter, month: month }).then((res) => {
                        this.setState({ loadingSpin: false })
                        Get_chartSick(res)
                    })
                } else if (this.state.Data_address === 'by_sub_district') {
                    API_GetDataByType({ sub_district_id: this.state.DataValue_address, type: 'blood_sugar', platform_type: "sick", time: "8", date_type: this.state.date_type, year: year, quarter: quarter, month: month }).then((res) => {
                        this.setState({ loadingSpin: false })
                        Get_chartSick(res)
                    })
                } else if (this.state.Data_address === 'by_hospital') {
                    API_GetDataByType({ hospital_id: this.state.DataValue_address, type: 'blood_sugar', platform_type: "sick", time: "8", date_type: this.state.date_type, year: year, quarter: quarter, month: month }).then((res) => {
                        this.setState({ loadingSpin: false })
                        Get_chartSick(res)
                    })
                } else {
                    API_GetDataByType({ type: 'blood_sugar', platform_type: "sick", time: "8", date_type: this.state.date_type, year: year, quarter: quarter, month: month }).then((res) => {
                        this.setState({ loadingSpin: false })
                        Get_chartSick(res)
                    })
                }
            }
        }
    }
    //------------------------------------------------------------------------------//

    //Table
    handleChange_TypeTable = (e) => {
        console.log(this.state.Data_address, e);
        this.setState({ SetData_uninfecte_sick_group: '', value_group_fasting: 'not_fasting', loadingSpin: true })
        var year = this.state.SetValueBy_year === undefined ? '' : Number(this.state.SetValueBy_year)
        var quarter = this.state.SetValueBy_quarter === undefined ? '' : Number(this.state.SetValueBy_quarter)
        var month = this.state.SetValueBy_month === undefined ? '' : Number(this.state.SetValueBy_month)


        if (this.state.Data_address === 'by_health_area') {
            if (e === 'all') {
                API_GetPeople_screenedArea(this.state.SetValueBy_health_area, this.state.date_type, year, quarter, month).then((res) => {
                    this.setState({
                        DataTable: res,
                        DataColumns_adress: ColumnsTable_adress('จังหวัด', 'area_name'),
                        loadingSpin: false,
                        DataTable_Number_records: 'ตารางแสดงจำนวนการคัดกรอง NCDs',
                        DataTable_Type: e
                    })
                })
            } else if (e === 'bmi') {
                API_GetDataByTypeTableTableArea(this.state.SetValueBy_health_area, e, '', '', this.state.date_type, year, quarter, month).then((res) => {
                    this.setState({
                        loadingSpin: false,
                        DataTable: res,
                        DataTable_Number_records: 'ตารางแสดงรายงานดัชนีมวลกาย',
                        DataTable_Type: e,
                        DataColumnsTable_BMI: ColumnsTable_BMI('จังหวัด')
                    })
                })
            } else if (e === 'blood_sugar') {
                API_GetDataByTypeTableTableArea(this.state.SetValueBy_health_area, e, 'normal', '0', this.state.date_type, year, quarter, month).then((res) => {
                    this.setState({
                        loadingSpin: false,
                        DataTable: res,
                        DataTable_Number_records: 'ตารางแสดงรายงานค่าน้ำตาลในเลือด',
                        DataTable_Type: e,
                        DataColumnsTable_blood_sugar: ColumnsTable_blood_sugar('จังหวัด')
                    })
                })
            } else if (e === 'blood_pressure') {
                API_GetDataByTypeTableTableArea(this.state.SetValueBy_health_area, e, 'normal', '', this.state.date_type, year, quarter, month).then((res) => {
                    this.setState({
                        loadingSpin: false,
                        DataTable: res,
                        DataTable_Number_records: 'ตารางแสดงรายงานค่าความดันโลหิต',
                        DataTable_Type: e,
                        DataColumnsTable_blood_pressure: ColumnsTable_blood_pressure('จังหวัด')
                    })
                })
            } else if (e === 'waist_circumference') {
                API_GetDataByTypeTableTableArea(this.state.SetValueBy_health_area, e, '', '', this.state.date_type, year, quarter, month).then((res) => {
                    this.setState({
                        loadingSpin: false,
                        DataTable: res,
                        DataTable_Number_records: 'ตารางแสดงรายงานค่ารอบเอว',
                        DataTable_Type: e,
                        DataColumnsTable_waist: ColumnsTable_waist('จังหวัด')
                    })
                })
            }
        } else if (this.state.Data_address === 'by_province') {
            if (e === 'all') {
                API_People_screened_province(this.state.Set_ID_province, this.state.date_type, year, quarter, month).then((res) => {
                    this.setState({
                        DataTable: res,
                        DataColumns_adress: ColumnsTable_adress('อำเภอ', 'district_name'),
                        loadingSpin: false,
                        DataTable_Number_records: 'ตารางแสดงจำนวนการคัดกรอง NCDs',
                        DataTable_Type: e
                    })
                })
            } else if (e === 'bmi') {
                API_GetDataByTypeTableProvince(this.state.Set_ID_province, e, '', '', this.state.date_type, year, quarter, month).then((res) => {
                    this.setState({
                        loadingSpin: false,
                        DataTable: res,
                        DataTable_Number_records: 'ตารางแสดงรายงานดัชนีมวลกาย',
                        DataTable_Type: e,
                        DataColumnsTable_BMI: ColumnsTable_BMI('อำเภอ')
                    })
                })
            } else if (e === 'blood_sugar') {
                API_GetDataByTypeTableProvince(this.state.Set_ID_province, e, 'normal', '0', this.state.date_type, year, quarter, month).then((res) => {
                    this.setState({
                        loadingSpin: false,
                        DataTable: res,
                        DataTable_Number_records: 'ตารางแสดงรายงานค่าน้ำตาลในเลือด',
                        DataTable_Type: e,
                        DataColumnsTable_blood_sugar: ColumnsTable_blood_sugar('อำเภอ')
                    })
                })
            } else if (e === 'blood_pressure') {
                API_GetDataByTypeTableProvince(this.state.Set_ID_province, e, 'normal', '', this.state.date_type, year, quarter, month).then((res) => {
                    this.setState({
                        loadingSpin: false,
                        DataTable: res,
                        DataTable_Number_records: 'ตารางแสดงรายงานค่าความดันโลหิต',
                        DataTable_Type: e,
                        DataColumnsTable_blood_pressure: ColumnsTable_blood_pressure('อำเภอ')
                    })
                })
            } else if (e === 'waist_circumference') {
                API_GetDataByTypeTableProvince(this.state.Set_ID_province, e, '', '', this.state.date_type, year, quarter, month).then((res) => {
                    this.setState({
                        loadingSpin: false,
                        DataTable: res,
                        DataTable_Number_records: 'ตารางแสดงรายงานค่ารอบเอว',
                        DataTable_Type: e,
                        DataColumnsTable_waist: ColumnsTable_waist('อำเภอ')
                    })
                })
            }
        } else if (this.state.Data_address === 'by_district') {
            if (e === 'all') {
                API_People_screened_district(this.state.Set_ID_district, this.state.date_type, year, quarter, month).then((res) => {
                    this.setState({
                        DataTable: res,
                        DataColumns_adress: ColumnsTable_adress('ตำบล', 'sub_district_name'),
                        loadingSpin: false,
                        DataTable_Number_records: 'ตารางแสดงจำนวนการคัดกรอง NCDs',
                        DataTable_Type: e
                    })
                })
            } else if (e === 'bmi') {
                API_GetDataByTypeTableDistrict(this.state.Set_ID_district, e, '', '', this.state.date_type, year, quarter, month).then((res) => {
                    this.setState({
                        loadingSpin: false,
                        DataTable: res,
                        DataTable_Number_records: 'ตารางแสดงรายงานดัชนีมวลกาย',
                        DataTable_Type: e,
                        DataColumnsTable_BMI: ColumnsTable_BMI('ตำบล')
                    })
                })
            } else if (e === 'blood_sugar') {
                API_GetDataByTypeTableDistrict(this.state.Set_ID_district, e, 'normal', '0', this.state.date_type, year, quarter, month).then((res) => {
                    this.setState({
                        loadingSpin: false,
                        DataTable: res,
                        DataTable_Number_records: 'ตารางแสดงรายงานค่าน้ำตาลในเลือด',
                        DataTable_Type: e,
                        DataColumnsTable_blood_sugar: ColumnsTable_blood_sugar('ตำบล')
                    })
                })
            } else if (e === 'blood_pressure') {
                API_GetDataByTypeTableDistrict(this.state.Set_ID_district, e, 'normal', '', this.state.date_type, year, quarter, month).then((res) => {
                    this.setState({
                        loadingSpin: false,
                        DataTable: res,
                        DataTable_Number_records: 'ตารางแสดงรายงานค่าความดันโลหิต',
                        DataTable_Type: e,
                        DataColumnsTable_blood_pressure: ColumnsTable_blood_pressure('ตำบล')
                    })
                })
            } else if (e === 'waist_circumference') {
                API_GetDataByTypeTableDistrict(this.state.Set_ID_district, e, '', '', this.state.date_type, year, quarter, month).then((res) => {
                    this.setState({
                        loadingSpin: false,
                        DataTable: res,
                        DataTable_Number_records: 'ตารางแสดงรายงานค่ารอบเอว',
                        DataTable_Type: e,
                        DataColumnsTable_waist: ColumnsTable_waist('จังหตำบลวัด')
                    })
                })
            }

        } else if (this.state.Data_address === 'by_sub_district') {
            if (e === 'all') {
                API_People_screened_SubDistrict(this.state.Set_ID_sub_district, this.state.date_type, year, quarter, month).then((res) => {
                    this.setState({
                        DataTable: res,
                        DataColumns_adress: ColumnsTable_adress('หน่วยบริการ', 'hospital_name'),
                        loadingSpin: false,
                        DataTable_Number_records: 'ตารางแสดงจำนวนการคัดกรอง NCDs',
                        DataTable_Type: e
                    })
                })
            } else if (e === 'bmi') {
                API_GetDataByTypeTableSubDistrict(this.state.Set_ID_sub_district, e, '', '', this.state.date_type, year, quarter, month).then((res) => {
                    this.setState({
                        loadingSpin: false,
                        DataTable: res,
                        DataTable_Number_records: 'ตารางแสดงรายงานดัชนีมวลกาย',
                        DataTable_Type: e,
                        DataColumnsTable_BMI: ColumnsTable_BMI('หน่วยบริการ')
                    })
                })
            } else if (e === 'blood_sugar') {
                API_GetDataByTypeTableSubDistrict(this.state.Set_ID_sub_district, e, 'normal', '0', this.state.date_type, year, quarter, month).then((res) => {
                    this.setState({
                        loadingSpin: false,
                        DataTable: res,
                        DataTable_Number_records: 'ตารางแสดงรายงานค่าน้ำตาลในเลือด',
                        DataTable_Type: e,
                        DataColumnsTable_blood_sugar: ColumnsTable_blood_sugar('หน่วยบริการ')
                    })
                })
            } else if (e === 'blood_pressure') {
                API_GetDataByTypeTableSubDistrict(this.state.Set_ID_sub_district, e, 'normal', '', this.state.date_type, year, quarter, month).then((res) => {
                    this.setState({
                        loadingSpin: false,
                        DataTable: res,
                        DataTable_Number_records: 'ตารางแสดงรายงานค่าความดันโลหิต',
                        DataTable_Type: e,
                        DataColumnsTable_blood_pressure: ColumnsTable_blood_pressure('หน่วยบริการ')
                    })
                })
            } else if (e === 'waist_circumference') {
                API_GetDataByTypeTableSubDistrict(this.state.Set_ID_sub_district, e, '', '', this.state.date_type, year, quarter, month).then((res) => {
                    this.setState({
                        loadingSpin: false,
                        DataTable: res,
                        DataTable_Number_records: 'ตารางแสดงรายงานค่ารอบเอว',
                        DataTable_Type: e,
                        DataColumnsTable_waist: ColumnsTable_waist('หน่วยบริการ')
                    })
                })
            }
        } else if (this.state.Data_address === 'by_hospital') {
            if (e === 'all') {
                API_GetDataByHospital(this.state.Set_ID_hospital, this.state.date_type, year, quarter, month).then((res) => {
                    this.setState({
                        DataTable: res,
                        loadingSpin: false,
                        DataColumns_adress: Colums_Hospital_ALL,
                        DataTable_Number_records: 'ตารางแสดงจำนวนผู้ได้รับการคัดกรอง NCDs',
                        DataTable_Type: e
                    })
                })
            } else if (e === 'bmi') {
                API_GetDataHealthRecord(this.state.Set_ID_hospital, e, '', '', this.state.date_type, year, quarter, month).then((res) => {
                    this.setState({
                        loadingSpin: false,
                        DataTable: res,
                        DataTable_Number_records: 'ตารางแสดงรายงานดัชนีมวลกาย',
                        DataTable_Type: e,
                        DataColumnsTable_BMI: Colums_Hospital_BMI
                    })
                })
            } else if (e === 'blood_pressure') {
                API_GetDataHealthRecord(this.state.Set_ID_hospital, e, 'normal', '', this.state.date_type, year, quarter, month).then((res) => {
                    this.setState({
                        loadingSpin: false,
                        DataTable: res,
                        DataTable_Number_records: 'ตารางแสดงรายงานค่าความดันโลหิต',
                        DataTable_Type: e,
                        DataColumnsTable_blood_pressure: Colums_Hospital_blood_pressure
                    })
                })
            } else if (e === 'blood_sugar') {
                API_GetDataHealthRecord(this.state.Set_ID_hospital, e, 'normal', '0', this.state.date_type, year, quarter, month).then((res) => {
                    this.setState({
                        loadingSpin: false,
                        DataTable: res,
                        DataTable_Number_records: 'ตารางแสดงรายงานค่าน้ำตาลในเลือด',
                        DataTable_Type: e,
                        DataColumnsTable_blood_sugar: Colums_Hospital_blood_sugar
                    })
                })
            } else if (e === 'waist_circumference') {
                API_GetDataHealthRecord(this.state.Set_ID_hospital, e, '', '', this.state.date_type, year, quarter, month).then((res) => {
                    this.setState({
                        loadingSpin: false,
                        DataTable: res,
                        DataTable_Number_records: 'ตารางแสดงรายงานค่ารอบเอว',
                        DataTable_Type: e,
                        DataColumnsTable_waist: Colums_Hospital_waist
                    })
                })
            }

        } else {
            if (e === 'all') {
                API_GetPeople_screenedAreaAll(this.state.date_type, year, quarter, month).then((res) => {
                    this.setState({
                        DataTable: res,
                        DataColumns_adress: ColumnsTable_adress('เขตสุขภาพ', 'area_name'),
                        loadingSpin: false,
                        DataTable_Number_records: 'ตารางแสดงจำนวนการคัดกรอง NCDs',
                        DataTable_Type: e
                    })
                })
            } else if (e === 'bmi') {
                API_GetDataByTypeTableTableAreaAll(e, '', '', this.state.date_type, year, quarter, month).then((res) => {
                    this.setState({
                        loadingSpin: false,
                        DataTable: res,
                        DataTable_Number_records: 'ตารางแสดงรายงานดัชนีมวลกาย',
                        DataTable_Type: e,
                        DataColumnsTable_BMI: ColumnsTable_BMI('เขตสุขภาพ')
                    })
                })
            } else if (e === 'blood_sugar') {
                API_GetDataByTypeTableTableAreaAll(e, 'normal', '0', this.state.date_type, year, quarter, month).then((res) => {
                    this.setState({
                        loadingSpin: false,
                        DataTable: res,
                        DataTable_Number_records: 'ตารางแสดงรายงานค่าน้ำตาลในเลือด',
                        DataTable_Type: e,
                        DataColumnsTable_blood_sugar: ColumnsTable_blood_sugar('เขตสุขภาพ')
                    })
                })
            } else if (e === 'blood_pressure') {
                API_GetDataByTypeTableTableAreaAll(e, 'normal', '', this.state.date_type, year, quarter, month).then((res) => {
                    this.setState({
                        loadingSpin: false,
                        DataTable: res,
                        DataTable_Number_records: 'ตารางแสดงรายงานค่าความดันโลหิต',
                        DataTable_Type: e,
                        DataColumnsTable_blood_pressure: ColumnsTable_blood_pressure('เขตสุขภาพ')
                    })
                })
            } else if (e === 'waist_circumference') {
                API_GetDataByTypeTableTableAreaAll(e, '', '', this.state.date_type, year, quarter, month).then((res) => {
                    console.log(res);
                    this.setState({
                        loadingSpin: false,
                        DataTable: res,
                        DataTable_Number_records: 'ตารางแสดงรายงานค่ารอบเอว',
                        DataTable_Type: e,
                        DataColumnsTable_waist: ColumnsTable_waist('เขตสุขภาพ')
                    })
                })
            }
        }
    }
    //onclick active
    uninfecte_sick_group = (e) => {
        // console.log(e.target.innerText);
        this.setState({ value_group_fasting: 'not_fasting', loadingSpin: true })
        let SetData_uninfecte_sick_group = ''
        var year = this.state.SetValueBy_year === undefined ? '' : Number(this.state.SetValueBy_year)
        var quarter = this.state.SetValueBy_quarter === undefined ? '' : Number(this.state.SetValueBy_quarter)
        var month = this.state.SetValueBy_month === undefined ? '' : Number(this.state.SetValueBy_month)


        if (e.target.innerText === 'กลุ่มป่วย') {
            SetData_uninfecte_sick_group = 'active'
            if (this.state.DataTable_Type === 'blood_sugar') {
                if (this.state.Data_address === 'by_province') {
                    API_GetDataByTypeTableProvince(this.state.Set_ID_province, this.state.DataTable_Type, 'sick', '2', this.state.date_type, year, quarter, month).then((res) => {
                        this.setState({
                            DataTable: res,
                            loadingSpin: false,
                            DataColumnsTable_blood_sugar: ColumnsTable_blood_sugar('อำเภอ', 'sick')
                        })
                    })
                } else if (this.state.Data_address === 'by_district') {
                    API_GetDataByTypeTableDistrict(this.state.Set_ID_district, this.state.DataTable_Type, 'sick', '2', this.state.date_type, year, quarter, month).then((res) => {
                        this.setState({
                            DataTable: res,
                            loadingSpin: false,
                            DataColumnsTable_blood_sugar: ColumnsTable_blood_sugar('ตำบล', 'sick')
                        })
                    })
                } else if (this.state.Data_address === 'by_sub_district') {
                    API_GetDataByTypeTableSubDistrict(this.state.Set_ID_sub_district, this.state.DataTable_Type, 'sick', '2', this.state.date_type, year, quarter, month).then((res) => {
                        this.setState({
                            DataTable: res,
                            loadingSpin: false,
                            DataColumnsTable_blood_sugar: ColumnsTable_blood_sugar('หน่วยบริการ', 'sick')
                        })
                    })
                } else if (this.state.Data_address === 'by_hospital') {
                    API_GetDataHealthRecord(this.state.Set_ID_hospital, this.state.DataTable_Type, 'sick', '2', this.state.date_type, year, quarter, month).then((res) => {
                        this.setState({ DataTable: res, loadingSpin: false })
                    })
                } else {
                    API_GetDataByTypeTableProvinceAll(this.state.DataTable_Type, 'sick', '2', this.state.date_type, year, quarter, month).then((res) => {
                        this.setState({
                            DataTable: res,
                            loadingSpin: false,
                            DataColumnsTable_blood_sugar: ColumnsTable_blood_sugar('จังหวัด', 'sick')
                        })
                    })
                }
            } else if (this.state.DataTable_Type === 'blood_pressure') {
                if (this.state.Data_address === 'by_province') {
                    API_GetDataByTypeTableProvince(this.state.Set_ID_province, this.state.DataTable_Type, 'sick', '', this.state.date_type, year, quarter, month).then((res) => {
                        this.setState({
                            DataTable: res,
                            loadingSpin: false,
                            DataColumnsTable_blood_sugar: ColumnsTable_blood_sugar('อำเภอ', 'sick')
                        })
                    })
                } else if (this.state.Data_address === 'by_district') {
                    API_GetDataByTypeTableDistrict(this.state.Set_ID_district, this.state.DataTable_Type, 'sick', '', this.state.date_type, year, quarter, month).then((res) => {
                        this.setState({
                            DataTable: res,
                            loadingSpin: false,
                            DataColumnsTable_blood_sugar: ColumnsTable_blood_sugar('ตำบล', 'sick')
                        })
                    })
                } else if (this.state.Data_address === 'by_sub_district') {
                    API_GetDataByTypeTableSubDistrict(this.state.Set_ID_sub_district, this.state.DataTable_Type, 'sick', '', this.state.date_type, year, quarter, month).then((res) => {
                        this.setState({
                            DataTable: res,
                            loadingSpin: false,
                            DataColumnsTable_blood_sugar: ColumnsTable_blood_sugar('หน่วยบริการ', 'sick')
                        })
                    })
                } else if (this.state.Data_address === 'by_hospital') {
                    API_GetDataHealthRecord(this.state.Set_ID_hospital, this.state.DataTable_Type, 'sick', '', this.state.date_type, year, quarter, month).then((res) => {
                        this.setState({ DataTable: res, loadingSpin: false })
                    })
                } else {
                    API_GetDataByTypeTableProvinceAll(this.state.DataTable_Type, 'sick', '', this.state.date_type, year, quarter, month).then((res) => {
                        this.setState({
                            DataTable: res,
                            loadingSpin: false,
                            DataColumnsTable_blood_pressure: ColumnsTable_blood_pressure('จังหวัด', 'sick')
                        })
                    })
                }
            }
        } else if (e.target.innerText === 'กลุ่มที่ยังไม่ป่วย') {
            SetData_uninfecte_sick_group = ''
            this.setState({ DataColumnsTable_blood_pressure: '' })
            if (this.state.DataTable_Type === 'blood_sugar') {
                if (this.state.Data_address === 'by_province') {
                    API_GetDataByTypeTableProvince(this.state.Set_ID_province, this.state.DataTable_Type, 'normal', '0', this.state.date_type, year, quarter, month).then((res) => {
                        this.setState({
                            DataTable: res,
                            loadingSpin: false,
                            DataColumnsTable_blood_sugar: ColumnsTable_blood_sugar('อำเภอ')
                        })
                    })
                } else if (this.state.Data_address === 'by_district') {
                    API_GetDataByTypeTableDistrict(this.state.Set_ID_district, this.state.DataTable_Type, 'normal', '0', this.state.date_type, year, quarter, month).then((res) => {
                        this.setState({
                            DataTable: res,
                            loadingSpin: false,
                            DataColumnsTable_blood_sugar: ColumnsTable_blood_sugar('ตำบล')
                        })
                    })
                } else if (this.state.Data_address === 'by_sub_district') {
                    API_GetDataByTypeTableSubDistrict(this.state.Set_ID_sub_district, this.state.DataTable_Type, 'normal', '0', this.state.date_type, year, quarter, month).then((res) => {
                        this.setState({
                            DataTable: res,
                            loadingSpin: false,
                            DataColumnsTable_blood_sugar: ColumnsTable_blood_sugar('หน่วยบริการ')
                        })
                    })
                } else if (this.state.Data_address === 'by_hospital') {
                    API_GetDataHealthRecord(this.state.Set_ID_hospital, this.state.DataTable_Type, 'normal', '0', this.state.date_type, year, quarter, month).then((res) => {
                        this.setState({ DataTable: res, loadingSpin: false })
                    })
                } else {
                    API_GetDataByTypeTableProvinceAll(this.state.DataTable_Type, 'normal', '0', this.state.date_type, year, quarter, month).then((res) => {
                        this.setState({
                            DataTable: res,
                            loadingSpin: false,
                            DataColumnsTable_blood_sugar: ColumnsTable_blood_sugar('จังหวัด')
                        })
                    })
                }
            } else if (this.state.DataTable_Type === 'blood_pressure') {
                if (this.state.Data_address === 'by_province') {
                    API_GetDataByTypeTableProvince(this.state.Set_ID_province, this.state.DataTable_Type, 'normal', '', this.state.date_type, year, quarter, month).then((res) => {
                        this.setState({
                            DataTable: res,
                            loadingSpin: false,
                            DataColumnsTable_blood_pressure: ColumnsTable_blood_pressure('อำเภอ')
                        })
                    })
                } else if (this.state.Data_address === 'by_district') {
                    API_GetDataByTypeTableDistrict(this.state.Set_ID_district, this.state.DataTable_Type, 'normal', '', this.state.date_type, year, quarter, month).then((res) => {
                        this.setState({
                            DataTable: res,
                            loadingSpin: false,
                            DataColumnsTable_blood_pressure: ColumnsTable_blood_pressure('ตำบล')
                        })
                    })
                } else if (this.state.Data_address === 'by_sub_district') {
                    API_GetDataByTypeTableSubDistrict(this.state.Set_ID_sub_district, this.state.DataTable_Type, 'normal', '', this.state.date_type, year, quarter, month).then((res) => {
                        this.setState({
                            DataTable: res,
                            loadingSpin: false,
                            DataColumnsTable_blood_pressure: ColumnsTable_blood_pressure('หน่วยบริการ')
                        })
                    })
                } else if (this.state.Data_address === 'by_hospital') {
                    API_GetDataHealthRecord(this.state.Set_ID_hospital, this.state.DataTable_Type, 'normal', '', this.state.date_type, year, quarter, month).then((res) => {
                        this.setState({
                            loadingSpin: false,
                            DataTable: res,
                            DataTable_Number_records: 'ตารางแสดงรายงานค่าความดันโลหิต',
                            DataTable_Type: this.state.DataTable_Type,
                            DataColumnsTable_blood_pressure: Colums_Hospital_blood_pressure
                        })
                    })
                } else {
                    API_GetDataByTypeTableProvinceAll(this.state.DataTable_Type, 'normal', '', this.state.date_type, year, quarter, month).then((res) => {
                        this.setState({
                            DataTable: res,
                            loadingSpin: false,
                            DataColumnsTable_blood_pressure: ColumnsTable_blood_pressure('จังหวัด')
                        })
                    })
                }
            }
        }
        this.setState({ SetData_uninfecte_sick_group: SetData_uninfecte_sick_group })
    }
    //fasting and not fasting
    uninfecte_sick_group_fasting = (e) => {
        // console.log(e);
        this.setState({ value_group_fasting: e, loadingSpin: true })
        var year = this.state.SetValueBy_year === undefined ? '' : Number(this.state.SetValueBy_year)
        var quarter = this.state.SetValueBy_quarter === undefined ? '' : Number(this.state.SetValueBy_quarter)
        var month = this.state.SetValueBy_month === undefined ? '' : Number(this.state.SetValueBy_month)


        if (this.state.SetData_uninfecte_sick_group === 'active') {
            if (e === 'not_fasting') {
                if (this.state.Data_address === 'by_province') {
                    API_GetDataByTypeTableProvince(this.state.Set_ID_province, this.state.DataTable_Type, 'sick', '2', this.state.date_type, year, quarter, month).then((res) => {
                        this.setState({ DataTable: res, loadingSpin: false })
                    })
                } else if (this.state.Data_address === 'by_district') {
                    API_GetDataByTypeTableDistrict(this.state.Set_ID_district, this.state.DataTable_Type, 'sick', '2', this.state.date_type, year, quarter, month).then((res) => {
                        this.setState({ DataTable: res, loadingSpin: false })
                    })
                } else if (this.state.Data_address === 'by_sub_district') {
                    API_GetDataByTypeTableSubDistrict(this.state.Set_ID_sub_district, this.state.DataTable_Type, 'sick', '2', this.state.date_type, year, quarter, month).then((res) => {
                        this.setState({ DataTable: res, loadingSpin: false })
                    })
                } else if (this.state.Data_address === 'by_hospital') {
                    API_GetDataHealthRecord(this.state.Set_ID_hospital, this.state.DataTable_Type, 'sick', '2', this.state.date_type, year, quarter, month).then((res) => {
                        this.setState({ DataTable: res, loadingSpin: false })
                    })
                } else {
                    API_GetDataByTypeTableProvinceAll(this.state.DataTable_Type, 'sick', '2', this.state.date_type, year, quarter, month).then((res) => {
                        this.setState({ DataTable: res, loadingSpin: false })
                    })
                }
            } else if (e === 'fasting') {
                if (this.state.Data_address === 'by_province') {
                    API_GetDataByTypeTableProvince(this.state.Set_ID_province, this.state.DataTable_Type, 'sick', '8', this.state.date_type, year, quarter, month).then((res) => {
                        this.setState({ DataTable: res, loadingSpin: false })
                    })
                } else if (this.state.Data_address === 'by_district') {
                    API_GetDataByTypeTableDistrict(this.state.Set_ID_district, this.state.DataTable_Type, 'sick', '8', this.state.date_type, year, quarter, month).then((res) => {
                        this.setState({ DataTable: res, loadingSpin: false })
                    })
                } else if (this.state.Data_address === 'by_sub_district') {
                    API_GetDataByTypeTableSubDistrict(this.state.Set_ID_sub_district, this.state.DataTable_Type, 'sick', '8', this.state.date_type, year, quarter, month).then((res) => {
                        this.setState({ DataTable: res, loadingSpin: false })
                    })
                } else if (this.state.Data_address === 'by_hospital') {
                    API_GetDataHealthRecord(this.state.Set_ID_hospital, this.state.DataTable_Type, 'sick', '8', this.state.date_type, year, quarter, month).then((res) => {
                        this.setState({ DataTable: res, loadingSpin: false })
                    })
                } else {
                    API_GetDataByTypeTableProvinceAll(this.state.DataTable_Type, 'sick', '8', this.state.date_type, year, quarter, month).then((res) => {
                        this.setState({ DataTable: res, loadingSpin: false })
                    })
                }
            }
        } else {
            if (e === 'not_fasting') {
                if (this.state.Data_address === 'by_province') {
                    API_GetDataByTypeTableProvince(this.state.Set_ID_province, this.state.DataTable_Type, 'normal', '0', this.state.date_type, year, quarter, month).then((res) => {
                        this.setState({
                            DataTable: res,
                            loadingSpin: false,
                            DataColumnsTable_blood_sugar: ColumnsTable_blood_sugar('อำเภอ')
                        })
                    })
                } else if (this.state.Data_address === 'by_district') {
                    API_GetDataByTypeTableDistrict(this.state.Set_ID_district, this.state.DataTable_Type, 'normal', '0', this.state.date_type, year, quarter, month).then((res) => {
                        this.setState({
                            DataTable: res,
                            loadingSpin: false,
                            DataColumnsTable_blood_sugar: ColumnsTable_blood_sugar('ตำบล')
                        })
                    })
                } else if (this.state.Data_address === 'by_sub_district') {
                    API_GetDataByTypeTableSubDistrict(this.state.Set_ID_sub_district, this.state.DataTable_Type, 'normal', '0', this.state.date_type, year, quarter, month).then((res) => {
                        this.setState({
                            DataTable: res,
                            loadingSpin: false,
                            DataColumnsTable_blood_sugar: ColumnsTable_blood_sugar('หน่วยบริการ')
                        })
                    })
                } else if (this.state.Data_address === 'by_hospital') {
                    API_GetDataHealthRecord(this.state.Set_ID_hospital, this.state.DataTable_Type, 'normal', '0', this.state.date_type, year, quarter, month).then((res) => {
                        this.setState({ DataTable: res, loadingSpin: false })
                    })
                } else {
                    API_GetDataByTypeTableProvinceAll(this.state.DataTable_Type, 'normal', '0', this.state.date_type, year, quarter, month).then((res) => {
                        this.setState({
                            DataTable: res,
                            loadingSpin: false,
                            DataColumnsTable_blood_sugar: ColumnsTable_blood_sugar('จังหวัด')
                        })
                    })
                }
            } else if (e === 'fasting') {
                if (this.state.Data_address === 'by_province') {
                    API_GetDataByTypeTableProvince(this.state.Set_ID_province, this.state.DataTable_Type, 'normal', '8', this.state.date_type, year, quarter, month).then((res) => {
                        this.setState({
                            DataTable: res,
                            loadingSpin: false,
                            DataColumnsTable_blood_sugar: ColumnsTable_blood_sugar('อำเภอ', '', '8')
                        })
                    })
                } else if (this.state.Data_address === 'by_district') {
                    API_GetDataByTypeTableDistrict(this.state.Set_ID_district, this.state.DataTable_Type, 'normal', '8', this.state.date_type, year, quarter, month).then((res) => {
                        this.setState({
                            DataTable: res,
                            loadingSpin: false,
                            DataColumnsTable_blood_sugar: ColumnsTable_blood_sugar('ตำบล', '', '8')
                        })
                    })
                } else if (this.state.Data_address === 'by_sub_district') {
                    API_GetDataByTypeTableSubDistrict(this.state.Set_ID_sub_district, this.state.DataTable_Type, 'normal', '8', this.state.date_type, year, quarter, month).then((res) => {
                        this.setState({
                            DataTable: res,
                            loadingSpin: false,
                            DataColumnsTable_blood_sugar: ColumnsTable_blood_sugar('หน่วยบริการ', '', '8')
                        })
                    })
                } else if (this.state.Data_address === 'by_hospital') {
                    API_GetDataHealthRecord(this.state.Set_ID_hospital, this.state.DataTable_Type, 'normal', '8', this.state.date_type, year, quarter, month).then((res) => {
                        this.setState({ DataTable: res, loadingSpin: false })
                    })
                } else {
                    API_GetDataByTypeTableProvinceAll(this.state.DataTable_Type, 'normal', '8', this.state.date_type, year, quarter, month).then((res) => {
                        this.setState({
                            DataTable: res,
                            loadingSpin: false,
                            DataColumnsTable_blood_sugar: ColumnsTable_blood_sugar('จังหวัด', '', '8')
                        })
                    })
                }
            }
        }
    }

    Color_value_description = (value) => {
        console.log(value);
        this.setState({ SetValueColor_description: value })
    }
    //------------------------------------------------------------------------------//

    downloadFile = (filePath) => {
        var link = document.createElement('a');
        link.href = filePath;
        link.download = filePath.substr(filePath.lastIndexOf('/') + 1);
        link.click();
    }

    DownloadExcel = async  () => {
        this.setState({ loadingSpin: true })
        var year = this.state.SetValueBy_year
        var quarter = this.state.SetValueBy_quarter
        var month = this.state.SetValueBy_month

        await getAllExportExcel(this.state.Set_ID_hospital, this.state.date_type, year, quarter, month).then(res => {
            console.log(res)
            let url = window.URL.createObjectURL(res['data'])
            window.open(url)
            this.setState({ loadingSpin: false })
        }).catch(() => {
            alert('ไม่ตอบสนอง')
            this.setState({ loadingSpin: false })
        })
        
    }

    menuChange(menu) {
        this.setState({
            menu: menu
        })

        if (menu === 'health_station') {
            this.componentDidMount()
        }

    }

    logout() {
        localStorage.clear()
        this.props.history.push('/guest')
    }

    medicineDetailButton(id) {
        this.setState({
            menu: 'medicine',
            medicineId: id
        })
    }

    render() {
        let data_SetValues;
        if (this.state.SetValueBy_health_area !== undefined && this.state.SetValueBy_province === undefined) {
            data_SetValues = this.state.SetValueBy_health_area
        } else if (this.state.SetValueBy_province !== undefined && this.state.SetValueBy_district === undefined) {
            data_SetValues = this.state.SetValueBy_province
        } else if (this.state.SetValueBy_district !== undefined && this.state.SetValueBy_sub_district === undefined) {
            data_SetValues = this.state.SetValueBy_district
        } else if (this.state.SetValueBy_sub_district !== undefined && this.state.SetValueBy_hospital === undefined) {
            data_SetValues = this.state.SetValueBy_sub_district
        } else if (this.state.SetValueBy_hospital !== undefined) {
            data_SetValues = this.state.SetValueBy_hospital
        }
        return (
            <Spin className="example" tip="Loading..." spinning={this.state.loadingSpin}>
                <Layout>
                    <Sider className="sider-menu-item"
                        breakpoint="lg"
                        collapsedWidth="0">
                        {/* <img src={require('../icon/logo_nux.svg').default} alt="logo_nux" className="App-logo" /> */}
                        <Menu mode="inline" defaultSelectedKeys={['1']} style={{ marginTop: '30px' }}>
                            <Menu.Item key="1" onClick={() => { this.menuChange('health_station') }}>สถิติการบันทึกข้อมูล health station</Menu.Item>
                            <Menu.Item key="2" onClick={() => { this.menuChange('home_medicine') }}>รายชื่อผู้ป่วยรอรับยาที่บ้าน</Menu.Item>
                            {

                                this.state.showMenuUserManage
                                    ?
                                    <Menu.Item key="3" onClick={() => { this.menuChange('user_management') }}>จัดการผู้ใช้งาน</Menu.Item>
                                    :
                                    null
                            }


                            <Menu.Item key="4" onClick={() => { this.logout() }}>ออกจากระบบ</Menu.Item>
                        </Menu>
                    </Sider>
                    <Layout style={{ backgroundColor: 'white', minHeight: '100vh' }}>

                        {this.state.menu === 'health_station' ? <Content style={{ padding: '20px' }}>
                            <Row align="middle" className="Row-col-Header-content">
                                <Col sm={{ span: 9, offset: 0 }} xs={{ span: 24, offset: 0 }} coll="true" className="icon-hospital-svg">
                                    {/* <span><img src={require('../icon/hospital.svg').default} alt="icon-hospital-svg" /> รพ.สต.ท่าโพธิ์ จังหวัดพิษณุโลก</span> */}
                                </Col>
                                <Col sm={{ span: 3, offset: 4 }} xs={{ span: 9, offset: 1 }} coll="true" className="col-page-header">
                                    <div onClick={() => this.onclick_TabPage('Graph')}>
                                        {/* <img src={require('../icon/circle-graph.svg').default} alt="graph" /> */}
                                        <span> กราฟวงกลม</span>
                                        {this.state.SetvaluePage === 'Graph' ? <div className="button-page-onclick-on" /> : <></>}
                                    </div>
                                </Col>
                                <Col sm={{ span: 3, offset: 1 }} xs={{ span: 9, offset: 4 }} coll="true" className="col-page-header">
                                    <div onClick={() => this.onclick_TabPage('Table')}>
                                        <img src={require('../icon/table.svg').default} alt="table" />
                                        <span> ตารางรายชื่อ</span>
                                        {this.state.SetvaluePage === 'Table' ? <div className="button-page-onclick-on" /> : <></>}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={{ span: 20 }} xs={{ span: 24 }} coll="true">
                                    <div className="container-background-header">
                                        <div>เลือกข้อมูล</div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={{ span: 6 }} xs={{ span: 24 }} coll="true">
                                    <Form.Item>
                                        <Select
                                            value={this.state.SetValueBy_health_area}
                                            onChange={(value, select) => this.handleChange('by_health_area', value, select)}
                                            placeholder="เขตสุขภาพ"
                                            disabled={false}
                                        >
                                            {this.state.health_area_name}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col sm={{ span: 6, offset: 1 }} xs={{ span: 24 }} coll="true">
                                    <Form.Item>
                                        <Select
                                            value={this.state.SetValueBy_province}
                                            placeholder="จังหวัด"
                                            onChange={(value, select) => this.handleChange('by_province', value, select)}
                                            disabled={this.state.SetValueBy_health_area === undefined ? true : false}
                                        >
                                            {this.state.province_name}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col sm={{ span: 6, offset: 1 }} xs={{ span: 24 }} coll="true">
                                    <Form.Item>
                                        <Select
                                            value={this.state.SetValueBy_district}
                                            placeholder="อำเภอ"
                                            onChange={(value, select) => this.handleChange('by_district', value, select)}
                                            disabled={this.state.SetValueBy_province === undefined ? true : false}
                                        >
                                            {this.state.district_name}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={{ span: 6 }} xs={{ span: 24 }} coll="true">
                                    <Form.Item>
                                        <Select
                                            value={this.state.SetValueBy_sub_district}
                                            placeholder="ตำบล"
                                            onChange={(value, select) => this.handleChange('by_sub_district', value, select)}
                                            disabled={this.state.SetValueBy_district === undefined ? true : false}
                                        >
                                            {this.state.sub_district_name}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col sm={{ span: 6, offset: 1 }} xs={{ span: 24 }} coll="true">
                                    <Form.Item>
                                        <Select
                                            value={this.state.SetValueBy_hospital}
                                            placeholder="หน่วยบริการ"
                                            onChange={(value, select) => this.handleChange('by_hospital', value, select)}
                                            disabled={this.state.SetValueBy_sub_district === undefined ? true : false}
                                        >
                                            {this.state.hospital_name}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={{ span: 20 }} xs={{ span: 24 }} coll="true">
                                    <div className="container-background-header">
                                        <span>เลือกช่วงเวลา</span>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={{ span: 6 }} xs={{ span: 24 }} coll="true">
                                    <Form.Item>
                                        <Select
                                            value={this.state.SetValueBy_year}
                                            placeholder="ปีงบประมาณ"
                                            onChange={(value, select) => this.handleChange('by_year', value, select)}
                                        >
                                            {this.state.fiscal_year_name}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col sm={{ span: 6, offset: 1 }} xs={{ span: 24 }} coll="true">
                                    <Form.Item>
                                        <Select
                                            disabled={this.state.SetValueBy_year === undefined ? true : false}
                                            value={this.state.SetValueBy_quarter}
                                            placeholder="ไตรมาส"
                                            onChange={(value, select) => this.handleChange('by_quarter', value, select)}
                                        >
                                            <Option key="1" value="1">1</Option>
                                            <Option key="2" value="2">2</Option>
                                            <Option key="3" value="3">3</Option>
                                            <Option key="4" value="4">4</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col sm={{ span: 6, offset: 1 }} xs={{ span: 24 }} coll="true">
                                    <Form.Item>
                                        <Select
                                            disabled={this.state.SetValueBy_quarter === undefined ? true : false}
                                            placeholder="เดือน"
                                            value={this.state.SetValueBy_month}
                                            onChange={(value, select) => this.handleChange('by_month', value, select)}
                                        >
                                            {this.state.SetValueBy_quarter === '2' ? <>
                                                <Option key="1" value="1">มกราคม</Option>
                                                <Option key="2" value="2">กุมภาพันธ์</Option>
                                                <Option key="3" value="3">มีนาคม</Option>
                                            </> : null}
                                            {this.state.SetValueBy_quarter === '3' ? <>
                                                <Option key="4" value="4">เมษายน</Option>
                                                <Option key="5" value="5">พฤษภาคม</Option>
                                                <Option key="6" value="6">มิถุนายน</Option>
                                            </> : null}
                                            {this.state.SetValueBy_quarter === '4' ? <>
                                                <Option key="7" value="7">กรกฎาคม</Option>
                                                <Option key="8" value="8">สิงหาคม</Option>
                                                <Option key="9" value="9">กันยายน</Option>
                                            </> : null}
                                            {this.state.SetValueBy_quarter === '1' ? <>
                                                <Option key="10" value="10">ตุลาคม</Option>
                                                <Option key="11" value="11">พฤศจิกายน</Option>
                                                <Option key="12" value="12">ธันวาคม</Option>
                                            </> : null}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>

                            {this.state.SetvaluePage === 'Graph' ? <>
                                <Row>
                                    <Col sm={{ span: 20 }} xs={{ span: 24 }} coll="true">
                                        <div className="container-background-header">
                                            <span>เลือกประเภทสัญญาณชีพ</span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={{ span: 6 }} xs={{ span: 24 }} coll="true">
                                        <Form.Item>
                                            <Select value={this.state.DataGraph_Type} placeholder="ประเภทค่าสัญญาณชีพ"
                                                onChange={(value) => this.handleChange_TypeGraph(value)}
                                            >
                                                <Option key="0" value="all">ทั้งหมด</Option>
                                                <Option key="1" value="blood_pressure">ค่าความดันโลหิต</Option>
                                                <Option key="2" value="blood_sugar">ค่าน้ำตาลในเลือด</Option>
                                                <Option key="3" value="bmi">ค่าดัชนีมวลกาย</Option>
                                                <Option key="4" value="waist_circumference">ค่ารอบเอว</Option>
                                                {/* <Option value="oxygen_saturation">ออกซิเจนในเลือด</Option> */}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={{ span: 6 }} xs={{ span: 24 }} coll="true">
                                        <div className="container-background-h4u-souvenirs">
                                            <span>จำนวนผู้ใช้งาน H4U ทั้งหมด <span style={{ color: 'red', fontWeight: 'bold' }}>{this.state.SumUserTotal}</span> คน</span>
                                        </div>
                                    </Col>
                                    {/* <Col sm={{ span: 6, offset: 1 }} xs={{ span: 24 }} coll="true">
                                        <div className="container-background-h4u-souvenirs">
                                            <span>จำนวนผู้ใช้งาน สมาร์ท อสม. <span style={{ color: 'red', fontWeight: 'bold' }}>...</span> คน</span>
                                        </div>
                                    </Col> */}
                                </Row>

                                {this.state.DataGraph_Type === 'all' ? <>
                                    <Row>
                                        <Col sm={{ span: 20 }} xs={{ span: 24 }} coll="true">
                                            <div><img src={require('../icon/Number_of_records.svg').default} alt="Number_of_records" />
                                                <span style={{ color: '#5C2D91', paddingLeft: '15px' }}>จำนวนที่บันทึกเข้ามา</span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row align="middle">
                                        <Col sm={{ span: 20 }} xs={{ span: 24 }} coll="true">
                                            <div id="chartdiv" className="chartdiv-width-height" />
                                        </Col>
                                    </Row>
                                </> : null}
                                {this.state.DataGraph_Type === 'blood_pressure' ? <>
                                    <Row>
                                        <Col sm={{ span: 20 }} xs={{ span: 24 }} coll="true">
                                            <div><img src={require('../icon/health_record_total.svg').default} alt="health_record_total" />
                                                <span style={{ color: '#5C2D91', paddingLeft: '15px' }}>จำนวนการบันทึกค่าความดันโลหิตแยกตามความเสี่ยง</span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={{ span: 19, offset: 1 }} xs={{ span: 24 }} coll="true" style={{ marginTop: '20px' }}>
                                            <span style={{ color: '#5C2D91' }}>กลุ่มคนที่ยังไม่ป่วยเป็นโรคความดันโลหิต</span>
                                        </Col>
                                    </Row>
                                    <Row align="middle">
                                        <Col sm={{ span: 20 }} xs={{ span: 24 }} coll="true">
                                            <div id="chart_normal" className="chartdiv-width-height" />
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row>
                                        <Col sm={{ span: 19, offset: 1 }} xs={{ span: 24 }} coll="true" style={{ marginTop: '20px' }}>
                                            <span style={{ color: '#5C2D91' }}>กลุ่มคนที่ป่วยเป็นโรคความดันโลหิต</span>
                                        </Col>
                                    </Row>
                                    <Row align="middle">
                                        <Col sm={{ span: 20 }} xs={{ span: 24 }} coll="true">
                                            <div id="chart_sick" className="chartdiv-width-height" />
                                        </Col>
                                    </Row>
                                </> : null}
                                {this.state.DataGraph_Type === 'blood_sugar' ? <>
                                    <Row>
                                        <Col sm={{ span: 20 }} xs={{ span: 24 }} coll="true">
                                            <div><img src={require('../icon/blood_sugar_total.svg').default} alt="blood_sugar_total" />
                                                <span style={{ color: '#5C2D91', paddingLeft: '15px' }}>จำนวนการบันทึกค่าน้ำตาลในเลือดแยกตามความเสี่ยง</span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={{ span: 19, offset: 1 }} xs={{ span: 24 }} coll="true" style={{ marginTop: '20px' }}>
                                            <span style={{ color: '#5C2D91' }}>กลุ่มคนยังไม่ป่วย</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={{ span: 19, offset: 1 }} xs={{ span: 24 }} coll="true" style={{ marginTop: '20px' }}>
                                            <div style={{ display: 'flex' }}>
                                                <span style={{ paddingRight: '20px', color: '#5C2D91' }}>เลือกประเภท</span>
                                                <Form.Item style={{ width: '220px' }}>
                                                    <Select defaultValue="not_fasting" placeholder="เลือกกลุ่มตาราง" onChange={(e) => { this.selectType_fasting('normal', e) }}>
                                                        <Option value="not_fasting">ไม่งดอาหาร</Option>
                                                        <Option value="fasting">งดอาหารอย่างน้อย 8 ชั่วโมง</Option>
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row align="middle">
                                        <Col sm={{ span: 20 }} xs={{ span: 24 }} coll="true">
                                            <div id="chart_normal" className="chartdiv-width-height" />
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row>
                                        <Col sm={{ span: 19, offset: 1 }} xs={{ span: 24 }} coll="true" style={{ marginTop: '20px' }}>
                                            <span style={{ color: '#5C2D91' }}>กลุ่มคนป่วย</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={{ span: 19, offset: 1 }} xs={{ span: 24 }} coll="true" style={{ marginTop: '20px' }}>
                                            <div style={{ display: 'flex' }}>
                                                <span style={{ paddingRight: '20px', color: '#5C2D91' }}>เลือกประเภท</span>
                                                <Form.Item style={{ width: '220px' }}>
                                                    <Select defaultValue="not_fasting" placeholder="เลือกกลุ่มตาราง" onChange={(e) => { this.selectType_fasting('sick', e) }}>
                                                        <Option value="not_fasting">งดอาหารอย่างน้อย 2 ชั่วโมง</Option>
                                                        <Option value="fasting">งดอาหารอย่างน้อย 8 ชั่วโมง</Option>
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row align="middle">
                                        <Col sm={{ span: 20 }} xs={{ span: 24 }} coll="true">
                                            <div id="chart_sick" className="chartdiv-width-height" />
                                        </Col>
                                    </Row>
                                </> : null}
                                {this.state.DataGraph_Type === 'bmi' ? <>
                                    <Row>
                                        <Col sm={{ span: 20 }} xs={{ span: 24 }} coll="true">
                                            <div><img src={require('../icon/bmi.svg').default} alt="bmi" />
                                                <span style={{ color: '#5C2D91', paddingLeft: '15px' }}>จำนวนการบันทึกค่าดัชนีมวลกาย (BMI) แยกตามความเสี่ยง</span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row align="middle">
                                        <Col sm={{ span: 20 }} xs={{ span: 24 }} coll="true">
                                            <div id="chartdiv" className="chartdiv-width-height" />
                                        </Col>
                                    </Row>
                                </> : null}
                                {this.state.DataGraph_Type === 'waist_circumference' ? <>
                                    <Row>
                                        <Col sm={{ span: 20 }} xs={{ span: 24 }} coll="true">
                                            <div><img src={require('../icon/bmi.svg').default} alt="bmi" />
                                                <span style={{ color: '#5C2D91', paddingLeft: '15px' }}>จำนวนการบันทึกค่ารอบเอว</span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row align="middle">
                                        <Col sm={{ span: 20 }} xs={{ span: 24 }} coll="true">
                                            <div id="chartdiv" className="chartdiv-width-height" />
                                        </Col>
                                    </Row>
                                </> : null}
                            </> : null}

                            {this.state.SetvaluePage === 'Table' ? <>
                                <Row>
                                    <Col sm={{ span: 20 }} xs={{ span: 24 }} coll="true">
                                        <div className="container-background-header">
                                            <div>เลือกกลุ่มรายงาน</div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={{ span: 6 }} xs={{ span: 24 }} coll="true">
                                        <Form.Item>
                                            <Select value={this.state.DataTable_Type} placeholder="เลือกกลุ่มรายงาน" onChange={(value) => this.handleChange_TypeTable(value)}>
                                                <Option key="0" value="all">ทั้งหมด</Option>
                                                <Option key="1" value="bmi">ค่าดัชนีมวลกาย</Option>
                                                <Option key="2" value="blood_pressure">ค่าความดันโลหิต</Option>
                                                <Option key="3" value="blood_sugar">ค่าระดับน้ำตาลในเลือด</Option>
                                                <Option key="4" value="waist_circumference">ค่ารอบเอว</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={{ span: 20 }} xs={{ span: 24 }} coll="true" style={{ marginBottom: '20px' }}>
                                        <div className="title-group-table">
                                            <div>
                                                <div style={{ color: '#5c2d91' }}>{this.state.DataTable_Number_records}</div>
                                                <div style={{ color: '#848484' }}>{data_SetValues}</div>
                                            </div>
                                            {this.state.Data_address === 'by_hospital' ?
                                                <div className="botton-Export-pdf-file" onClick={() => this.DownloadExcel()}>Export Excel</div>
                                                : null}
                                        </div>
                                    </Col>
                                </Row>
                                {this.state.Data_address === 'by_hospital' ?
                                    <Row>
                                        <Col sm={{ span: 6 }} xs={{ span: 24 }}></Col>
                                        <Col sm={{ span: 6, offset: 1 }} xs={{ span: 24 }}></Col>
                                        <Col sm={{ span: 6, offset: 1 }} xs={{ span: 24 }}>
                                            <input
                                                className='searchInput'
                                                placeholder="ค้นหาด้วยชื่อ"
                                                value={this.state.searchText}
                                                onChange={(event) => { this.setState({ searchText: event.target.value }) }}
                                            />
                                        </Col>
                                    </Row>
                                    : null}

                                {this.state.DataTable_Type === 'all' ? <>
                                    <Row>
                                        <Col sm={{ span: 20 }} xs={{ span: 24 }} coll="true">
                                            <Table
                                                columns={this.state.DataColumns_adress}
                                                dataSource={
                                                    this.state.Data_address === 'by_hospital' ?
                                                        this.state.DataTable.filter(item => {
                                                            return `${item['pname']} ${item['fname']} ${item['lname']}`.includes(this.state.searchText)
                                                        })
                                                        : this.state.DataTable
                                                }
                                            />
                                        </Col>
                                    </Row>
                                    {this.state.Data_address === 'by_hospital' ? <>
                                        <div style={{ color: '#5C2D91' }}>คำอธิบายค่าสี</div>
                                        <Row>
                                            <Col sm={{ span: 4 }} xs={{ span: 24 }} coll="true">
                                                <Form.Item>
                                                    <Select
                                                        value={this.state.SetValueColor_description}
                                                        onChange={(value) => this.Color_value_description(value)}
                                                    >
                                                        <Option key="1" value="bmi">ค่าดัชนีมวลกาย</Option>
                                                        <Option key="2" value="blood_pressure">ค่าความดันโลหิต</Option>
                                                        <Option key="3" value="blood_sugar">ค่าน้ำตาลในเลือด</Option>
                                                        <Option key="4" value="waist_circumference">ค่ารอบเอว</Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            {this.state.SetValueColor_description === 'bmi' ? <>
                                                <Col sm={{ span: 14, offset: 1 }} xs={{ span: 24 }} coll="true">
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div style={{ backgroundColor: '#FF67FF' }} className="Box-color-description"></div>
                                                        <div style={{ marginRight: '25px' }}>ต่ำกว่าเกณฑ์</div>
                                                        <div style={{ backgroundColor: '#92D050' }} className="Box-color-description"></div>
                                                        <div style={{ marginRight: '25px' }}>สมส่วน</div>
                                                        <div style={{ backgroundColor: '#FFFF02' }} className="Box-color-description"></div>
                                                        <div style={{ marginRight: '25px' }}>น้ำหนักเกิน</div>
                                                        <div style={{ backgroundColor: '#FFC001' }} className="Box-color-description"></div>
                                                        <div style={{ marginRight: '25px' }}>อ้วน</div>
                                                        <div style={{ backgroundColor: '#FF0000' }} className="Box-color-description"></div>
                                                        <div style={{ marginRight: '25px' }}>อ้วนอันตราย</div>
                                                    </div>
                                                </Col>
                                            </> : null}
                                            {this.state.SetValueColor_description === 'blood_pressure' ? <>
                                                <Col sm={{ span: 14, offset: 1 }} xs={{ span: 24 }} coll="true">
                                                    <div style={{ color: '#5C2D91' }}>กลุ่มที่ยังไม่ป่วยเป็นโรคความดันโลหิต</div>
                                                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                                                        <div style={{ backgroundColor: '#92D050' }} className="Box-color-description"></div>
                                                        <div style={{ marginRight: '25px' }}>ปกติ</div>
                                                        <div style={{ backgroundColor: '#FFFF02' }} className="Box-color-description"></div>
                                                        <div style={{ marginRight: '25px' }}>เกือบสูง</div>
                                                        <div style={{ backgroundColor: '#FFC001' }} className="Box-color-description"></div>
                                                        <div style={{ marginRight: '25px' }}>สูง</div>
                                                        <div style={{ backgroundColor: '#FF0000' }} className="Box-color-description"></div>
                                                        <div style={{ marginRight: '25px' }}>อันตราย</div>
                                                    </div>
                                                </Col>
                                                <Col sm={{ span: 14, offset: 5 }} xs={{ span: 24 }} coll="true">
                                                    <div style={{ color: '#5C2D91' }}>กลุ่มที่ป่วยเป็นโรคความดันโลหิต</div>
                                                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                                                        <div style={{ backgroundColor: '#FF67FF' }} className="Box-color-description"></div>
                                                        <div style={{ marginRight: '25px' }}>ต่ำกว่าเกณฑ์</div>
                                                        <div style={{ backgroundColor: '#92D050' }} className="Box-color-description"></div>
                                                        <div style={{ marginRight: '25px' }}>เหมาะสม</div>
                                                        <div style={{ backgroundColor: '#FFFF02' }} className="Box-color-description"></div>
                                                        <div style={{ marginRight: '25px' }}>สูงเล็กน้อย</div>
                                                        <div style={{ backgroundColor: '#FFC001' }} className="Box-color-description"></div>
                                                        <div style={{ marginRight: '25px' }}>สูงปานกลาง</div>
                                                        <div style={{ backgroundColor: '#FF0000' }} className="Box-color-description"></div>
                                                        <div style={{ marginRight: '25px' }}>สูงอันตราย</div>
                                                    </div>
                                                </Col>
                                            </> : null}
                                            {this.state.SetValueColor_description === 'blood_sugar' ? <>
                                                <Col sm={{ span: 14, offset: 1 }} xs={{ span: 24 }} coll="true">
                                                    <div style={{ color: '#5C2D91' }}>กลุ่มที่ยังไม่ป่วยเป็นโรคเบาหวาน</div>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <li style={{ color: '#5C2D91', marginRight: '30px' }}>ไม่งดอาหาร</li>
                                                        <div style={{ backgroundColor: '#92D050' }} className="Box-color-description"></div>
                                                        <div style={{ marginRight: '25px' }}>ปกติ</div>
                                                        <div style={{ backgroundColor: '#FFFF02' }} className="Box-color-description"></div>
                                                        <div style={{ marginRight: '25px' }}>เสี่ยงเบาหวาน</div>
                                                        <div style={{ backgroundColor: '#FFC001' }} className="Box-color-description"></div>
                                                        <div style={{ marginRight: '25px' }}>สงสัยป่วยเบาหวาน</div>
                                                    </div>
                                                </Col>
                                                <Col sm={{ span: 14, offset: 5 }} xs={{ span: 24 }} coll="true">
                                                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                                                        <li style={{ color: '#5C2D91', marginRight: '30px' }}>งดอาหารอย่างน้อย 8 ชั่วโมง</li>
                                                        <div style={{ backgroundColor: '#FF67FF' }} className="Box-color-description"></div>
                                                        <div style={{ marginRight: '25px' }}>ต่ำ</div>
                                                        <div style={{ backgroundColor: '#92D050' }} className="Box-color-description"></div>
                                                        <div style={{ marginRight: '25px' }}>ปกติ</div>
                                                        <div style={{ backgroundColor: '#FFFF02' }} className="Box-color-description"></div>
                                                        <div style={{ marginRight: '25px' }}>เสี่ยงเบาหวาน</div>
                                                        <div style={{ backgroundColor: '#FFC001' }} className="Box-color-description"></div>
                                                        <div style={{ marginRight: '25px' }}>สงสัยป่วยเบาหวาน</div>
                                                    </div>
                                                </Col>
                                                <Col sm={{ span: 14, offset: 5 }} xs={{ span: 24 }} coll="true">
                                                    <div style={{ color: '#5C2D91' }}>กลุ่มที่ป่วยเป็นโรคเบาหวาน</div>
                                                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                                                        <div style={{ backgroundColor: '#690E8A' }} className="Box-color-description"></div>
                                                        <div style={{ marginRight: '25px' }}>ต่ำรุนแรง</div>
                                                        <div style={{ backgroundColor: '#92D050' }} className="Box-color-description"></div>
                                                        <div style={{ marginRight: '25px' }}>เหมาะสม</div>
                                                        <div style={{ backgroundColor: '#FFFF02' }} className="Box-color-description"></div>
                                                        <div style={{ marginRight: '25px' }}>สูงกว่าเป้าหมาย</div>
                                                        <div style={{ backgroundColor: '#FFC001' }} className="Box-color-description"></div>
                                                        <div style={{ marginRight: '25px' }}>สูงมาก</div>
                                                        <div style={{ backgroundColor: '#FF0000' }} className="Box-color-description"></div>
                                                        <div style={{ marginRight: '25px' }}>สูงอันตรายถึงชีวิต</div>
                                                    </div>
                                                </Col>
                                            </> : null}
                                            {this.state.SetValueColor_description === 'waist_circumference' ? <>
                                                <Col sm={{ span: 14, offset: 1 }} xs={{ span: 24 }} coll="true">
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div style={{ backgroundColor: '#92D050' }} className="Box-color-description"></div>
                                                        <div style={{ marginRight: '25px' }}>เหมาะสม</div>
                                                        <div style={{ backgroundColor: '#FFFF02' }} className="Box-color-description"></div>
                                                        <div style={{ marginRight: '25px' }}>มีภาวะอ้วนลงพุง</div>
                                                    </div>
                                                </Col>
                                            </> : null}
                                        </Row>
                                    </> : null}
                                </> : null}
                                {this.state.DataTable_Type === 'bmi' ? <>
                                    <Row>
                                        <Col sm={{ span: 20 }} xs={{ span: 24 }} coll="true">
                                            <Table
                                                columns={this.state.DataColumnsTable_BMI}
                                                dataSource={
                                                    this.state.Data_address === 'by_hospital' ?
                                                        this.state.DataTable.filter(item => {
                                                            return `${item['pname']} ${item['fname']} ${item['lname']}`.includes(this.state.searchText)
                                                        })
                                                        : this.state.DataTable
                                                } />
                                        </Col>
                                    </Row>
                                    {this.state.Data_address === 'by_hospital' ? <>
                                        <div style={{ color: '#5C2D91' }}>คำอธิบายค่าสี</div>
                                        <Row>
                                            <Col sm={{ span: 20 }} xs={{ span: 24 }} coll="true">
                                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                                                    <div style={{ backgroundColor: '#FF67FF' }} className="Box-color-description"></div>
                                                    <div style={{ marginRight: '25px' }}>ต่ำกว่าเกณฑ์</div>
                                                    <div style={{ backgroundColor: '#92D050' }} className="Box-color-description"></div>
                                                    <div style={{ marginRight: '25px' }}>สมส่วน</div>
                                                    <div style={{ backgroundColor: '#FFFF02' }} className="Box-color-description"></div>
                                                    <div style={{ marginRight: '25px' }}>น้ำหนักเกิน</div>
                                                    <div style={{ backgroundColor: '#FFC001' }} className="Box-color-description"></div>
                                                    <div style={{ marginRight: '25px' }}>อ้วน</div>
                                                    <div style={{ backgroundColor: '#FF0000' }} className="Box-color-description"></div>
                                                    <div style={{ marginRight: '25px' }}>อ้วนอันตราย</div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </> : null}
                                </> : null}
                                {this.state.DataTable_Type === 'blood_pressure' ? <>
                                    <Row>
                                        <Col sm={{ span: 20 }} xs={{ span: 24 }} coll="true">
                                            <div className="header-the-group-table" onClick={e => this.uninfecte_sick_group(e)}>
                                                <div className={this.state.SetData_uninfecte_sick_group === 'active' ? "the-sick-group-table" : "the-uninfected-group-table"}>กลุ่มที่ยังไม่ป่วย</div>
                                                <div className={this.state.SetData_uninfecte_sick_group === 'active' ? "the-uninfected-group-table" : "the-sick-group-table"}>กลุ่มป่วย</div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={{ span: 20 }} xs={{ span: 24 }} coll="true">
                                            <Table
                                                columns={this.state.DataColumnsTable_blood_pressure}
                                                dataSource={
                                                    this.state.Data_address === 'by_hospital' ?
                                                        this.state.DataTable.filter(item => {
                                                            return `${item['pname']} ${item['fname']} ${item['lname']}`.includes(this.state.searchText)
                                                        })
                                                        : this.state.DataTable
                                                } />
                                        </Col>
                                    </Row>
                                    {this.state.Data_address === 'by_hospital' ? <>
                                        <div style={{ color: '#5C2D91' }}>คำอธิบายค่าสี</div>
                                        <Row>
                                            <Col sm={{ span: 20 }} xs={{ span: 24 }} coll="true">
                                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                                                    {this.state.SetData_uninfecte_sick_group === 'active' ? <>
                                                        <div style={{ backgroundColor: '#FF67FF' }} className="Box-color-description"></div>
                                                        <div style={{ marginRight: '25px' }}>ต่ำกว่าเกณฑ์</div>
                                                        <div style={{ backgroundColor: '#92D050' }} className="Box-color-description"></div>
                                                        <div style={{ marginRight: '25px' }}>สมส่วน</div>
                                                        <div style={{ backgroundColor: '#FFFF02' }} className="Box-color-description"></div>
                                                        <div style={{ marginRight: '25px' }}>น้ำหนักเกิน</div>
                                                        <div style={{ backgroundColor: '#FFC001' }} className="Box-color-description"></div>
                                                        <div style={{ marginRight: '25px' }}>อ้วน</div>
                                                        <div style={{ backgroundColor: '#FF0000' }} className="Box-color-description"></div>
                                                        <div style={{ marginRight: '25px' }}>อ้วนอันตราย</div>
                                                    </> : <>
                                                        <div style={{ backgroundColor: '#92D050' }} className="Box-color-description"></div>
                                                        <div style={{ marginRight: '25px' }}>ปกติ</div>
                                                        <div style={{ backgroundColor: '#FFFF02' }} className="Box-color-description"></div>
                                                        <div style={{ marginRight: '25px' }}>เกือบสูง</div>
                                                        <div style={{ backgroundColor: '#FFC001' }} className="Box-color-description"></div>
                                                        <div style={{ marginRight: '25px' }}>สูง</div>
                                                        <div style={{ backgroundColor: '#FF0000' }} className="Box-color-description"></div>
                                                        <div style={{ marginRight: '25px' }}>อันตราย</div>
                                                    </>}
                                                </div>
                                            </Col>
                                        </Row>
                                    </> : null}
                                </> : null}
                                {this.state.DataTable_Type === 'blood_sugar' ? <>
                                    <Row>
                                        <Col sm={{ span: 20 }} xs={{ span: 24 }} coll="true">
                                            <div className="header-the-group-table" onClick={e => this.uninfecte_sick_group(e)}>
                                                <div className={this.state.SetData_uninfecte_sick_group === 'active' ? "the-sick-group-table" : "the-uninfected-group-table"}>กลุ่มที่ยังไม่ป่วย</div>
                                                <div className={this.state.SetData_uninfecte_sick_group === 'active' ? "the-uninfected-group-table" : "the-sick-group-table"}>กลุ่มป่วย</div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={{ span: 20 }} xs={{ span: 24 }} coll="true">
                                            <div className="container-background-header">
                                                <span>เลือกกลุ่มตาราง</span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={{ span: 6 }} xs={{ span: 24 }} coll="true">
                                            <Form.Item>
                                                <Select value={this.state.value_group_fasting} placeholder="เลือกกลุ่มตาราง" onChange={e => this.uninfecte_sick_group_fasting(e)}>
                                                    <Option value="not_fasting">{this.state.SetData_uninfecte_sick_group === 'active' ? 'หลังอาหาร  2 ชั่วโมง' : 'ไม่งดอาหาร'}</Option>
                                                    <Option value="fasting">งดอาหารอย่างน้อย 8 ชั่วโมง</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={{ span: 20 }} xs={{ span: 24 }} coll="true">
                                            <Table
                                                columns={this.state.DataColumnsTable_blood_sugar}
                                                dataSource={
                                                    this.state.Data_address === 'by_hospital' ?
                                                        this.state.DataTable.filter(item => {
                                                            return `${item['pname']} ${item['fname']} ${item['lname']}`.includes(this.state.searchText)
                                                        })
                                                        : this.state.DataTable
                                                } />
                                        </Col>
                                    </Row>
                                    {this.state.Data_address === 'by_hospital' ? <>
                                        <div style={{ color: '#5C2D91' }}>คำอธิบายค่าสี</div>
                                        <Row>
                                            <Col sm={{ span: 20 }} xs={{ span: 24 }} coll="true">
                                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                                                    {this.state.SetData_uninfecte_sick_group === 'active' ? <>
                                                        <div style={{ backgroundColor: '#690E8A' }} className="Box-color-description"></div>
                                                        <div style={{ marginRight: '25px' }}>ต่ำรุนแรง</div>
                                                        <div style={{ backgroundColor: '#92D050' }} className="Box-color-description"></div>
                                                        <div style={{ marginRight: '25px' }}>เหมาะสม</div>
                                                        <div style={{ backgroundColor: '#FFFF02' }} className="Box-color-description"></div>
                                                        <div style={{ marginRight: '25px' }}>สูงกว่าเป้าหมาย</div>
                                                        <div style={{ backgroundColor: '#FFC001' }} className="Box-color-description"></div>
                                                        <div style={{ marginRight: '25px' }}>สูงมาก</div>
                                                        <div style={{ backgroundColor: '#FF0000' }} className="Box-color-description"></div>
                                                        <div style={{ marginRight: '25px' }}>สูงอันตรายถึงชีวิต</div>
                                                    </> : <>
                                                        {this.state.value_group_fasting === 'not_fasting' ? <>
                                                            <div style={{ backgroundColor: '#92D050' }} className="Box-color-description"></div>
                                                            <div style={{ marginRight: '25px' }}>ปกติ</div>
                                                            <div style={{ backgroundColor: '#FFFF02' }} className="Box-color-description"></div>
                                                            <div style={{ marginRight: '25px' }}>เสี่ยงเบาหวาน</div>
                                                            <div style={{ backgroundColor: '#FFC001' }} className="Box-color-description"></div>
                                                            <div style={{ marginRight: '25px' }}>สงสัยป่วยเบาหวาน</div>
                                                        </> : <>
                                                            <div style={{ backgroundColor: '#FF67FF' }} className="Box-color-description"></div>
                                                            <div style={{ marginRight: '25px' }}>ต่ำ</div>
                                                            <div style={{ backgroundColor: '#92D050' }} className="Box-color-description"></div>
                                                            <div style={{ marginRight: '25px' }}>ปกติ</div>
                                                            <div style={{ backgroundColor: '#FFFF02' }} className="Box-color-description"></div>
                                                            <div style={{ marginRight: '25px' }}>เสี่ยงเบาหวาน</div>
                                                            <div style={{ backgroundColor: '#FFC001' }} className="Box-color-description"></div>
                                                            <div style={{ marginRight: '25px' }}>สงสัยป่วยเบาหวาน</div>
                                                        </>}
                                                    </>}
                                                </div>
                                            </Col>
                                        </Row>
                                    </> : null}
                                </> : null}
                                {this.state.DataTable_Type === 'waist_circumference' ? <>
                                    <Row>
                                        <Col sm={{ span: 20 }} xs={{ span: 24 }} coll="true">
                                            <Table
                                                columns={this.state.DataColumnsTable_waist}
                                                dataSource={
                                                    this.state.Data_address === 'by_hospital' ?
                                                        this.state.DataTable.filter(item => {
                                                            return `${item['pname']} ${item['fname']} ${item['lname']}`.includes(this.state.searchText)
                                                        })
                                                        : this.state.DataTable
                                                } />
                                        </Col>
                                    </Row>
                                    {this.state.Data_address === 'by_hospital' ? <>
                                        <div style={{ color: '#5C2D91' }}>คำอธิบายค่าสี</div>
                                        <Row>
                                            <Col sm={{ span: 20 }} xs={{ span: 24 }} coll="true">
                                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                                                    <div style={{ backgroundColor: '#92D050' }} className="Box-color-description"></div>
                                                    <div style={{ marginRight: '25px' }}>เหมาะสม</div>
                                                    <div style={{ backgroundColor: '#FFFF02' }} className="Box-color-description"></div>
                                                    <div style={{ marginRight: '25px' }}>มีภาวะอ้วนลงพุง</div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </> : null}
                                </> : null}
                                {this.state.Data_address === 'by_hospital' ? <>
                                    <div>หมายเหตุ: หากสีไม่ตรง แสดงว่าเป็นค่าสีเดิมที่เคยบันทึกเข้ามา</div>
                                </> : null}
                            </> : null}
                        </Content> : null}

                        {this.state.menu === 'home_medicine' ? <MedicineList menuChange={this.menuChange}></MedicineList> : null}
                        {this.state.menu === 'user_management' ? <UserManagement menuChange={this.menuChange}></UserManagement> : null}



                    </Layout>
                </Layout>
            </Spin >
        );
    }
}

export default withRouter(Home);