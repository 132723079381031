import React from "react";
import { withRouter } from 'react-router-dom';
import { Row, Col, Select, Button } from 'antd';
import styles from '../login/style.module.css';
import Qrcode from "qrcode.react";
import Api from '../lib/api/api';
const Option = Select.Option;

class AboutPage extends React.Component {
  constructor() {
    super();
    this.qrCodeCheck = this.qrCodeCheck.bind(this);
  }

  state = {
    qr: '',
    authReqId: '',
    userData: '',
    apiCheckInterval: null,
    status: 'Register',
    // status: 'Government_service',
    Level_type: '',
    SetValue_search: [],
    SetID_ADDADMIN: '',
    SetData_ADDADMIN: '',
  }

  componentDidMount() {
    this.initialStateCheck();
  }

  componentWillUnmount() {
    clearInterval(this.state.apiCheckInterval);
  }

  async initialStateCheck() {
    await this.getQrApi();
    var apiCheckInterval = setInterval(this.qrCodeCheck, 3000);
    this.setState({
      apiCheckInterval: apiCheckInterval
    });
  }

  getQrApi() {
    return new Promise((resolve, reject) => {
      Api.GetDiDQr().then((response) => {
        console.log(response.data);
        let result = response.data;
        if (result['code'] === 200) {
          this.setState({
            authReqId: result['qr_code']['auth_req_id'],
            qr: JSON.stringify(result['qr_code']),
          }, () => {
            resolve(null);
          });
        } else {
          resolve(null);
        }
      }).catch(err => {
        console.log(err);
        resolve(null);
      });
    });
  }

  qrCodeCheck() {
    if (this.state.authReqId === '') {
      return;
    }
    Api.GetQrCodeData(this.state.authReqId).then((res) => {
      let result = res.data;
      console.log(result);
      if (result["code"] === 200) {
        if (result["status"] === 'approved') {
          clearInterval(this.state.apiCheckInterval);
          let access_token = result['access_token'];
          this.getUserDataOneDidApi(access_token);
        }
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  getUserDataOneDidApi(token) {
    Api.GetUserData(token).then((res) => {
      let result = res.data;
      if (result["code"] === 200) {
        this.setState({
          status: 'Check_Login',
          userData: result["ones_did_user_data"]
        })
      } else {
        alert("ไม่พบข้อมูล");
      }
    }).catch((err) => {
      console.log(err);
      alert("พบข้อผิดพลาด");
    });
  }

  onclick_to_inputDid() {
    this.props.history.push({
      pathname: '/'
    });
  }

  onclick_to_startLogin() {
    let login = {
      "onesdid3": this.state.userData["username"],
      "pid": this.state.userData["additionalInfo"]["identityDocumentNumber"]
    }
    // console.log(login);
    Api.Login(login).then((res) => {
      // console.log(res);
      let result = res.data;
      if (result["code"] === 200) {
        this.props.history.push({
          pathname: '/Health_services',
          // query: ''
        });
      } else {
        this.setState({ status: 'Choose_service_type' })
      }
    }).catch((err) => {
      alert("พบข้อผิดพลาด");
    });
  }

  Register() {
    return (
      <>
        <Row style={{ backgroundColor: '#F0F2F6', paddingBottom: '65px' }}>
          <Col sm={{ span: 12 }} xs={{ span: 24 }} coll="true" style={{ textAlign: 'center' }}>
            <div style={{ paddingTop: '100px' }}>
              <img src={require('../icon/nuxsmartcityos-logo.svg').default} style={{ width: '500px', height: '500px' }} alt="logo" />
            </div>
          </Col>
          <Col sm={{ span: 12 }} xs={{ span: 24 }} coll="true">
            <div style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              paddingTop: '60px'
            }}>
              <img src={require('../icon/smartcity_login.svg').default} alt="logo_nux" className="App-logo" style={{ width: '187px', height: '76px' }} />
              <div className={styles.border_login_main}>
                <div className={styles.card_container_login_text}>
                  <span>ลงทะเบียน</span>
                </div>
                <div><Qrcode size={200} value={this.state.qr} /></div>
                <div className={styles.card_container_login_flow_text}>
                  <span>ขั้นตอนการเข้าสู่ระบบด้วย Digital ID</span>
                </div>
                <div className={styles.card_container_login_tutorial_container}>
                  <div className={styles.card_container_login_tutorial_item}>
                    <div className={styles.card_container_login_tutorial_bullet}></div>
                    <span className={styles.card_container_login_tutorial_text}>
                      เปิดแอพพลิเคชัน Ones ID
                    </span>
                  </div>
                  <div className={styles.card_container_login_tutorial_item}>
                    <div className={styles.card_container_login_tutorial_bullet}></div>
                    <span className={styles.card_container_login_tutorial_text}>
                      กดที่รูปโปรไฟล์ของคุณ
                    </span>
                  </div>
                  <div className={styles.card_container_login_tutorial_item}>
                    <div className={styles.card_container_login_tutorial_bullet}></div>
                    <span className={styles.card_container_login_tutorial_text}>
                      กดเมนู Manage Accounts เลือก QR Scanner
                    </span>
                  </div>
                </div>
              </div>
              <div className={styles.login_by_oneid_container}>
                <span className={styles.login_by_oneid_text1}>หรือเข้าสู่ระบบด้วย </span>
                <span onClick={() => this.onclick_to_inputDid()} className={styles.login_by_oneid_text2}>ONES DID3</span>
              </div>

              <div className={styles.button_register_dowland} onClick={() => window.open("https://onesid1.com/", "_blank")}>
                <img src={require('../icon/icon-dowloadfile.svg').default} alt="logo_nux" className="App-logo" style={{ width: '15px', height: '15px', marginRight: '10px' }} />
                <span>ดาวน์โหลดแอปพลิเคชัน</span>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col sm={{ span: 24 }} xs={{ span: 24 }} coll="true" style={{ marginTop: '40px', marginBottom: '40px' }}>
            <img src={require('../icon/our_service.svg').default} alt="logo_nux" className="App-logo" style={{ width: '1000px', height: '600px' }} />
          </Col>
        </Row>

        <Row align="middle" style={{ backgroundColor: 'white', marginBottom: '40px' }}>
          <Col sm={{ span: 12 }} xs={{ span: 24 }} coll="true">
            <img src={require('../icon/Digital_ID.svg').default} alt="logo_nux" className="App-logo" style={{ width: '500px', height: '500px' }} />
          </Col>
          <Col sm={{ span: 8, offset: 2 }} xs={{ span: 24 }} coll="true">
            <div style={{ color: '#4D24A1', fontSize: '32px', fontWeight: '500' }}>
              <span>Digital ID</span>
            </div>
            <div style={{ color: '#BDBDBD', fontSize: '28px', fontStyle: 'italic' }}>
              <span>สะดวก ปลอดภัย</span>
            </div>
            <div style={{ textIndent: '50px' }}>
              <span>วันซ์ไอดี เป็นนวัตกรรมกลางที่ถูกออกแบบและพัฒนาใหม่หมด<br />
                เพื่อการใช้ดิจิทัลไอดีสำหรับประเทศไทยตามมาตรฐานสากลที่ให้สถานีพิสูจน์<br />
                ตัวตนและลงทะเบียนที่ระดับความน่าเชื่อถือของไอเดนทิตีระดับ IAL3<br />
                แบบคอมพิวเตอร์ฝังตัว (OnesMAX) ที่มี Trusted Platform Module<br />
                (TPM) สำหรับการลงทะเบียนและพิสูจน์ตัวตน (Enrolment and Identify <br />
                Proofing) แบบเฉพาะต่อหน้านายทะเบียนพร้อมการ ตรวจสอบข้อมูลชีวมิติ<br />
                (Biometric) แบบการจดจำใบหน้า (Facial Recognition) เพื่อป้องกัน<br />
                การปลอมตัวเป็นบุคคลอื่น (Presentation Attack Detection, PAD) <br />
                การหลอกลวง การลงทะเบียนซ้ำ</span>
            </div>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", paddingTop: '20px' }}>
              <div className={styles.button_register_dowland}>
                <img src={require('../icon/icon-dowloadfile.svg').default} alt="logo_nux" className="App-logo" style={{ width: '15px', height: '15px', marginRight: '10px' }} />
                <span>ดาวน์โหลด</span>
              </div>
            </div>
          </Col>
        </Row>

        <Row align="middle">
          <Col sm={{ span: 10, offset: 2 }} xs={{ span: 24 }} coll="true" >
            <div style={{ color: '#4D24A1', fontSize: '32px', fontWeight: '500' }}>
              <span>Connect</span>
            </div>
            <div style={{ color: '#BDBDBD', fontSize: '28px', fontStyle: 'italic' }}>
              <span>เชื่อมต่อทุกข้อมูล</span>
            </div>
            <div style={{ textIndent: '50px' }}>
              <span>รวบรวมข้อมูลสุขภาพจากการบันทึกข้อมูลของประชาชนหรือ<br />จากอาสาสมัครสาธารณสุขประจำหมู่บ้าน (อสม.)</span>
            </div>
          </Col>
          <Col sm={{ span: 12 }} xs={{ span: 24 }} coll="true">
            <img src={require('../icon/Connect.svg').default} alt="logo_nux" className="App-logo" style={{ width: '500px', height: '500px' }} />
          </Col>
        </Row>
      </>
    )
  }

  Check_Login() {
    return (
      <>
        <Row>
          <Col sm={{ span: 24 }} xs={{ span: 24 }} coll="true" style={{ textAlign: 'center' }}>
            <div style={{ paddingTop: '100px' }}>
              <img src={require('../icon/smartcity_login.svg').default} alt="logo_nux" className="App-logo" style={{ width: '187px', height: '76px' }} />

              <div style={{ fontSize: '24px', fontWeight: 500, marginTop: '190px', marginBottom: '140px' }}>
                <span>ยินดีต้อนรับ</span><br />
                <span style={{ color: '#5D2E92' }}>
                  {this.state.userData["additionalInfo"]["localizedTitle"] + " " + this.state.userData["additionalInfo"]["localizedFirstName"] + " " + this.state.userData["additionalInfo"]["localizedLastName"]}
                </span>
              </div>
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <div className={styles.button_register_dowland} onClick={() => this.onclick_to_startLogin()}>
                  <span>เริ่มต้นใช้งาน</span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </>
    )
  }

  Government_service() {
    return (
      <>
        <Row>
          <Col sm={{ span: 24 }} xs={{ span: 24 }} coll="true" style={{ textAlign: 'center' }}>
            <div style={{ paddingTop: '180px', marginBottom: '100px' }}>
              <span style={{ color: '#666666', fontSize: '24px', fontWeight: 500 }}>เลือกประเภทบริการภาครัฐ</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={{ span: 24 }} xs={{ span: 24 }} coll="true">
            <div style={{ display: 'flex', justifyContent: "center" }}>
              <div onClick={() => this.setState({ status: 'Choose_service', Level_type: 'by_area' })} style={{ marginRight: '20px' }}>
                <span>เขตสุขภาพ</span>
              </div>
              <div onClick={() => this.setState({ status: 'Choose_service', Level_type: 'by_province' })} style={{ marginRight: '20px' }}>
                <span>จังหวัด</span>
              </div>
              <div onClick={() => this.setState({ status: 'Choose_service', Level_type: 'by_district' })} style={{ marginRight: '20px' }}>
                <span>อำเภอ</span>
              </div>
              <div onClick={() => this.setState({ status: 'Choose_service', Level_type: 'by_hospital' })}>
                <img src={require('../icon/Government_hospital.svg').default} alt="Government_hospital" className="App-logo" style={{ width: '250px', height: '250px', cursor: 'pointer' }} />
              </div>
            </div>
          </Col>
        </Row>
      </>
    )
  }


  Choose_service_type() {
    return (<>
      <Row>
        <Col sm={{ span: 24 }} xs={{ span: 24 }} coll="true" style={{ textAlign: 'center' }}>
          <div style={{ paddingTop: '180px', marginBottom: '100px' }}>
            <span style={{ color: '#666666', fontSize: '24px', fontWeight: 500 }}>เลือกประเภทบริการ</span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={{ span: 24 }} xs={{ span: 24 }} coll="true">
          <div style={{ display: 'flex', justifyContent: "center" }}>
            <div onClick={() => this.setState({ status: 'Government_service' })} style={{ marginRight: '20px' }}>
              <img src={require('../icon/government_service.svg').default} alt="government_service" className="App-logo" style={{ width: '250px', height: '250px', cursor: 'pointer' }} />
            </div>
            <div onClick={() => this.setState({ status: 'Private_service' })}>
              <img src={require('../icon/Private_service.svg').default} alt="Private_service" className="App-logo" style={{ width: '250px', height: '250px', cursor: 'pointer' }} />
            </div>
          </div>
        </Col>
      </Row>
    </>)
  }

  Private_service() {
    return (<>
      <Row>
        <Col sm={{ span: 24 }} xs={{ span: 24 }} coll="true" style={{ textAlign: 'center' }}>
          <div style={{ paddingTop: '180px', marginBottom: '100px' }}>
            <span style={{ color: '#666666', fontSize: '24px', fontWeight: 500 }}>เลือกประเภทบริการภาคเอกชน</span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={{ span: 24 }} xs={{ span: 24 }} coll="true">
          <div style={{ display: 'flex', justifyContent: "center" }}>
            <div onClick={() => { }} style={{ marginRight: '20px' }}>
              <img src={require('../icon/industrial_factory.svg').default} alt="government_service" className="App-logo" style={{ width: '250px', height: '250px', cursor: 'pointer' }} />
            </div>
            <div onClick={() => { }}>
              <img src={require('../icon/transportation.svg').default} alt="Private_service" className="App-logo" style={{ width: '250px', height: '250px', cursor: 'pointer' }} />
            </div>
          </div>
        </Col>
      </Row>
    </>)
  }

  onclick_search = (value) => {
    Api.SearchAffiliation(this.state.Level_type, value).then((res) => {
      let result = res.data;
      console.log(result);
      if (result["code"] === 200) {
        let SetValue_search = []
        for (let index = 0; index < result["result"].length; index++) {

          switch (this.state.Level_type) {
            case 'by_area':
              SetValue_search.push(<Option key={index + 1} value={result["result"][index]["health_area_id"]}>{result["result"][index]["health_area_name"]}</Option>)
              break;
            case 'by_province':
              SetValue_search.push(<Option key={index + 1} value={result["result"][index]["province_id"]}>{result["result"][index]["province_name"]}</Option>)
              break;
            case 'by_district':
              SetValue_search.push(<Option key={index + 1} value={result["result"][index]["district_id"]}>{result["result"][index]["district_name"]}</Option>)
              break;
            case 'by_hospital':
              SetValue_search.push(<Option key={index + 1} value={result["result"][index]["hospital_id"]}>{result["result"][index]["hospital_name"]}</Option>)
              break;
            default:
              break;
          }

        }
        this.setState({ SetValue_search: SetValue_search })
      }
    })
  }

  onclick_SubmitSearch = () => {
    Api.SearchAffiliation(this.state.Level_type, this.state.SetID_ADDADMIN).then((res) => {
      let result = res.data;
      if (result["code"] === 200) {
        this.setState({ status: 'Service_information', SetData_ADDADMIN: result["result"] })
      }
    })
  }

  Choose_service() {
    let nametitle = ''
    switch (this.state.Level_type) {
      case 'by_area':
        nametitle = 'ค้นหาจากชือเขตสุขภาพ หรือ รหัสเขตสุขภาพ'
        break;
      case 'by_province':
        nametitle = 'ค้นหาจากชือจังหวัด หรือ รหัสจังหวัด'
        break;
      case 'by_district':
        nametitle = 'ค้นหาจากชืออำเภอ หรือ รหัสอำเภอ'
        break;
      case 'by_hospital':
        nametitle = 'ค้นหาจากชือโรงพยาบาล หรือ รหัสโรงพยาบาล'
        break;
      default:
        nametitle = 'เกิดข้อผิดพลาด !'
        break;
    }
    return (
      <>
        <Row style={{ paddingTop: '200px' }}>
          <Col sm={{ span: 8, offset: 8 }} xs={{ span: 24 }} coll="true" style={{ textAlign: 'center' }}>
            <div style={{ border: '1px solid #E4E7FB', borderRadius: '30px', padding: '20px' }}>
              <span style={{ color: '#4D23A0', fontSize: '24px', fontWeight: 500 }}>เลือกสถานบริการ</span><br /><br />
              <span style={{ color: '#666666', fontSize: '18px', fontWeight: 500 }}>{nametitle}</span>

              <div style={{ margin: '40px 0px 40px 0px' }}>
                <Select
                  showSearch
                  style={{ width: '90%' }}
                  optionFilterProp="children"
                  onSearch={(e) => this.onclick_search(e)}
                  onChange={(value, select) => this.setState({ SetID_ADDADMIN: value })}
                >
                  {this.state.SetValue_search}
                </Select>
              </div>

              <Button type="primary" htmlType="submit" onClick={() => this.onclick_SubmitSearch()}
                style={{ backgroundColor: 'green', color: 'white', borderRadius: '30px', padding: '0px 50px 0px 50px' }}
              >
                ตกลง
              </Button>
            </div>
          </Col>
        </Row>
      </>
    )
  }

  Service_information() {
    return (
      <>
        <Row style={{ paddingTop: '200px' }}>
          <Col sm={{ span: 8, offset: 8 }} xs={{ span: 24 }} coll="true">
            <div style={{ border: '1px solid #E4E7FB', borderRadius: '30px', padding: '20px', textAlign: 'center' }}>
              <span style={{ color: '#4D23A0', fontSize: '24px', fontWeight: 500 }}>ข้อมูลสถานบริการ</span>

              <div className={styles.some_page_wrapper}>
                <div className={styles.row_service_information}>
                  <div className={styles.column_service_information}>
                    เขตสุขภาพ
                  </div>
                  <div className={styles.column_service_information} style={{ color: '#4D23A0' }}>
                    {this.state.SetData_ADDADMIN[0]["health_area_name"]}
                  </div>
                </div>

                <div className={styles.row_service_information}>
                  <div className={styles.column_service_information}>
                    จังหวัด
                  </div>
                  <div className={styles.column_service_information} style={{ color: '#4D23A0' }}>
                    {this.state.SetData_ADDADMIN[0]["province_name"]}
                  </div>
                </div>

                <div className={styles.row_service_information}>
                  <div className={styles.column_service_information}>
                    อำเภอ
                  </div>
                  <div className={styles.column_service_information} style={{ color: '#4D23A0' }}>
                    {this.state.SetData_ADDADMIN[0]["district_name"]}
                  </div>
                </div>

                <div className={styles.row_service_information}>
                  <div className={styles.column_service_information}>
                    ตำบล
                  </div>
                  <div className={styles.column_service_information} style={{ color: '#4D23A0' }}>
                    {this.state.SetData_ADDADMIN[0]["sub_district_name"]}
                  </div>
                </div>

                <div className={styles.row_service_information}>
                  <div className={styles.column_service_information}>
                    รหัสโรงพยาบาล
                  </div>
                  <div className={styles.column_service_information} style={{ color: '#4D23A0' }}>
                    {this.state.SetData_ADDADMIN[0]["hospital_name"]}
                  </div>
                </div>
              </div>
              <Button type="primary" htmlType="submit" onClick={() => this.onclick_SubmitSuccessLogin()}
                style={{ backgroundColor: '#5D3BAB', color: 'white', borderRadius: '30px', padding: '0px 50px 0px 50px', textAlign: 'center' }}
              >
                ยืนยัน
              </Button>
            </div>
          </Col>
        </Row>
      </>
    )
  }

  onclick_SubmitSuccessLogin = () => {

    let data = {
      "pid": this.state.userData["additionalInfo"]["identityDocumentNumber"],
      "email": this.state.userData["email"],
      "fname": this.state.userData["additionalInfo"]["localizedFirstName"],
      "lname": this.state.userData["additionalInfo"]["localizedLastName"],
      "onesdid3": this.state.userData["username"],
      "pname": this.state.userData["additionalInfo"]["localizedTitle"],
      "mobile": this.state.userData["phoneNumber"],
      "affiliation_type": this.state.Level_type,
      "affiliation_id": this.state.SetID_ADDADMIN,
      "affiliation_name": this.state.SetData_ADDADMIN[0]["province_name"]
    }

    Api.ADDADMIN(data).then((_response) => {
      let response = _response.data;
      if (response["code"] === 200) {
        let login = {
          "onesdid3": this.state.userData["username"],
          "pid": this.state.userData["additionalInfo"]["identityDocumentNumber"]
        }
        Api.Login(login).then((res) => {
          let result = res.data;
          if (result["code"] === 200) {
            Api.LoginNux(result.access_token).then((res) => {
              if (result["code"] === 200) {
                this.props.history.push({
                  pathname: '/Health_services'
                });
              } else {
                alert("ไม่พบข้อมูล");
              }
            }).catch((err) => {
              alert("พบข้อผิดพลาด");
            });
          } else {
            alert("ไม่พบข้อมูล");
          }
        }).catch((err) => {
          alert("พบข้อผิดพลาด");
        });
      } else {
        alert("ONES DID3 ของท่านมีข้อมูล User อยู่ในระบบ !");
        this.props.history.push({
          pathname: '/login'
        });
      }
    }).catch(err => {
      alert("พบข้อผิดพลาด");
    });
  }

  render() {
    return (
      <>
        {this.state.status === 'Register' ? this.Register() : null}
        {this.state.status === 'Check_Login' ? this.Check_Login() : null}
        {this.state.status === 'Choose_service_type' ? this.Choose_service_type() : null}
        {this.state.status === 'Government_service' ? this.Government_service() : null}
        {this.state.status === 'Private_service' ? this.Private_service() : null}
        {this.state.status === 'Choose_service' ? this.Choose_service() : null}
        {this.state.status === 'Service_information' ? this.Service_information() : null}
      </>
    )
  }
}

export default withRouter(AboutPage);