import React from 'react'
import { VideoCameraFilled, message } from '@ant-design/icons'

import HomeTable from '../HomeTable/HomeTable'

export function addressColumn(title, dataIndex) {
    return [
        {
            title: "",
            render: (text, record) => (
                <React.Fragment>
                    <div className="columns-header-responsive-start">
                        <div>{title}</div><div>{record[dataIndex]}</div>
                    </div>
                    <div className="columns-header-responsive">
                        <div>จำนวนผู้บันทึก<br />ดัชนีมวลกาย (คน)</div><div>{record.bmi}</div>
                    </div>
                    <div className="columns-header-responsive">
                        <div>จำนวนผู้บันทึก<br />รอบเอว (คน)</div><div>{record.waistline}</div>
                    </div>
                    <div className="columns-header-responsive">
                        <div>จำนวนผู้บันทึก<br />ค่าความดันโลหิต (คน)</div><div>{record.blood_pressure}</div>
                    </div>
                    <div className="columns-header-responsive-end">
                        <div>จำนวนผู้บันทึกค่า<br />ระดับน้ำตาลในเลือด (คน)</div><div>{record.blood_sugar}</div>
                    </div>
                </React.Fragment>
            ),
            responsive: ["xs"]
        },
        {
            title: <div style={{ color: '#5C2D91' }}>{title}</div>,
            dataIndex: dataIndex,
            responsive: ["sm"],
            align: 'center',
            render(text, record) {
                return {
                    props: {
                        style: { color: '#5C2D91' }
                    },
                    children: <div style={{ padding: '10px' }}>{text}</div>
                };
            }
        },
        {
            title: <div style={{ color: '#5C2D91' }}>จำนวนผู้บันทึก<br />ดัชนีมวลกาย (คน)</div>,
            dataIndex: "bmi",
            responsive: ["sm"],
            align: 'center',
            render(text, record) {
                return {
                    props: {
                        style: { color: '#5C2D91' }
                    },
                    children: <div>{text}</div>
                };
            }
        },
        {
            title: <div style={{ color: '#5C2D91' }}>จำนวนผู้บันทึก<br />รอบเอว (คน)</div>,
            dataIndex: "waistline",
            responsive: ["sm"],
            align: 'center',
            render(text, record) {
                return {
                    props: {
                        style: { color: '#5C2D91' }
                    },
                    children: <div>{text}</div>
                };
            }
        },
        {
            title: <div style={{ color: '#5C2D91' }}>จำนวนผู้บันทึก<br />ค่าความดันโลหิต (คน)</div>,
            dataIndex: "blood_pressure",
            responsive: ["sm"],
            align: 'center',
            render(text, record) {
                return {
                    props: {
                        style: { color: '#5C2D91' }
                    },
                    children: <div>{text}</div>
                };
            }
        },
        {
            title: <div style={{ color: '#5C2D91' }}>จำนวนผู้บันทึกค่า<br />ระดับน้ำตาลในเลือด (คน)</div>,
            dataIndex: "blood_sugar",
            responsive: ["sm"],
            align: 'center',
            render(text, record) {
                return {
                    props: {
                        style: { color: '#5C2D91' }
                    },
                    children: <div>{text}</div>
                };
            }
        }
    ];
}

export function bmiColumn(title) {
    return [
        {
            title: "",
            render: (text, record) => (
                <React.Fragment>
                    <div className="columns-header-responsive-start">
                        <div>{title}</div><div>{record.name}</div>
                    </div>
                    <div className="columns-header-responsive">
                        <div>ต่ำกว่าเกณฑ์ (คน)<br />{'0-<18.5'}</div><div>{record.underweight}</div>
                    </div>
                    <div className="columns-header-responsive">
                        <div>สมส่วน (คน)<br />{'18.5-22.9'}</div><div>{record.normalweight}</div>
                    </div>
                    <div className="columns-header-responsive">
                        <div>น้ำหนักเกิน (คน)<br />{'23-24.9'}</div><div>{record.normal}</div>
                    </div>
                    <div className="columns-header-responsive">
                        <div>อ้วน (คน)<br />{'25-29.9'}</div><div>{record.overweight}</div>
                    </div>
                    <div className="columns-header-responsive-end">
                        <div>อันตราย (คน)<br />{'>=30'}</div><div>{record.obesity}</div>
                    </div>
                </React.Fragment>
            ),
            responsive: ["xs"]
        },
        {
            title: <div style={{ color: '#5C2D91' }}>{title}</div>,
            dataIndex: "name",
            responsive: ["sm"],
            align: 'center',
            render(text, record) {
                return {
                    props: {
                        style: { color: '#5C2D91' }
                    },
                    children: <div style={{ padding: '10px' }}>{text}</div>
                };
            }
        },
        {
            title: <div style={{ color: '#5C2D91' }}>จำนวนดัชนีมวลกายแยกตามกลุ่ม</div>,
            responsive: ["sm"],
            children: [
                {
                    key: 'underweight',
                    title: <div style={{ backgroundColor: '#D27FF5' }}>ต่ำกว่าเกณฑ์ (คน)<br />{'0-<18.5'}</div>,
                    dataIndex: 'underweight',
                    align: 'center'
                },
                {
                    key: 'normalweight',
                    title: <div style={{ backgroundColor: '#A0EA57' }}>สมส่วน (คน)<br />{'18.5-22.9'}</div>,
                    dataIndex: 'normalweight',
                    align: 'center'
                },
                {
                    key: 'normal',
                    title: <div style={{ backgroundColor: '#ECEC5F' }}>น้ำหนักเกิน (คน)<br />{'23-24.9'}</div>,
                    dataIndex: 'normal',
                    align: 'center'
                },
                {
                    key: 'overweight',
                    title: <div style={{ backgroundColor: '#EEAE52' }}>อ้วน (คน)<br />{'25-29.9'}</div>,
                    dataIndex: 'overweight',
                    align: 'center'
                },
                {
                    key: 'obesity',
                    title: <div style={{ backgroundColor: '#FD6056' }}>อันตราย (คน)<br />{'>=30'}</div>,
                    dataIndex: 'obesity',
                    align: 'center'
                }
            ]
        }
    ]
}

export function bloodPressureColumn(title, type) {
    if (type === 'sick') {
        return [
            {
                title: "",
                render: (text, record) => (
                    <React.Fragment>
                        <div className="columns-header-responsive-start">
                            <div>{title}</div><div>{record.name}</div>
                        </div>
                        <div className="columns-header-responsive">
                            <div>ต่ำกว่าเกณฑ์ (คน)<br />{'( SBP < 90  DBP< 60) '}</div><div>{record.underweight}</div>
                        </div>
                        <div className="columns-header-responsive">
                            <div>ปกติ (คน)<br />{'(S = 120-129 หรือ D = 80-84)'}</div><div>{record.normal}</div>
                        </div>
                        <div className="columns-header-responsive">
                            <div>เกือบสูง (คน)<br />{'(S = 130-139 หรือ D = 85-89)'}</div><div>{record.almosthigh}</div>
                        </div>
                        <div className="columns-header-responsive">
                            <div>สูง (คน)<br />{'(S = 140-179 หรือ D = 90-109)'}</div><div>{record.hypertension}</div>
                        </div>
                        <div className="columns-header-responsive-end">
                            <div>อันตราย (คน)<br />{'(S >= 180  หรือ D >= 110)'}</div><div>{record.high_hypertension}</div>
                        </div>
                    </React.Fragment>
                ),
                responsive: ["xs"]
            },
            {
                title: <div style={{ color: '#5C2D91' }}>{title}</div>,
                dataIndex: "name",
                responsive: ["sm"],
                align: 'center',
                render(text, record) {
                    return {
                        props: {
                            style: { color: '#5C2D91' }
                        },
                        children: <div style={{ padding: '10px' }}>{text}</div>
                    };
                }
            },
            {
                title: <div style={{ color: '#5C2D91' }}>จำนวนรายงานความดันโลหิตแยกตามกลุ่ม</div>,
                align: 'center',
                responsive: ["sm"],
                children: [
                    {
                        key: 'underweight',
                        title: <div style={{ backgroundColor: '#FF67FF' }}>ต่ำกว่าเกณฑ์ (คน)<br />{'( SBP < 90  DBP< 60) '}</div>,
                        dataIndex: 'underweight',
                        align: 'center'
                    },
                    {
                        key: 'normal',
                        title: <div style={{ backgroundColor: '#A0EA57' }}>ปกติ (คน)<br />{'(S = 120-129 หรือ D = 80-84)'}</div>,
                        dataIndex: 'normal',
                        align: 'center'
                    },
                    {
                        key: 'almosthigh',
                        title: <div style={{ backgroundColor: '#ECEC5F' }}>เกือบสูง (คน)<br />{'(S = 130-139 หรือ D = 85-89)'}</div>,
                        dataIndex: 'almosthigh',
                        align: 'center'
                    },
                    {
                        key: 'hypertension',
                        title: <div style={{ backgroundColor: '#EEAE52' }}>สูง (คน)<br />{'(S = 140-179 หรือ D = 90-109)'}</div>,
                        dataIndex: 'hypertension',
                        align: 'center'
                    },
                    {
                        key: 'high_hypertension',
                        title: <div style={{ backgroundColor: '#FD6056' }}>อันตราย (คน)<br />{'(S >= 180  หรือ D >= 110)'}</div>,
                        dataIndex: 'high_hypertension',
                        align: 'center'
                    }
                ]
            }
        ];
    } else {
        return [
            {
                title: "",
                render: (text, record) => (
                    <React.Fragment>
                        <div className="columns-header-responsive-start">
                            <div>{title}</div><div>{record.name}</div>
                        </div>
                        <div className="columns-header-responsive">
                            <div>ปกติ (คน)<br />{'(S = 120-129 หรือ D = 80-84)'}</div><div>{record.normal}</div>
                        </div>
                        <div className="columns-header-responsive">
                            <div>เกือบสูง (คน)<br />{'(S = 130-139 หรือ D = 85-89)'}</div><div>{record.almosthigh}</div>
                        </div>
                        <div className="columns-header-responsive">
                            <div>สูง (คน)<br />{'(S = 140-179 หรือ D = 90-109)'}</div><div>{record.hypertension}</div>
                        </div>
                        <div className="columns-header-responsive-end">
                            <div>อันตราย (คน)<br />{'(S >= 180  หรือ D >= 110)'}</div><div>{record.high_hypertension}</div>
                        </div>
                    </React.Fragment>
                ),
                responsive: ["xs"]
            },
            {
                title: <div style={{ color: '#5C2D91' }}>{title}</div>,
                dataIndex: "name",
                responsive: ["sm"],
                align: 'center',
                render(text, record) {
                    return {
                        props: {
                            style: { color: '#5C2D91' }
                        },
                        children: <div style={{ padding: '10px' }}>{text}</div>
                    };
                }
            },
            {
                title: <div style={{ color: '#5C2D91' }}>จำนวนรายงานความดันโลหิตแยกตามกลุ่ม</div>,
                responsive: ["sm"],
                align: 'center',
                children: [
                    {
                        key: 'normal',
                        title: <div style={{ backgroundColor: '#A0EA57' }}>ปกติ (คน)<br />{'(S = 120-129 หรือ D = 80-84)'}</div>,
                        dataIndex: 'normal',
                        align: 'center'
                    },
                    {
                        key: 'almosthigh',
                        title: <div style={{ backgroundColor: '#ECEC5F' }}>เกือบสูง (คน)<br />{'(S = 130-139 หรือ D = 85-89)'}</div>,
                        dataIndex: 'almosthigh',
                        align: 'center'
                    },
                    {
                        key: 'hypertension',
                        title: <div style={{ backgroundColor: '#EEAE52' }}>สูง (คน)<br />{'(S = 140-179 หรือ D = 90-109)'}</div>,
                        dataIndex: 'hypertension',
                        align: 'center'
                    },
                    {
                        key: 'high_hypertension',
                        title: <div style={{ backgroundColor: '#FD6056' }}>อันตราย (คน)<br />{'(S >= 180  หรือ D >= 110)'}</div>,
                        dataIndex: 'high_hypertension',
                        align: 'center'
                    }
                ]
            }
        ];
    }
}

export function bloodSugarColumn(title, type, time) {
    if (type === 'sick') {
        return [
            {
                title: "",
                render: (text, record) => (
                    <React.Fragment>
                        <div className="columns-header-responsive-start">
                            <div>{title}</div><div>{record.name}</div>
                        </div>
                        <div className="columns-header-responsive">
                            <div>ต่ำระดับรุนแรง (คน)<br />{'0-49'}</div><div>{record.severelylow}</div>
                        </div>
                        <div className="columns-header-responsive">
                            <div>ต่ำ (คน)<br />{'50-69'}</div><div>{record.low}</div>
                        </div>
                        <div className="columns-header-responsive">
                            <div>เหมาะสม (คน)<br />{'70-179'}</div><div>{record.normal}</div>
                        </div>
                        <div className="columns-header-responsive">
                            <div>การควบคุม (คน)<br />{'180-249'}</div><div>{record.high}</div>
                        </div>
                        <div className="columns-header-responsive">
                            <div>แทรกซ้อนที่รุนแรง (คน))<br />{'>=250'}</div><div>{record.very_high}</div>
                        </div>
                        <div className="columns-header-responsive-end">
                            <div>น้ำตาลสูงมาก (คน)<br />{'>300'}</div><div>{record.very_very_hight}</div>
                        </div>
                    </React.Fragment>
                ),
                responsive: ["xs"]
            },
            {
                title: <div style={{ color: '#5C2D91' }}>{title}</div>,
                dataIndex: "name",
                responsive: ["sm"],
                align: 'center',
                render(text, record) {
                    return {
                        props: {
                            style: { color: '#5C2D91' }
                        },
                        children: <div style={{ padding: '10px' }}>{text}</div>
                    };
                }
            },
            {
                title: <div style={{ color: '#5C2D91' }}>ระดับน้ำตาลในเลือด<br />ต่ำระดับรุนแรง (คน)</div>,
                responsive: ["sm"],
                align: 'center',
                children: [
                    {
                        key: 'severelylow',
                        title: <div style={{ backgroundColor: '#690E8A' }}>{'0-49'}</div>,
                        dataIndex: 'severelylow',
                        align: 'center'
                    }
                ]
            },
            {
                title: <div style={{ color: '#5C2D91' }}>ระดับน้ำตาล<br />ในเลือดต่ำ (คน)</div>,
                responsive: ["sm"],
                align: 'center',
                children: [
                    {
                        key: 'low',
                        title: <div style={{ backgroundColor: '#FF67FF' }}>{'50-69'}</div>,
                        dataIndex: 'low',
                        align: 'center',
                    }
                ]
            },
            {
                title: <div style={{ color: '#5C2D91' }}>ระดับน้ำตาลอยู่ใน<br />เกณฑ์เหมาะสม (คน)</div>,
                responsive: ["sm"],
                align: 'center',
                children: [
                    {
                        key: 'normal',
                        title: <div style={{ backgroundColor: '#92D050' }}>{'70-179'}</div>,
                        dataIndex: 'normal',
                        align: 'center'
                    }
                ]
            },
            {
                title: <div style={{ color: '#5C2D91' }}>ระดับน้ำตาลสูงกว่าเป้า<br />หมายการควบคุม (คน)</div>,
                responsive: ["sm"],
                align: 'center',
                children: [
                    {
                        key: 'high',
                        title: <div style={{ backgroundColor: '#FFFF02' }}>{'180-249'}</div>,
                        dataIndex: 'high',
                        align: 'center'
                    }
                ]
            },
            {
                title: <div style={{ color: '#5C2D91' }}>ระดับน้ำตาลสูงมาก<br />เสี่ยงต่อการเกิดภาวะ<br />แทรกซ้อนที่รุนแรง (คน)</div>,
                responsive: ["sm"],
                align: 'center',
                children: [
                    {
                        key: 'very_high',
                        title: <div style={{ backgroundColor: '#FFC001' }}>{'>=250'}</div>,
                        dataIndex: 'very_high',
                        align: 'center'
                    }
                ]
            },
            {
                title: <div style={{ color: '#5C2D91' }}>ระดับน้ำตาลสูงมาก<br />เสี่ยงต่อการเกิดภาวะ<br />แทรกซ้อนรุนแรงระดับ<br />น้ำตาลสูงมาก (คน)</div>,
                responsive: ["sm"],
                align: 'center',
                children: [
                    {
                        key: 'very_very_hight',
                        title: <div style={{ backgroundColor: '#FF0000' }}>{'>300'}</div>,
                        dataIndex: 'very_very_hight',
                        align: 'center'
                    }
                ]
            }
        ];
    } else {
        if (time === '8') {
            return [
                {
                    title: "",
                    render: (text, record) => (
                        <React.Fragment>
                            <div className="columns-header-responsive-start">
                                <div>{title}</div><div>{record.name}</div>
                            </div>
                            <div className="columns-header-responsive">
                                <div>ต่ำกว่าปกติ (คน)<br />{'<50'}</div><div>{record.low}</div>
                            </div>
                            <div className="columns-header-responsive">
                                <div>ปกติ (คน)<br />{'<140'}</div><div>{record.normal}</div>
                            </div>
                            <div className="columns-header-responsive">
                                <div>เสี่ยงเบาหวาน (คน)<br />{'141-199'}</div><div>{record.high}</div>
                            </div>
                            <div className="columns-header-responsive-end">
                                <div>สูงกว่าปกติ (คน)<br />{'<=200'}</div><div>{record.very_high}</div>
                            </div>
                        </React.Fragment>
                    ),
                    responsive: ["xs"]
                },
                {
                    title: <div style={{ color: '#5C2D91' }}>{title}</div>,
                    dataIndex: "name",
                    responsive: ["sm"],
                    align: 'center',
                    render(text, record) {
                        return {
                            props: {
                                style: { color: '#5C2D91' }
                            },
                            children: <div style={{ padding: '10px' }}>{text}</div>
                        };
                    }
                },
                {
                    title: <div style={{ color: '#5C2D91' }}>ภาวะน้ำตาลใน<br />เลือดต่ำกว่าปกติ (คน)</div>,
                    responsive: ["sm"],
                    align: 'center',
                    children: [
                        {
                            key: 'low',
                            title: <div style={{ backgroundColor: '#FF67FF' }}>{'<50'}</div>,
                            dataIndex: 'low',
                            align: 'center'
                        }
                    ]
                },
                {
                    title: <div style={{ color: '#5C2D91' }}>ภาวะน้ำตาลในเลือดปกติ (คน)</div>,
                    responsive: ["sm"],
                    align: 'center',
                    children: [
                        {
                            key: 'normal',
                            title: <div style={{ backgroundColor: '#92D050' }}>{'<140'}</div>,
                            dataIndex: 'normal',
                            align: 'center'
                        }
                    ]
                },
                {
                    title: <div style={{ color: '#5C2D91' }}>มีความเสี่ยงเบาหวาน (คน)</div>,
                    responsive: ["sm"],
                    align: 'center',
                    children: [
                        {
                            key: 'high',
                            title: <div style={{ backgroundColor: '#FFFF02' }}>{'141-199'}</div>,
                            dataIndex: 'high',
                            align: 'center',
                        }
                    ]
                },
                {
                    title: <div style={{ color: '#5C2D91' }}>ภาวะน้ำตาลในเลือดสูงกว่า<br />ปกติ (คน)</div>,
                    responsive: ["sm"],
                    align: 'center',
                    children: [
                        {
                            key: 'very_high',
                            title: <div style={{ backgroundColor: '#FFC001' }}>{'<=200'}</div>,
                            dataIndex: 'very_high',
                            align: 'center'
                        }
                    ]
                }
            ];
        } else {
            return [
                {
                    title: "",
                    render: (text, record) => (
                        <React.Fragment>
                            <div className="columns-header-responsive-start">
                                <div>{title}</div><div>{record.name}</div>
                            </div>
                            <div className="columns-header-responsive">
                                <div>ปกติ (คน)<br />{'<140'}</div><div>{record.normal}</div>
                            </div>
                            <div className="columns-header-responsive">
                                <div>เสี่ยงเบาหวาน (คน)<br />{'141-199'}</div><div>{record.high}</div>
                            </div>
                            <div className="columns-header-responsive-end">
                                <div>สูงกว่าปกติ (คน)<br />{'<=200'}</div><div>{record.very_high}</div>
                            </div>
                        </React.Fragment>
                    ),
                    responsive: ["xs"]
                },
                {
                    title: <div style={{ color: '#5C2D91' }}>{title}</div>,
                    dataIndex: "name",
                    responsive: ["sm"],
                    align: 'center',
                    render(text, record) {
                        return {
                            props: {
                                style: { color: '#5C2D91' }
                            },
                            children: <div style={{ padding: '10px' }}>{text}</div>
                        };
                    }
                },
                {
                    title: <div style={{ color: '#5C2D91' }}>ภาวะน้ำตาลในเลือดปกติ (คน)</div>,
                    responsive: ["sm"],
                    align: 'center',
                    children: [
                        {
                            key: 'normal',
                            title: <div style={{ backgroundColor: '#92D050' }}>{'<140'}</div>,
                            dataIndex: 'normal',
                            align: 'center'
                        }
                    ]
                },
                {
                    title: <div style={{ color: '#5C2D91' }}>มีความเสี่ยงเบาหวาน (คน)</div>,
                    responsive: ["sm"],
                    align: 'center',
                    children: [
                        {
                            key: 'high',
                            title: <div style={{ backgroundColor: '#FFFF02' }}>{'141-199'}</div>,
                            dataIndex: 'high',
                            align: 'center',
                        }
                    ]
                },
                {
                    title: <div style={{ color: '#5C2D91' }}>ภาวะน้ำตาลในเลือดสูงกว่า<br />ปกติ (คน)</div>,
                    responsive: ["sm"],
                    align: 'center',
                    children: [
                        {
                            key: 'very_high',
                            title: <div style={{ backgroundColor: '#FFC001' }}>{'<=200'}</div>,
                            dataIndex: 'very_high',
                            align: 'center'
                        }
                    ]
                }
            ];
        }
    }
}

export function waistColumn(title) {
    return [
        {
            title: "",
            render: (text, record) => (
                <React.Fragment>
                    <div className="columns-header-responsive-start">
                        <div>{title}</div><div>{record.name}</div>
                    </div>
                    <div className="columns-header-responsive">
                        <div>{'<ส่วนสูง/2'}<br />(เหมาะสม)</div><div>{record.normal}</div>
                    </div>
                    <div className="columns-header-responsive-end">
                        <div>{'>=ส่วนสูง/2'}<br />(มีภาวะอ้วนลงพุง)</div><div>{record.abnormal}</div>
                    </div>
                </React.Fragment>
            ),
            responsive: ["xs"]
        },
        {
            title: <div style={{ color: '#5C2D91' }}>{title}</div>,
            dataIndex: "name",
            responsive: ["sm"],
            align: 'center',
            render(text, record) {
                return {
                    props: {
                        style: { color: '#5C2D91' }
                    },
                    children: <div style={{ padding: '10px' }}>{text}</div>
                };
            }
        },
        {
            title: <div style={{ color: '#5C2D91' }}>รอบเอว</div>,
            responsive: ["sm"],
            align: 'center',
            children: [
                {
                    key: 'normal',
                    title: <div style={{ backgroundColor: '#A0EA57' }}>{'<ส่วนสูง/2'}<br />(เหมาะสม)</div>,
                    dataIndex: 'normal',
                    align: 'center'
                },
                {
                    key: 'abnormal',
                    title: <div style={{ backgroundColor: '#ECEC5F' }}>{'>=ส่วนสูง/2'}<br />(มีภาวะอ้วนลงพุง)</div>,
                    dataIndex: 'abnormal',
                    align: 'center'
                }
            ]
        }
    ];
}

export const hospitalAllColumn = [
    {
        title: "",
        render: (text, record) => (
            <React.Fragment>
                <div className="columns-header-responsive-start">
                    <div>รายชื่อ</div><div>{record.name}</div>
                </div>
                <div className="columns-header-responsive">
                    <div>เลขบัตรประจำตัวประชาชน</div><div>{record.pid}</div>
                </div>
                <div className="columns-header-responsive">
                    <div>เบอร์โทรศัพท์</div><div>{record.phone_number}</div>
                </div>
                <div className="columns-header-responsive">
                    <div>BP (mmHg)</div><div>{record.blood_pressure}</div>
                </div>
                <div className="columns-header-responsive">
                    <div>DTX (mg/dL)</div><div>{record.blood_sugar}</div>
                </div>
                <div className="columns-header-responsive">
                    <div>BMI (kg/m2)</div><div>{record.bmi}</div>
                </div>
                <div className="columns-header-responsive-end">
                    <div>รอบเอว (cm)</div><div>{record.waist_circumference}</div>
                </div>
            </React.Fragment>
        ),
        responsive: ["xs"]
    },
    {
        title: <div style={{ color: '#5C2D91' }}>รายชื่อ</div>,
        dataIndex: "name",
        responsive: ["sm"],
        align: 'center',
        render(text, record) {
            return {
                props: {
                    style: { color: '#5C2D91' }
                },
                children: <div>{text}</div>
            };
        }
    }, {
        title: <div style={{ color: '#5C2D91' }}>เลขบัตรประจำตัว<br />ประชาชน</div>,
        dataIndex: "pid",
        responsive: ["sm"],
        align: 'center'
    }, {
        title: <div style={{ color: '#5C2D91' }}>เบอร์โทรศัพท์</div>,
        dataIndex: "phone_number",
        responsive: ["sm"],
        align: 'center'
    }, {
        title: <div style={{ color: '#5C2D91' }}>BP (mmHg)</div>,
        dataIndex: "blood_pressure",
        responsive: ["sm"],
        align: 'center',
        render: (text, record) => (
            <div className="table-space-between-color">
                <div style={{ backgroundColor: record.blood_pressure_color, width: '15px', height: '45px' }}></div>
                <span style={{ margin: '0 auto' }}>{text}</span>
            </div>
        )
    }, {
        title: <div style={{ color: '#5C2D91' }}>DTX (mg/dL)</div>,
        dataIndex: "blood_sugar",
        responsive: ["sm"],
        align: 'center',
        render: (text, record) => (
            <div className="table-space-between-color">
                <div style={{ backgroundColor: record.blood_sugar_color, width: '15px', height: '45px' }}></div>
                <span style={{ margin: '0 auto' }}>{text}</span>
            </div>
        )
    }, {
        title: <div style={{ color: '#5C2D91' }}>BMI (kg/m2)</div>,
        dataIndex: "bmi",
        responsive: ["sm"],
        align: 'center',
        render: (text, record) => (
            <div className="table-space-between-color">
                <div style={{ backgroundColor: record.bmi_coler, width: '15px', height: '45px' }}></div>
                <span style={{ margin: '0 auto' }}>{text}</span>
            </div>
        )
    }, {
        title: <div style={{ color: '#5C2D91' }}>รอบเอว (cm)</div>,
        dataIndex: "waist_circumference",
        responsive: ["sm"],
        align: 'center',
        render: (text, record) => (
            <div className="table-space-between-color">
                <div style={{ backgroundColor: record.waist_circumference_color, width: '15px', height: '45px' }}></div>
                <span style={{ margin: '0 auto' }}>{text}</span>
            </div>
        )
    }, {
        title: <div style={{ color: '#5C2D91' }}>ติดต่อ</div>,
        dataIndex: "pid",
        responsive: ["sm"],
        align: 'center',
        render: (text, record) => (
            <div>
                <VideoCameraFilled className="video-call-button" style={{ color: '#6D1795' }} onClick={() => {
                    // window.open('https://h4u-mhealth.moph.go.th/h4u-video-call/#/nux-dashboard-to-h4u?pid=' + text + '&token=' + localStorage.getItem("Authorization"), '_system')

                    let homeTable = new HomeTable
                    homeTable.conferrenceButtonClick(text)
                }} />
            </div>
        )
    },
]
export const hospitalBmiColumn = [
    {
        title: "",
        render: (text, record) => (
            <React.Fragment>
                <div className="columns-header-responsive-start">
                    <div>ชื่อ-นามสกุล</div><div>{record.name}</div>
                </div>
                <div className="columns-header-responsive">
                    <div>เบอร์โทร</div><div>{record.phone_number}</div>
                </div>
                <div className="columns-header-responsive">
                    <div>อายุ</div><div>{record.age}</div>
                </div>
                <div className="columns-header-responsive">
                    <div>เพศ</div><div>{record.gender}</div>
                </div>
                <div className="columns-header-responsive">
                    <div>วันที่ชั่งน้ำหนัก</div><div>{record.date}</div>
                </div>
                <div className="columns-header-responsive">
                    <div>ส่วนสูง</div><div>{record.height}</div>
                </div>
                <div className="columns-header-responsive">
                    <div>น้ำหนัก</div><div>{record.weight}</div>
                </div>
                <div className="columns-header-responsive">
                    <div>ดัชนีมวลกาย</div><div>{record.value}</div>
                </div>
                <div className="columns-header-responsive-end">
                    <div>แปลผล</div><div>{record.analysis}</div>
                </div>
            </React.Fragment>
        ),
        responsive: ["xs"]
    },
    {
        title: <div style={{ color: '#5C2D91' }}>ชื่อ-นามสกุล</div>,
        dataIndex: "name",
        responsive: ["sm"],
        align: 'center',
        render(text, record) {
            return {
                props: {
                    style: { color: '#5C2D91' }
                },
                children: <div style={{ padding: '10px' }}>{text}</div>
            };
        }
    }, {
        title: <div style={{ color: '#5C2D91' }}>เบอร์โทร</div>,
        dataIndex: "phone_number",
        responsive: ["sm"],
        align: 'center'
    }, {
        title: <div style={{ color: '#5C2D91' }}>อายุ</div>,
        dataIndex: "age",
        responsive: ["sm"],
        align: 'center'
    }, {
        title: <div style={{ color: '#5C2D91' }}>เพศ</div>,
        dataIndex: "gender",
        responsive: ["sm"],
        align: 'center'
    }, {
        title: <div style={{ color: '#5C2D91' }}>วันที่ชั่งน้ำหนัก</div>,
        dataIndex: "date",
        responsive: ["sm"],
        align: 'center'
    }, {
        title: <div style={{ color: '#5C2D91' }}>ส่วนสูง</div>,
        dataIndex: "height",
        responsive: ["sm"],
        align: 'center'
    }, {
        title: <div style={{ color: '#5C2D91' }}>น้ำหนัก</div>,
        dataIndex: "weight",
        responsive: ["sm"],
        align: 'center'
    }, {
        title: <div style={{ color: '#5C2D91' }}>ดัชนีมวลกาย</div>,
        dataIndex: "value",
        responsive: ["sm"],
        align: 'center'
    }, {
        title: <div style={{ color: '#5C2D91' }}>แปลผล</div>,
        dataIndex: "analysis",
        responsive: ["sm"],
        align: 'center',
        render(text, record) {
            return {
                props: {
                    style: { backgroundColor: record.color }
                },
                children: <div>{text}</div>
            };
        }
    }, {
        title: <div style={{ color: '#5C2D91' }}>ติดต่อ</div>,
        dataIndex: "pid",
        responsive: ["sm"],
        align: 'center',
        render: (text, record) => (
            <div>
                <VideoCameraFilled className="video-call-button" style={{ color: '#6D1795' }} onClick={() => {
                    // window.open('https://h4u-mhealth.moph.go.th/h4u-video-call/#/nux-dashboard-to-h4u?pid=' + text + '&token=' + localStorage.getItem("Authorization"), '_system')
                    let homeTable = new HomeTable
                    homeTable.conferrenceButtonClick(text)
                }} />
            </div>
        )
    },
    //  {
    //     title: <div style={{ color: '#5C2D91' }}>การจัดการ<br />รายบุคคล</div>,
    //     dataIndex: "option",
    //     responsive: ["sm"],
    //     align: 'center'
    // }
]
export const hospitalBloodPressureColumn = [
    {
        title: "",
        render: (text, record) => (
            <React.Fragment>
                <div className="columns-header-responsive-start">
                    <div>ชื่อ-นามสกุล</div><div>{record.name}</div>
                </div>
                <div className="columns-header-responsive">
                    <div>เบอร์โทร</div><div>{record.phone_number}</div>
                </div>
                <div className="columns-header-responsive">
                    <div>อายุ</div><div>{record.age}</div>
                </div>
                <div className="columns-header-responsive">
                    <div>เพศ</div><div>{record.gender}</div>
                </div>
                <div className="columns-header-responsive">
                    <div>วันที่วัดความดัน</div><div>{record.date}</div>
                </div>
                <div className="columns-header-responsive">
                    <div>ค่าความดันตัวบน</div><div>{record.systolic}</div>
                </div>
                <div className="columns-header-responsive">
                    <div>ค่าความดันตัวล่าง</div><div>{record.diastolic}</div>
                </div>
                <div className="columns-header-responsive">
                    <div>แปลผล</div><div>{record.analysis}</div>
                </div>
                <div className="columns-header-responsive-end">
                    <div>ที่มาข้อมูล</div><div>{record.platform_type}</div>
                </div>
            </React.Fragment>
        ),
        responsive: ["xs"]
    },
    {
        title: <div style={{ color: '#5C2D91' }}>ชื่อ-นามสกุล</div>,
        dataIndex: "name",
        responsive: ["sm"],
        align: 'center',
        render(text, record) {
            return {
                props: {
                    style: { color: '#5C2D91' }
                },
                children: <div style={{ padding: '10px' }}>{text}</div>
            };
        }
    }, {
        title: <div style={{ color: '#5C2D91' }}>เบอร์โทร</div>,
        dataIndex: "phone_number",
        responsive: ["sm"],
        align: 'center'
    }, {
        title: <div style={{ color: '#5C2D91' }}>อายุ</div>,
        dataIndex: "age",
        responsive: ["sm"],
        align: 'center'
    }, {
        title: <div style={{ color: '#5C2D91' }}>เพศ</div>,
        dataIndex: "gender",
        responsive: ["sm"],
        align: 'center'
    }, {
        title: <div style={{ color: '#5C2D91' }}>วันที่วัดความดัน</div>,
        dataIndex: "date",
        responsive: ["sm"],
        align: 'center'
    }, {
        title: <div style={{ color: '#5C2D91' }}>ค่าความดัน<br />ตัวบน</div>,
        dataIndex: "systolic",
        responsive: ["sm"],
        align: 'center'
    }, {
        title: <div style={{ color: '#5C2D91' }}>ค่าความดัน<br />ตัวล่าง</div>,
        dataIndex: "diastolic",
        responsive: ["sm"],
        align: 'center'
    }, {
        title: <div style={{ color: '#5C2D91' }}>แปลผล</div>,
        dataIndex: "analysis",
        responsive: ["sm"],
        align: 'center',
        render(text, record) {
            return {
                props: {
                    style: { backgroundColor: record.color }
                },
                children: <div>{text}</div>
            };
        }
    }, {
        title: <div style={{ color: '#5C2D91' }}>ที่มาข้อมูล</div>,
        dataIndex: "platform_type",
        responsive: ["sm"],
        align: 'center'
    }, {
        title: <div style={{ color: '#5C2D91' }}>ติดต่อ</div>,
        dataIndex: "pid",
        responsive: ["sm"],
        align: 'center',
        render: (text, record) => (
            <div>
                <VideoCameraFilled className="video-call-button" style={{ color: '#6D1795' }} onClick={() => {
                    // window.open('https://h4u-mhealth.moph.go.th/h4u-video-call/#/nux-dashboard-to-h4u?pid=' + text + '&token=' + localStorage.getItem("Authorization"), '_system')
                    let homeTable = new HomeTable
                    homeTable.conferrenceButtonClick(text)
                }} />
            </div>
        )
    },
    // {
    //     title: <div style={{ color: '#5C2D91' }}>การจัดการ<br />รายบุคคล</div>,
    //     dataIndex: "option",
    //     responsive: ["sm"],
    //     align: 'center'
    // }
]
export const hospitalBloodSugarColumn = [
    {
        title: "",
        render: (text, record) => (
            <React.Fragment>
                <div className="columns-header-responsive-start">
                    <div>ชื่อ-นามสกุล</div><div>{record.name}</div>
                </div>
                <div className="columns-header-responsive">
                    <div>เบอร์โทร</div><div>{record.phone_number}</div>
                </div>
                <div className="columns-header-responsive">
                    <div>อายุ</div><div>{record.age}</div>
                </div>
                <div className="columns-header-responsive">
                    <div>เพศ</div><div>{record.gender}</div>
                </div>
                <div className="columns-header-responsive">
                    <div>วันที่ตรวจ</div><div>{record.date}</div>
                </div>
                <div className="columns-header-responsive">
                    <div>ระดับน้ำตาลในเลือด</div><div>{record.value}</div>
                </div>
                <div className="columns-header-responsive-end">
                    <div>แปลผล</div><div>{record.analysis}</div>
                </div>
            </React.Fragment>
        ),
        responsive: ["xs"]
    },
    {
        title: <div style={{ color: '#5C2D91' }}>ชื่อ-นามสกุล</div>,
        dataIndex: "name",
        responsive: ["sm"],
        align: 'center',
        render(text, record) {
            return {
                props: {
                    style: { color: '#5C2D91' }
                },
                children: <div style={{ padding: '10px' }}>{text}</div>
            };
        }
    }, {
        title: <div style={{ color: '#5C2D91' }}>เบอร์โทร</div>,
        dataIndex: "phone_number",
        responsive: ["sm"],
        align: 'center'
    }, {
        title: <div style={{ color: '#5C2D91' }}>อายุ</div>,
        dataIndex: "age",
        responsive: ["sm"],
        align: 'center'
    }, {
        title: <div style={{ color: '#5C2D91' }}>เพศ</div>,
        dataIndex: "gender",
        responsive: ["sm"],
        align: 'center'
    }, {
        title: <div style={{ color: '#5C2D91' }}>วันที่ตรวจ</div>,
        dataIndex: "date",
        responsive: ["sm"],
        align: 'center'
    }, {
        title: <div style={{ color: '#5C2D91' }}>ระดับน้ำตาลในเลือด</div>,
        dataIndex: "value",
        responsive: ["sm"],
        align: 'center'
    }, {
        title: <div style={{ color: '#5C2D91' }}>แปลผล</div>,
        dataIndex: "analysis",
        responsive: ["sm"],
        align: 'center',
        render(text, record) {
            return {
                props: {
                    style: { backgroundColor: record.color }
                },
                children: <div>{text}</div>
            };
        }
    }, {
        title: <div style={{ color: '#5C2D91' }}>ติดต่อ</div>,
        dataIndex: "pid",
        responsive: ["sm"],
        align: 'center',
        render: (text, record) => (
            <div>
                <VideoCameraFilled className="video-call-button" style={{ color: '#6D1795' }} onClick={() => {
                    // window.open('https://h4u-mhealth.moph.go.th/h4u-video-call/#/nux-dashboard-to-h4u?pid=' + text + '&token=' + localStorage.getItem("Authorization"), '_system')
                    let homeTable = new HomeTable
                    homeTable.conferrenceButtonClick(text)
                }} />
            </div>
        )
    },
    // {
    //     title: <div style={{ color: '#5C2D91' }}>การจัดการ<br />รายบุคคล</div>,
    //     dataIndex: "option",
    //     responsive: ["sm"],
    //     align: 'center'
    // }
]
export const hospitalWaistColumn = [
    {
        title: "",
        render: (text, record) => (
            <React.Fragment>
                <div className="columns-header-responsive-start">
                    <div>ชื่อ-นามสกุล</div><div>{record.name}</div>
                </div>
                <div className="columns-header-responsive">
                    <div>เบอร์โทร</div><div>{record.phone_number}</div>
                </div>
                <div className="columns-header-responsive">
                    <div>อายุ</div><div>{record.age}</div>
                </div>
                <div className="columns-header-responsive">
                    <div>เพศ</div><div>{record.gender}</div>
                </div>
                <div className="columns-header-responsive">
                    <div>วันที่ตรวจ</div><div>{record.date}</div>
                </div>
                <div className="columns-header-responsive">
                    <div>รอบเอว</div><div>{record.value}</div>
                </div>
                <div className="columns-header-responsive-end">
                    <div>แปลผล</div><div>{record.analysis}</div>
                </div>
            </React.Fragment>
        ),
        responsive: ["xs"]
    },
    {
        title: <div style={{ color: '#5C2D91' }}>ชื่อ-นามสกุล</div>,
        dataIndex: "name",
        responsive: ["sm"],
        align: 'center',
        render(text, record) {
            return {
                props: {
                    style: { color: '#5C2D91' }
                },
                children: <div style={{ padding: '10px' }}>{text}</div>
            };
        }
    }, {
        title: <div style={{ color: '#5C2D91' }}>เบอร์โทร</div>,
        dataIndex: "phone_number",
        responsive: ["sm"],
        align: 'center'
    }, {
        title: <div style={{ color: '#5C2D91' }}>อายุ</div>,
        dataIndex: "age",
        responsive: ["sm"],
        align: 'center'
    }, {
        title: <div style={{ color: '#5C2D91' }}>เพศ</div>,
        dataIndex: "gender",
        responsive: ["sm"],
        align: 'center'
    }, {
        title: <div style={{ color: '#5C2D91' }}>วันที่ตรวจ</div>,
        dataIndex: "date",
        responsive: ["sm"],
        align: 'center'
    }, {
        title: <div style={{ color: '#5C2D91' }}>รอบเอว</div>,
        dataIndex: "value",
        responsive: ["sm"],
        align: 'center'
    }, {
        title: <div style={{ color: '#5C2D91' }}>แปลผล</div>,
        dataIndex: "analysis",
        responsive: ["sm"],
        align: 'center',
        render(text, record) {
            return {
                props: {
                    style: { backgroundColor: record.color }
                },
                children: <div>{text}</div>
            };
        }
    }, {
        title: <div style={{ color: '#5C2D91' }}>ติดต่อ</div>,
        dataIndex: "pid",
        responsive: ["sm"],
        align: 'center',
        render: (text, record) => (
            <div>
                <VideoCameraFilled className="video-call-button" style={{ color: '#6D1795' }} onClick={() => {
                    // window.open('https://h4u-mhealth.moph.go.th/h4u-video-call/#/nux-dashboard-to-h4u?pid=' + text + '&token=' + localStorage.getItem("Authorization"), '_system')
                    let homeTable = new HomeTable
                    homeTable.conferrenceButtonClick(text)
                }} />
            </div>
        )
    },
    // {
    //     title: <div style={{ color: '#5C2D91' }}>การจัดการ<br />รายบุคคล</div>,
    //     dataIndex: "option",
    //     responsive: ["sm"],
    //     align: 'center'
    // }
]



export const hospitalAllColumnMicroservice = [
    {
        title: "",
        render: (text, record) => (
            <React.Fragment>
                <div className="columns-header-responsive-start">
                    <div>รายชื่อ</div><div>{record.name}</div>
                </div>
                <div className="columns-header-responsive">
                    <div>เลขบัตรประจำตัวประชาชน</div><div>{record.pid}</div>
                </div>
                <div className="columns-header-responsive">
                    <div>เบอร์โทรศัพท์</div><div>{record.phone_number}</div>
                </div>
                <div className="columns-header-responsive">
                    <div>BP (mmHg)</div><div>{record.blood_pressure}</div>
                </div>
                <div className="columns-header-responsive">
                    <div>DTX (mg/dL)</div><div>{record.blood_sugar}</div>
                </div>
                <div className="columns-header-responsive">
                    <div>BMI (kg/m2)</div><div>{record.bmi}</div>
                </div>
                <div className="columns-header-responsive-end">
                    <div>รอบเอว (cm)</div><div>{record.waist_circumference}</div>
                </div>
            </React.Fragment>
        ),
        responsive: ["xs"]
    },
    {
        title: <div style={{ color: '#5C2D91' }}>รายชื่อ</div>,
        dataIndex: "name",
        responsive: ["sm"],
        align: 'center',
        render(text, record) {
            return {
                props: {
                    style: { color: '#5C2D91' }
                },
                children: <div>{text}</div>
            };
        }
    }, {
        title: <div style={{ color: '#5C2D91' }}>เลขบัตรประจำตัว<br />ประชาชน</div>,
        dataIndex: "pid",
        responsive: ["sm"],
        align: 'center'
    }, {
        title: <div style={{ color: '#5C2D91' }}>เบอร์โทรศัพท์</div>,
        dataIndex: "phone_number",
        responsive: ["sm"],
        align: 'center'
    }, {
        title: <div style={{ color: '#5C2D91' }}>BP (mmHg)</div>,
        dataIndex: "blood_pressure",
        responsive: ["sm"],
        align: 'center',
        render: (text, record) => (
            <div className="table-space-between-color">
                <div style={{ backgroundColor: record.blood_pressure_color, width: '15px', height: '45px' }}></div>
                <span style={{ margin: '0 auto' }}>{text}</span>
            </div>
        )
    }, {
        title: <div style={{ color: '#5C2D91' }}>DTX (mg/dL)</div>,
        dataIndex: "blood_sugar",
        responsive: ["sm"],
        align: 'center',
        render: (text, record) => (
            <div className="table-space-between-color">
                <div style={{ backgroundColor: record.blood_sugar_color, width: '15px', height: '45px' }}></div>
                <span style={{ margin: '0 auto' }}>{text}</span>
            </div>
        )
    }, {
        title: <div style={{ color: '#5C2D91' }}>BMI (kg/m2)</div>,
        dataIndex: "bmi",
        responsive: ["sm"],
        align: 'center',
        render: (text, record) => (
            <div className="table-space-between-color">
                <div style={{ backgroundColor: record.bmi_coler, width: '15px', height: '45px' }}></div>
                <span style={{ margin: '0 auto' }}>{text}</span>
            </div>
        )
    }, {
        title: <div style={{ color: '#5C2D91' }}>รอบเอว (cm)</div>,
        dataIndex: "waist_circumference",
        responsive: ["sm"],
        align: 'center',
        render: (text, record) => (
            <div className="table-space-between-color">
                <div style={{ backgroundColor: record.waist_circumference_color, width: '15px', height: '45px' }}></div>
                <span style={{ margin: '0 auto' }}>{text}</span>
            </div>
        )
    },
    // {
    //     title: <div style={{ color: '#5C2D91' }}>ติดต่อ</div>,
    //     dataIndex: "pid",
    //     responsive: ["sm"],
    //     align: 'center',
    //     render: (text, record) => (
    //         <div>
    //             <VideoCameraFilled className="video-call-button" style={{ color: '#6D1795' }} onClick={() => {
    //                 window.open('https://h4u-mhealth.moph.go.th/h4u-video-call/#/microservice?pid=' + text + '&token=' + localStorage.getItem("Authorization"), '_system')
    //             }} />
    //         </div>
    //     )
    // },
]

export const hospitalBmiColumnMicroservice = [
    {
        title: "",
        render: (text, record) => (
            <React.Fragment>
                <div className="columns-header-responsive-start">
                    <div>ชื่อ-นามสกุล</div><div>{record.name}</div>
                </div>
                <div className="columns-header-responsive">
                    <div>เบอร์โทร</div><div>{record.phone_number}</div>
                </div>
                <div className="columns-header-responsive">
                    <div>อายุ</div><div>{record.age}</div>
                </div>
                <div className="columns-header-responsive">
                    <div>เพศ</div><div>{record.gender}</div>
                </div>
                <div className="columns-header-responsive">
                    <div>วันที่ชั่งน้ำหนัก</div><div>{record.date}</div>
                </div>
                <div className="columns-header-responsive">
                    <div>ส่วนสูง</div><div>{record.height}</div>
                </div>
                <div className="columns-header-responsive">
                    <div>น้ำหนัก</div><div>{record.weight}</div>
                </div>
                <div className="columns-header-responsive">
                    <div>ดัชนีมวลกาย</div><div>{record.value}</div>
                </div>
                <div className="columns-header-responsive-end">
                    <div>แปลผล</div><div>{record.analysis}</div>
                </div>
            </React.Fragment>
        ),
        responsive: ["xs"]
    },
    {
        title: <div style={{ color: '#5C2D91' }}>ชื่อ-นามสกุล</div>,
        dataIndex: "name",
        responsive: ["sm"],
        align: 'center',
        render(text, record) {
            return {
                props: {
                    style: { color: '#5C2D91' }
                },
                children: <div style={{ padding: '10px' }}>{text}</div>
            };
        }
    }, {
        title: <div style={{ color: '#5C2D91' }}>เบอร์โทร</div>,
        dataIndex: "phone_number",
        responsive: ["sm"],
        align: 'center'
    }, {
        title: <div style={{ color: '#5C2D91' }}>อายุ</div>,
        dataIndex: "age",
        responsive: ["sm"],
        align: 'center'
    }, {
        title: <div style={{ color: '#5C2D91' }}>เพศ</div>,
        dataIndex: "gender",
        responsive: ["sm"],
        align: 'center'
    }, {
        title: <div style={{ color: '#5C2D91' }}>วันที่ชั่งน้ำหนัก</div>,
        dataIndex: "date",
        responsive: ["sm"],
        align: 'center'
    }, {
        title: <div style={{ color: '#5C2D91' }}>ส่วนสูง</div>,
        dataIndex: "height",
        responsive: ["sm"],
        align: 'center'
    }, {
        title: <div style={{ color: '#5C2D91' }}>น้ำหนัก</div>,
        dataIndex: "weight",
        responsive: ["sm"],
        align: 'center'
    }, {
        title: <div style={{ color: '#5C2D91' }}>ดัชนีมวลกาย</div>,
        dataIndex: "value",
        responsive: ["sm"],
        align: 'center'
    }, {
        title: <div style={{ color: '#5C2D91' }}>แปลผล</div>,
        dataIndex: "analysis",
        responsive: ["sm"],
        align: 'center',
        render(text, record) {
            return {
                props: {
                    style: { backgroundColor: record.color }
                },
                children: <div>{text}</div>
            };
        }
    },
    //  {
    //     title: <div style={{ color: '#5C2D91' }}>ติดต่อ</div>,
    //     dataIndex: "pid",
    //     responsive: ["sm"],
    //     align: 'center',
    //     render: (text, record) => (
    //         <div>
    //             <VideoCameraFilled className="video-call-button" style={{ color: '#6D1795' }} onClick={() => {
    //                 window.open('https://h4u-mhealth.moph.go.th/h4u-video-call/#/microservice?pid=' + text + '&token=' + localStorage.getItem("Authorization"), '_system')
    //             }} />
    //         </div>
    //     )
    // },
    //  {
    //     title: <div style={{ color: '#5C2D91' }}>การจัดการ<br />รายบุคคล</div>,
    //     dataIndex: "option",
    //     responsive: ["sm"],
    //     align: 'center'
    // }
]

export const hospitalBloodPressureColumnMicroservice = [
    {
        title: "",
        render: (text, record) => (
            <React.Fragment>
                <div className="columns-header-responsive-start">
                    <div>ชื่อ-นามสกุล</div><div>{record.name}</div>
                </div>
                <div className="columns-header-responsive">
                    <div>เบอร์โทร</div><div>{record.phone_number}</div>
                </div>
                <div className="columns-header-responsive">
                    <div>อายุ</div><div>{record.age}</div>
                </div>
                <div className="columns-header-responsive">
                    <div>เพศ</div><div>{record.gender}</div>
                </div>
                <div className="columns-header-responsive">
                    <div>วันที่วัดความดัน</div><div>{record.date}</div>
                </div>
                <div className="columns-header-responsive">
                    <div>ค่าความดันตัวบน</div><div>{record.systolic}</div>
                </div>
                <div className="columns-header-responsive">
                    <div>ค่าความดันตัวล่าง</div><div>{record.diastolic}</div>
                </div>
                <div className="columns-header-responsive">
                    <div>แปลผล</div><div>{record.analysis}</div>
                </div>
                <div className="columns-header-responsive-end">
                    <div>ที่มาข้อมูล</div><div>{record.platform_type}</div>
                </div>
            </React.Fragment>
        ),
        responsive: ["xs"]
    },
    {
        title: <div style={{ color: '#5C2D91' }}>ชื่อ-นามสกุล</div>,
        dataIndex: "name",
        responsive: ["sm"],
        align: 'center',
        render(text, record) {
            return {
                props: {
                    style: { color: '#5C2D91' }
                },
                children: <div style={{ padding: '10px' }}>{text}</div>
            };
        }
    }, {
        title: <div style={{ color: '#5C2D91' }}>เบอร์โทร</div>,
        dataIndex: "phone_number",
        responsive: ["sm"],
        align: 'center'
    }, {
        title: <div style={{ color: '#5C2D91' }}>อายุ</div>,
        dataIndex: "age",
        responsive: ["sm"],
        align: 'center'
    }, {
        title: <div style={{ color: '#5C2D91' }}>เพศ</div>,
        dataIndex: "gender",
        responsive: ["sm"],
        align: 'center'
    }, {
        title: <div style={{ color: '#5C2D91' }}>วันที่วัดความดัน</div>,
        dataIndex: "date",
        responsive: ["sm"],
        align: 'center'
    }, {
        title: <div style={{ color: '#5C2D91' }}>ค่าความดัน<br />ตัวบน</div>,
        dataIndex: "systolic",
        responsive: ["sm"],
        align: 'center'
    }, {
        title: <div style={{ color: '#5C2D91' }}>ค่าความดัน<br />ตัวล่าง</div>,
        dataIndex: "diastolic",
        responsive: ["sm"],
        align: 'center'
    }, {
        title: <div style={{ color: '#5C2D91' }}>แปลผล</div>,
        dataIndex: "analysis",
        responsive: ["sm"],
        align: 'center',
        render(text, record) {
            return {
                props: {
                    style: { backgroundColor: record.color }
                },
                children: <div>{text}</div>
            };
        }
    }, {
        title: <div style={{ color: '#5C2D91' }}>ที่มาข้อมูล</div>,
        dataIndex: "platform_type",
        responsive: ["sm"],
        align: 'center'
    },
    // {
    //     title: <div style={{ color: '#5C2D91' }}>ติดต่อ</div>,
    //     dataIndex: "pid",
    //     responsive: ["sm"],
    //     align: 'center',
    //     render: (text, record) => (
    //         <div>
    //             <VideoCameraFilled className="video-call-button" style={{ color: '#6D1795' }} onClick={() => {
    //                 window.open('https://h4u-mhealth.moph.go.th/h4u-video-call/#/nux-dashboard-to-h4u?pid=' + text + '&token=' + localStorage.getItem("Authorization"), '_system')
    //             }} />
    //         </div>
    //     )
    // },
    // {
    //     title: <div style={{ color: '#5C2D91' }}>การจัดการ<br />รายบุคคล</div>,
    //     dataIndex: "option",
    //     responsive: ["sm"],
    //     align: 'center'
    // }
]

export const hospitalBloodSugarColumnMicroservice = [
    {
        title: "",
        render: (text, record) => (
            <React.Fragment>
                <div className="columns-header-responsive-start">
                    <div>ชื่อ-นามสกุล</div><div>{record.name}</div>
                </div>
                <div className="columns-header-responsive">
                    <div>เบอร์โทร</div><div>{record.phone_number}</div>
                </div>
                <div className="columns-header-responsive">
                    <div>อายุ</div><div>{record.age}</div>
                </div>
                <div className="columns-header-responsive">
                    <div>เพศ</div><div>{record.gender}</div>
                </div>
                <div className="columns-header-responsive">
                    <div>วันที่ตรวจ</div><div>{record.date}</div>
                </div>
                <div className="columns-header-responsive">
                    <div>ระดับน้ำตาลในเลือด</div><div>{record.value}</div>
                </div>
                <div className="columns-header-responsive-end">
                    <div>แปลผล</div><div>{record.analysis}</div>
                </div>
            </React.Fragment>
        ),
        responsive: ["xs"]
    },
    {
        title: <div style={{ color: '#5C2D91' }}>ชื่อ-นามสกุล</div>,
        dataIndex: "name",
        responsive: ["sm"],
        align: 'center',
        render(text, record) {
            return {
                props: {
                    style: { color: '#5C2D91' }
                },
                children: <div style={{ padding: '10px' }}>{text}</div>
            };
        }
    }, {
        title: <div style={{ color: '#5C2D91' }}>เบอร์โทร</div>,
        dataIndex: "phone_number",
        responsive: ["sm"],
        align: 'center'
    }, {
        title: <div style={{ color: '#5C2D91' }}>อายุ</div>,
        dataIndex: "age",
        responsive: ["sm"],
        align: 'center'
    }, {
        title: <div style={{ color: '#5C2D91' }}>เพศ</div>,
        dataIndex: "gender",
        responsive: ["sm"],
        align: 'center'
    }, {
        title: <div style={{ color: '#5C2D91' }}>วันที่ตรวจ</div>,
        dataIndex: "date",
        responsive: ["sm"],
        align: 'center'
    }, {
        title: <div style={{ color: '#5C2D91' }}>ระดับน้ำตาลในเลือด</div>,
        dataIndex: "value",
        responsive: ["sm"],
        align: 'center'
    }, {
        title: <div style={{ color: '#5C2D91' }}>แปลผล</div>,
        dataIndex: "analysis",
        responsive: ["sm"],
        align: 'center',
        render(text, record) {
            return {
                props: {
                    style: { backgroundColor: record.color }
                },
                children: <div>{text}</div>
            };
        }
    },
    // {
    //     title: <div style={{ color: '#5C2D91' }}>ติดต่อ</div>,
    //     dataIndex: "pid",
    //     responsive: ["sm"],
    //     align: 'center',
    //     render: (text, record) => (
    //         <div>
    //             <VideoCameraFilled className="video-call-button" style={{ color: '#6D1795' }} onClick={() => {
    //                 window.open('https://h4u-mhealth.moph.go.th/h4u-video-call/#/nux-dashboard-to-h4u?pid=' + text + '&token=' + localStorage.getItem("Authorization"), '_system')
    //             }} />
    //         </div>
    //     )
    // },
    // {
    //     title: <div style={{ color: '#5C2D91' }}>การจัดการ<br />รายบุคคล</div>,
    //     dataIndex: "option",
    //     responsive: ["sm"],
    //     align: 'center'
    // }
]
export const hospitalWaistColumnMicroservice = [
    {
        title: "",
        render: (text, record) => (
            <React.Fragment>
                <div className="columns-header-responsive-start">
                    <div>ชื่อ-นามสกุล</div><div>{record.name}</div>
                </div>
                <div className="columns-header-responsive">
                    <div>เบอร์โทร</div><div>{record.phone_number}</div>
                </div>
                <div className="columns-header-responsive">
                    <div>อายุ</div><div>{record.age}</div>
                </div>
                <div className="columns-header-responsive">
                    <div>เพศ</div><div>{record.gender}</div>
                </div>
                <div className="columns-header-responsive">
                    <div>วันที่ตรวจ</div><div>{record.date}</div>
                </div>
                <div className="columns-header-responsive">
                    <div>รอบเอว</div><div>{record.value}</div>
                </div>
                <div className="columns-header-responsive-end">
                    <div>แปลผล</div><div>{record.analysis}</div>
                </div>
            </React.Fragment>
        ),
        responsive: ["xs"]
    },
    {
        title: <div style={{ color: '#5C2D91' }}>ชื่อ-นามสกุล</div>,
        dataIndex: "name",
        responsive: ["sm"],
        align: 'center',
        render(text, record) {
            return {
                props: {
                    style: { color: '#5C2D91' }
                },
                children: <div style={{ padding: '10px' }}>{text}</div>
            };
        }
    }, {
        title: <div style={{ color: '#5C2D91' }}>เบอร์โทร</div>,
        dataIndex: "phone_number",
        responsive: ["sm"],
        align: 'center'
    }, {
        title: <div style={{ color: '#5C2D91' }}>อายุ</div>,
        dataIndex: "age",
        responsive: ["sm"],
        align: 'center'
    }, {
        title: <div style={{ color: '#5C2D91' }}>เพศ</div>,
        dataIndex: "gender",
        responsive: ["sm"],
        align: 'center'
    }, {
        title: <div style={{ color: '#5C2D91' }}>วันที่ตรวจ</div>,
        dataIndex: "date",
        responsive: ["sm"],
        align: 'center'
    }, {
        title: <div style={{ color: '#5C2D91' }}>รอบเอว</div>,
        dataIndex: "value",
        responsive: ["sm"],
        align: 'center'
    }, {
        title: <div style={{ color: '#5C2D91' }}>แปลผล</div>,
        dataIndex: "analysis",
        responsive: ["sm"],
        align: 'center',
        render(text, record) {
            return {
                props: {
                    style: { backgroundColor: record.color }
                },
                children: <div>{text}</div>
            };
        }
    },
    // {
    //     title: <div style={{ color: '#5C2D91' }}>ติดต่อ</div>,
    //     dataIndex: "pid",
    //     responsive: ["sm"],
    //     align: 'center',
    //     render: (text, record) => (
    //         <div>
    //             <VideoCameraFilled className="video-call-button" style={{ color: '#6D1795' }} onClick={() => {
    //                 window.open('https://h4u-mhealth.moph.go.th/h4u-video-call/#/nux-dashboard-to-h4u?pid=' + text + '&token=' + localStorage.getItem("Authorization"), '_system')
    //             }} />
    //         </div>
    //     )
    // },
    // {
    //     title: <div style={{ color: '#5C2D91' }}>การจัดการ<br />รายบุคคล</div>,
    //     dataIndex: "option",
    //     responsive: ["sm"],
    //     align: 'center'
    // }
]