import React, { Component } from 'react'
import styles from './medicine.module.css'
import { Spin } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import {
    getPatinetHomeMedicine,
    approvedPatientMidicine,
    approvedPatientMedicineByNux,
    createConferrenceRoom,
    shareLinkConferrence
} from '../services/api'
export default class Medicine extends Component {

    constructor(props) {
        super(props)

        this.state = {
            loadingSpin: false,
            id: this.props.id,
            isShowModal: false,
            showModalSendMedicine: false,
            modalType: '',
            pname: '',
            fname: '',
            lname: '',
            pid: '',
            phone_number: '',
            require_medicine_date: '',
            ncdsList: [],
            day7: false,
            address: false,
            addressName: '',
            subDistrictName: '',
            districtName: '',
            provinceName: '',
            postCode: '',
            received_medicine: false,
            send_medicine: false,
            prescribe_medicine: false,
            takeMedicine: 'sub_district_health' // post, sub_district_health
        }

        // this.props.backToMedicineList()


    }

    monthList = [
        {
            "month_id": "01",
            "month_th": "มกราคม",
            "month_en": "January"
        },
        {
            "month_id": "02",
            "month_th": "กุมภาพันธ์",
            "month_en": "February"
        },
        {
            "month_id": "03",
            "month_th": "มีนาคม",
            "month_en": "March"
        },
        {
            "month_id": "04",
            "month_th": "เมษายน",
            "month_en": "April"
        },
        {
            "month_id": "05",
            "month_th": "พฤษภาคม",
            "month_en": "May"
        },
        {
            "month_id": "06",
            "month_th": "มิถุนายน",
            "month_en": "June"
        },
        {
            "month_id": "07",
            "month_th": "กรกฎาคม",
            "month_en": "July"
        },
        {
            "month_id": "08",
            "month_th": "สิงหาคม",
            "month_en": "August"
        },
        {
            "month_id": "09",
            "month_th": "กันยายน",
            "month_en": "September"
        },
        {
            "month_id": "10",
            "month_th": "ตุลาคม",
            "month_en": "October"
        },
        {
            "month_id": "11",
            "month_th": "พฤศจิกายน",
            "month_en": "November"
        },
        {
            "month_id": "12",
            "month_th": "ธันวาคม",
            "month_en": "December"
        }
    ]

    componentDidMount() {
        this.getPatinetHomeMedicine(this.state.id)
    }

    async conferrenceButtonClick(pid) {
        this.setState({
            loadingSpin: true
        })
        let res = await createConferrenceRoom()

        if (res) {
            if (res['data']['code'] == 200) {
                let meetingID = res['data']['data']['meetingID']
                let url = res['data']['data']['url']
                this.shareLink(pid, meetingID, url)
            } else {
                alert(res['data']['message'])
            }
        } else {
            alert('ไม่ตอบสนอง')
        }
    }

    async shareLink(pid, meetingID, joinLink) {
        let res = await shareLinkConferrence(meetingID, pid)

        if (res) {
            if (res['data']['code'] == 200) {
                this.setState({
                    loadingSpin: false
                })
                window.open(joinLink, '_system')
            } else {
                alert(res['data']['message'])
            }
        } else {
            alert('ไม่ตอบสนอง')
        }
    }

    async getPatinetHomeMedicine(id) {
        this.setState({ loadingSpin: true })
        let res = await getPatinetHomeMedicine(id)
        this.setState({ loadingSpin: false })
        if (res) {
            if (res['data']['code'] === 200) {
                console.log(res['data'])
                this.setState({
                    pname: res['data']['result']['personal_information']['pname'],
                    fname: res['data']['result']['personal_information']['fname'],
                    lname: res['data']['result']['personal_information']['lname'],
                    pid: res['data']['result']['personal_information']['pid'],
                    phone_number: res['data']['result']['personal_information']['phone_number'],
                    require_medicine_date: this.convertDate(res['data']['result']['require_medicine_date']),
                    ncdsList: [
                        res['data']['result']['appointment_list']['day7'],
                        res['data']['result']['appointment_list']['day6'],
                        res['data']['result']['appointment_list']['day5'],
                        res['data']['result']['appointment_list']['day4'],
                        res['data']['result']['appointment_list']['day3'],
                        res['data']['result']['appointment_list']['day2'],
                        res['data']['result']['appointment_list']['day1'],
                    ],
                    address: res['data']['result']['user_check']['address'],
                    addressName: res['data']['result']['address']['address_detail'],
                    subDistrictName: res['data']['result']['address']['sub_district_name'],
                    districtName: res['data']['result']['address']['district_name'],
                    provinceName: res['data']['result']['address']['province_name'],
                    postCode: res['data']['result']['address']['post_code'],
                    day7: res['data']['result']['user_check']['day7'],
                    send_medicine: res['data']['result']['user_check']['send_medicine'],
                    received_medicine: res['data']['result']['user_check']['received_medicine'],
                    prescribe_medicine: res['data']['result']['user_check']['prescribe_medicine']

                })
            } else {
                alert(res['data']['message_th'] || res['data']['message'])
            }
        } else {
            alert('ไม่ตอบสนอง')
        }
    }
    async approvedPatientMidicine(id) {
        this.setState({ loadingSpin: true })
        let res = await approvedPatientMidicine(id)
        this.setState({ loadingSpin: false })
        if (res) {
            if (res['data']['code'] === 200) {
                alert('ยืนยันสำเร็จ');
                this.closeModal()
                this.getPatinetHomeMedicine(this.state.id)

            } else {
                alert(res['data']['message_th'] || res['data']['message'])
            }
        } else {
            alert('ไม่ตอบสนอง')
        }
    }



    async submitSendMedicineButton() {

        this.setState({ loadingSpin: true })
        let res = await approvedPatientMedicineByNux(this.state.id, this.state.takeMedicine)
        this.setState({ loadingSpin: false })

        if (res) {
            if (res['data']['code'] == 200) {
                alert('สำเร็จ')
                this.setState({
                    showModalSendMedicine: false
                })
                this.getPatinetHomeMedicine(this.state.id)
            } else {
                alert(res['data']['message'])
            }
        } else {
            alert('ไม่ตอบสนอง')
        }
    }


    convertDate(date) {
        let y = date.split('-')[0]
        let m = date.split('-')[1]
        let d = date.split('-')[2]
        let _m = ''
        this.monthList.forEach(item => {
            if (item['month_id'] === m) {
                _m = item['month_th']

            }
        });
        return d + ' ' + _m + ' ' + (Number(y) + 543)
    }



    openModal(type) {
        if (type == 'send_medicine') {
            this.setState({
                showModalSendMedicine: true
            })
        } else if (type == 'prescribe_medicine') {
            this.setState({
                isShowModal: true
            })
        }
    }

    closeModal() {
        this.setState({
            isShowModal: false
        })
    }

    checkNull(data) {
        return data ? data : '-'
    }

    checkPatientType(type) {
        if (type === 'normal') {
            return 'ปกติ'
        } else if (type === 'sick') {
            return 'กลุ่มป่วย'
        } else {
            return '-'
        }
    }

    render() {
        return (
            <Spin className="example" tip="Loading..." spinning={this.state.loadingSpin}>
                <div className={styles.content}>

                    <div className={styles.backButton}>
                        <LeftOutlined onClick={() => { this.props.backToMedicineList('medicine_list') }} />
                    </div>

                    <div className={styles.title1}>Timeline การบันทึกค่าความดันและค่าน้ำตาลในเลือด</div>
                    <div className={styles.title2}>7 วันก่อนนัดหมายรับยา</div>

                    <div className={styles.flexCenter} style={{ marginTop: 20, gridGap: 30 }}>
                        <div className={styles.informationCard}>

                            <img className={styles.profileImage} src={require('../icon/profile-icon.svg').default}></img>
                            <div className={styles.textContainer}>
                                <div className={styles.value}>{this.state.pname} {this.state.fname} {this.state.lname}</div>
                                <div style={{ display: 'flex', alignItems: 'center', gridGap: 16 }}>
                                    <div style={{ flex: 1 }}>
                                        <div className={styles.key}>เลขประจำตัวประชาชน</div>
                                        <div className={styles.value}>{this.state.pid}</div>
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <div className={styles.key}>เบอร์โทร</div>
                                        <div className={styles.value}>{this.state.phone_number}</div>
                                    </div>
                                </div>
                                <div className={styles.key}>วันที่นัดหมาย</div>
                                <div className={styles.value}>{this.state.require_medicine_date}</div>
                            </div>
                            <div className={styles.informationLine}></div>
                            <div className={styles.userCheckCard}>
                                <div className={styles.userCheck}>
                                    {(this.state.address) ? <img src={require('../icon/status_green.svg').default}></img> : <img src={require('../icon/status_red.svg').default}></img>}
                                    <div className={styles.text}>ยืนยันที่อยู่จัดส่งยา</div>
                                </div>
                                <div className={styles.userCheck}>
                                    {(this.state.day7) ? <img src={require('../icon/status_green.svg').default}></img> : <img src={require('../icon/status_red.svg').default}></img>}
                                    <div className={styles.text}>วัดค่าสัญญาณชีพครบ 7 วันก่อนนัดหมาย</div>
                                </div>
                                <div className={styles.userCheck}>
                                    {(this.state.send_medicine) ? <img src={require('../icon/status_green.svg').default}></img> : <img src={require('../icon/status_red.svg').default}></img>}
                                    <div className={styles.text}>ยืนยันการจัดส่งยาแล้ว</div>
                                </div>
                                <div className={styles.userCheck}>
                                    {(this.state.received_medicine) ? <img src={require('../icon/status_green.svg').default}></img> : <img src={require('../icon/status_red.svg').default}></img>}
                                    <div className={styles.text}>ยืนยันการรับยาแล้ว</div>
                                </div>
                            </div>


                        </div>

                    </div>

                    <div style={{
                        marginTop: 20,
                        display: 'flex',
                        alignItems: 'center',
                        gridGap: 20
                    }}>
                        <div className={styles.refillCard}>
                            <div className={styles.text}>1. ยืนยันสั่งยา</div>
                            <div className={`${styles.button} ${this.state.prescribe_medicine ? styles.buttonSuccess : ''}`} onClick={() => {
                                if (!this.state.prescribe_medicine) {
                                    this.openModal('prescribe_medicine')
                                }
                            }}>
                                {this.state.prescribe_medicine ? 'สั่งจ่ายยาแล้ว' : 'จัดการ'}
                            </div>
                        </div>
                        <div className={styles.refillCard}>
                            <div className={styles.text}>2. ยืนยันจัดส่งยา</div>
                            <div className={`${styles.button} ${this.state.send_medicine ? styles.buttonSuccess : ''}`} onClick={() => {
                                if (!this.state.send_medicine) {
                                    this.openModal('send_medicine')
                                }
                            }}>
                                {this.state.send_medicine ? 'จัดส่งยาแล้ว' : 'จัดการ'}
                            </div>
                        </div>
                        <div className={styles.refillCard}>
                            <div className={styles.text}>ติดต่อผู้ป่วย</div>
                            <div className={`${styles.button}`} onClick={() => {
                                // window.open('https://h4u-mhealth.moph.go.th/h4u-video-call/#/nux-dashboard-to-h4u?token=' + localStorage.getItem('Authorization') + '&pid=' + this.state.pid, '_system')
                                this.conferrenceButtonClick(this.state.pid)
                            }}>
                                โทร
                            </div>
                        </div>

                    </div>

                    <div className={styles.titleContainer}>
                        <img className={styles.icon} src={require('../icon/paper-icon.svg').default}></img>
                        <div className={styles.text}>ประวัติการบันทึกสัญญาณชีพ 7 วันก่อนการนัดหมาย</div>
                    </div>

                    <div className={styles.historyContent}>
                        <div className={styles.historyScroll}>
                            {
                                this.state.ncdsList.map((item, index) => {
                                    // console.log(item)
                                    return <>
                                        <div className={styles.ncdsCard}>
                                            <div className={styles.date}>{this.convertDate(item['date'])} (วันที่ {7 - index})</div>

                                            <div className={styles.typeContainer}>
                                                <div className={styles.type}>
                                                    <div className={styles.text}>ความดันโลหิต</div>
                                                    <div className={styles.underLine}></div>
                                                </div>
                                                <div className={styles.patientType}>{this.checkPatientType(this.checkNull(item['blood_pressure']['patient_type']))}</div>
                                            </div>

                                            <div style={{ marginTop: 10, display: 'grid', gridGap: 5 }}>
                                                <div className={styles.valueContainer}>
                                                    <div className={styles.key}>SYS</div>
                                                    <div className={styles.value}>{this.checkNull(item['blood_pressure']['systolic'])}</div>
                                                    <div className={styles.unit}>mmHg</div>
                                                </div>
                                                <div className={styles.line}></div>
                                                <div className={styles.valueContainer}>
                                                    <div className={styles.key}>DIA</div>
                                                    <div className={styles.value}>{this.checkNull(item['blood_pressure']['diastolic'])}</div>
                                                    <div className={styles.unit}>mmHg</div>
                                                </div>
                                                <div className={styles.line}></div>
                                                <div className={styles.valueContainer}>
                                                    <div className={styles.key}>PULSE</div>
                                                    <div className={styles.value}>{this.checkNull(item['blood_pressure']['pulse_rate'])}</div>
                                                    <div className={styles.unit}>/min</div>
                                                </div>
                                                <div className={styles.line}></div>
                                            </div>


                                            {/* <div className={styles.result}>สูงมาก</div> */}

                                            <div className={styles.typeContainer}>
                                                <div className={styles.type}>
                                                    <div className={styles.text}>น้ำตาลในเลือด</div>
                                                    <div className={styles.underLine}></div>
                                                </div>

                                                <div className={styles.patientType}>{this.checkPatientType(this.checkNull(item['blood_sugar']['patient_type']))}</div>
                                            </div>

                                            <div style={{ marginTop: 10, display: 'grid', gridGap: 5 }}>
                                                <div className={styles.valueBsContainer}>
                                                    <div className={styles.key}></div>
                                                    <div className={styles.value}>{this.checkNull(item['blood_sugar']['blood_sugar'])}</div>
                                                    <div className={styles.unit}>mm/dL</div>
                                                </div>

                                                {/* <div className={styles.result}>สูงมาก</div>  */}
                                            </div>

                                        </div>
                                    </>
                                })
                            }
                        </div>
                    </div>
                    {
                        (this.state.isShowModal) ? <>
                            <div className={styles.modalPage}>

                            </div>
                            <div className={styles.modalCard}>
                                <div className={styles.modalCardTitle}>ยืนยันการรับยาที่บ้าน</div>

                                <div className={styles.line}></div>
                                <div className={styles.patientInformation}>ข้อมูลผู้ป่วย</div>
                                <div className={styles.cardContainer}>
                                    <div className={styles.dataUser}>
                                        <div className={styles.keyData}>ชื่อ</div>
                                        <div className={styles.valueData}>{this.state.pname} {this.state.fname} {this.state.lname}</div>
                                    </div>
                                    <div className={styles.dataUser}>
                                        <div className={styles.keyData}>เลขประจำตัวประชาชน</div>
                                        <div className={styles.valueData}>{this.state.pid}</div>
                                    </div>
                                    <div className={styles.dataUser}>
                                        <div className={styles.keyData}>เบอร์โทร</div>
                                        <div className={styles.valueData}>{this.state.phone_number}</div>
                                    </div>
                                    <div className={styles.dataUser}>
                                        <div className={styles.keyData}>วันที่นัดหมาย</div>
                                        <div className={styles.valueData}>{this.state.require_medicine_date}</div>
                                    </div>
                                </div>
                                <div className={styles.line}></div>
                                <div className={styles.buttonSubmit}>
                                    <div className={styles.buttonSubmitDetail} onClick={() => { this.approvedPatientMidicine(this.state.id) }}>ยืนยัน</div>
                                </div>
                                <div className={styles.Modalclose} onClick={() => { this.closeModal() }}>ปิดหน้านี้</div>
                            </div></> : null}

                    {
                        this.state.showModalSendMedicine ?

                            <div className={styles.modalSendComponent}>
                                <div className={styles.modalSendCard}>
                                    <div className={styles.modalTitle}>ยืนยันจัดส่งยา</div>
                                    <div className={styles.line}></div>
                                    <div
                                        style={{
                                            padding: 20,
                                            paddingTop: 0
                                        }}
                                    >

                                        <div style={{
                                            fontWeight: 500,
                                            fontSize: 16,
                                            color: '#666666'
                                        }}>เลือกรูปแบบจัดส่งยา</div>

                                        <div className={styles.selectBox} >
                                            <div className={styles.headBox} onClick={() => { this.setState({ takeMedicine: 'sub_district_health' }) }}>
                                                <div className={styles.circleCheck}>
                                                    {this.state.takeMedicine == 'sub_district_health' ? <img src={require('../icon/check-icon.svg').default}></img> : null}

                                                </div>
                                                <div className={styles.selectText}>แจ้งผู้ป่วยรับยาที่ รพช. / รพ.สต.</div>
                                            </div>
                                        </div>

                                        <div className={styles.selectBox}>
                                            <div className={styles.headBox} onClick={() => { this.setState({ takeMedicine: 'post' }) }}>
                                                <div className={styles.circleCheck}>
                                                    {this.state.takeMedicine == 'post' ? <img src={require('../icon/check-icon.svg').default}></img> : null}
                                                </div>
                                                <div className={styles.selectText}>จัดส่งทางไปรษณีย์</div>
                                            </div>
                                            <div className={`${styles.contentBox} ${this.state.takeMedicine == 'post' ? styles.contentBoxActive : ''}`}>
                                                <div className={styles.contentKey}>ที่อยู่ :</div>
                                                <div className={styles.contentValue}>
                                                    <div>{this.state.addressName}</div>
                                                    <div>{this.state.subDistrictName}</div>
                                                    <div>{this.state.districtName}</div>
                                                    <div>{this.state.provinceName}</div>
                                                    <div>{this.state.postCode}</div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className={styles.modalSubmitButton} onClick={() => { this.submitSendMedicineButton() }}>ยืนยัน</div>
                                        <div className={styles.modalCloseButton} onClick={() => { this.setState({ showModalSendMedicine: false }) }}>ปิดหน้านี้</div>

                                    </div>
                                </div>
                            </div>

                            : null

                    }

                </div>
            </Spin>
        )
    }
}
