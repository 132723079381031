import React from 'react';
import { withRouter } from "react-router-dom";
import '../Home/App.css';
import {
    API_GetDataByHospital_ByParam, API_GetFiscal_Year, API_GetSumUserTotal, API_GetHospitalAll,
    Get_chart, Get_chartSick, Get_chartNormal, API_GetDataByType, Export_Excel, API_GetUserData,
    API_GetPeople_screenedAreaAll, ColumnsTable_adress, API_GetDataByHospital, API_GetDataHealthRecord,
    Colums_Hospital_waist, Colums_Hospital_ALL, Colums_Hospital_BMI, Colums_Hospital_blood_pressure, Colums_Hospital_blood_sugar
} from '../untils/function';
import { Form, Row, Col, Select, Layout, Menu, Table, Spin } from 'antd';

const { Option } = Select;
const { Footer, Sider, Content } = Layout;


class PCC extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            //page Graph & Table
            SetvaluePage: 'Graph',
            SumUserTotal: '',
            DataGraph_Type: 'all',

            Set_ID_hospital: '',
            SetValueColor_description: 'bmi',

            SetValueBy_health_area: undefined,
            SetValueBy_province: undefined,
            SetValueBy_district: undefined,
            SetValueBy_sub_district: undefined,
            SetValueBy_hospital: undefined,

            //year month day
            fiscal_year_name: '',
            date_type: 'by_year',
            SetValueBy_year: undefined,
            SetValueBy_quarter: undefined,
            SetValueBy_month: undefined,

            loadingSpin: false,
            Check_Authorization: '',

            DataTable: [],
            DataValue_address: '',
            DataColumns_adress: '',
            DataColumnsTable_BMI: '',
            DataColumnsTable_blood_pressure: '',
            DataColumnsTable_blood_sugar: '',
            DataColumnsTable_waist: '',
            DataTable_Number_records: 'ตารางแสดงจำนวนการคัดกรอง NCDs',
            SetData_uninfecte_sick_group: '',
            value_group_fasting: 'not_fasting',
        }
    }

    componentDidMount() {
        // console.log(this.props.match.params.Authorization);

        API_GetUserData(this.props.match.params.Authorization).then((res) => {
            if (res["code"] === 200) {
                this.setState({ Check_Authorization: true })
            } else {
                this.setState({ Check_Authorization: false })
            }
        })

        API_GetDataByHospital_ByParam(this.props.match.params.Authorization).then((response) => {
            // console.log(response);
            this.setState({
                loadingSpin: false,
                Set_ID_hospital: response['hospital_id'],
                SetValueBy_health_area: response['area_health'],
                SetValueBy_province: response['province_name'],
                SetValueBy_district: response['district_name'],
                SetValueBy_sub_district: response['sub_district_name'],
                SetValueBy_hospital: response['hospital_name'],
            })

            API_GetSumUserTotal('by_hospital', response['hospital_id']).then((response_SumUserTotal) => {
                this.setState({ SumUserTotal: response_SumUserTotal })
            })

            API_GetHospitalAll(response['hospital_id'], 'by_year').then((response_Hospital) => {
                Get_chart(response_Hospital)
            })
        })

        API_GetFiscal_Year().then((response) => {
            const fiscal_year_name = []
            for (let index = 0; index < response.length; index++) {
                fiscal_year_name.push(<Option key={index + 1} value={response[index].year}>{response[index].year}</Option>)
            }
            this.setState({ fiscal_year_name: fiscal_year_name })
        })
    }

    onclick_TabPage = (e) => {
        this.setState({ SetvaluePage: e }, () => {
            this.setState({
                loadingSpin: true,
                date_type: 'by_year',
                DataGraph_Type: 'all',
                value_group_fasting: 'not_fasting',
                SetValueBy_year: undefined,
                SetValueBy_quarter: undefined,
                SetValueBy_month: undefined,
            })

            if (this.state.SetvaluePage === 'Graph') {
                API_GetSumUserTotal('by_hospital', this.state.Set_ID_hospital).then((response) => {
                    this.setState({ SumUserTotal: response })
                })

                API_GetHospitalAll(this.state.Set_ID_hospital, 'by_year').then((response) => {
                    this.setState({ loadingSpin: false })
                    Get_chart(response)
                })
            } else if (this.state.SetvaluePage === 'Table') {
                API_GetDataByHospital(this.state.Set_ID_hospital, 'by_year').then((res) => {
                    this.setState({
                        DataTable: res,
                        loadingSpin: false,
                        DataColumns_adress: Colums_Hospital_ALL,
                        DataTable_Number_records: 'ตารางแสดงจำนวนผู้ได้รับการคัดกรอง NCDs',
                    })
                })
            }
        })
    };

    handleChange = (type, value, select) => {
        this.setState({
            loadingSpin: true,
            DataGraph_Type: 'all',
            value_group_fasting: 'not_fasting',
        })
        if (type === 'by_year') {
            this.setState({
                date_type: 'by_year',
                SetValueBy_year: value,
                SetValueBy_quarter: undefined,
                SetValueBy_month: undefined,
            })
            if (this.state.SetvaluePage === 'Graph') {
                API_GetHospitalAll(this.state.Set_ID_hospital, 'by_year', value).then((response) => {
                    this.setState({ loadingSpin: false })
                    Get_chart(response)
                })
            } else if (this.state.SetvaluePage === 'Table') {
                API_GetDataByHospital(this.state.Set_ID_hospital, 'by_year', value).then((res) => {
                    this.setState({
                        DataTable: res,
                        loadingSpin: false,
                        DataColumns_adress: Colums_Hospital_ALL,
                        DataTable_Number_records: 'ตารางแสดงจำนวนผู้ได้รับการคัดกรอง NCDs',
                        DataTable_Type: 'all'
                    })
                })
            }
        } else if (type === 'by_quarter') {
            this.setState({ SetValueBy_quarter: value, SetValueBy_month: undefined, date_type: 'by_quarter' })

            if (this.state.SetvaluePage === 'Graph') {
                API_GetHospitalAll(this.state.Set_ID_hospital, 'by_quarter', this.state.SetValueBy_year, value).then((response) => {
                    this.setState({ loadingSpin: false })
                    Get_chart(response)
                })
            } else if (this.state.SetvaluePage === 'Table') {
                API_GetDataByHospital(this.state.Set_ID_hospital, 'by_quarter', this.state.SetValueBy_year, value).then((res) => {
                    this.setState({
                        DataTable: res,
                        loadingSpin: false,
                        DataColumns_adress: Colums_Hospital_ALL,
                        DataTable_Number_records: 'ตารางแสดงจำนวนผู้ได้รับการคัดกรอง NCDs',
                        DataTable_Type: 'all'
                    })
                })
            }
        } else if (type === 'by_month') {
            this.setState({ SetValueBy_month: value, date_type: 'by_month' })
            if (this.state.SetvaluePage === 'Graph') {
                API_GetHospitalAll(this.state.Set_ID_hospital, 'by_month', this.state.SetValueBy_year, this.state.SetValueBy_quarter, value).then((response) => {
                    this.setState({ loadingSpin: false })
                    Get_chart(response)
                })
            } else if (this.state.SetvaluePage === 'Table') {
                API_GetDataByHospital(this.state.Set_ID_hospital, 'by_month', this.state.SetValueBy_year, this.state.SetValueBy_quarter, value).then((res) => {
                    this.setState({
                        DataTable: res,
                        loadingSpin: false,
                        DataColumns_adress: Colums_Hospital_ALL,
                        DataTable_Number_records: 'ตารางแสดงจำนวนผู้ได้รับการคัดกรอง NCDs',
                        DataTable_Type: 'all'
                    })
                })
            }
        }
    }

    handleChange_Type = (e) => {
        this.setState({
            loadingSpin: true,
            DataGraph_Type: 'all',
            SetData_uninfecte_sick_group: '',
            value_group_fasting: 'not_fasting'
        })
        var check_DataValue_address = this.state.DataValue_address
        var year = this.state.SetValueBy_year === undefined ? '' : Number(this.state.SetValueBy_year)
        var quarter = this.state.SetValueBy_quarter === undefined ? '' : Number(this.state.SetValueBy_quarter)
        var month = this.state.SetValueBy_month === undefined ? '' : Number(this.state.SetValueBy_month)

        if (e === 'all') {
            if (this.state.SetvaluePage === 'Graph') {
                API_GetHospitalAll(check_DataValue_address, this.state.date_type, year, quarter, month).then((response) => {
                    this.setState({ loadingSpin: false, DataGraph_Type: e })
                    Get_chart(response)
                })
            } else if (this.state.SetvaluePage === 'Table') {
                API_GetDataByHospital(this.state.Set_ID_hospital, this.state.date_type, year, quarter, month).then((res) => {
                    this.setState({
                        DataTable: res,
                        loadingSpin: false,
                        DataColumns_adress: Colums_Hospital_ALL,
                        DataTable_Number_records: 'ตารางแสดงจำนวนผู้ได้รับการคัดกรอง NCDs',
                        DataGraph_Type: e
                    })
                })
            }
        } else if (e === 'bmi') {
            if (this.state.SetvaluePage === 'Graph') {
                API_GetDataByType({ by_hospital: check_DataValue_address, type: e, date_type: this.state.date_type, year: year, quarter: quarter, month: month }).then((res) => {
                    this.setState({
                        loadingSpin: false,
                        DataGraph_Type: e
                    })
                    Get_chart(res)
                })
            } else if (this.state.SetvaluePage === 'Table') {
                API_GetDataHealthRecord(this.state.Set_ID_hospital, e, '', '', this.state.date_type, year, quarter, month).then((res) => {
                    this.setState({
                        loadingSpin: false,
                        DataTable: res,
                        DataTable_Number_records: 'ตารางแสดงรายงานดัชนีมวลกาย',
                        DataGraph_Type: e,
                        DataColumnsTable_BMI: Colums_Hospital_BMI
                    })
                })
            }
        } else if (e === 'blood_sugar') {
            if (this.state.SetvaluePage === 'Graph') {
                API_GetDataByType({ by_hospital: check_DataValue_address, type: e, date_type: this.state.date_type, year: year, quarter: quarter, month: month, platform_type: "normal", time: "0" }).then((res) => {
                    this.setState({
                        loadingSpin: false,
                        DataGraph_Type: e
                    })
                    Get_chartNormal(res)
                })
                API_GetDataByType({ by_hospital: check_DataValue_address, type: e, date_type: this.state.date_type, year: year, quarter: quarter, month: month, platform_type: "sick", time: "2" }).then((res) => {
                    this.setState({
                        loadingSpin: false,
                        DataGraph_Type: e
                    })
                    Get_chartSick(res)
                })
            } else if (this.state.SetvaluePage === 'Table') {
                API_GetDataHealthRecord(this.state.Set_ID_hospital, e, 'normal', '0', this.state.date_type, year, quarter, month).then((res) => {
                    this.setState({
                        loadingSpin: false,
                        DataTable: res,
                        DataTable_Number_records: 'ตารางแสดงรายงานค่าน้ำตาลในเลือด',
                        DataGraph_Type: e,
                        DataColumnsTable_blood_sugar: Colums_Hospital_blood_sugar
                    })
                })
            }
        } else if (e === 'blood_pressure') {
            if (this.state.SetvaluePage === 'Graph') {
                API_GetDataByType({ by_hospital: check_DataValue_address, type: e, date_type: this.state.date_type, year: year, quarter: quarter, month: month, platform_type: "normal" }).then((res) => {
                    this.setState({
                        loadingSpin: false,
                        DataGraph_Type: e
                    })
                    Get_chartNormal(res)
                })
                API_GetDataByType({ by_hospital: check_DataValue_address, type: e, date_type: this.state.date_type, year: year, quarter: quarter, month: month, platform_type: "sick" }).then((res) => {
                    this.setState({
                        loadingSpin: false,
                        DataGraph_Type: e
                    })
                    Get_chartSick(res)
                })
            } else if (this.state.SetvaluePage === 'Table') {
                API_GetDataHealthRecord(this.state.Set_ID_hospital, e, 'normal', '', this.state.date_type, year, quarter, month).then((res) => {
                    this.setState({
                        loadingSpin: false,
                        DataTable: res,
                        DataTable_Number_records: 'ตารางแสดงรายงานค่าความดันโลหิต',
                        DataGraph_Type: e,
                        DataColumnsTable_blood_pressure: Colums_Hospital_blood_pressure
                    })
                })
            }
        } else if (e === 'waist_circumference') {
            if (this.state.SetvaluePage === 'Graph') {
                API_GetDataByType({ by_hospital: check_DataValue_address, type: e, date_type: this.state.date_type, year: year, quarter: quarter, month: month }).then((res) => {
                    console.log(res);
                    this.setState({
                        loadingSpin: false,
                        DataGraph_Type: e
                    })
                    Get_chart(res)
                })
            } else if (this.state.SetvaluePage === 'Table') {
                API_GetDataHealthRecord(this.state.Set_ID_hospital, e, '', '', this.state.date_type, year, quarter, month).then((res) => {
                    this.setState({
                        loadingSpin: false,
                        DataTable: res,
                        DataTable_Number_records: 'ตารางแสดงรายงานค่ารอบเอว',
                        DataGraph_Type: e,
                        DataColumnsTable_waist: Colums_Hospital_waist
                    })
                })
            }
        }
    }

    selectType_fasting = (type, vlaue) => {
        var year = this.state.SetValueBy_year === undefined ? '' : Number(this.state.SetValueBy_year)
        var quarter = this.state.SetValueBy_quarter === undefined ? '' : Number(this.state.SetValueBy_quarter)
        var month = this.state.SetValueBy_month === undefined ? '' : Number(this.state.SetValueBy_month)

        if (type === 'normal') {
            if (vlaue === 'not_fasting') {
                API_GetDataByType({ hospital_id: this.state.DataValue_address, type: 'blood_sugar', platform_type: "normal", time: "0", date_type: this.state.date_type, year: year, quarter: quarter, month: month }).then((res) => {
                    this.setState({ loadingSpin: false })
                    Get_chartNormal(res)
                })
            } else if (vlaue === 'fasting') {
                API_GetDataByType({ hospital_id: this.state.DataValue_address, type: 'blood_sugar', platform_type: "normal", time: "8", date_type: this.state.date_type, year: year, quarter: quarter, month: month }).then((res) => {
                    this.setState({ loadingSpin: false })
                    Get_chartNormal(res)
                })
            }
        } else if (type === 'sick') {
            if (vlaue === 'not_fasting') {
                API_GetDataByType({ hospital_id: this.state.DataValue_address, type: 'blood_sugar', platform_type: "sick", time: "2", date_type: this.state.date_type, year: year, quarter: quarter, month: month }).then((res) => {
                    this.setState({ loadingSpin: false })
                    Get_chartSick(res)
                })
            } else if (vlaue === 'fasting') {
                API_GetDataByType({ hospital_id: this.state.DataValue_address, type: 'blood_sugar', platform_type: "sick", time: "8", date_type: this.state.date_type, year: year, quarter: quarter, month: month }).then((res) => {
                    this.setState({ loadingSpin: false })
                    Get_chartSick(res)
                })
            }
        }
    }

    downloadFile = (filePath) => {
        var link = document.createElement('a');
        link.href = filePath;
        link.download = filePath.substr(filePath.lastIndexOf('/') + 1);
        link.click();
    }

    DownloadExcel = () => {
        this.setState({ loadingSpin: true })
        var year = this.state.SetValueBy_year
        var quarter = this.state.SetValueBy_quarter
        var month = this.state.SetValueBy_month

        Export_Excel(this.state.Set_ID_hospital, this.state.date_type, year, quarter, month).then((response) => {
            this.downloadFile(response);
            this.setState({ loadingSpin: false })
        })
    }

    Color_value_description = (value) => {
        this.setState({ SetValueColor_description: value })
    }

    uninfecte_sick_group = (e) => {
        // console.log(e.target.innerText);
        this.setState({ value_group_fasting: 'not_fasting', loadingSpin: true })
        let SetData_uninfecte_sick_group = ''
        var year = this.state.SetValueBy_year === undefined ? '0' : this.state.SetValueBy_year
        var quarter = this.state.SetValueBy_quarter === undefined ? '0' : this.state.SetValueBy_quarter
        var month = this.state.SetValueBy_month === undefined ? '0' : this.state.SetValueBy_month

        if (e.target.innerText === 'กลุ่มป่วย') {
            SetData_uninfecte_sick_group = 'active'
            if (this.state.DataGraph_Type === 'blood_sugar') {
                API_GetDataHealthRecord(this.state.Set_ID_hospital, this.state.DataGraph_Type, 'sick', '2', this.state.date_type, year, quarter, month).then((res) => {
                    this.setState({ DataTable: res, loadingSpin: false })
                })
            } else if (this.state.DataGraph_Type === 'blood_pressure') {
                API_GetDataHealthRecord(this.state.Set_ID_hospital, this.state.DataGraph_Type, 'sick', '', this.state.date_type, year, quarter, month).then((res) => {
                    this.setState({ DataTable: res, loadingSpin: false })
                })
            }
        } else if (e.target.innerText === 'กลุ่มที่ยังไม่ป่วย') {
            SetData_uninfecte_sick_group = ''
            this.setState({ DataColumnsTable_blood_pressure: '' })
            if (this.state.DataGraph_Type === 'blood_sugar') {
                API_GetDataHealthRecord(this.state.Set_ID_hospital, this.state.DataGraph_Type, 'normal', '0', this.state.date_type, year, quarter, month).then((res) => {
                    this.setState({ DataTable: res, loadingSpin: false })
                })
            } else if (this.state.DataGraph_Type === 'blood_pressure') {
                API_GetDataHealthRecord(this.state.Set_ID_hospital, this.state.DataGraph_Type, 'normal', '', this.state.date_type, year, quarter, month).then((res) => {
                    this.setState({
                        loadingSpin: false,
                        DataTable: res,
                        DataTable_Number_records: 'ตารางแสดงรายงานค่าความดันโลหิต',
                        DataGraph_Type: this.state.DataGraph_Type,
                        DataColumnsTable_blood_pressure: Colums_Hospital_blood_pressure
                    })
                })
            }
        }
        this.setState({ SetData_uninfecte_sick_group: SetData_uninfecte_sick_group })
    }

    uninfecte_sick_group_fasting = (e) => {
        // console.log(e);
        this.setState({ value_group_fasting: e, loadingSpin: true })
        var year = this.state.SetValueBy_year === undefined ? '0' : this.state.SetValueBy_year
        var quarter = this.state.SetValueBy_quarter === undefined ? '0' : this.state.SetValueBy_quarter
        var month = this.state.SetValueBy_month === undefined ? '0' : this.state.SetValueBy_month

        if (this.state.SetData_uninfecte_sick_group === 'active') {
            if (e === 'not_fasting') {
                API_GetDataHealthRecord(this.state.Set_ID_hospital, this.state.DataGraph_Type, 'sick', '2', this.state.date_type, year, quarter, month).then((res) => {
                    this.setState({ DataTable: res, loadingSpin: false })
                })
            } else if (e === 'fasting') {
                API_GetDataHealthRecord(this.state.Set_ID_hospital, this.state.DataGraph_Type, 'sick', '8', this.state.date_type, year, quarter, month).then((res) => {
                    this.setState({ DataTable: res, loadingSpin: false })
                })
            }
        } else {
            if (e === 'not_fasting') {
                API_GetDataHealthRecord(this.state.Set_ID_hospital, this.state.DataGraph_Type, 'normal', '0', this.state.date_type, year, quarter, month).then((res) => {
                    this.setState({ DataTable: res, loadingSpin: false })
                })
            } else if (e === 'fasting') {
                API_GetDataHealthRecord(this.state.Set_ID_hospital, this.state.DataGraph_Type, 'normal', '8', this.state.date_type, year, quarter, month).then((res) => {
                    this.setState({ DataTable: res, loadingSpin: false })
                })
            }
        }
    }

    pcc() {
        return (
            <Spin className="example" tip="Loading..." spinning={this.state.loadingSpin}>
                <Layout>
                    <Sider className="sider-menu-item"
                        breakpoint="lg"
                        collapsedWidth="0">
                        <Menu mode="inline" defaultSelectedKeys={['1']} style={{ marginTop: '30px' }}>
                            <Menu.Item key="1">สถิติการบันทึกข้อมูล health station</Menu.Item>
                        </Menu>
                    </Sider>
                    <Layout style={{ backgroundColor: 'white', minHeight: '100vh' }}>
                        <Content style={{ padding: '20px' }}>
                            <Row align="middle" className="Row-col-Header-content">
                                <Col sm={{ span: 9, offset: 0 }} xs={{ span: 24, offset: 0 }} coll="true" className="icon-hospital-svg">
                                </Col>
                                <Col sm={{ span: 3, offset: 4 }} xs={{ span: 9, offset: 1 }} coll="true" className="col-page-header">
                                    <div onClick={() => this.onclick_TabPage('Graph')}>
                                        <span>กราฟวงกลม</span>
                                        {this.state.SetvaluePage === 'Graph' ? <div className="button-page-onclick-on" /> : <></>}
                                    </div>
                                </Col>
                                <Col sm={{ span: 3, offset: 1 }} xs={{ span: 9, offset: 4 }} coll="true" className="col-page-header">
                                    <div onClick={() => this.onclick_TabPage('Table')}>
                                        <img src={require('../icon/table.svg').default} alt="table" />
                                        <span> ตารางรายชื่อ</span>
                                        {this.state.SetvaluePage === 'Table' ? <div className="button-page-onclick-on" /> : <></>}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={{ span: 20 }} xs={{ span: 24 }} coll="true">
                                    <div className="container-background-header">
                                        <div>เลือกข้อมูล</div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={{ span: 6 }} xs={{ span: 24 }} coll="true">
                                    <Form.Item>
                                        <Select
                                            value={this.state.SetValueBy_health_area}
                                            placeholder="เขตสุขภาพ"
                                        >
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col sm={{ span: 6, offset: 1 }} xs={{ span: 24 }} coll="true">
                                    <Form.Item>
                                        <Select
                                            value={this.state.SetValueBy_province}
                                            placeholder="จังหวัด"
                                        >
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col sm={{ span: 6, offset: 1 }} xs={{ span: 24 }} coll="true">
                                    <Form.Item>
                                        <Select
                                            value={this.state.SetValueBy_district}
                                            placeholder="อำเภอ"
                                        >
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={{ span: 6 }} xs={{ span: 24 }} coll="true">
                                    <Form.Item>
                                        <Select
                                            value={this.state.SetValueBy_sub_district}
                                            placeholder="ตำบล"
                                        >
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col sm={{ span: 6, offset: 1 }} xs={{ span: 24 }} coll="true">
                                    <Form.Item>
                                        <Select
                                            value={this.state.SetValueBy_hospital}
                                            placeholder="หน่วยบริการ"
                                        >
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={{ span: 20 }} xs={{ span: 24 }} coll="true">
                                    <div className="container-background-header">
                                        <span>เลือกช่วงเวลา</span>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={{ span: 6 }} xs={{ span: 24 }} coll="true">
                                    <Form.Item>
                                        <Select
                                            value={this.state.SetValueBy_year}
                                            placeholder="ปีงบประมาณ"
                                            onChange={(value, select) => this.handleChange('by_year', value, select)}
                                        >
                                            {this.state.fiscal_year_name}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col sm={{ span: 6, offset: 1 }} xs={{ span: 24 }} coll="true">
                                    <Form.Item>
                                        <Select
                                            disabled={this.state.SetValueBy_year === undefined ? true : false}
                                            value={this.state.SetValueBy_quarter}
                                            placeholder="ไตรมาส"
                                            onChange={(value, select) => this.handleChange('by_quarter', value, select)}
                                        >
                                            <Option key="1" value="1">1</Option>
                                            <Option key="2" value="2">2</Option>
                                            <Option key="3" value="3">3</Option>
                                            <Option key="4" value="4">4</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col sm={{ span: 6, offset: 1 }} xs={{ span: 24 }} coll="true">
                                    <Form.Item>
                                        <Select
                                            disabled={this.state.SetValueBy_quarter === undefined ? true : false}
                                            placeholder="เดือน"
                                            value={this.state.SetValueBy_month}
                                            onChange={(value, select) => this.handleChange('by_month', value, select)}
                                        >
                                            {this.state.SetValueBy_quarter === '2' ? <>
                                                <Option key="1" value="1">มกราคม</Option>
                                                <Option key="2" value="2">กุมภาพันธ์</Option>
                                                <Option key="3" value="3">มีนาคม</Option>
                                            </> : null}
                                            {this.state.SetValueBy_quarter === '3' ? <>
                                                <Option key="4" value="4">เมษายน</Option>
                                                <Option key="5" value="5">พฤษภาคม</Option>
                                                <Option key="6" value="6">มิถุนายน</Option>
                                            </> : null}
                                            {this.state.SetValueBy_quarter === '4' ? <>
                                                <Option key="7" value="7">กรกฎาคม</Option>
                                                <Option key="8" value="8">สิงหาคม</Option>
                                                <Option key="9" value="9">กันยายน</Option>
                                            </> : null}
                                            {this.state.SetValueBy_quarter === '1' ? <>
                                                <Option key="10" value="10">ตุลาคม</Option>
                                                <Option key="11" value="11">พฤศจิกายน</Option>
                                                <Option key="12" value="12">ธันวาคม</Option>
                                            </> : null}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={{ span: 20 }} xs={{ span: 24 }} coll="true">
                                    <div className="container-background-header">
                                        {this.state.SetvaluePage === 'Graph' ? <span>เลือกประเภทสัญญาณชีพ</span> : <span>เลือกกลุ่มรายงาน</span>}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={{ span: 6 }} xs={{ span: 24 }} coll="true">
                                    <Form.Item>
                                        <Select value={this.state.DataGraph_Type} placeholder="ประเภทค่าสัญญาณชีพ"
                                            onChange={(value) => this.handleChange_Type(value)}
                                        >
                                            <Option key="0" value="all">ทั้งหมด</Option>
                                            <Option key="1" value="blood_pressure">ค่าความดันโลหิต</Option>
                                            <Option key="2" value="blood_sugar">ค่าน้ำตาลในเลือด</Option>
                                            <Option key="3" value="bmi">ค่าดัชนีมวลกาย</Option>
                                            <Option key="4" value="waist_circumference">ค่ารอบเอว</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>

                            {this.state.SetvaluePage === 'Graph' ? <>
                                <Row>
                                    <Col sm={{ span: 6 }} xs={{ span: 24 }} coll="true">
                                        <div className="container-background-h4u-souvenirs">
                                            <span>จำนวนผู้ใช้งาน H4U ทั้งหมด <span style={{ color: 'red', fontWeight: 'bold' }}>{this.state.SumUserTotal}</span> คน</span>
                                        </div>
                                    </Col>
                                </Row>


                                {this.state.DataGraph_Type === 'all' ? <>
                                    <Row>
                                        <Col sm={{ span: 20 }} xs={{ span: 24 }} coll="true">
                                            <div><img src={require('../icon/Number_of_records.svg').default} alt="Number_of_records" />
                                                <span style={{ color: '#5C2D91', paddingLeft: '15px' }}>จำนวนที่บันทึกเข้ามา</span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row align="middle">
                                        <Col sm={{ span: 20 }} xs={{ span: 24 }} coll="true">
                                            <div id="chartdiv" className="chartdiv-width-height" />
                                        </Col>
                                    </Row>
                                </> : null}
                                {this.state.DataGraph_Type === 'blood_pressure' ? <>
                                    <Row>
                                        <Col sm={{ span: 20 }} xs={{ span: 24 }} coll="true">
                                            <div><img src={require('../icon/health_record_total.svg').default} alt="health_record_total" />
                                                <span style={{ color: '#5C2D91', paddingLeft: '15px' }}>จำนวนการบันทึกค่าความดันโลหิตแยกตามความเสี่ยง</span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={{ span: 19, offset: 1 }} xs={{ span: 24 }} coll="true" style={{ marginTop: '20px' }}>
                                            <span style={{ color: '#5C2D91' }}>กลุ่มคนที่ยังไม่ป่วยเป็นโรคความดันโลหิต</span>
                                        </Col>
                                    </Row>
                                    <Row align="middle">
                                        <Col sm={{ span: 20 }} xs={{ span: 24 }} coll="true">
                                            <div id="chart_normal" className="chartdiv-width-height" />
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row>
                                        <Col sm={{ span: 19, offset: 1 }} xs={{ span: 24 }} coll="true" style={{ marginTop: '20px' }}>
                                            <span style={{ color: '#5C2D91' }}>กลุ่มคนที่ป่วยเป็นโรคความดันโลหิต</span>
                                        </Col>
                                    </Row>
                                    <Row align="middle">
                                        <Col sm={{ span: 20 }} xs={{ span: 24 }} coll="true">
                                            <div id="chart_sick" className="chartdiv-width-height" />
                                        </Col>
                                    </Row>
                                </> : null}
                                {this.state.DataGraph_Type === 'blood_sugar' ? <>
                                    <Row>
                                        <Col sm={{ span: 20 }} xs={{ span: 24 }} coll="true">
                                            <div><img src={require('../icon/blood_sugar_total.svg').default} alt="blood_sugar_total" />
                                                <span style={{ color: '#5C2D91', paddingLeft: '15px' }}>จำนวนการบันทึกค่าน้ำตาลในเลือดแยกตามความเสี่ยง</span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={{ span: 19, offset: 1 }} xs={{ span: 24 }} coll="true" style={{ marginTop: '20px' }}>
                                            <span style={{ color: '#5C2D91' }}>กลุ่มคนยังไม่ป่วย</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={{ span: 19, offset: 1 }} xs={{ span: 24 }} coll="true" style={{ marginTop: '20px' }}>
                                            <div style={{ display: 'flex' }}>
                                                <span style={{ paddingRight: '20px', color: '#5C2D91' }}>เลือกประเภท</span>
                                                <Form.Item style={{ width: '220px' }}>
                                                    <Select defaultValue="not_fasting" placeholder="เลือกกลุ่มตาราง" onChange={(e) => { this.selectType_fasting('normal', e) }}>
                                                        <Option value="not_fasting">ไม่งดอาหาร</Option>
                                                        <Option value="fasting">งดอาหารอย่างน้อย 8 ชั่วโมง</Option>
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row align="middle">
                                        <Col sm={{ span: 20 }} xs={{ span: 24 }} coll="true">
                                            <div id="chart_normal" className="chartdiv-width-height" />
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row>
                                        <Col sm={{ span: 19, offset: 1 }} xs={{ span: 24 }} coll="true" style={{ marginTop: '20px' }}>
                                            <span style={{ color: '#5C2D91' }}>กลุ่มคนป่วย</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={{ span: 19, offset: 1 }} xs={{ span: 24 }} coll="true" style={{ marginTop: '20px' }}>
                                            <div style={{ display: 'flex' }}>
                                                <span style={{ paddingRight: '20px', color: '#5C2D91' }}>เลือกประเภท</span>
                                                <Form.Item style={{ width: '220px' }}>
                                                    <Select defaultValue="not_fasting" placeholder="เลือกกลุ่มตาราง" onChange={(e) => { this.selectType_fasting('sick', e) }}>
                                                        <Option value="not_fasting">งดอาหารอย่างน้อย 2 ชั่วโมง</Option>
                                                        <Option value="fasting">งดอาหารอย่างน้อย 8 ชั่วโมง</Option>
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row align="middle">
                                        <Col sm={{ span: 20 }} xs={{ span: 24 }} coll="true">
                                            <div id="chart_sick" className="chartdiv-width-height" />
                                        </Col>
                                    </Row>
                                </> : null}
                                {this.state.DataGraph_Type === 'bmi' ? <>
                                    <Row>
                                        <Col sm={{ span: 20 }} xs={{ span: 24 }} coll="true">
                                            <div><img src={require('../icon/bmi.svg').default} alt="bmi" />
                                                <span style={{ color: '#5C2D91', paddingLeft: '15px' }}>จำนวนการบันทึกค่าดัชนีมวลกาย (BMI) แยกตามความเสี่ยง</span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row align="middle">
                                        <Col sm={{ span: 20 }} xs={{ span: 24 }} coll="true">
                                            <div id="chartdiv" className="chartdiv-width-height" />
                                        </Col>
                                    </Row>
                                </> : null}
                                {this.state.DataGraph_Type === 'waist_circumference' ? <>
                                    <Row>
                                        <Col sm={{ span: 20 }} xs={{ span: 24 }} coll="true">
                                            <div><img src={require('../icon/bmi.svg').default} alt="bmi" />
                                                <span style={{ color: '#5C2D91', paddingLeft: '15px' }}>จำนวนการบันทึกค่ารอบเอว</span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row align="middle">
                                        <Col sm={{ span: 20 }} xs={{ span: 24 }} coll="true">
                                            <div id="chartdiv" className="chartdiv-width-height" />
                                        </Col>
                                    </Row>
                                </> : null}
                            </> : null}

                            {this.state.SetvaluePage === 'Table' ? <>
                                <Row>
                                    <Col sm={{ span: 20 }} xs={{ span: 24 }} coll="true" style={{ marginBottom: '20px' }}>
                                        <div className="title-group-table">
                                            <div>
                                                <div style={{ color: '#5c2d91' }}>{this.state.DataTable_Number_records}</div>
                                                <div style={{ color: '#848484' }}>{this.state.SetValueBy_hospital}</div>
                                            </div>
                                            <div className="botton-Export-pdf-file" onClick={() => this.DownloadExcel()}>Export Excel</div>
                                        </div>
                                    </Col>
                                </Row>

                                {this.state.DataGraph_Type === 'all' ? <>
                                    <Row>
                                        <Col sm={{ span: 20 }} xs={{ span: 24 }} coll="true">
                                            <Table
                                                columns={this.state.DataColumns_adress}
                                                dataSource={this.state.DataTable} />
                                        </Col>
                                    </Row>
                                    <div style={{ color: '#5C2D91' }}>คำอธิบายค่าสี</div>
                                    <Row>
                                        <Col sm={{ span: 4 }} xs={{ span: 24 }} coll="true">
                                            <Form.Item>
                                                <Select
                                                    value={this.state.SetValueColor_description}
                                                    onChange={(value) => this.Color_value_description(value)}
                                                >
                                                    <Option key="1" value="bmi">ค่าดัชนีมวลกาย</Option>
                                                    <Option key="2" value="blood_pressure">ค่าความดันโลหิต</Option>
                                                    <Option key="3" value="blood_sugar">ค่าน้ำตาลในเลือด</Option>
                                                    <Option key="4" value="waist_circumference">ค่ารอบเอว</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        {this.state.SetValueColor_description === 'bmi' ? <>
                                            <Col sm={{ span: 14, offset: 1 }} xs={{ span: 24 }} coll="true">
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div style={{ backgroundColor: '#FF67FF' }} className="Box-color-description"></div>
                                                    <div style={{ marginRight: '25px' }}>ต่ำกว่าเกณฑ์</div>
                                                    <div style={{ backgroundColor: '#92D050' }} className="Box-color-description"></div>
                                                    <div style={{ marginRight: '25px' }}>สมส่วน</div>
                                                    <div style={{ backgroundColor: '#FFFF02' }} className="Box-color-description"></div>
                                                    <div style={{ marginRight: '25px' }}>น้ำหนักเกิน</div>
                                                    <div style={{ backgroundColor: '#FFC001' }} className="Box-color-description"></div>
                                                    <div style={{ marginRight: '25px' }}>อ้วน</div>
                                                    <div style={{ backgroundColor: '#FF0000' }} className="Box-color-description"></div>
                                                    <div style={{ marginRight: '25px' }}>อ้วนอันตราย</div>
                                                </div>
                                            </Col>
                                        </> : null}
                                        {this.state.SetValueColor_description === 'blood_pressure' ? <>
                                            <Col sm={{ span: 14, offset: 1 }} xs={{ span: 24 }} coll="true">
                                                <div style={{ color: '#5C2D91' }}>กลุ่มที่ยังไม่ป่วยเป็นโรคความดันโลหิต</div>
                                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                                                    <div style={{ backgroundColor: '#92D050' }} className="Box-color-description"></div>
                                                    <div style={{ marginRight: '25px' }}>ปกติ</div>
                                                    <div style={{ backgroundColor: '#FFFF02' }} className="Box-color-description"></div>
                                                    <div style={{ marginRight: '25px' }}>เกือบสูง</div>
                                                    <div style={{ backgroundColor: '#FFC001' }} className="Box-color-description"></div>
                                                    <div style={{ marginRight: '25px' }}>สูง</div>
                                                    <div style={{ backgroundColor: '#FF0000' }} className="Box-color-description"></div>
                                                    <div style={{ marginRight: '25px' }}>อันตราย</div>
                                                </div>
                                            </Col>
                                            <Col sm={{ span: 14, offset: 5 }} xs={{ span: 24 }} coll="true">
                                                <div style={{ color: '#5C2D91' }}>กลุ่มที่ป่วยเป็นโรคความดันโลหิต</div>
                                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                                                    <div style={{ backgroundColor: '#FF67FF' }} className="Box-color-description"></div>
                                                    <div style={{ marginRight: '25px' }}>ต่ำกว่าเกณฑ์</div>
                                                    <div style={{ backgroundColor: '#92D050' }} className="Box-color-description"></div>
                                                    <div style={{ marginRight: '25px' }}>เหมาะสม</div>
                                                    <div style={{ backgroundColor: '#FFFF02' }} className="Box-color-description"></div>
                                                    <div style={{ marginRight: '25px' }}>สูงเล็กน้อย</div>
                                                    <div style={{ backgroundColor: '#FFC001' }} className="Box-color-description"></div>
                                                    <div style={{ marginRight: '25px' }}>สูงปานกลาง</div>
                                                    <div style={{ backgroundColor: '#FF0000' }} className="Box-color-description"></div>
                                                    <div style={{ marginRight: '25px' }}>สูงอันตราย</div>
                                                </div>
                                            </Col>
                                        </> : null}
                                        {this.state.SetValueColor_description === 'blood_sugar' ? <>
                                            <Col sm={{ span: 14, offset: 1 }} xs={{ span: 24 }} coll="true">
                                                <div style={{ color: '#5C2D91' }}>กลุ่มที่ยังไม่ป่วยเป็นโรคเบาหวาน</div>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <li style={{ color: '#5C2D91', marginRight: '30px' }}>ไม่งดอาหาร</li>
                                                    <div style={{ backgroundColor: '#92D050' }} className="Box-color-description"></div>
                                                    <div style={{ marginRight: '25px' }}>ปกติ</div>
                                                    <div style={{ backgroundColor: '#FFFF02' }} className="Box-color-description"></div>
                                                    <div style={{ marginRight: '25px' }}>เสี่ยงเบาหวาน</div>
                                                    <div style={{ backgroundColor: '#FFC001' }} className="Box-color-description"></div>
                                                    <div style={{ marginRight: '25px' }}>สงสัยป่วยเบาหวาน</div>
                                                </div>
                                            </Col>
                                            <Col sm={{ span: 14, offset: 5 }} xs={{ span: 24 }} coll="true">
                                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                                                    <li style={{ color: '#5C2D91', marginRight: '30px' }}>งดอาหารอย่างน้อย 8 ชั่วโมง</li>
                                                    <div style={{ backgroundColor: '#FF67FF' }} className="Box-color-description"></div>
                                                    <div style={{ marginRight: '25px' }}>ต่ำ</div>
                                                    <div style={{ backgroundColor: '#92D050' }} className="Box-color-description"></div>
                                                    <div style={{ marginRight: '25px' }}>ปกติ</div>
                                                    <div style={{ backgroundColor: '#FFFF02' }} className="Box-color-description"></div>
                                                    <div style={{ marginRight: '25px' }}>เสี่ยงเบาหวาน</div>
                                                    <div style={{ backgroundColor: '#FFC001' }} className="Box-color-description"></div>
                                                    <div style={{ marginRight: '25px' }}>สงสัยป่วยเบาหวาน</div>
                                                </div>
                                            </Col>
                                            <Col sm={{ span: 14, offset: 5 }} xs={{ span: 24 }} coll="true">
                                                <div style={{ color: '#5C2D91' }}>กลุ่มที่ป่วยเป็นโรคเบาหวาน</div>
                                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                                                    <div style={{ backgroundColor: '#690E8A' }} className="Box-color-description"></div>
                                                    <div style={{ marginRight: '25px' }}>ต่ำรุนแรง</div>
                                                    <div style={{ backgroundColor: '#92D050' }} className="Box-color-description"></div>
                                                    <div style={{ marginRight: '25px' }}>เหมาะสม</div>
                                                    <div style={{ backgroundColor: '#FFFF02' }} className="Box-color-description"></div>
                                                    <div style={{ marginRight: '25px' }}>สูงกว่าเป้าหมาย</div>
                                                    <div style={{ backgroundColor: '#FFC001' }} className="Box-color-description"></div>
                                                    <div style={{ marginRight: '25px' }}>สูงมาก</div>
                                                    <div style={{ backgroundColor: '#FF0000' }} className="Box-color-description"></div>
                                                    <div style={{ marginRight: '25px' }}>สูงอันตรายถึงชีวิต</div>
                                                </div>
                                            </Col>
                                        </> : null}
                                        {this.state.SetValueColor_description === 'waist_circumference' ? <>
                                            <Col sm={{ span: 14, offset: 1 }} xs={{ span: 24 }} coll="true">
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div style={{ backgroundColor: '#92D050' }} className="Box-color-description"></div>
                                                    <div style={{ marginRight: '25px' }}>เหมาะสม</div>
                                                    <div style={{ backgroundColor: '#FFFF02' }} className="Box-color-description"></div>
                                                    <div style={{ marginRight: '25px' }}>มีภาวะอ้วนลงพุง</div>
                                                </div>
                                            </Col>
                                        </> : null}
                                    </Row>
                                </> : null}
                                {this.state.DataGraph_Type === 'bmi' ? <>
                                    <Row>
                                        <Col sm={{ span: 20 }} xs={{ span: 24 }} coll="true">
                                            <Table
                                                columns={this.state.DataColumnsTable_BMI}
                                                dataSource={this.state.DataTable} />
                                        </Col>
                                    </Row>
                                    <div style={{ color: '#5C2D91' }}>คำอธิบายค่าสี</div>
                                    <Row>
                                        <Col sm={{ span: 20 }} xs={{ span: 24 }} coll="true">
                                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                                                <div style={{ backgroundColor: '#FF67FF' }} className="Box-color-description"></div>
                                                <div style={{ marginRight: '25px' }}>ต่ำกว่าเกณฑ์</div>
                                                <div style={{ backgroundColor: '#92D050' }} className="Box-color-description"></div>
                                                <div style={{ marginRight: '25px' }}>สมส่วน</div>
                                                <div style={{ backgroundColor: '#FFFF02' }} className="Box-color-description"></div>
                                                <div style={{ marginRight: '25px' }}>น้ำหนักเกิน</div>
                                                <div style={{ backgroundColor: '#FFC001' }} className="Box-color-description"></div>
                                                <div style={{ marginRight: '25px' }}>อ้วน</div>
                                                <div style={{ backgroundColor: '#FF0000' }} className="Box-color-description"></div>
                                                <div style={{ marginRight: '25px' }}>อ้วนอันตราย</div>
                                            </div>
                                        </Col>
                                    </Row>
                                </> : null}
                                {this.state.DataGraph_Type === 'blood_pressure' ? <>
                                    <Row>
                                        <Col sm={{ span: 20 }} xs={{ span: 24 }} coll="true">
                                            <div className="header-the-group-table" onClick={e => this.uninfecte_sick_group(e)}>
                                                <div className={this.state.SetData_uninfecte_sick_group === 'active' ? "the-sick-group-table" : "the-uninfected-group-table"}>กลุ่มที่ยังไม่ป่วย</div>
                                                <div className={this.state.SetData_uninfecte_sick_group === 'active' ? "the-uninfected-group-table" : "the-sick-group-table"}>กลุ่มป่วย</div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={{ span: 20 }} xs={{ span: 24 }} coll="true">
                                            <Table
                                                columns={this.state.DataColumnsTable_blood_pressure}
                                                dataSource={this.state.DataTable} />
                                        </Col>
                                    </Row>
                                    <div style={{ color: '#5C2D91' }}>คำอธิบายค่าสี</div>
                                    <Row>
                                        <Col sm={{ span: 20 }} xs={{ span: 24 }} coll="true">
                                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                                                {this.state.SetData_uninfecte_sick_group === 'active' ? <>
                                                    <div style={{ backgroundColor: '#FF67FF' }} className="Box-color-description"></div>
                                                    <div style={{ marginRight: '25px' }}>ต่ำกว่าเกณฑ์</div>
                                                    <div style={{ backgroundColor: '#92D050' }} className="Box-color-description"></div>
                                                    <div style={{ marginRight: '25px' }}>สมส่วน</div>
                                                    <div style={{ backgroundColor: '#FFFF02' }} className="Box-color-description"></div>
                                                    <div style={{ marginRight: '25px' }}>น้ำหนักเกิน</div>
                                                    <div style={{ backgroundColor: '#FFC001' }} className="Box-color-description"></div>
                                                    <div style={{ marginRight: '25px' }}>อ้วน</div>
                                                    <div style={{ backgroundColor: '#FF0000' }} className="Box-color-description"></div>
                                                    <div style={{ marginRight: '25px' }}>อ้วนอันตราย</div>
                                                </> : <>
                                                    <div style={{ backgroundColor: '#92D050' }} className="Box-color-description"></div>
                                                    <div style={{ marginRight: '25px' }}>ปกติ</div>
                                                    <div style={{ backgroundColor: '#FFFF02' }} className="Box-color-description"></div>
                                                    <div style={{ marginRight: '25px' }}>เกือบสูง</div>
                                                    <div style={{ backgroundColor: '#FFC001' }} className="Box-color-description"></div>
                                                    <div style={{ marginRight: '25px' }}>สูง</div>
                                                    <div style={{ backgroundColor: '#FF0000' }} className="Box-color-description"></div>
                                                    <div style={{ marginRight: '25px' }}>อันตราย</div>
                                                </>}
                                            </div>
                                        </Col>
                                    </Row>
                                </> : null}
                                {this.state.DataGraph_Type === 'blood_sugar' ? <>
                                    <Row>
                                        <Col sm={{ span: 20 }} xs={{ span: 24 }} coll="true">
                                            <div className="header-the-group-table" onClick={e => this.uninfecte_sick_group(e)}>
                                                <div className={this.state.SetData_uninfecte_sick_group === 'active' ? "the-sick-group-table" : "the-uninfected-group-table"}>กลุ่มที่ยังไม่ป่วย</div>
                                                <div className={this.state.SetData_uninfecte_sick_group === 'active' ? "the-uninfected-group-table" : "the-sick-group-table"}>กลุ่มป่วย</div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={{ span: 20 }} xs={{ span: 24 }} coll="true">
                                            <div className="container-background-header">
                                                <span>เลือกกลุ่มตาราง</span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={{ span: 6 }} xs={{ span: 24 }} coll="true">
                                            <Form.Item>
                                                <Select value={this.state.value_group_fasting} placeholder="เลือกกลุ่มตาราง" onChange={e => this.uninfecte_sick_group_fasting(e)}>
                                                    <Option value="not_fasting">{this.state.SetData_uninfecte_sick_group === 'active' ? 'หลังอาหาร  2 ชั่วโมง' : 'ไม่งดอาหาร'}</Option>
                                                    <Option value="fasting">งดอาหารอย่างน้อย 8 ชั่วโมง</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={{ span: 20 }} xs={{ span: 24 }} coll="true">
                                            <Table
                                                columns={this.state.DataColumnsTable_blood_sugar}
                                                dataSource={this.state.DataTable} />
                                        </Col>
                                    </Row>
                                    <div style={{ color: '#5C2D91' }}>คำอธิบายค่าสี</div>
                                    <Row>
                                        <Col sm={{ span: 20 }} xs={{ span: 24 }} coll="true">
                                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                                                {this.state.SetData_uninfecte_sick_group === 'active' ? <>
                                                    <div style={{ backgroundColor: '#690E8A' }} className="Box-color-description"></div>
                                                    <div style={{ marginRight: '25px' }}>ต่ำรุนแรง</div>
                                                    <div style={{ backgroundColor: '#92D050' }} className="Box-color-description"></div>
                                                    <div style={{ marginRight: '25px' }}>เหมาะสม</div>
                                                    <div style={{ backgroundColor: '#FFFF02' }} className="Box-color-description"></div>
                                                    <div style={{ marginRight: '25px' }}>สูงกว่าเป้าหมาย</div>
                                                    <div style={{ backgroundColor: '#FFC001' }} className="Box-color-description"></div>
                                                    <div style={{ marginRight: '25px' }}>สูงมาก</div>
                                                    <div style={{ backgroundColor: '#FF0000' }} className="Box-color-description"></div>
                                                    <div style={{ marginRight: '25px' }}>สูงอันตรายถึงชีวิต</div>
                                                </> : <>
                                                    {this.state.value_group_fasting === 'not_fasting' ? <>
                                                        <div style={{ backgroundColor: '#92D050' }} className="Box-color-description"></div>
                                                        <div style={{ marginRight: '25px' }}>ปกติ</div>
                                                        <div style={{ backgroundColor: '#FFFF02' }} className="Box-color-description"></div>
                                                        <div style={{ marginRight: '25px' }}>เสี่ยงเบาหวาน</div>
                                                        <div style={{ backgroundColor: '#FFC001' }} className="Box-color-description"></div>
                                                        <div style={{ marginRight: '25px' }}>สงสัยป่วยเบาหวาน</div>
                                                    </> : <>
                                                        <div style={{ backgroundColor: '#FF67FF' }} className="Box-color-description"></div>
                                                        <div style={{ marginRight: '25px' }}>ต่ำ</div>
                                                        <div style={{ backgroundColor: '#92D050' }} className="Box-color-description"></div>
                                                        <div style={{ marginRight: '25px' }}>ปกติ</div>
                                                        <div style={{ backgroundColor: '#FFFF02' }} className="Box-color-description"></div>
                                                        <div style={{ marginRight: '25px' }}>เสี่ยงเบาหวาน</div>
                                                        <div style={{ backgroundColor: '#FFC001' }} className="Box-color-description"></div>
                                                        <div style={{ marginRight: '25px' }}>สงสัยป่วยเบาหวาน</div>
                                                    </>}
                                                </>}
                                            </div>
                                        </Col>
                                    </Row>
                                </> : null}
                                {this.state.DataGraph_Type === 'waist_circumference' ? <>
                                    <Row>
                                        <Col sm={{ span: 20 }} xs={{ span: 24 }} coll="true">
                                            <Table
                                                columns={this.state.DataColumnsTable_waist}
                                                dataSource={this.state.DataTable} />
                                        </Col>
                                    </Row>
                                    <div style={{ color: '#5C2D91' }}>คำอธิบายค่าสี</div>
                                    <Row>
                                        <Col sm={{ span: 20 }} xs={{ span: 24 }} coll="true">
                                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                                                <div style={{ backgroundColor: '#92D050' }} className="Box-color-description"></div>
                                                <div style={{ marginRight: '25px' }}>เหมาะสม</div>
                                                <div style={{ backgroundColor: '#FFFF02' }} className="Box-color-description"></div>
                                                <div style={{ marginRight: '25px' }}>มีภาวะอ้วนลงพุง</div>
                                            </div>
                                        </Col>
                                    </Row>
                                </> : null}
                                <div>หมายเหตุ: หากสีไม่ตรง แสดงว่าเป็นค่าสีเดิมที่เคยบันทึกเข้ามา</div>
                            </> : null}

                        </Content>
                    </Layout>
                </Layout>
            </Spin >
        );
    }

    onclick_login() {
        this.props.history.push({
            pathname: '/login'
        });
    }

    render() {
        return (<>
            {this.state.Check_Authorization === true ? this.pcc() : null}
            {this.state.Check_Authorization === false ? this.onclick_login() : null}
        </>)
    }
}

export default withRouter(PCC);