import React, { useState } from "react";
import { Row, Col, Card, Form, Button, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import "yup-phone";
import axios from "axios";
import iconLogo from "./../icon/smartcity_login.svg";
import iconDoctor from "./../icon/iconDoctor.svg";
import iconFooterLogin from "./../icon/iconFooterLogin.svg";
import iconArrowLeftBackward from "./../icon/iconArrowLeftBackward.svg";
import registerIcon from './../icon/register-icon.svg'
import iconSuccess from "./../icon/iconSuccess.svg";
import "./Login_nux_dashboard.css";

function Login_nux_dashboard() {

  localStorage.removeItem('register-data')
  const [showLoginSuccess, setShowLoginSuccess] = useState(false);
  const [showSendEmailSuccess, setShowSendEmailSuccess] = useState(false);

  const handleLoginSuccessClose = () => setShowLoginSuccess(false);
  const handleLoginSuccess = () => setShowLoginSuccess(true);

  const handleSendEmailSuccessClose = () => {
    setShowSendEmailSuccess(false);
    setShowLoginSuccess(false);
  };

  const handleSendEmailSuccess = () => setShowSendEmailSuccess(true);

  let history = useHistory();
  const MovegoPageDetail = (params) => {
    const pushData = {
      pathname: "/Health_services",
      formpage: "Login_nux_dasboard",
      access_token: { params },
    };
    history.push(pushData);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("กรุณากรอกอีเมลล์")
        .email("กรุณากรอกเป็นอีเมลล์"),
      password: Yup.string().required("กรุณากรอกรหัสผ่าน"),
    }),
    onSubmit: (values) => {
      console.log(values);

      var data = JSON.stringify({
        email: values.email,
        password: values.password,
      });

      var config = {
        method: "post",
        url: "https://ncds.numedapp.com/api/dashboard/v2/h4u/Email/login",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));

          if (response.data.code === 200) {
            localStorage.setItem("Authorization", response.data.user_data.access_token)
            MovegoPageDetail(response.data.user_data.access_token);
            console.log(
              response.data.user_data.access_token,
              "access_token_login"
            );
          } else {
            alert(response['data']['message'])
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  });

  const ForgetPassword = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("กรุณากรอกอีเมลล์")
        .email("กรุณากรอกเป็นอีเมลล์"),
    }),
    onSubmit: (values) => {
      console.log(values);

      var data = JSON.stringify({
        email: values.email,
      });

      var config = {
        method: "post",
        url: "https://ncds.numedapp.com/api/dashboard/v2/h4u/Email/requestForgotPassword",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          if (
            response.data.message === "success" &&
            response.data.code === 200
          ) {
            handleSendEmailSuccess();
          } else if (
            response.data.message === "email is require" &&
            response.data.code === 400
          ) {
            alert("จำเป็นต้องใช้อีเมล");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  });

  const registerButton = () => {
    console.log('register button')

    const pushData = {
      pathname: "/register",
      formpage: "Login_nux_dasboard"
    };
    history.push(pushData);
  }

  return (
    <div>
      <div className="container-login-nux">
        <div className="header-login-nux">
          <div className="btn-header-page">
            <div className="btn-backward" onClick={() => {
              const pushData = {
                pathname: "/guest",
                formpage: "Login_nux_dasboard"
              };
              history.push(pushData);
            }}>
              <img src={iconArrowLeftBackward}></img>
              <div className="text-btn-backward">ย้อนกลับ</div>
            </div>
            {/* <div className='register-button' onClick={registerButton}>
              <img src={registerIcon}></img>
              <div className="button-text">สมัครสมาชิก</div>
            </div> */}
          </div>
        </div>
        <div className="body-login-nux">
          <form onSubmit={formik.handleSubmit}>
            <div className="card-login-left">
              <img src={iconLogo}></img>
              <div className="title-login-nux">เข้าสู่ระบบ</div>
              <Row>
                <Col className="col-login-nux">
                  <Form.Control
                    className="form-control-login"
                    placeholder="อีเมล"
                    name="email"
                    type="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  ></Form.Control>
                </Col>
                {formik.touched.email && formik.errors.email ? (
                  <p>{formik.errors.email}</p>
                ) : null}
              </Row>
              <Row>
                <Col className="col-login-nux">
                  <Form.Control
                    className="form-control-login"
                    placeholder="รหัสผ่าน"
                    type="password"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  ></Form.Control>
                </Col>
                {formik.touched.password && formik.errors.password ? (
                  <p>{formik.errors.password}</p>
                ) : null}
              </Row>
              <Row>
                <Col>
                  <p className="link-push-page">
                    <a
                      className="font-link-page "
                      onClick={() => handleLoginSuccess(true)}
                    >
                      ลืมรหัสผ่าน?
                    </a>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col className="col-login-nux">
                  <Button
                    className="btn-sumit-login"
                    // onClick={() => handleLoginSuccess(true)}
                    type="submit"
                  >
                    <div className="text-btn-login">เข้าสู่ระบบ</div>
                  </Button>
                </Col>
              </Row>
            </div>
          </form>

          <div className="card-login-right">
            <img src={iconDoctor}></img>
          </div>
        </div>
        <div className="footer-login-nux">
          <div className="">
            ระบบเพื่อการประมวลผลค่าสัญญาณชีพและการรับยาที่บ้านในกลุ่มงานโรคไม่ติดต่อเรื้อรัง
          </div>
          <div>ผ่านแอปพลิเคชัน H4U สมาร์ท อสม. และระบบในเครือข่าย</div>
          <div className="">
            <img src={iconFooterLogin}></img>
          </div>
        </div>

        <Modal
          size="lg"
          centered
          show={showLoginSuccess}
          onHide={handleLoginSuccessClose}
        >
          <form onSubmit={ForgetPassword.handleSubmit}>
            <Modal.Header
              closeButton
              style={{ marginLeft: "4.4rem", marginRight: "3rem" }}
            >
              <Modal.Title centered className="modal-title">
                <div className="text-title-center">กรุณากรอกอีเมล</div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body" style={{ textAlign: "left" }}>
              <Row>
                <Col>
                  <Form.Label>อีเมล</Form.Label>
                  <Form.Control
                    className="form-control-modal"
                    name="email"
                    type="email"
                    value={ForgetPassword.values.email}
                    onChange={ForgetPassword.handleChange}
                    onBlur={ForgetPassword.handleBlur}
                  ></Form.Control>
                </Col>
                {ForgetPassword.touched.email && ForgetPassword.errors.email ? (
                  <p>{ForgetPassword.errors.email}</p>
                ) : null}
              </Row>
            </Modal.Body>
            <Modal.Footer className="modal-footer">
              <button
                className="btn-modal-confirm"
                // onClick={handleSendEmailSuccess}
                type="submit"
              >
                <div className="font-submit">ขอรหัส</div>
              </button>
            </Modal.Footer>
          </form>
        </Modal>

        <Modal
          size="lg"
          centered
          show={showSendEmailSuccess}
          onHide={handleSendEmailSuccessClose}
        >
          <Modal.Header closeButton style={{ marginRight: "3rem" }}>
            <Modal.Title centered className="modal-title">
              <img src={iconSuccess}></img>
              <div className="text-title-center">ยืนยันการขอรหัสเรียบร้อย</div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-body">
            <Row>
              <Col style={{ padding: "7px" }}>
                เราได้ทำการส่งลิ้งค์สำหรับตั้งค่ารหัสผ่านไปที่อีเมล
              </Col>
            </Row>
            <Row>
              <Col style={{ color: "#6D1795" }}>
                {ForgetPassword.values.email}
              </Col>
            </Row>
            <Row>
              <Col style={{ padding: "7px" }}>
                กรุณาตรวจสอบและดำเนินการตามคำแนะนำ
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="modal-footer">
            <buton
              className="btn-modal-confirm"
              onClick={handleSendEmailSuccessClose}
            >
              <div className="font-submit">ปิด</div>
            </buton>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default Login_nux_dashboard;
