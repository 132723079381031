var axios = require('axios');



export async function getHomeMedicineList(filterType, status, id, year, quarter, month) {
      return new Promise(resolve => {
            let url = 'https://ncds.numedapp.com/api/dashboard/v2/h4u/thridparty/getHomeMedicineList'
            let body = {
                  filter_type: filterType, // all, health_area, province, district, sub_district, hospital
                  status: status // activated, pending
            }
            body[filterType + '_id'] = id

            if (year) {
                  body['year'] = year
                  body['quarter'] = quarter ? quarter : ''
                  body['month'] = month ? month : ''
            }
            let header = {
                  'Content-Type': 'application/json',
                  'Authorization': localStorage.getItem('Authorization')
            }
            axios.post(url, body, { headers: header }).then(res => {
                  resolve(res)
            }).catch(() => {
                  resolve(false)
            })
      })
}
export async function getPatinetHomeMedicine(id) {
      return new Promise(resolve => {
            let url = 'https://ncds.numedapp.com/api/dashboard/v2/h4u/thridparty/getPatinetHomeMedicine'
            let body = {
                  _id: id
            }
            let header = {
                  'Content-Type': 'application/json',
                  'Authorization': localStorage.getItem('Authorization')
            }
            axios.post(url, body, { headers: header }).then(res => {
                  resolve(res)
            }).catch(() => {
                  resolve(false)
            })
      })
}
export async function approvedPatientMidicine(id) {
      return new Promise(resolve => {
            let url = 'https://ncds.numedapp.com/api/dashboard/v2/h4u/thridparty/approvedPatientMedicine'
            let body = {
                  _id: id
            }
            let header = {
                  'Content-Type': 'application/json',
                  'Authorization': localStorage.getItem('Authorization')
            }
            axios.post(url, body, { headers: header }).then(res => {
                  resolve(res)
            }).catch(() => {
                  resolve(false)
            })
      })
}
export async function approvedPatientMedicineByNux(id, take_medicine) {
      return new Promise(resolve => {
            let url = 'https://ncds.numedapp.com/api/dashboard/v2/h4u/thridparty/approvedPatientMedicineByNux'
            let body = {
                  _id: id,
                  take_medicine: take_medicine
            }
            let header = {
                  'Content-Type': 'application/json',
                  'Authorization': localStorage.getItem('Authorization')
            }
            axios.post(url, body, { headers: header }).then(res => {
                  resolve(res)
            }).catch(() => {
                  resolve(false)
            })
      })
}
export async function getFilterAddress(type, id) {
      return new Promise(resolve => {
            let url = 'https://ncds.numedapp.com/api/dashboard/v2/h4u/getFilterAddress'
            let body = {
                  type: type,
                  id: id
            }
            let header = {
                  'Content-Type': 'application/json',
                  'Authorization': localStorage.getItem('Authorization')
            }
            axios.post(url, body, { headers: header }).then(res => {
                  resolve(res)
            }).catch(() => {
                  resolve(false)
            })
      })
}
export async function searchAffiliation(searchAffiliation, affiliationType) {
      return new Promise(resolve => {
            let url = 'https://ncds.numedapp.com/api/dashboard/v2/h4u/searchAffiliation'
            let body = {
                  searchAffiliation: searchAffiliation,
                  affiliation_type: affiliationType
            }
            let header = {
                  'Content-Type': 'application/json',
                  // 'Authorization': localStorage.getItem('Authorization')
            }
            axios.post(url, body, { headers: header }).then(res => {
                  resolve(res)
            }).catch(() => {
                  resolve(false)
            })
      })
}
export async function getUserData() {
      return new Promise(resolve => {
            let url = 'https://ncds.numedapp.com/api/dashboard/v2/h4u/OnesDiD/getUserData'
            let body = {}
            let header = {
                  'Content-Type': 'application/json',
                  'Authorization': localStorage.getItem('Authorization')
            }
            axios.post(url, body, { headers: header }).then(res => {
                  resolve(res)
            }).catch(() => {
                  resolve(false)
            })
      })
}
export async function register(data) {
      return new Promise(resolve => {
            let url = 'https://ncds.numedapp.com/api/dashboard/v2/h4u/Email/register/addAdmin'
            let body = data
            let header = {
                  'Content-Type': 'application/json'
            }
            axios.post(url, body, { headers: header }).then(res => {
                  resolve(res)
            }).catch(() => {
                  resolve(false)
            })
      })
}

export async function getAreaAll(data) {
      return new Promise(resolve => {
            let url = 'https://ncds.numedapp.com/api/dashboard/v2/h4u/getAreaAll'
            let body = data
            let header = {
                  'Content-Type': 'application/json'
            }
            axios.post(url, body, { headers: header }).then(res => {
                  resolve(res)
            }).catch(() => {
                  resolve(false)
            })
      })
}
export async function getSumUserTotal(type, id) {
      return new Promise(resolve => {
            let url = 'https://ncds.numedapp.com/api/dashboard/v2/h4u/getSumUserTotal'
            let body = { type, id }
            let header = {
                  'Content-Type': 'application/json'
            }
            axios.post(url, body, { headers: header }).then(res => {
                  resolve(res)
            }).catch(() => {
                  resolve(false)
            })
      })
}
export async function getDataByType(data) {
      return new Promise(resolve => {
            let url = 'https://ncds.numedapp.com/api/dashboard/v2/h4u/getDataByType'
            let body = data
            let header = {
                  'Content-Type': 'application/json'
            }
            axios.post(url, body, { headers: header }).then(res => {
                  resolve(res)
            }).catch(() => {
                  resolve(false)
            })
      })
}

export async function getSummaryNcds(data) {
      return new Promise(resolve => {
            let url = 'https://ncds.numedapp.com/api/dashboard/v2/h4u/getSummary/ncds'
            let body = data
            let header = {
                  'Content-Type': 'application/json'
            }
            axios.post(url, body, { headers: header }).then(res => {
                  resolve(res)
            }).catch(() => {
                  resolve(false)
            })
      })
}

export async function getUserAffiliationList(page, numberPerPage, search) {
      return new Promise(resolve => {
            let url = 'https://ncds.numedapp.com/api/dashboard/v2/admin/getUserAffiliationList'
            if (search) {
                  url += `?search=${search}`
            } else {
                  url += `?page=${page}&number_per_page=${numberPerPage}`
            }
            let header = {
                  'Content-Type': 'application/json',
                  'Authorization': localStorage.getItem('Authorization')
            }
            axios.get(url, { headers: header }).then(res => {
                  resolve(res)
            }).catch(() => {
                  resolve(false)
            })
      })
}
export async function adminApprovedUser(id) {
      return new Promise(resolve => {
            let url = 'https://ncds.numedapp.com/api/dashboard/v2/admin/adminApprovedUser'
            let body = {
                  id: id
            }
            let header = {
                  'Content-Type': 'application/json',
                  'Authorization': localStorage.getItem('Authorization')
            }
            axios.post(url, body, { headers: header }).then(res => {
                  resolve(res)
            }).catch(() => {
                  resolve(false)
            })
      })
}




export function getAge(date) {
      var dob = new Date(date)
      var today = new Date()
      var result = {
            years: 0,
            months: 0,
            days: 0,
            toString: ''
      };
      result.months =
            ((today.getFullYear() * 12) + (today.getMonth() + 1))
            - ((dob.getFullYear() * 12) + (dob.getMonth() + 1));
      if (0 > (result.days = today.getDate() - dob.getDate())) {
            var y = today.getFullYear(), m = today.getMonth();
            m = (--m < 0) ? 11 : m;
            result.days +=
                  [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][m]
                  + (((1 === m) && ((y % 4) === 0) && (((y % 100) > 0) || ((y % 400) === 0)))
                        ? 1 : 0);
            --result.months;
      }
      result.years = (result.months - (result.months % 12)) / 12;
      result.months = (result.months % 12);
      result.toString = (result.years ? result.years + ' ปี ' : '')
            + (result.months ? result.months + ' เดือน ' : '')
            + (result.days ? result.days + ' วัน' : '')
      return result;
}
export function checkPid(pid) {
      var total = 0;
      var iPID;
      var chk;
      var Validchk;
      iPID = pid.replace(/-/g, '');
      Validchk = iPID.substr(12, 1);
      var j = 0;
      var pidcut;
      for (var n = 0; n < 12; n++) {
            pidcut = parseInt(iPID.substr(j, 1));
            total = total + pidcut * (13 - n);
            j++;
      }
      chk = 11 - (total % 11);

      if (chk == 10) {
            chk = 0;
      } else if (chk == 11) {
            chk = 1;
      }
      return chk == Validchk;
}
export function createConferrenceRoom() {
      return new Promise(resolve => {
          let url = 'https://h4u-mhealth.moph.go.th/api/v1/microservice/conferrence/createConferrenceRoom'
          let body = {}
          let header = {
              'Content-Type': 'application/json',
              'Authorization': localStorage.getItem('Authorization'),
              'platform_type': 'ncds-dashboard'
          }
          axios.post(url, body, { headers: header }).then(res => {
              resolve(res)
          }).catch(() => {
              resolve(false)
          })
      })
  }
  export function shareLinkConferrence(meetingID, pid) {
      return new Promise(resolve => {
          let url = 'https://h4u-mhealth.moph.go.th/api/v1/microservice/conferrence/shareConferrence'
          let body = {
              'meetingID': meetingID,
              'platform': 'h4u',
              'pid': pid
          }
          let header = {
              'Content-Type': 'application/json',
              'Authorization': localStorage.getItem('Authorization'),
              'platform_type': 'ncds-dashboard'
          }
          axios.post(url, body, { headers: header }).then(res => {
              resolve(res)
          }).catch(() => {
              resolve(false)
          })
      })
  }