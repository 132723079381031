// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HealthStationMicroservice_content__syIRR {
padding-top: 40px;
}

.HealthStationMicroservice_selectTypeContainer__-J2Zv {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    grid-gap: 20px;
    padding-right: 10px;
}

.HealthStationMicroservice_button__kDQbH {
    
}
.HealthStationMicroservice_button__kDQbH:hover {
    cursor: pointer;
}
.HealthStationMicroservice_buttonActive__fKDSB {
    border-bottom: 2px solid #5c2d91;
}

`, "",{"version":3,"sources":["webpack://./src/HealthStationMicroservice/HealthStationMicroservice.module.css"],"names":[],"mappings":"AAAA;AACA,iBAAiB;AACjB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,cAAc;IACd,mBAAmB;AACvB;;AAEA;;AAEA;AACA;IACI,eAAe;AACnB;AACA;IACI,gCAAgC;AACpC","sourcesContent":[".content {\npadding-top: 40px;\n}\n\n.selectTypeContainer {\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n    grid-gap: 20px;\n    padding-right: 10px;\n}\n\n.button {\n    \n}\n.button:hover {\n    cursor: pointer;\n}\n.buttonActive {\n    border-bottom: 2px solid #5c2d91;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `HealthStationMicroservice_content__syIRR`,
	"selectTypeContainer": `HealthStationMicroservice_selectTypeContainer__-J2Zv`,
	"button": `HealthStationMicroservice_button__kDQbH`,
	"buttonActive": `HealthStationMicroservice_buttonActive__fKDSB`
};
export default ___CSS_LOADER_EXPORT___;
