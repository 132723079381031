import React, { Component } from 'react'
import styles from './RegisterAddress.module.css'
import {
    getFilterAddress,
    searchAffiliation,
    register
} from '../services/api'
import { Select, Spin } from 'antd'
import { CloseOutlined } from '@ant-design/icons';
const { Option } = Select

export default class RegisterAddress extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loadingSpin: false,
            showModal: false,

            affiliationType: '',
            healthAreaList: [],
            provinceList: [],
            districtList: [],

            healthAreaId: '',
            healthAreaName: '',
            provinceId: '',
            provinceName: '',
            districtId: '',
            districtName: '',
            subDistrictId: '',
            subDistrictName: '',
            hospitalId: '',
            hospitalName: '',

            searchText: '',

            modalList: [],
        }
    }

    componentDidMount() {

        if (localStorage.getItem('register-data')) {
            let registerData = JSON.parse(localStorage.getItem('register-data'))


            this.setState({
                affiliationType: registerData['affiliation_type']
            })


            this.getFilterAddressApi(registerData['affiliation_type'])
        }
    }

    async submitButton() {
        let data = JSON.parse(localStorage.getItem('register-data'))
        if (!this.state.healthAreaId) {
            alert('กรุณาเลือกข้อมูล')
            return
        }

        if (this.state.affiliationType === 'by_area') {
            data['affiliation_id'] = this.state.healthAreaId
        } else if (this.state.affiliationType === 'by_province') {
            data['affiliation_id'] = this.state.provinceId
        } else if (this.state.affiliationType === 'by_district') {
            data['affiliation_id'] = this.state.districtId
        } else if (this.state.affiliationType === 'by_hospital') {
            data['affiliation_id'] = this.state.hospitalId
        }

        let res = await register(data)
        if (res) {
            if (res['data']['code'] === 200) {
                alert('ลงทะเบียนสำเร็จ')
                this.props.history.push({
                    pathname: '/login',
                    query: ''
                });
            } else {
                alert(res['data']['message'])
            }
        } else {
            alert('ไม่ตอบสนอง')
        }
    }

    async getFilterAddressApi(type) {
        if (type === 'by_area') {
            this.setState({ loadingSpin: true })
            let res = await getFilterAddress('by_area', '')
            this.setState({ loadingSpin: false })
            console.log(res)

            if (res) {
                if (res['data']['code'] === 200) {
                    let healthAreaList = res['data']['results'].map(item => {
                        let value = {
                            id: item['health_area'],
                            name: item['health_area_name']
                        }
                        return value
                    })
                    healthAreaList.sort((a, b) => a.id - b.id);
                    this.setState({
                        healthAreaList: healthAreaList
                    })
                } else {
                    alert(res['data']['message'])
                }
            } else {
                alert('ไม่ตอบสนอง')
            }
        }
    }

    async getFilterAddressAfter(type, id) {
        this.setState({ loadingSpin: true })
        let res = await getFilterAddress(type, id)
        this.setState({ loadingSpin: false })

        if (res) {
            if (res['data']['code'] === 200) {
                console.log(res)
            } else {
                alert(res['data']['message'])
            }
        } else {
            alert('ไม่ตอบสนอง')
        }
    }


    healthAreaComponent() {
        return (
            <div className={styles.addressCard}>
                <div className={styles.titleCard}>เลือกเขตสุขภาพ</div>
                <div className={styles.selectContainer}>
                    <div className={styles.selectKey}>เขตสุขภาพที่</div>
                    <Select
                        value={this.state.healthAreaId}
                        className={styles.select}
                        onChange={(e) => { this.setState({ healthAreaId: e }) }}
                    >
                        <Option value=''>เลือกเขตสุขภาพ</Option>
                        {
                            this.state.healthAreaList.map(item => {
                                return <Option value={item['id']}>{item['name']}</Option>
                            })
                        }
                    </Select>
                </div>
                <div className={styles.submitButton} onClick={() => this.submitButton()}>ยืนยัน</div>
            </div>
        )
    }

    provinceComponent() {
        return (
            <div className={styles.addressCard}>
                <div className={styles.titleCard}>เลือกจังหวัด</div>
                <div className={styles.selectContainer}>
                    <div className={styles.selectKey}>เลือกจังหวัด</div>
                    <div className={styles.selectModal} onClick={() => { this.setState({ showModal: true }) }}>{this.state.provinceId ? this.state.provinceName : 'เลือกจังหวัด'}</div>

                    <div className={styles.detail}>
                        <div className={styles.detailKey}>เขตสุขภาพที่</div>
                        <div className={styles.detailValue}>{this.state.healthAreaId}</div>
                    </div>
                </div>
                <div className={styles.submitButton} onClick={() => this.submitButton()}>ยืนยัน</div>
            </div>
        )
    }
    districtComponent() {
        return (
            <div className={styles.addressCard}>
                <div className={styles.titleCard}>เลือกอำเภอ</div>
                <div className={styles.selectContainer}>
                    <div className={styles.selectKey}>เลือกอำเภอ</div>
                    <div className={styles.selectModal} onClick={() => { this.setState({ showModal: true }) }}>{this.state.districtId ? this.state.districtName : 'เลือกอำเภอ'}</div>

                    <div className={styles.detail}>
                        <div className={styles.detailKey}>จังหวัด</div>
                        <div className={styles.detailValue}>{this.state.provinceName}</div>
                    </div>
                    <div className={styles.detail}>
                        <div className={styles.detailKey}>อำเภอ</div>
                        <div className={styles.detailValue}>{this.state.districtName}</div>
                    </div>
                    <div className={styles.detail}>
                        <div className={styles.detailKey}>เขตสุขภาพที่</div>
                        <div className={styles.detailValue}>{this.state.healthAreaId}</div>
                    </div>
                </div>
                <div className={styles.submitButton} onClick={() => this.submitButton()}>ยืนยัน</div>
            </div>
        )
    }
    hospitalComponent() {
        return (
            <div className={styles.addressCard}>
                <div className={styles.titleCard}>ระบุข้อมูลหน่วยบริการ</div>
                <div className={styles.selectContainer}>
                    <div className={styles.selectKey}>ระบุข้อมูลหน่วยบริการ</div>
                    <div className={styles.selectModal} onClick={() => { this.setState({ showModal: true }) }}>{this.state.hospitalId ? this.state.hospitalName : 'เลือกโรงพยาบาล'}</div>

                    <div className={styles.detail}>
                        <div className={styles.detailKey}>จังหวัด</div>
                        <div className={styles.detailValue}>{this.state.provinceName}</div>
                    </div>
                    <div className={styles.detail}>
                        <div className={styles.detailKey}>อำเภอ</div>
                        <div className={styles.detailValue}>{this.state.districtName}</div>
                    </div>
                    <div className={styles.detail}>
                        <div className={styles.detailKey}>ตำบล</div>
                        <div className={styles.detailValue}>{this.state.subDistrictName}</div>
                    </div>
                    <div className={styles.detail}>
                        <div className={styles.detailKey}>เขตสุขภาพที่</div>
                        <div className={styles.detailValue}>{this.state.healthAreaId}</div>
                    </div>
                </div>
                <div className={styles.submitButton} onClick={() => this.submitButton()}>ยืนยัน</div>
            </div>
        )
    }


    async searchModal() {
        this.setState({ loadingSpin: true })
        let res = await searchAffiliation(this.state.searchText, this.state.affiliationType)
        this.setState({ loadingSpin: false })
        console.log(res)

        if (res) {
            if (res['data']['code'] === 200) {
                let list = res['data']['result'].map(item => {
                    if (this.state.affiliationType === 'by_province') {
                        item['id'] = item['province_id']
                        item['name'] = item['province_name']
                    } else if (this.state.affiliationType === 'by_district') {
                        item['id'] = item['district_id']
                        item['name'] = item['district_name']
                    } else if (this.state.affiliationType === 'by_hospital') {
                        item['id'] = item['hospital_id']
                        item['name'] = item['hospital_name']
                    }
                    return item
                })
                this.setState({ modalList: list })
            } else {
                this.setState({ modalList: [] })
            }
        } else {
            this.setState({ modalList: [] })
        }
    }

    modalSelect(item) {
        console.log(item)
        this.setState({
            showModal: false,
            healthAreaId: item['health_area_id'],
            healthAreaName: item['health_area_name'],
            provinceId: item['province_id'],
            provinceName: item['province_name'],
            districtId: item['district_id'],
            districtName: item['district_name'],
            subDistrictId: item['sub_district_id'],
            subDistrictName: item['sub_district_name'],
            hospitalId: item['hospital_id'],
            hospitalName: item['hospital_name']
        })


    }


    modalComponent() {
        return (
            <div className={styles.modal}>
                <div className={styles.modalCard}>
                    <div className={styles.closeModal}>
                        <CloseOutlined
                            style={{ fontSize: '30px' }}
                            onClick={() => { this.setState({ showModal: false }) }}
                        />
                    </div>

                    <div className={styles.titleModal}>
                        {this.state.affiliationType === 'by_province' ? 'ค้นหาจังหวัด' : null}
                        {this.state.affiliationType === 'by_district' ? 'ค้นหาอำเภอ' : null}
                        {this.state.affiliationType === 'by_hospital' ? 'ค้นหาโรงพยาบาล' : null}
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}>
                        <input
                            className={styles.searchInput}
                            styles={{ flex: 1 }}
                            placeholder='ค้นหาชื่อ'
                            value={this.state.searchText}
                            onChange={(e) => {
                                this.setState({ searchText: e.target.value })
                            }}
                        />
                        <div className={styles.searchButton} onClick={() => this.searchModal()}>ค้นหา</div>
                    </div>

                    <div className={styles.scrollModal}>
                        {
                            this.state.modalList.map(item => {
                                return (
                                    <div className={styles.itemModal} onClick={() => this.modalSelect(item)}>
                                        <div className={styles.nameItemModal}>{item['name']}</div>
                                        <div className={styles.idItemModal}>รหัส : {item['id']}</div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }






    backButton() {
        this.props.history.push({
            pathname: '/register-level',
            query: ''
        });
    }

    render() {
        return (
            <Spin className="example" tip="Loading..." spinning={this.state.loadingSpin}>
                <div>
                    <div className={styles.header}>
                        <div className={styles.backButton} onClick={() => this.backButton()}>
                            <img src={require('../icon/back-icon.svg').default}></img>
                            <span>ย้อนกลับ</span>
                        </div>
                    </div>

                    <div className={styles.content}>
                        <img className={styles.logo} src={require('../icon/logo-smart-city.svg').default}></img>

                        {this.state.affiliationType === 'by_area' ? this.healthAreaComponent() : null}
                        {this.state.affiliationType === 'by_province' ? this.provinceComponent() : null}
                        {this.state.affiliationType === 'by_district' ? this.districtComponent() : null}
                        {this.state.affiliationType === 'by_hospital' ? this.hospitalComponent() : null}

                        <div className={styles.footer}>
                            <div className={styles.footerText}>ระบบเพื่อการประมวลผลค่าสัญญาณชีพและการรับยาที่บ้านในกลุ่มงานโรคไม่ติดต่อเรื้อรัง ผ่านแอปพลิเคชัน H4U สมาร์ท อสม. และระบบในเครือข่าย</div>
                            <div className={styles.footerLogo}>
                                <img className={styles.logo} src={require('../icon/ict-icon.svg').default}></img>
                                <img className={styles.logo} src={require('../icon/nu-icon.svg').default}></img>
                            </div>
                        </div>

                    </div>
                </div>

                {this.state.showModal ? this.modalComponent() : null}
            </Spin>
        )
    }
}
