import React, { Component } from 'react'
import styles from './MedicineList.module.css'
import { Form, Row, Col, Select, Table, Spin } from 'antd';
import Medicine from '../medicine/Medicine';
import {
      getHomeMedicineList,
      getAge,
      getUserData,
      getFilterAddress,
      searchAffiliation,
      createConferrenceRoom,
      shareLinkConferrence
      
} from '../services/api'

import {
      VideoCameraFilled
} from '@ant-design/icons'

const { Option } = Select;
export default class MedicineList extends Component {

      constructor(props) {
            super(props)
            this.state = {
                  pageState: 'medicine_list',  // medicine_list , medicine
                  medicineId: '',
                  loadingSpin: false,

                  healthAreaId: '',
                  provinceId: '',
                  districtId: '',
                  subDistrictId: '',
                  hospitalId: '',

                  healthAreaList: [],
                  provinceList: [],
                  districtList: [],
                  subDistrictList: [],
                  hospitalList: [],

                  healthAreaName: '',
                  provinceName: '',
                  districtName: '',
                  subDistrictName: '',
                  hospitalName: '',

                  yearList: [],
                  quarterList: ['1', '2', '3', '4'],
                  monthList: [],

                  year: undefined,
                  quarter: '',
                  month: '',
                  refillType: 'pending', // pending, activated
                  dataTable: [],
                  columnsTable: [],


                  disableHealthArea: true,
                  disableProvince: true,
                  disableDistrict: true,
                  disableSubDistrict: true,
                  disableHospital: true,
                  disableYear: false,
                  disableQuarter: true,
                  disableMonth: true,

                  setValue: 'medicine_list', //ยัดค่า
                  searchId: '',
                  searchType: 'all',
                  affiliationType: ''
            }

            this.backToMedicineList = this.backToMedicineList.bind(this)
      }

      async componentDidMount() {
            this.getHealthAreaList()
            this.getYearList()

            // this.getHomeMedicineList()
            await this.getUserDataAPI()

      }

      async getUserDataAPI() {
            let res = await getUserData()
            if (res) {
                  if (res['data']['code'] === 200) {

                        let affiliationType = res['data']['user']['affiliation']['type']
                        let affiliationId = res['data']['user']['affiliation']['id']
                        this.setState({
                              affiliationType: affiliationType
                        })
                        if (affiliationType === 'by_area') {
                              this.setState({
                                    searchType: 'health_area',
                                    healthAreaId: affiliationId,
                                    healthAreaName: 'เขตสุขภาพที่ ' + affiliationId
                              }, () => {
                                    // this.searchAffiliationAPI('by_area', affiliationId)
                                    this.selectOnChange('health_area', affiliationId)
                              })
                        } else if (affiliationType === 'by_province') {
                              this.setState({
                                    searchType: 'province',
                                    provinceId: affiliationId
                              }, () => {
                                    this.searchAffiliationAPI('by_province', affiliationId)
                                    this.selectOnChange('province', affiliationId)
                              })
                        } else if (affiliationType === 'by_district') {
                              this.setState({
                                    searchType: 'district',
                                    districtId: affiliationId
                              }, () => {
                                    this.searchAffiliationAPI('by_district', affiliationId)
                                    this.selectOnChange('district', affiliationId)
                              })
                        } else if (affiliationType === 'by_hospital') {
                              this.setState({
                                    searchType: 'hospital',
                                    hospitalId: affiliationId
                              }, () => {
                                    this.searchAffiliationAPI('by_hospital', affiliationId)
                                    this.selectOnChange('hospital', affiliationId)
                              })
                        }
                  } else {
                        alert(res['data']['message_th'] || res['data']['message'])
                  }
            } else {
                  alert('ไม่ตอบสนอง')
            }
      }

      async searchAffiliationAPI(type, id) {
            let res = await searchAffiliation(id, type)
            if (res) {
                  if (res['data']['code'] === 200) {
                        let data = res['data']['result'][0]
                        if (type === 'by_area') {
                              this.setState({
                                    healthAreaName: data['health_area_name']
                              })
                        } else if (type === 'by_province') {
                              this.setState({
                                    healthAreaName: data['health_area_name'],
                                    provinceName: data['province_name']
                              })
                        } else if (type === 'by_district') {
                              this.setState({
                                    healthAreaName: data['health_area_name'],
                                    provinceName: data['province_name'],
                                    districtName: data['district_name']
                              })
                        } else if (type === 'by_sub_district') {
                              this.setState({
                                    healthAreaName: data['health_area_name'],
                                    provinceName: data['province_name'],
                                    districtName: data['district_name'],
                                    subDistrictName: data['sub_district_name']
                              })
                        }
                  } else {
                        alert(res['data']['message_th'] || res['data']['message'])
                  }
            }
      }

      selectOnChange(type, id) {
            if (type === 'health_area') {
                  this.setState({
                        healthAreaId: id,
                        provinceId: '',
                        districtId: '',
                        subDistrictId: '',
                        hospitalId: ''
                  }, async () => {
                        if (this.state.healthAreaId) {
                              await this.getFilterAddress('by_area', this.state.healthAreaId)
                              this.setState({
                                    searchType: 'health_area',
                                    searchId: this.state.healthAreaId
                              }, async () => {
                                    await this.getHomeMedicineList()
                              })
                        } else {
                              this.setState({
                                    searchType: 'all'
                              }, async () => {
                                    await this.getHomeMedicineList()
                              })
                        }
                  })
            } else if (type === 'province') {
                  this.setState({
                        provinceId: id,
                        districtId: '',
                        subDistrictId: '',
                        hospitalId: ''
                  }, async () => {
                        if (this.state.provinceId) {
                              await this.getFilterAddress('by_province', this.state.provinceId)
                              this.setState({
                                    searchType: 'province',
                                    searchId: this.state.provinceId
                              }, async () => {
                                    await this.getHomeMedicineList()
                              })
                        } else {
                              this.setState({
                                    searchType: 'health_area',
                                    searchId: this.state.healthAreaId
                              }, () => {
                                    this.getHomeMedicineList()
                              })
                        }
                  })
            } else if (type === 'district') {
                  this.setState({
                        districtId: id,
                        subDistrictId: '',
                        hospitalId: ''
                  }, async () => {
                        if (this.state.districtId) {
                              await this.getFilterAddress('by_district', this.state.districtId)
                              this.setState({
                                    searchType: 'district',
                                    searchId: this.state.districtId
                              }, async () => {
                                    await this.getHomeMedicineList()
                              })
                        } else {
                              this.setState({
                                    searchType: 'province',
                                    searchId: this.state.provinceId
                              }, async () => {
                                    await this.getHomeMedicineList()
                              })
                        }
                  })
            } else if (type === 'sub_district') {
                  this.setState({
                        subDistrictId: id,
                        hospitalId: ''
                  }, async () => {
                        if (this.state.subDistrictId) {
                              await this.getFilterAddress('by_sub_district', this.state.subDistrictId)
                              this.setState({
                                    searchType: 'sub_district',
                                    searchId: this.state.subDistrictId
                              }, async () => {
                                    await this.getHomeMedicineList()
                              })
                        } else {
                              this.setState({
                                    searchType: 'district',
                                    searchId: this.state.districtId
                              }, async () => {
                                    await this.getHomeMedicineList()
                              })
                        }
                  })
            } else if (type === 'hospital') {
                  this.setState({
                        hospitalId: id
                  }, () => {
                        if (this.state.hospitalId) {
                              this.setState({
                                    searchType: 'hospital',
                                    searchId: this.state.hospitalId
                              }, async () => {
                                    await this.getHomeMedicineList()
                              })
                        } else {
                              this.setState({
                                    searchType: 'sub_district',
                                    searchId: this.state.subDistrictId
                              }, async () => {
                                    await this.getHomeMedicineList()
                              })
                        }
                  })
            }
      }

      setAddressList(type, list) {
            console.log(type, list)
            if (type === 'province') {
                  let addressList = list.map(item => {
                        return {
                              id: item['province_id'],
                              name: item['province_name']
                        }
                  })
                  this.setState({
                        provinceList: addressList
                  })
            } else if (type === 'district') {
                  let addressList = list.map(item => {
                        return {
                              id: item['district_id'],
                              name: item['district_name']
                        }
                  })
                  this.setState({
                        districtList: addressList
                  })
            } else if (type === 'sub_district') {
                  let addressList = list.map(item => {
                        return {
                              id: item['sub_district_id'],
                              name: item['sub_district_name']
                        }
                  })
                  this.setState({
                        subDistrictList: addressList
                  })
            } else if (type === 'hospital') {
                  let addressList = list.map(item => {
                        return {
                              id: item['hospital_id'],
                              name: item['hospital_name']
                        }
                  })
                  this.setState({
                        hospitalList: addressList
                  })
            }
      }

      getHealthAreaList() {
            let healthAreaList = []
            for (let i = 1; i < 14; i += 1) {
                  healthAreaList.push({
                        id: String(i),
                        name: 'เขตสุขภาพที่ ' + i
                  })
            }
            this.setState({
                  healthAreaList: healthAreaList
            })
      }

      getYearList() {
            var moment = require('moment');
            console.log(moment().year())

            let year = moment().year()
            let yearList = []

            for (let i = 0; i < 10; i++) {
                  yearList.push(year - i)
            }

            this.setState({
                  yearList: yearList
            })

      }

      getQuarterList() {
            let quarterList = []
            for (let i = 1; i < 5; i += 1) {
                  quarterList.push({
                        id: String(i),
                        name: 'ไตรมาสที่ ' + i
                  })
            }
            this.setState({
                  quarterList: quarterList
            })
      }

      async backToMedicineList(p) {
            console.log('back back back')
            this.setState({
                  pageState: p
            })

            await this.getHomeMedicineList()
      }

      refillTypeButton(type) {

            this.setState({
                  refillType: type
            }, async () => {
                  await this.getHomeMedicineList()
            })

      }

      async getFilterAddress(type, id) {
            let res = await getFilterAddress(type, id)
            if (res) {
                  if (res['data']['code'] === 200) {
                        if (type === 'by_area') {
                              this.setAddressList('province', res['data']['results'])
                        } else if (type === 'by_province') {
                              this.setAddressList('district', res['data']['results'])
                        } else if (type === 'by_district') {
                              this.setAddressList('sub_district', res['data']['results'])
                        } else if (type === 'by_sub_district') {
                              this.setAddressList('hospital', res['data']['results'])
                        }
                  } else {
                        alert(res['data']['message_th'] || res['data']['message'])
                  }
            } else {
                  alert('ไม่ตอบสนอง')
            }
      }


      detailButton(item) {
            console.log(item)
            this.setState({
                  medicineId: item['_id'],
                  pageState: 'medicine'
            })

      }

      async getHomeMedicineList() {
            this.setState({ loadingSpin: true })
            let res = await getHomeMedicineList(this.state.searchType, this.state.refillType, this.state.searchId, this.state.year, this.state.quarter, this.state.month)
            this.setState({ loadingSpin: false })
            console.log(res)
            if (res) {
                  if (res['data']['code'] === 200) {
                        this.setDataTable(res['data']['medicine_sevice_list'])
                  } else {
                        alert(res['data']['message'] || res['data']['message'])
                  }
            }
      }

      setDataTable(dataList) {
            console.log(dataList)

            let dataTable = dataList.map(item => {
                  let data = {
                        name: `${item['information']['pname']} ${item['information']['fname']} ${item['information']['lname']}`,
                        age: getAge(item['information']['birth_day']).years,
                        pid: item['information']['pid'],
                        phone_number: item['information']['phone_number'],
                        bp: `${item['health_record']['blood_pressure']['systolic']}/${item['health_record']['blood_pressure']['diastolic']}`,
                        dtx: item['health_record']['blood_sugar']['blood_sugar'],
                        detail_button: item

                  }
                  return data
            })

            let columnsTable = [
                  {
                        title: <div style={{ height: 40, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>ชื่อ</div>,
                        dataIndex: 'name',
                        key: 'name',
                        align: 'center',
                        responsive: ["sm"],
                  },
                  {
                        title: <div style={{ height: 40, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>อายุ</div>,
                        dataIndex: 'age',
                        key: 'age',
                        align: 'center',
                        responsive: ["sm"],
                  },
                  {
                        title: <div style={{ height: 40, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>เลขประจำตัวประชาชน</div>,
                        dataIndex: 'pid',
                        key: 'pid',
                        align: 'center',
                        responsive: ["sm"],
                  },
                  {
                        title: <div style={{ height: 40, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>เบอร์โทร</div>,
                        dataIndex: 'phone_number',
                        key: 'phone_number',
                        align: 'center',
                        responsive: ["sm"],
                  },
                  {
                        title: <div style={{ height: 40, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>BP (ครั้งล่าสุด)</div>,
                        dataIndex: 'bp',
                        key: 'bp',
                        align: 'center',
                        responsive: ["sm"],
                  },
                  {
                        title: <div style={{ height: 40, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>DTX (ครั้งล่าสุด)</div>,
                        dataIndex: 'dtx',
                        key: 'dtx',
                        align: 'center',
                        responsive: ["sm"],
                  },
                  {
                        title: <div style={{ height: 40, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Timeline</div>,
                        dataIndex: 'detail_button',
                        key: 'detail_button',
                        align: 'center',
                        responsive: ["sm"],
                        render: item => {
                              return <div style={{ display: 'flex', justifyContent: 'center', padding: 5 }}>
                                    <div onClick={() => { this.detailButton(item) }} className={styles.detailButton}>เพิ่มเติม</div>
                              </div>

                        }
                  },
                  {
                        title: <div style={{ height: 40, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>ติดต่อ</div>,
                        dataIndex: 'pid',
                        key: 'detail_button',
                        align: 'center',
                        responsive: ["sm"],
                        render: item => {
                              return <div style={{ display: 'flex', justifyContent: 'center', padding: 5 }}>
                                    {/* <div onClick={() => { this.detailButton(item) }} className={styles.detailButton}>เพิ่มเติม</div> */}
                                    <VideoCameraFilled style={{ color: '#5C2D91' }} onClick={() => {
                                          // window.open('https://h4u-mhealth.moph.go.th/h4u-video-call/#/nux-dashboard-to-h4u?token=' + localStorage.getItem('Authorization') + '&pid=' + item, '_system')
                                          this.conferrenceButtonClick(item)
                                    }} />
                              </div>

                        }
                  }
            ]

            this.setState({
                  dataTable: dataTable,
                  columnsTable: columnsTable
            })

      }

      async conferrenceButtonClick(pid) {
            this.setState({
                  loadingSpin: true
              })
            let res = await createConferrenceRoom()

            if (res) {
                  if (res['data']['code'] == 200) {
                        let meetingID = res['data']['data']['meetingID']
                        let url = res['data']['data']['url']
                        this.shareLink(pid, meetingID, url)
                  } else {
                        alert(res['data']['message'])
                  }
            } else {
                  alert('ไม่ตอบสนอง')
            }
      }

      async shareLink(pid, meetingID, joinLink) {
            let res = await shareLinkConferrence(meetingID, pid)

            if (res) {
                  if (res['data']['code'] == 200) {
                        this.setState({
                              loadingSpin: false
                          })
                        window.open(joinLink, '_system')
                  } else {
                        alert(res['data']['message'])
                  }
            } else {
                  alert('ไม่ตอบสนอง')
            } 
      }



      handleChange = (type, value, select) => {
            if (type === 'year') {
                  console.log(value)
                  this.setState({
                        year: value.value,
                        disableQuarter: this.state.year ? false : true
                  })

            }
      }

      render() {
            const { Option } = Select;
            return (
                  <Spin className="example" tip="Loading..." spinning={this.state.loadingSpin}>

                        {this.state.pageState === 'medicine_list' ? <div className={styles.content}>
                              <div className={styles.titleBar}>เลือกข้อมูล</div>
                              <Row style={{ marginTop: 20 }}>
                                    <Col sm={{ span: 6 }} xs={{ span: 24 }} coll="true">
                                          <Form.Item>
                                                <Select
                                                      value={this.state.healthAreaName}
                                                      onChange={(id) => { this.selectOnChange('health_area', id) }}
                                                      placeholder="เขตสุขภาพ"
                                                      disabled={true}
                                                >
                                                      <Option value=''>เลือกเขตสุขภาพ</Option>
                                                      {this.state.healthAreaList.map(item => {
                                                            return <Option value={item['id']}>{item['name']}</Option>
                                                      })}
                                                </Select>
                                          </Form.Item>
                                    </Col>
                                    <Col sm={{ span: 6, offset: 1 }} xs={{ span: 24 }} coll="true">
                                          <Form.Item>
                                                <Select
                                                      value={['by_province', 'by_district', 'by_sub_district', 'by_hospital'].includes(this.state.affiliationType) ? this.state.provinceName : this.provinceId}
                                                      placeholder="จังหวัด"
                                                      onChange={(id) => { this.selectOnChange('province', id) }}
                                                      disabled={!this.state.healthAreaId || ['by_province', 'by_district', 'by_sub_district', 'by_hospital'].includes(this.state.affiliationType)}
                                                >
                                                      <Option value=''>เลือกจังหวัด</Option>
                                                      {this.state.provinceList.map(item => {
                                                            return <Option value={item['id']}>{item['name']}</Option>
                                                      })}
                                                </Select>
                                          </Form.Item>
                                    </Col>
                                    <Col sm={{ span: 6, offset: 1 }} xs={{ span: 24 }} coll="true">
                                          <Form.Item>
                                                <Select
                                                      value={['by_district', 'by_sub_district', 'by_hospital'].includes(this.state.affiliationType) ? this.state.districtName : this.state.districtId}
                                                      placeholder="อำเภอ"
                                                      onChange={(id) => { this.selectOnChange('district', id) }}
                                                      disabled={!this.state.provinceId || ['by_district', 'by_sub_district', 'by_hospital'].includes(this.state.affiliationType)}
                                                >
                                                      <Option value=''>เลือกอำเภอ</Option>
                                                      {this.state.districtList.map(item => {
                                                            return <Option value={item['id']}>{item['name']}</Option>
                                                      })}
                                                </Select>
                                          </Form.Item>
                                    </Col>
                              </Row>
                              <Row>
                                    <Col sm={{ span: 6 }} xs={{ span: 24 }} coll="true">
                                          <Form.Item>
                                                <Select
                                                      value={['by_sub_district', 'by_hospital'].includes(this.state.affiliationType) ? this.state.subDistrictName : this.state.subDistrictId}
                                                      placeholder="ตำบล"
                                                      onChange={(id) => { this.selectOnChange('sub_district', id) }}
                                                      disabled={!this.state.districtId || ['by_sub_district', 'by_hospital'].includes(this.state.affiliationType)}
                                                >
                                                      <Option value=''>เลือกตำบล</Option>
                                                      {this.state.subDistrictList.map(item => {
                                                            return <Option value={item['id']}>{item['name']}</Option>
                                                      })}
                                                </Select>
                                          </Form.Item>
                                    </Col>
                                    <Col sm={{ span: 6, offset: 1 }} xs={{ span: 24 }} coll="true">
                                          <Form.Item>
                                                <Select
                                                      value={['by_hospital'].includes(this.state.affiliationType) ? this.state.hospitalName : this.state.hospitalId}
                                                      placeholder="โรงพยาบาล"
                                                      onChange={(id) => { this.selectOnChange('hospital', id) }}
                                                      disabled={!this.state.subDistrictId || ['by_hospital'].includes(this.state.affiliationType)}
                                                >
                                                      <Option value=''>เลือกโรงพยาบาล</Option>
                                                      {this.state.hospitalList.map(item => {
                                                            return <Option value={item['id']}>{item['name']}</Option>
                                                      })}
                                                </Select>
                                          </Form.Item>
                                    </Col>
                              </Row>
                              {/* <div className={styles.titleBar}>เลือกช่วงเวลา</div> */}
                              {/* <Row style={{ marginTop: 20 }}>
                                    <Col sm={{ span: 6 }} xs={{ span: 24 }} coll="true">
                                          <Form.Item>
                                                <Select
                                                      value={this.state.year}
                                                      onChange={(value, select) => this.handleChange('year', value, select)}
                                                      placeholder="ปีงบประมาณ"
                                                      disabled={this.state.disableYear}

                                                >
                                                      {this.state.yearList.map((item) => {
                                                            return <>
                                                                  <Option value={item}>{item + 534}</Option>
                                                            </>
                                                      })}
                                                </Select>
                                          </Form.Item>
                                    </Col>
                                    <Col sm={{ span: 6, offset: 1 }} xs={{ span: 24 }} coll="true">
                                          <Form.Item>
                                                <Select
                                                      value={this.state.quarter}
                                                      placeholder="ไตรมาส"
                                                      onChange={(value, select) => this.handleChange('quarter', value, select)}
                                                      disabled={this.state.disableQuarter}

                                                >
                                                      {this.state.quarterList.map((item) => {
                                                            return <>
                                                                  <Option value={item}>{item}</Option>
                                                            </>
                                                      })}
                                                </Select>
                                          </Form.Item>
                                    </Col>
                                    <Col sm={{ span: 6, offset: 1 }} xs={{ span: 24 }} coll="true">
                                          <Form.Item>
                                                <Select
                                                      value={this.state.month}
                                                      placeholder="เดือน"
                                                      onChange={(value, select) => this.handleChange('month', value, select)}
                                                      disabled={this.state.disableMonth}

                                                // disabled={this.state.province === undefined ? true : false}
                                                >
                                                      <Option value=''>month</Option>
                                                      <Option value='1'>month</Option>
                                                </Select>
                                          </Form.Item>
                                    </Col>
                              </Row> */}

                              <div className={styles.refillButtonContainer}>
                                    <div onClick={() => { this.refillTypeButton('pending') }} className={`${styles.button} ${this.state.refillType === 'pending' ? styles.active : ''}`}>รอยืนยันคำขอ refill ยา</div>
                                    <div onClick={() => { this.refillTypeButton('activated') }} className={`${styles.button} ${this.state.refillType === 'activated' ? styles.active : ''}`}>ยืนยันการ refill ยาแล้ว</div>
                              </div>

                              <div className={styles.titleContainer}>
                                    <div className={styles.tableTitle}>ตารางแสดงรายชื่อผู้ป่วยรอรับยาที่บ้าน</div>
                                    {/* <div className={styles.exportExcelButton}>Export Excel</div> */}
                              </div>

                              <Table
                                    style={{ marginTop: 20 }}
                                    dataSource={this.state.dataTable}
                                    columns={this.state.columnsTable}
                              />

                        </div> : null}


                        {this.state.pageState === 'medicine' ? <Medicine id={this.state.medicineId} backToMedicineList={this.backToMedicineList}></Medicine> : null}

                  </Spin>
            )
      }
}
