import React, { Component } from 'react'
import styles from './UserManagement.module.css'
import { 
    Spin
 } from 'antd'
import {
    getUserAffiliationList,
    adminApprovedUser
} from '../services/api'
export default class UserManagement extends Component {

    constructor(props) {
        super(props)

        this.state = {
            loadingSpin: false,
            searchText: '',
            maxPage: 2,
            page: 1,
            dataTableList: []
        }
    }

    componentDidMount() {
        this.getUserAffiliationListApi()
    }

    async getUserAffiliationListApi() {
        if (!this.state.searchText) {
            this.setState({ loadingSpin: true })
        }

        let res = await getUserAffiliationList(this.state.page, 6, this.state.searchText)

        if (!this.state.searchText) {
            this.setState({ loadingSpin: false })
        }
        console.log(res)
        if (res) {
            if (res['data']['code'] == 200) {
                let maxPage = Math.ceil(res['data']['total'] / 6)
                this.setState({
                    dataTableList: res['data']['result'],
                    maxPage: maxPage
                })
            } else {
                alert(res['data']['message'])
            }
        } else {
            alert('ไม่ตอบสนอง')
        }
    }

    async adminApprovedUserApi(id) {
        this.setState({ loadingSpin: true })
        let res = await adminApprovedUser(id)
        this.setState({ loadingSpin: false })
        if (res) {
            if (res['data']['code'] == 200) {
                alert('สำเร็จ')
                this.getUserAffiliationListApi()
            } else {
                alert(res['data']['message'])
            }
        } else {
            alert('ไม่ตอบสนอง')
        }
    }

    searchInputOnChange(text) {
        this.setState({
            searchText: text
        }, () => {
            this.getUserAffiliationListApi()
        })
    }

    pageOnChange(type) {
        // type = back, forward
        if (type === 'forward') {
            if (this.state.page < this.state.maxPage) {
                this.setState({
                    page: this.state.page + 1
                }, () => {
                    this.getUserAffiliationListApi()
                })
            }
        } else if (type === 'back') {
            if (this.state.page > 1) {
                this.setState({
                    page: this.state.page - 1
                }, () => {
                    this.getUserAffiliationListApi()
                })
            }
        }
    }

    statusButtonOnClick(item) {
        let text = ''
        if (item['activate']) {
            text = 'ยกเลิกการอนุญาต ' + item['fname'] + ' ' + item['lname'] + ' ใช่หรือไม่'
        } else {
            text = 'ยันยันการอนุญาต ' + item['fname'] + ' ' + item['lname'] + ' ใช่หรือไม่'
        }
        if (window.confirm(text)) {
            this.adminApprovedUserApi(item['id'])
        }
    }
    


    tableComponent() {
        let dataList = this.state.dataTableList

        let loopCount = 5 - dataList.length
        for (let i = 0; i < loopCount; i += 1) {
            dataList.push(null)
        }


        return (
            <div className={styles.tableComponent}>
                <div className={styles.headTableRow}>
                    <div className={styles.itemHeaderTable} style={{ width: 100 }}>ลำดับ</div>
                    <div className={styles.itemHeaderTable} style={{ flex: 1 }}>ชื่อ - นามสกุล</div>
                    <div className={styles.itemHeaderTable} style={{ flex: 1 }}>อีเมล</div>
                    <div className={styles.itemHeaderTable} style={{ flex: 1 }}>เบอร์โทร</div>
                    <div className={styles.itemHeaderTable} style={{ flex: 1 }}>สังกัด</div>
                    <div className={styles.itemHeaderTable} style={{ width: 200 }}>จัดการผู้ใช้งาน</div>
                </div>
                {
                    dataList.map((item, index) => {
                        if (item !== null) {
                            return (
                                <div className={styles.tableRow}>
                                    <div className={styles.itemTable} style={{ width: 100, justifyContent: 'center' }}>{(this.state.page * 6) - 6 + index + 1}</div>
                                    <div className={styles.itemTable} style={{ flex: 1 }}><span>{item['fname']} {item['lname']}</span></div>
                                    <div className={styles.itemTable} style={{ flex: 1 }}><span>{item['email']}</span></div>
                                    <div className={styles.itemTable} style={{ flex: 1, justifyContent: 'center' }}>{item['moblie'] ? item['mobile'] : '-'}</div>
                                    <div className={styles.itemTable} style={{ flex: 1 }}>{item['affiliation']['name']}</div>
                                    <div className={styles.itemTable} style={{ width: 200, gridGap: 10, justifyContent: 'center' }}>
                                        <div className={`${styles.statusButton} ${styles.buttonOpacity} ${item['activate'] ? styles.buttonGreen : styles.buttonRed}`} onClick={() => { this.statusButtonOnClick(item) }}>{item['activate'] ? 'อนุญาตแล้ว' : 'ยังไม่ได้รับอนุญาต'}</div>
                                        {/* <div className={`${styles.detailButton} ${styles.buttonOpacity}`} onClick={() => { this.detailButtonOnClick(item) }}>เพิ่มเติม</div> */}
                                    </div>
                                </div>
                            )
                        } else {
                            return (
                                <div className={styles.tableRow}>
                                    <div className={styles.itemTable} style={{ width: 100 }}></div>
                                    <div className={styles.itemTable} style={{ flex: 1 }}></div>
                                    <div className={styles.itemTable} style={{ flex: 1 }}></div>
                                    <div className={styles.itemTable} style={{ flex: 1 }}></div>
                                    <div className={styles.itemTable} style={{ flex: 1 }}></div>
                                    <div className={styles.itemTable} style={{ width: 200, gridGap: 10 }}></div>
                                </div>
                            )
                        }

                    })
                }

            </div>
        )
    }


    render() {
        return (
            <Spin spinning={this.state.loadingSpin}>
                <div className={styles.content}>
                    <div className={styles.title}>จัดการผู้ใช้งาน</div>
                    <div className={styles.description}>(สำหรับแอดมิน) เพิ่ม แก้ไข อนุญาตผู้ใช้งานอื่น ๆ</div>

                    <div className={styles.titleBar}>รายชื่อผู้ใช้งานที่มีอยู่ในระบบ</div>

                    <div className={styles.searchContainer}>
                        <input
                            placeholder='ค้นหาชื่อ'
                            value={this.state.searchText}
                            onChange={(e) => this.searchInputOnChange(e.target.value)}
                        />
                        <img src={require('../icon/user-management/search-icon.svg').default} />
                    </div>
                    <div className={styles.xScroll}>
                        {this.tableComponent()}
                    </div>
                    <div className={styles.pagerTableContainer}>
                        <img className={styles.buttonOpacity} onClick={() => this.pageOnChange('back')} src={require('../icon/user-management/page-left.svg').default} />
                        <div className={styles.pagerBox}>{this.state.page}/{this.state.maxPage}</div>
                        <img className={styles.buttonOpacity} onClick={() => this.pageOnChange('forward')} src={require('../icon/user-management/page-right.svg').default} />
                    </div>
                </div>
            </Spin>
        )
    }
}
