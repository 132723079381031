import React, { Component } from 'react'
import styles from './RegisterLevel.module.css'

export default class RegisterLevel extends Component {

    constructor(props) {
        super(props)

    }


    backButton() {
        this.props.history.push({
            pathname: '/register',
            query: ''
        });
    }

    menuClick(type) {
        // set data to location storage
        let registerData = {} 
        if (localStorage.getItem('register-data')) {
            registerData = JSON.parse(localStorage.getItem('register-data'))
        }

        registerData['affiliation_type'] = type

        localStorage.setItem('register-data', JSON.stringify(registerData))

        this.props.history.push({
            pathname: '/register-address',
            query: ''
        });
    }

    render() {
        return (
            <div>
                <div className={styles.header}>
                    <div className={styles.backButton} onClick={() => this.backButton()}>
                        <img src={require('../icon/back-icon.svg').default}></img>
                        <span>ย้อนกลับ</span>
                    </div>
                </div>

                <div className={styles.content}>
                    <img className={styles.logo} src={require('../icon/logo-smart-city.svg').default}></img>
                    <div className={styles.title}>กรุณาเลือกระดับการเข้าใช้งาน</div>

                    <div className={styles.menuContainer}>
                        <img onClick={() => this.menuClick('by_area')} src={require('../icon/register/menu1.svg').default}></img>
                        <img onClick={() => this.menuClick('by_province')} src={require('../icon/register/menu2.svg').default}></img>
                        <img onClick={() => this.menuClick('by_district')} src={require('../icon/register/menu3.svg').default}></img>
                        <img onClick={() => this.menuClick('by_hospital')} src={require('../icon/register/menu4.svg').default}></img>
                    </div>

                    <div className={styles.footer}>
                        <div className={styles.footerText}>ระบบเพื่อการประมวลผลค่าสัญญาณชีพและการรับยาที่บ้านในกลุ่มงานโรคไม่ติดต่อเรื้อรัง ผ่านแอปพลิเคชัน H4U สมาร์ท อสม. และระบบในเครือข่าย</div>
                        <div className={styles.footerLogo}>
                            <img className={styles.logo} src={require('../icon/ict-icon.svg').default}></img>
                            <img className={styles.logo} src={require('../icon/nu-icon.svg').default}></img>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}
