import React, { Component } from 'react'
import styles from './HomeGraph.module.css'
import {
    Select,
    Spin
} from 'antd';
import {
    getFilterAddress,
    getAreaAll,
    getSummaryNcds,
    getSumUserTotal,
    getDataByType
} from '../services/api'
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import moment from 'moment';
import defaultMonthList from '../services/default-month-list.json'
const { Option } = Select;
export default class HomeGraph extends Component {

    constructor(props) {
        super(props)

        this.state = {
            summaryNoData: false,
            dataByTypeNoData: false,
            loadingSpin: false,
            healthAreaId: '',
            provinceId: '',
            districtId: '',
            subDistrictId: '',
            hospitalId: '',
            yearId: '',
            quarterId: '',
            monthId: '',
            chartId: '',

            dateType: 'by_year',
            summaryType: 'by_area',
            summaryId: '',

            h4uAmount: 0,

            healthAreaList: [],
            provinceList: [],
            districtList: [],
            subDistrictList: [],
            hospitalList: [],
            yearList: [],
            quarterList: [],
            monthList: [],
            chartList: [],

            bloodPressureAmount: 0,
            bloodSugarAmount: 0,
            bmiAmount: 0,
            waistAmount: 0,
            chart1DataList: [],
            chart2DataList: [],

            sugarNormalTimeId: '0',
            sugarSickTimeId: '2',
            sugarNomalTimeList: [
                {
                    id: '0',
                    name: 'ไม่งดอาหาร'
                },
                {
                    id: '8',
                    name: 'งดอาหาร 8 ชั่วโมง'
                }
            ],
            sugarSickTimeList: [
                {
                    id: '2',
                    name: 'งดอาหาร 2 ชั่วโมง'
                },
                {
                    id: '8',
                    name: 'งดอาหาร 8 ชั่วโมง'
                }
            ]

        }

        this.rootTotal = null;
        this.rootChart1 = null;
        this.rootChart2 = null;
    }


    componentDidMount() {
        this.getSummaryNcdsApi()

        // create health area list 
        let healthAreaList = []
        for (let i = 1; i < 14; i += 1) {
            let healthArea = {
                id: i,
                name: 'เขตสุขภาพที่ ' + i
            }
            healthAreaList.push(healthArea)
        }

        // create year list

        let currentYear = Number(moment().format('YYYY'))
        let yearList = []
        for (let i = -1; i < 10; i += 1) {
            let year = {
                id: currentYear - i,
                name: currentYear - i + 543
            }
            yearList.push(year)
        }

        // create quarter list
        let quarterList = []
        for (let i = 1; i < 5; i += 1) {
            let quarter = {
                id: i,
                name: 'ไตรมาสที่ ' + i
            }
            quarterList.push(quarter)
        }


        this.setState({
            healthAreaList: healthAreaList,
            yearList: yearList,
            quarterList: quarterList,
            chartList: [
                {
                    id: 'blood_pressure',
                    name: 'จำนวนการบันทึกค่าความดันโลหิตแยกตามความเสี่ยง'
                },
                {
                    id: 'blood_sugar',
                    name: 'จำนวนการบันทึกค่าน้ำตาลในเลือดแยกตามความเสี่ยง'
                },
                {
                    id: 'bmi',
                    name: 'จำนวนการบันทึกค่าดัชนีมวลกาย (BMI) แยกตามความเสี่ยง'
                },
                {
                    id: 'waist_circumference',
                    name: 'จำนวนการบันทึกค่ารอบเอว'
                }
            ]
        }, () => {
            this.onSelectChange('chart', 'blood_pressure')
        })

    }

    loginButton() {
        console.log('login button click')
        this.props.history.push({
            pathname: '/login',
            query: ''
        });
    }

    registerButton() {
        console.log('register button click')
        this.props.history.push({
            pathname: '/register',
            query: ''
        });
    }

    async getDataByTypeAll() {
        console.log(this.state)
        let objGetDataByType = {
            type: this.state.chartId,
            date_type: this.state.dateType,
            year: this.state.yearId ? Number(this.state.yearId) : '',
            quarter: this.state.quarterId ? Number(this.state.quarterId) : '',
            month: this.state.monthId ? Number(this.state.monthId) : '',
        }
        if (this.state.hospitalId) {
            objGetDataByType['hospital_id'] = String(this.state.hospitalId)
        } else if (this.state.subDistrictId) {
            objGetDataByType['sub_district_id'] = String(this.state.subDistrictId)
        } else if (this.state.districtId) {
            objGetDataByType['district_id'] = String(this.state.districtId)
        } else if (this.state.provinceId) {
            objGetDataByType['province_id'] = String(this.state.provinceId)
        } else if (this.state.healthAreaId) {
            objGetDataByType['area'] = String(this.state.healthAreaId)
        }

        if (this.state.chartId === 'blood_pressure') {
            objGetDataByType['platform_type'] = 'normal'
            await this.getDataByTypeApi(objGetDataByType)
            objGetDataByType['platform_type'] = 'sick'
            await this.getDataByTypeApi(objGetDataByType)
        } else if (this.state.chartId === 'blood_sugar') {
            objGetDataByType['platform_type'] = 'normal'
            objGetDataByType['time'] = '0'
            await this.getDataByTypeApi(objGetDataByType)

            objGetDataByType['platform_type'] = 'sick'
            objGetDataByType['time'] = '2'
            await this.getDataByTypeApi(objGetDataByType)
        } else {
            await this.getDataByTypeApi(objGetDataByType)
            this.setState({
                chart2DataList: []
            })
        }




    }

    async onSelectChange(type, event) {
        console.log(type)
        console.log(event)
        if (type === 'health_area') {
            this.setState({
                healthAreaId: event,
                h4uAmount: 0,
                provinceId: '',
                districtId: '',
                subDistrictId: '',
                hospitalId: '',
                summaryId: event,
                summaryType: 'by_area'
            }, () => {
                this.getFilterAddressApi('by_area', event)
                this.getSummaryNcdsApi()
                this.getDataByTypeAll()
            })
        } else if (type === 'province') {
            this.setState({
                provinceId: event,
                districtId: '',
                subDistrictId: '',
                hospitalId: '',
                summaryId: event,
                summaryType: 'by_province'
            }, () => {
                if (this.state.provinceId) {
                    this.getSumUserTotalApi('by_province', String(event))
                    this.getFilterAddressApi('by_province', event)
                    this.getSummaryNcdsApi()
                    this.getDataByTypeAll()
                } else {
                    this.getSumUserTotalApi('by_area', String(this.state.healthAreaId))
                    this.getDataByTypeAll()
                    this.setState({
                        h4uAmount: 0,
                        summaryId: this.healthAreaId,
                        summaryType: 'by_area'
                    }, () => {

                        this.getSummaryNcdsApi()
                    })
                }
            })
        } else if (type === 'district') {
            this.setState({
                districtId: event,
                subDistrictId: '',
                hospitalId: '',
                summaryId: event,
                summaryType: 'by_district'
            }, () => {
                if (this.state.districtId) {
                    this.getSumUserTotalApi('by_district', String(event))
                    this.getFilterAddressApi('by_district', event)
                    this.getSummaryNcdsApi()
                    this.getDataByTypeAll()
                } else {
                    this.getSumUserTotalApi('by_province', String(this.state.provinceId))
                    this.getDataByTypeAll()
                    this.setState({
                        summaryId: this.state.provinceId,
                        summaryType: 'by_province'
                    }, () => {
                        this.getSummaryNcdsApi()
                    })
                }
            })
        } else if (type === 'sub_district') {
            this.setState({
                subDistrictId: event,
                hospitalId: '',
                summaryId: event,
                summaryType: 'by_sub_district'
            }, () => {
                if (this.state.subDistrictId) {
                    this.getSumUserTotalApi('by_sub_district', String(event))
                    this.getFilterAddressApi('by_sub_district', event)
                    this.getSummaryNcdsApi()
                    this.getDataByTypeAll()
                } else {
                    this.getSumUserTotalApi('by_district', String(this.state.districtId))
                    this.getDataByTypeAll()
                    this.setState({
                        summaryId: this.state.districtId,
                        summaryType: 'by_district'
                    }, () => {
                        this.getSummaryNcdsApi()
                    })
                }

            })
        } else if (type === 'hospital') {
            this.setState({
                hospitalId: event,
                summaryId: event,
                summaryType: 'by_hospital'
            }, () => {
                if (this.state.hospitalId) {
                    this.getSumUserTotalApi('by_hospital', String(event))
                    this.getSummaryNcdsApi()
                    this.getDataByTypeAll()
                } else {
                    this.getSumUserTotalApi('by_sub_district', String(this.state.subDistrictList))
                    this.getDataByTypeAll()
                    this.setState({
                        summaryId: this.state.subDistrictId,
                        summaryType: 'by_sub_district'
                    }, () => {
                        this.getSummaryNcdsApi()
                    })
                }

            })
        } else if (type === 'year') {
            this.setState({
                yearId: event,
                dateType: 'by_year'
            }, () => {
                this.getDataByTypeAll()
                this.getSummaryNcdsApi()
            })
        } else if (type === 'quarter') {
            this.setState({
                quarterId: event,
                dateType: 'by_quarter'
            }, () => {
                this.getSummaryNcdsApi()
                this.getDataByTypeAll()
            })
            this.setMonthList(event)
        } else if (type === 'month') {
            this.setState({
                monthId: event,
                dateType: 'by_month'
            }, () => {
                this.getSummaryNcdsApi()
                this.getDataByTypeAll()
            })
        } else if (type === 'chart') {
            this.setState({
                chartId: event
            }, async () => {
                if (event) {
                    this.getDataByTypeAll()
                }
            })


        }
    }

    async selectTimeChange(patientType, event) {
        console.log(patientType, event)
        if (patientType === 'normal') {
            this.setState({
                sugarNormalTimeId: event
            }, async () => {
                let objGetDataByType = {
                    type: 'blood_sugar',
                    date_type: this.state.dateType,
                    platform_type: 'normal',
                    time: event,
                    year: this.state.yearId ? Number(this.state.yearId) : '',
                    quarter: this.state.quarterId ? Number(this.state.quarterId) : '',
                    month: this.state.monthId ? Number(this.state.monthId) : '',
                }
                if (this.state.hospitalId) {
                    objGetDataByType['hospital_id'] = String(this.state.hospitalId)
                } else if (this.state.subDistrictId) {
                    objGetDataByType['sub_district_id'] = String(this.state.subDistrictId)
                } else if (this.state.districtId) {
                    objGetDataByType['district_id'] = String(this.state.districtId)
                } else if (this.state.provinceId) {
                    objGetDataByType['province_id'] = String(this.state.provinceId)
                } else if (this.state.healthAreaId) {
                    objGetDataByType['area'] = String(this.state.healthAreaId)
                }
                await this.getDataByTypeApi(objGetDataByType)
            })

        } else if (patientType === 'sick') {
            this.setState({
                sugarSickTimeId: event
            }, async () => {
                let objGetDataByType = {
                    type: 'blood_sugar',
                    date_type: this.state.dateType,
                    platform_type: 'sick',
                    time: event,
                    year: this.state.yearId ? Number(this.state.yearId) : '',
                    quarter: this.state.quarterId ? Number(this.state.quarterId) : '',
                    month: this.state.monthId ? Number(this.state.monthId) : '',
                }
                if (this.state.hospitalId) {
                    objGetDataByType['hospital_id'] = String(this.state.hospitalId)
                } else if (this.state.subDistrictId) {
                    objGetDataByType['sub_district_id'] = String(this.state.subDistrictId)
                } else if (this.state.districtId) {
                    objGetDataByType['district_id'] = String(this.state.districtId)
                } else if (this.state.provinceId) {
                    objGetDataByType['province_id'] = String(this.state.provinceId)
                } else if (this.state.healthAreaId) {
                    objGetDataByType['area'] = String(this.state.healthAreaId)
                }
                await this.getDataByTypeApi(objGetDataByType)
            })
        }
    }

    async getFilterAddressApi(type, id) {
        this.setState({ loadingSpin: true })
        let res = await getFilterAddress(type, String(id))
        this.setState({ loadingSpin: false })
        if (res) {
            if (res['data']['code'] === 200) {
                console.log(res)
                this.setAddressList(type, res['data']['results'])

            } else {
                // alert(res['data']['message'])
            }
        } else {
            // alert('ไม่ตอบสนอง')
        }
    }

    async getSummaryNcdsApi() {
        let data = {
            type: this.state.summaryType,
            id: String(this.state.summaryId),
            date_type: this.state.dateType,
            year: this.state.yearId ? Number(this.state.yearId) : '',
            quarter: this.state.quarterId ? Number(this.state.quarterId) : '',
            month: this.state.monthId ? Number(this.state.monthId) : '',
        }
        this.setState({ loadingSpin: true })
        let res = await getSummaryNcds(data)
        this.setState({ loadingSpin: false })

        if (res) {
            if (res['data']['code'] === 200) {
                this.setState({
                    summaryNoData: false
                })
                let resultList = res['data']['result']


                this.setTotalChart(resultList.filter(item => {
                    return item['value'] && item['name_en'] && item['color'] && item['name_th']
                }))

                resultList.forEach(item => {
                    if (item['name_en'] === 'blood pressure') {
                        this.setState({ bloodPressureAmount: item['value'] })
                    } else if (item['name_en'] === 'blood sugar') {
                        this.setState({ bloodSugarAmount: item['value'] })
                    } else if (item['name_en'] === 'BMI') {
                        this.setState({ bmiAmount: item['value'] })
                    } else if (item['name_en'] === 'waist circumference') {
                        this.setState({ waistAmount: item['value'] })
                    }
                });



            } else {
                // alert(res['data']['message'])
                this.setState({
                    summaryNoData: true
                })
            }
        } else {
            // alert('ไม่ตอบสนอง')
        }
    }

    async getSumUserTotalApi(type, id) {
        this.setState({ loadingSpin: true })
        let res = await getSumUserTotal(type, id)
        this.setState({ loadingSpin: false })
        if (res) {
            if (res['data']['code'] === 200) {
                console.log(res)
                this.setState({
                    h4uAmount: res['data']['result']['sum']
                })

            } else {
                this.setState({
                    h4uAmount: 0
                })
                // alert(res['data']['message'])
            }
        } else {
            this.setState({
                h4uAmount: 0
            })
            // alert('ไม่ตอบสนอง')
        }
    }

    async getDataByTypeApi(data) {
        this.setState({ loadingSpin: true })
        let res = await getDataByType(data)
        this.setState({ loadingSpin: false })

        if (res) {
            if (res['data']['code'] === 200) {
                this.setState({
                    dataByTypeNoData: false
                })
                if (data['platform_type'] === 'normal' || !data['platform_type']) {
                    this.setState({
                        chart1DataList: res['data']['result']
                    }, () => {
                        this.setChart1()
                    })
                } else if (data['platform_type'] === 'sick') {
                    this.setState({
                        chart2DataList: res['data']['result']
                    }, () => {
                        this.setChart2()
                    })
                }
            } else {
                this.setState({
                    dataByTypeNoData: true
                })
                // alert(res['data']['message'])
            }
        } else {
            // alert('ไม่ตอบสนอง')
        }
    }

    setAddressList(type, list) {
        if (type === 'by_area') {
            let newList = list.map(item => {
                return {
                    id: item['province_id'],
                    name: item['province_name']
                }
            })
            this.setState({
                provinceList: newList
            })
        } else if (type === 'by_province') {
            let newList = list.map(item => {
                return {
                    id: item['district_id'],
                    name: item['district_name']
                }
            })
            this.setState({
                districtList: newList
            })
        } else if (type === 'by_district') {
            let newList = list.map(item => {
                return {
                    id: item['sub_district_id'],
                    name: item['sub_district_name']
                }
            })
            this.setState({
                subDistrictList: newList
            })
        } else if (type === 'by_sub_district') {
            let newList = list.map(item => {
                return {
                    id: item['hospital_id'],
                    name: item['hospital_name']
                }
            })
            this.setState({
                hospitalList: newList
            })
        }
    }

    setMonthList(quarter) {
        quarter = Number(quarter)
        let monthList = []

        if (quarter === 1) {
            monthList = defaultMonthList.filter(month => {
                return ['10', '11', '12'].includes(month['id'])
            })
        } else if (quarter === 2) {
            monthList = defaultMonthList.filter(month => {
                return ['01', '02', '03'].includes(month['id'])
            })
        } else if (quarter === 3) {
            monthList = defaultMonthList.filter(month => {
                return ['04', '05', '06'].includes(month['id'])
            })
        } else if (quarter === 4) {
            monthList = defaultMonthList.filter(month => {
                return ['07', '08', '09'].includes(month['id'])
            })
        }

        this.setState({
            monthList: monthList
        })
    }

    setTotalChart(dataList) {
        console.log(dataList)

        if (this.rootTotal) {
            this.rootTotal.dispose();
        }
        this.rootTotal = am5.Root.new("total_chart");
        this.rootTotal.setThemes([
            am5themes_Animated.new(this.rootTotal)
        ]);

        var chart = this.rootTotal.container.children.push(
            am5percent.PieChart.new(this.rootTotal, {
                endAngle: 270
            })
        );

        var series = chart.series.push(
            am5percent.PieSeries.new(this.rootTotal, {
                valueField: "value",
                categoryField: "name_th",
                endAngle: 270
            })
        );

        series.get("colors").set("colors", dataList.map(item => {
            return am5.color(item['color'])
        }));

        series.labels.template.setAll({
            text: "{value}"
        })

        series.states.create("hidden", {
            endAngle: -90
        });

        series.data.setAll(dataList);

        series.appear(1000, 100);
    }

    setChart1() {

        if (this.rootChart1) {
            this.rootChart1.dispose();
        }
        this.rootChart1 = am5.Root.new("ncds_chart1");
        this.rootChart1.setThemes([
            am5themes_Animated.new(this.rootChart1)
        ]);

        var chart = this.rootChart1.container.children.push(
            am5percent.PieChart.new(this.rootChart1, {
                endAngle: 270
            })
        );

        var series = chart.series.push(
            am5percent.PieSeries.new(this.rootChart1, {
                valueField: "value",
                categoryField: "name_th",
                endAngle: 270
            })
        );

        series.get("colors").set("colors", this.state.chart1DataList.map(item => {
            return am5.color(item['color'])
        }));

        series.labels.template.setAll({
            text: "{value}"
        })

        series.states.create("hidden", {
            endAngle: -90
        });

        series.data.setAll(this.state.chart1DataList);

        series.appear(1000, 100);
    }

    setChart2() {

        if (this.rootChart2) {
            this.rootChart2.dispose();
        }
        this.rootChart2 = am5.Root.new("ncds_chart2");
        this.rootChart2.setThemes([
            am5themes_Animated.new(this.rootChart2)
        ]);

        var chart = this.rootChart2.container.children.push(
            am5percent.PieChart.new(this.rootChart2, {
                endAngle: 270
            })
        );

        var series = chart.series.push(
            am5percent.PieSeries.new(this.rootChart2, {
                valueField: "value",
                categoryField: "name_th",
                endAngle: 270
            })
        );

        series.get("colors").set("colors", this.state.chart2DataList.map(item => {
            return am5.color(item['color'])
        }));

        series.labels.template.setAll({
            text: "{value}"
        })

        series.states.create("hidden", {
            endAngle: -90
        });

        series.data.setAll(this.state.chart2DataList);

        series.appear(1000, 100);
    }

    showNumber(number) {
        return Number(number).toLocaleString()
    }


    render() {
        return (
            <Spin className="example" tip="Loading..." spinning={this.state.loadingSpin}>
                <div>

                    <div className={styles.content}>
                        <div className={styles.selectTitle}>
                            <div className={styles.title}>เลือกข้อมูล</div>
                            <div className={styles.horizontalLine}></div>
                        </div>

                        <div className={styles.selectContainer}>

                            <Select
                                placeholder="เขตสุขภาพ"
                                disabled={false}
                                value={this.state.healthAreaId}
                                onChange={(e) => this.onSelectChange('health_area', e)}
                            >
                                <Option value=''>เขตสุขภาพทั้งหมด</Option>
                                {
                                    this.state.healthAreaList.map(item => {
                                        return <Option value={item.id}>{item.name}</Option>
                                    })
                                }

                            </Select>
                            <Select
                                placeholder="จังหวัด"
                                disabled={!this.state.healthAreaId}
                                value={this.state.provinceId}
                                onChange={(e) => this.onSelectChange('province', e)}
                            >
                                <Option value=''>จังหวัดทั้งหมด</Option>
                                {
                                    this.state.provinceList.map(item => {
                                        return <Option value={item.id}>{item.name}</Option>
                                    })
                                }
                            </Select>
                            <Select
                                placeholder="อำเภอ"
                                disabled={!this.state.provinceId}
                                value={this.state.districtId}
                                onChange={(e) => this.onSelectChange('district', e)}
                            >
                                <Option value=''>อำเภอทั้งหมด</Option>
                                {
                                    this.state.districtList.map(item => {
                                        return <Option value={item.id}>{item.name}</Option>
                                    })
                                }
                            </Select>
                            <Select
                                placeholder="ตำบล"
                                disabled={!this.state.districtId}
                                value={this.state.subDistrictId}
                                onChange={(e) => this.onSelectChange('sub_district', e)}
                            >
                                <Option value=''>ตำบลทั้งหมด</Option>
                                {
                                    this.state.subDistrictList.map(item => {
                                        return <Option value={item.id}>{item.name}</Option>
                                    })
                                }
                            </Select>
                            <Select
                                placeholder="หน่วยบริการ"
                                disabled={!this.state.subDistrictId}
                                value={this.state.hospitalId}
                                onChange={(e) => this.onSelectChange('hospital', e)}
                            >
                                <Option value=''>หน่วยบริการทั้งหมด</Option>
                                {
                                    this.state.hospitalList.map(item => {
                                        return <Option value={item.id}>{item.name}</Option>
                                    })
                                }
                            </Select>
                        </div>


                        <div className={styles.selectTitle}>
                            <div className={styles.title}>เลือกช่วงเวลา</div>
                            <div className={styles.horizontalLine}></div>
                        </div>

                        <div className={styles.selectContainer}>
                            <Select
                                placeholder="ปีงบประมาณ​"
                                disabled={false}
                                value={this.state.yearId}
                                onChange={(e) => this.onSelectChange('year', e)}
                            >
                                <Option value=''>เลือกปีงบประมาณ</Option>
                                {
                                    this.state.yearList.map(item => {
                                        return <Option value={item.id}>{item.name}</Option>
                                    })
                                }
                            </Select>
                            <Select
                                placeholder="ไตรมาส"
                                disabled={!this.state.yearId}
                                value={this.state.quarterId}
                                onChange={(e) => this.onSelectChange('quarter', e)}
                            >
                                <Option value=''>เลือกไตรมาส</Option>
                                {
                                    this.state.quarterList.map(item => {
                                        return <Option value={item.id}>{item.name}</Option>
                                    })
                                }
                            </Select>
                            <Select
                                placeholder="เดือน"
                                disabled={!this.state.quarterId}
                                value={this.state.monthId}
                                onChange={(e) => this.onSelectChange('month', e)}
                            >
                                <Option value=''>เลือกเดือน</Option>
                                {
                                    this.state.monthList.map(item => {
                                        return <Option value={item.id}>{item.name}</Option>
                                    })
                                }
                            </Select>
                        </div>

                        <div className={styles.horizontalLine} style={{ marginTop: 30 }}></div>

                        {
                            this.state.healthAreaId ?
                                <div className={styles.userTotal}>
                                    <div className={styles.text}>จำนวนผู้ใช้งานประชาชน</div>
                                    <div className={styles.total}>{this.state.h4uAmount ? this.showNumber(this.state.h4uAmount) : '0'}</div>
                                    <div className={styles.text}>คน</div>
                                </div>
                                : null
                        }



                        <div className={styles.totalChartContainer}>
                            <div className={styles.totalDetail}>
                                <div className={styles.titleContainer}>
                                    <img src={require('../icon/guest/title-total-icon.svg').default}></img>
                                    <div>จำนวนที่บันทึกเข้ามา</div>
                                </div>

                                <div className={styles.totalContainer}>
                                    <div className={styles.totalItem}>
                                        <img src={require('../icon/guest/bp-total-icon.svg').default}></img>
                                        <div className={styles.totalKey}>จำนวนการวัดความดันโลหิต</div>
                                        <div className={styles.totalValue}>{this.state.summaryNoData ? '0' : this.showNumber(this.state.bloodPressureAmount)} คน</div>
                                    </div>
                                    <div className={styles.totalItem}>
                                        <img src={require('../icon/guest/bs-total-icon.svg').default}></img>
                                        <div className={styles.totalKey}>จำนวนการวัดค่าน้ำตาลในเลือด</div>
                                        <div className={styles.totalValue}>{this.state.summaryNoData ? '0' : this.showNumber(this.state.bloodSugarAmount)} คน</div>
                                    </div>
                                    <div className={styles.totalItem}>
                                        <img src={require('../icon/guest/bmi-total-icon.svg').default}></img>
                                        <div className={styles.totalKey}>จำนวนการวัดค่าดัชนีมวลกาย</div>
                                        <div className={styles.totalValue}>{this.state.summaryNoData ? '0' : this.showNumber(this.state.bmiAmount)} คน</div>
                                    </div>
                                    <div className={styles.totalItem}>
                                        <img src={require('../icon/guest/waist-total-icon.svg').default}></img>
                                        <div className={styles.totalKey}>จำนวนการวัดค่ารอบเอว </div>
                                        <div className={styles.totalValue}>{this.state.summaryNoData ? '0' : this.showNumber(this.state.waistAmount)} คน</div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.totalChart} id='total_chart'></div>
                        </div>

                        <div className={styles.selectChartContainer}>
                            {/* <img src={require('../icon/guest/doctor-image.svg').default}></img> */}
                            <div className={styles.selectGraphContainer}>
                                <div className={styles.selectChartItem} style={{ marginTop: 100 }}>
                                    <div className={styles.key}>เลือกกราฟ</div>
                                    <Select
                                        style={{ flex: 1 }}
                                        placeholder="เลือกกราฟ"
                                        disabled={false}
                                        value={this.state.chartId}
                                        onChange={(e) => this.onSelectChange('chart', e)}
                                    >
                                        <Option value=''>เลือกกราฟ</Option>
                                        {
                                            this.state.chartList.map(item => {
                                                return <Option value={item.id}>{item.name}</Option>
                                            })
                                        }

                                    </Select>
                                </div>

                            </div>
                        </div>

                        {
                            this.state.chartId ?
                                <div>
                                    {/* <div className={styles.titleContainer} style={{ marginTop: 30 }}>
                                        <img src={require('../icon/guest/title-bs-icon.svg').default}></img>
                                        <div>จำนวนการบันทึกค่าน้ำตาลในเลือดแยกตามความเสี่ยง</div>
                                    </div> */}
                                    <div style={{ marginTop: 20, marginBottom: 20 }}></div>


                                    {


                                        this.state.chart1DataList.length > 0 ?

                                            <div>
                                                <div className={styles.chartPatientType}>กลุ่มคนที่ยังไม่ป่วย</div>
                                                {
                                                    this.state.chartId === 'blood_sugar' ?
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <div className={styles.key} style={{ marginRight: 10 }}>เลือกประเภท</div>
                                                            <Select
                                                                onChange={(e) => this.selectTimeChange('normal', e)}
                                                                value={this.state.sugarNormalTimeId}
                                                            >
                                                                {
                                                                    this.state.sugarNomalTimeList.map(item => {
                                                                        return (<Option value={item['id']}>{item['name']}</Option>)
                                                                    })
                                                                }

                                                            </Select>
                                                        </div>

                                                        : null
                                                }

                                                <div className={styles.ncdsChartContainer}>
                                                    <div className={styles.ncdsChart} id='ncds_chart1'></div>
                                                    <div className={styles.ncdsChartDetail}>
                                                        {
                                                            this.state.chart1DataList.map(item => {
                                                                return (
                                                                    <div className={styles.chartDetail}>
                                                                        <div
                                                                            className={styles.color}
                                                                            style={{
                                                                                background: item['color']
                                                                            }}
                                                                        ></div>
                                                                        <div className={styles.text}>{item['name_th']}</div>
                                                                    </div>
                                                                )
                                                            })

                                                        }

                                                    </div>
                                                </div>

                                            </div>

                                            : null
                                    }
                                    {

                                        this.state.chart2DataList.length > 0 ?

                                            <div>
                                                <div className={styles.chartPatientType}>กลุ่มคนป่วย</div>
                                                {
                                                    this.state.chartId === 'blood_sugar' ?
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <div style={{ marginRight: 10 }}>เลือกประเภท</div>
                                                            <Select
                                                                onChange={(e) => this.selectTimeChange('sick', e)}
                                                                value={this.state.sugarSickTimeId}
                                                            >
                                                                {
                                                                    this.state.sugarSickTimeList.map(item => {
                                                                        return (<Option value={item['id']}>{item['name']}</Option>)
                                                                    })
                                                                }

                                                            </Select>
                                                        </div>

                                                        : null
                                                }

                                                <div className={styles.ncdsChartContainer}>
                                                    <div className={styles.ncdsChart} id='ncds_chart2'></div>
                                                    <div className={styles.ncdsChartDetail}>
                                                        {
                                                            this.state.chart2DataList.map(item => {
                                                                return (
                                                                    <div className={styles.chartDetail}>
                                                                        <div
                                                                            className={styles.color}
                                                                            style={{
                                                                                background: item['color']
                                                                            }}
                                                                        ></div>
                                                                        <div className={styles.text}>{item['name_th']}</div>
                                                                    </div>
                                                                )
                                                            })

                                                        }

                                                    </div>
                                                </div>

                                            </div>

                                            : null
                                    }
                                </div>
                                : null

                        }





                    </div>
                </div>
            </Spin>
        )
    }
}
